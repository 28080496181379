import {YoContextEditor} from "@components/YoContextEditor";
import {useState} from "react";
import {InputField, PasswordInputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Alert, Anchor, Button, Container, Paper, Title} from "@mantine/core";
import classes from './style.module.css';
import {Link} from "wouter";
import ky from "ky";

export function RegisterPage() {
	const [state, setState] = useState<Partial<{loading: boolean, code: string, registered: boolean, err: any}>>({});
	const [data, setData] = useState(() => {
		const data = {} as any;
		const sp = new URLSearchParams(window.location.search);
		if(sp.has('inviteCode')) data.inviteCode = sp.get('inviteCode');
		return data;
	});
	const [changes, setChanges] = useState({});

	async function tryRegister() {
		setState({loading: true, code: ''});
		try {
			const result = await ky.put('/api/register', {json: data}).json<{status: boolean, code: string, err: any}>();
			if(!result.status) {
				setState({loading: false, code: result.code, err: result.err});
			} else {
				setState({loading: false, code: '', registered: true});
				setTimeout(() => {
					window.location.replace('/');
				}, 2500);
			}
		} catch (e) {
			setState({loading: false, code: "UNKNOWN_ERROR", err: e});
		}
	}

	return (
		<Container size={420} my={40} c={'yellow'}>
			<Title ta="center" className={classes.title}>
				Welcome to 7k
			</Title>
			<Paper withBorder shadow="md" p={30} mt={30} radius="md">
				{state.code ? (
					<Alert color={'red'}>
						{state.code === 'INVALID_PARAMS' ? (
							<>
								Please check your data and try again
								<ul>
									<li>
										{state.err.path.replace("/",'')}: {state.err.message}
									</li>
								</ul>
							</>
						) : state.code === 'INVALID_INVITE' ? (
							<>
								Invalid invite code
							</>
						): state.code === 'USER_EXISTS' ? (
							<>
								User already exists
								<br/>
								<Link href={'~/'}><Button size={'compact-sm'}>Log in</Button></Link>
							</>
						): (
							<>
								Unknown error
								<br/>
								<pre>{JSON.stringify(state,null,4)}</pre>
							</>
						)}
					</Alert>
				): null}
				{state.registered ? (
					<Alert title={'Success!'} color={'green'}>
						Please wait one moment...
					</Alert>
				): (
					<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges} bypassProps={{readOnly: state.loading}}>
						<InputField field={'inviteCode'} label={'Invitation code'} />
						<InputField field={'username'} label={'Login'} />
						<InputField field={'email'} label={'Email'} type={'email'} />
						<PasswordInputField field={'password'} label={'password'} />

						<Button fullWidth mt="xl" disabled={state.loading} onClick={tryRegister}>
							Sign up
						</Button>
						<br/>
						<Anchor component={Link} href={'~/'} c="dimmed" size="xs">
							Already have an account? Login
						</Anchor>
					</YoContextEditor>
				)}

			</Paper>
		</Container>
	)
}
