import {useState} from "react";
import {useDebounceEffect} from "@hooks/useDebouncedEffect.ts";
import useTeams from "../../Pages/Teams/Context/useTeams.ts";

export function usePromise<T = any>(fn: () => Promise<T>, deps: Array<string | boolean | number | null> = [], props?: {delay?: number}) {
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(false);
	const [errData, setErrData] = useState<Error | null>(null);
	const [data, setData] = useState<T>();

	function run() {
		setLoading(true);
		setError(false);
		fn().then(res => {
			setData(res);
		}).catch((err: Error) => {
			setError(true);
			setErrData(err);
		}).finally(() => setLoading(false));
	}

	useDebounceEffect(() => {
		run();
	},  props?.delay ?? 0 , deps);

	return {isLoading, isError, errData, data, run, setData};
}

export function usePromiseWithTeamDependency<T = any>(fn: () => Promise<T>, deps: Array<string | boolean | number> = [], props?: {delay?: number}) {
	const {activeTeam} = useTeams();

	return usePromise(fn, [activeTeam!, ...deps], props);
}
