import PQueue from "p-queue";

const queue = new PQueue({concurrency: 1});

export function getStatsQueue() {
	return queue;
}

export function addStatsJob<T>(job: () => Promise<T>): Promise<T> {
	return queue.add<T>(job) as Promise<T>;
}
