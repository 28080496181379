import {Badge, Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {AdminUsersApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {PasswordField} from "@components/YoContextEditor/components/passwordField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

const api = new AdminUsersApiClient()

interface IItem {
	_id: string;
}

export function ListAdminUsers() {
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.AdminUsers.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: '_id',
		header: 'Идентификатор',
		enableSorting: false,
		enableColumnActions: false,
	},{
		accessorKey: 'username',
		header: 'Логин',
	},{
		accessorKey: 'isSuperAdmin',
		header: 'Тип пользователя',
		Cell: props => props.cell.getValue<boolean>() ? <Badge color={'green'}>СуперАдмин</Badge>: <Badge color={'blue'}>Пользователь</Badge>
	},{
		accessorKey: 'actions',
		header: 'Действия',
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Button component={Link} variant="primary" href={`/v/${props.row.original._id}`} size={'compact-sm'}>Редактировать</Button>
			</>
		)
	}], []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const data = await yoFormModal({
			title: 'Новый пользователь',
			required: ['username', 'password'],
			body: (
				<>
					<InputField
						required
						field={'username'}
						label={'Имя пользователя'}
						description={'Должно быть уникальное'}
					/>
					<PasswordField required field={'password'} label={'Пароль'}/>
				</>
			)
		})
		return api.createItem(data)
			.then(res => navigate(`/v/${res.item._id}`))
			.catch(displayErrorNotification)
	}

	return <>
		<Header
			title={'AdminUsers'}
			items={(
				<>
					<Link href={'/invites'}>
						<Button color={'green'} size={'compact-sm'}>
							Invites links
						</Button>
					</Link>
					<Button variant="primary" size={'compact-sm'} onClick={tryCreate}>Новая запись</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
