import {Route} from "wouter";
import {ListWhitePagesTemplates} from "./listWhitePagesTemplates.tsx";
import {ViewWhitePagesTemplates} from "./viewWhitePagesTemplates.tsx";


export function WhitePagesTemplatesRouter(props: { basePath: string }) {
	return (
		<Route path={props.basePath} nest>
			<Route path={'/'} component={ListWhitePagesTemplates}/>
			<Route path={'/v/:id'} component={ViewWhitePagesTemplates}/>
		</Route>
	);
}
