import {Button, Card, Divider, Group, Modal, Space, Table, Text, ThemeIcon, Title} from '@mantine/core';
import {useEffect, useState} from 'react';
import DepositModal from './DepositModal';
import {PaymentsApiClient} from "./api";
import type {RawDocumentTypePayments} from "@shared/models";
import useTeams from "../../Teams/Context/useTeams.ts";
import {IconCalendarTime, IconCheck, IconCreditCardPay, IconX} from "@tabler/icons-react";
import {formatDate} from "date-fns/format";
import AwaitingPayment from "./Components/AwaitingPayment.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {openConfirmModal} from "@mantine/modals";

const api = new PaymentsApiClient();

function RenderStatus ({status}: { status: string }) {

	switch (status) {
		case 'canceled': return (
			<Group gap="xs" align="center">
				<ThemeIcon variant="transparent" c="red">
					<IconX />
				</ThemeIcon>
				<Text c="red">
					Canceled
				</Text>
			</Group>
		)
		case 'error': return (
			<Group gap="xs" align="center">
				<ThemeIcon variant="transparent" c="red">
					<IconX />
				</ThemeIcon>
				<Text c="red">
					Error
				</Text>
			</Group>
		)
		case 'pending': return (
			<Group gap="xs" align="center">
				<ThemeIcon variant="transparent" c="yellow">
					<IconCalendarTime />
				</ThemeIcon>
				<Text c="yellow">
					Pending
				</Text>
			</Group>
		)
		case 'completed': return (
			<Group gap="xs" align="center">
				<ThemeIcon variant="transparent" c="green">
					<IconCheck />
				</ThemeIcon>
				<Text c="green">
					Completed
				</Text>
			</Group>
		)
	}
}

function RenderActions ({ payment, loadPayments }: { payment: RawDocumentTypePayments, loadPayments: () => Promise<void> }) {
	const [awaitingPaymentModalOpened, setAwaitingPaymentModalOpened] = useState(false);

	function tryCancel () {
		openConfirmModal({
			title: 'Are you sure you want to cancel payment?',
			labels: {
				confirm: 'Yes, cancel',
				cancel: 'No',
			},
			onConfirm: async () => {
				const data = await api.cancelPayment(payment._id);
				if (!data.status) {
					void displayErrorNotification(data.message);
				}
				await loadPayments()
			}
		})
	}

	switch (payment.status) {
		case 'pending': return (
			<Group>
				<Modal
					title="Make deposit"
					opened={awaitingPaymentModalOpened}
					onClose={() => setAwaitingPaymentModalOpened(false)}
					size="xl"
				>
					<AwaitingPayment
						id={payment._id}
						paymentData={payment.shkeeperInvoiceData as any}
						onSuccess={() => {
							void loadPayments()
						}}
						onFailure={(message) => {
							void displayErrorNotification(message);
							void loadPayments()
						}}
					/>
				</Modal>
				<Button size="compact-sm" leftSection={<IconCreditCardPay />} variant="secondary" onClick={() => setAwaitingPaymentModalOpened(true)}>
					Pay
				</Button>
				<Button
					size="compact-sm"
					color="red"
					leftSection={<IconX/>}
					onClick={() => {
						tryCancel()
					}}
				>
					Cancel
				</Button>
			</Group>
		);
		case 'completed': return (
			<Group>
				{/*<Button size="compact-sm" leftSection={<IconEye />} variant="secondary">
					view
				</Button>*/}
			</Group>
		)
	}
}

function PagePayments() {
	const { activeTeam, myTeams } = useTeams();
	const team = myTeams.find(e => e._id === activeTeam)!;
	const [list, setList] = useState<RawDocumentTypePayments[]>([]);
	const [opened, setOpened] = useState(false);

	const loadPayments = async () => {
		const data = await api.fetchList({ sort: '-createdAt' });
		if (data.status) {
			setList((data.items || []));
		}
	};

	useEffect(() => {
		const loadPayments = async () => {
			const data = await api.fetchList({ sort: '-createdAt' });
			if (data.status) {
				setList((data.items || []));
			}
		};
		void loadPayments();
	}, [activeTeam]);

	return (
		<Card shadow="md">
			<DepositModal
				opened={opened}
				setOpened={setOpened}
				loadPayments={loadPayments}
			/>
			<Group justify="space-between">
				<Title order={2}>
					{`Balance: ${Number(team.balance || 0).toFixed(2)}$`}
				</Title>
				<Button
					onClick={() => {
						setOpened(true);
					}}
					size="md"
				>
					Deposit
				</Button>
			</Group>
			<Space h="md" />
			<Divider />
			<Space h="md" />
			{
				list.length > 0 && (
					<Table>
						<Table.Thead>
							<Table.Tr>
								<Table.Th>date</Table.Th>
								<Table.Th>price</Table.Th>
								<Table.Th>status</Table.Th>
								<Table.Th>actions</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{
								list.map((payment) => (
									<Table.Tr key={payment._id}>
										<Table.Td>{payment.createdAt ? formatDate(payment.createdAt, 'yyyy.MM.dd HH:mm') : '-'}</Table.Td>
										<Table.Td>
											{`${payment.amount}$`}
										</Table.Td>
										<Table.Td>
											<RenderStatus status={payment.status}/>
										</Table.Td>
										<Table.Td>
											<RenderActions
												payment={payment}
												loadPayments={loadPayments}
											/>
										</Table.Td>
									</Table.Tr>
								))
							}
						</Table.Tbody>
					</Table>
				)
			}
		</Card>
	);
}

export default PagePayments;
