import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button, Card, Loader, TextInput} from "@mantine/core";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {useYoProvider} from "@components/YoContextEditor";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {useState} from "react";
import {IconSearch, IconTrash} from "@tabler/icons-react";
import ky from "ky";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {useScopedLocale} from "../../../locale";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";

function FindUserByName(props: { user: {_id: string, username: string}, onChange: (e: any) => void }) {
	const tt = useScopedLocale('webApps.view.GeneralTab.FindUserByName.');
	const [disabled, setDisabled] = useState(false);
	const [username, setUsername] = useState('');

	async function trySearch() {
		setDisabled(true);
		try {
			const result = await ky.post(`/api/v1/utils/findUserByName`, {
				json: {username}
			}).json<{status: boolean, user: any}>();
			if(!result.status) throw new Error('Failed to find user');
			props.onChange(result.user);
		} catch (e) {
			return displayErrorNotification(e);
		} finally {
			setDisabled(false);
		}
	}

	return (
		<>
			<TextInput
				autoFocus
				description={props.user ? `${tt('description.user')}: ${props.user.username}, _id: ${props.user._id}` : undefined}
				value={username}
				onChange={e => setUsername(e.target.value)}
				disabled={disabled}
				onKeyUp={e => e.key === 'Enter' && trySearch()}
				rightSection={(
					disabled ? <Loader size={'sm'}/> : <IconSearch onClick={trySearch} style={{cursor: 'pointer'}}/>
				)}
				inputWrapperOrder={['label','input', 'description', 'error']}
			/>
		</>
	);
}

export function GeneralTab() {
	const tt = useScopedLocale('webApps.view.GeneralTab.');
	const ctx = useYoProvider<any>('sharedWith', {defaultValue: []});
	async function tryAddNewUser() {
		const result = await yoFormModal({
			title: tt('tryAddNewUser.title'),
			required: ['user'],
			body: (
				<>
					<RenderFuncField field={'user'}>
						{ctr => (
							<FindUserByName user={ctr.value as any} onChange={e => ctr.onChange({_id: e._id, username: e.username})}/>
						)}
					</RenderFuncField>
					<SelectField
						field={'role'}
						label={tt('tryAddNewUser.form.role.label')}
						data={[
							{value: 'editor', label: tt('tryAddNewUser.form.role.editor')},
							{value: 'viewer', label: tt('tryAddNewUser.form.role.viewer')}
						]}/>
				</>
			),
			defaultValue: {
				user: undefined,
				role: 'viewer'
			}
		});
		ctx.push({_id: mongoObjectId(), ...result});
	}

	return (
		<Card withBorder>
			<InputField field={'name'} label={tt('form.name')}/>
			<h4>{tt('form.sharing.label')}</h4>
			<YoContextArrayMap field={'sharedWith'}>
				<hr/>
				<RenderFuncField<{_id: string, username: string}> field={'user'}>
					{ctx => (
						<>
							<TextInput
								value={`${ctx.value.username} (${ctx.value._id})`}
								readOnly
								rightSection={(
									<IconTrash onClick={ctx.bypassProps.onRemove}/>
								)}
							/>
						</>
					)}
				</RenderFuncField>
				<SelectField
					field={'role'}
					label={tt('tryAddNewUser.form.role.label')}
					data={[
						{value: 'editor', label: tt('tryAddNewUser.form.role.editor')},
						{value: 'viewer', label: tt('tryAddNewUser.form.role.viewer')}
					]}/>
			</YoContextArrayMap>
			<br/>
			<div>
				<Button variant="secondary" onClick={tryAddNewUser}>{tt('tryAddNewUser.button')}</Button>
			</div>
			<TeamSelectField />
		</Card>
	);
}
