export const ruleTypesValues = [{
	label: 'Операционная система',
	value: 'osEquals'
}, {
	label: 'Браузер',
	value: 'browserEquals'
}, {
	label: 'Язык',
	value: 'language'
}, {
	label: 'A/B Тест',
	value: 'ab'
}];
