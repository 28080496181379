import {ActionIcon, Badge, Button, Checkbox, Collapse, Flex, Select, TextInput} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {LeadsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useEffect, useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {useSearchParams} from "@hooks/useSearchParams.ts";
import {Translation, useLocale} from "../../locale";
import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {IconFilter} from "@tabler/icons-react";
import {isUndefined, omitBy} from "lodash";

const api = new LeadsApiClient()

interface IItem {
	fireCloakingReason: string;
	fireCloaking: boolean;
	_id: string;
	browserName: string;
	browserVersion: string;

	osName: string;
	osVersion: string;

	tryAppInstall: boolean;

	appInstalled: boolean;
	appInstalledInOtherRun: boolean;

	createdAt: string;
}

function useCustomFilters() {
	const [sp, setQs] = useSearchParams();
	const [customFiltersOpen, setCustomFiltersOpen] = useState(false);
	const [customFilters, setCustomFilters] = useState<any>(() => {
		if(sp.has('customFilters')) {
			return JSON.parse(sp.get('customFilters') as string) as any;
		}
		return {};
	});


	useEffect(() => {
		setQs(prev => ({
			...Object.fromEntries(prev.entries()),
			customFilters: JSON.stringify(customFilters)
		}))
	}, [customFiltersOpen ? JSON.stringify(customFilters): false]);

	return {
		isOpen: customFiltersOpen,
		setOpen: setCustomFiltersOpen,

		values: customFilters,
		setValues: setCustomFilters,
	}
}


export function ListLeads() {
	const t = useLocale();
	const [onlyUnique, setOnlyUnique] = useState(true);
	const customFilters = useCustomFilters();
	const timeControls = useTimeState();

	const defaults = useDefaultsTableState();
	const [sp, setQs] = useSearchParams();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.Leads.columnVisibility',
		defaults,
		defaultHiddenColumns: ['deviceType', '_id', 'tryAppInstall', 'fireCloaking', 'fireCloakingReason']
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: '_id',
		header: t('leads.list.tableHeader._id'),
		enableSorting: false,
		enableColumnActions: false,
	},{
		accessorKey: 'clientId',
		header: t('leads.list.tableHeader.clientId'),
		Cell: (props) => (
			<>
				<p
					style={{cursor: 'pointer', backgroundColor: props.row.original.fireCloaking ? 'red' : 'inherit'}}
					onClick={() => {
						if (props.cell.getValue<string>() === sp.get('clientId')) return;
						setQs(prev => ({
							...Object.fromEntries(prev.entries()),
							clientId: props.cell.getValue<string>()
						}));
					}}
				>
					{props.cell.getValue<string>()}
				</p>
				<Link href={`~/leads/logs?clientId=${props.cell.getValue<string>()}`}>
					<Translation id={'leads.list.tableHeader.clientId.log'}/>
				</Link>
			</>
		)
	}, {
		accessorKey: 'runId',
		header: t('leads.list.tableHeader.runId'),
		Cell: props => (
			<>
				<p>{props.cell.getValue<string>()}</p>
				<Link href={`~/leads/logs?runId=${props.cell.getValue<string>()}`}>
					<Translation id={'leads.list.tableHeader.runId.log'}/>
				</Link>
			</>
		)
	}, {
		accessorKey: 'clientIp',
		header: t('leads.list.tableHeader.clientIp'),
	}, {
		accessorKey: 'browserName',
		header: t('leads.list.tableHeader.browserName'),
		Cell: props => `${props.row.original.browserName} ${props.row.original.browserVersion}`
	}, {
		accessorKey: 'osName',
		header: t('leads.list.tableHeader.osName'),
		Cell: props => `${props.row.original.osName} ${props.row.original.osVersion}`
	},{
		accessorKey: 'deviceType',
		header: t('leads.list.tableHeader.deviceType'),
	},{
		accessorKey: 'fireCloaking',
		header: t('leads.list.tableHeader.fireCloaking'),
		Cell: props => (props.cell.getValue<boolean>() ? (
			<Badge color={'red'}><Translation id={'leads.list.tableHeader.fireCloaking.true'}/></Badge>
		) : (
			<Badge color={'green'}><Translation id={'leads.list.tableHeader.fireCloaking.false'}/></Badge>
		))
	},{
		accessorKey: 'fireCloakingReason',
		header: t('leads.list.tableHeader.fireCloakingReason'),
		Cell: props => props.cell.getValue() ? `${t(`leads.list.tableHeader.fireCloakingReason.${props.cell.getValue<string>()}` as any)} (${props.cell.getValue<string>()})` : undefined
	},{
		accessorKey: 'tryAppInstall',
		header: t('leads.list.tableHeader.tryAppInstall'),
		filterVariant: 'checkbox',
		Cell: props => (props.cell.getValue<boolean>() ?
				<Badge color={'green'}><Translation id={'leads.list.tableHeader.tryAppInstall.true'}/></Badge> :
				<Badge color={'yellow'}><Translation id={'leads.list.tableHeader.tryAppInstall.false'}/></Badge>
		),
	},{
		accessorKey: 'appInstalled',
		header: t('leads.list.tableHeader.appInstalled'),
		Cell: props => (props.cell.getValue<boolean>() ?
			<Badge color={'green'}><Translation id={'leads.list.tableHeader.appInstalled.true'}/></Badge> :
			<Badge color={'red'}><Translation id={'leads.list.tableHeader.appInstalled.false'}/></Badge>
		),
		enableColumnFilter: false,
	},{
		accessorKey: 'userRegistered',
		header: t('leads.list.tableHeader.userRegistered'),
		Cell: props => (props.cell.getValue<boolean>() ?
			<Badge color={'green'}><Translation id={'leads.list.tableHeader.userRegistered.true'}/></Badge> :
			<Badge color={'yellow'}><Translation id={'leads.list.tableHeader.userRegistered.false'}/></Badge>
		),
	},{
		accessorKey: 'userDeposited',
		header: t('leads.list.tableHeader.userDeposited'),
		Cell: props => (props.cell.getValue<boolean>() ?
			<Badge color={'green'}><Translation id={'leads.list.tableHeader.userDeposited.true'}/></Badge> :
			<Badge color={'yellow'}><Translation id={'leads.list.tableHeader.userDeposited.false'}/></Badge>
		),
	},{
		accessorKey: 'pushSubscription.isSubscribed',
		header: t('leads.list.tableHeader.isSubscribed'),
		Cell: props => (props.cell.getValue<boolean>() ?
			<Badge color={'green'}><Translation id={'leads.list.tableHeader.isSubscribed.true'}/></Badge> :
			<Badge color={'yellow'}><Translation id={'leads.list.tableHeader.isSubscribed.false'}/></Badge>
		),
	},{
		accessorKey: 'createdAt',
		header: t('leads.list.tableHeader.createdAt'),
		Cell: props => new Date(props.row.original.createdAt).toLocaleString(),
		enableSorting: true,
		enableColumnFilter:false
	},{
		accessorKey: 'actions',
		header: t('leads.list.tableHeader.actions'),
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Button component={Link} href={`/v/${props.row.original._id}`} variant="primary" size={'compact-sm'}>
					<Translation id={'leads.list.tableHeader.actions.view'}/>
				</Button>
			</>
		)
	}], []);

	const data = useYoApi({
		apiClient: api,
		defaults,
		forceQuery: omitBy({
			clientId: sp.has('clientId') ? {value: sp.get('clientId')} : undefined,
			appId: sp.has('appId') ? {value: sp.get('appId')} : undefined,
			parentLead: onlyUnique ? {type: 'exists', value: false} : undefined,
			createdAt: timeControls.queryValue,
			...(customFilters.isOpen ? customFilters.values : {})
		}, isUndefined)
	});
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);


	return <>
		<Header
			title={t('leads.list.title')}
			items={(
				<>
					<ActionIcon variant={'outline'} onClick={() => customFilters.setOpen(v => !v)}>
						<IconFilter/>
					</ActionIcon>
					<TimeRangeSelect onChange={timeControls.setValue}/>
					<Checkbox checked={onlyUnique} onChange={e => setOnlyUnique(e.currentTarget.checked)} label={t('leads.list.onlyUniqs')}/>
				</>
			)}
		/>
		<Collapse in={customFilters.isOpen}>
			<Flex align={'center'} gap={'sm'}>
				<Select
					label={t('leads.list.customFilters.leadSource')}
					data={[
						{label: t('leads.list.customFilters.leadSource.any'), value: 'any'},
						{label: t('leads.list.customFilters.leadSource.facebook'), value: 'facebook'},
						{label: t('leads.list.customFilters.leadSource.google'), value: 'google'},
					]}
					value={(() => {
						if(customFilters.values.gclid) return 'google';
						if(customFilters.values.fbp) return 'facebook';
						return 'any';
					})()}
					onChange={e => customFilters.setValues((prev: any) => {
						const res = {...prev};
						delete res.gclid;
						delete res.fbp;
						if(e === 'facebook') res.fbp = {type: 'exists', value: true};
						if(e === 'google') res.gclid = {type: 'exists', value: true};
						return res;
					})}
				/>
				<TextInput
					label={t('leads.list.customFilters.refferer')}
					defaultValue={customFilters.values.referer?.value ?? ''}
					onBlur={e => customFilters.setValues((prev: any) => {
						if(!e.target.value) {
							const res = {...prev};
							delete res.referer;
							return res
						}
						return {
							...prev,
							referer: {type: 'contains', value: e.target.value}
						}
					})}
				/>
			</Flex>
		</Collapse>
		<MantineReactTable table={table} />
	</>;
}
