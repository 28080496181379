import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button, Card, Group, Stack, Text, Title} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {FbPixelsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {Translation, useLocale} from "../../locale";
import {PixelStats} from "./pixelStats.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {FbEventField} from "./fbEventField.tsx";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";
import {TransferFbPixelLogsJobApiClient} from "./TransferFbPixelLogsJob/api.ts";

const api = new FbPixelsApiClient();
const transferJobsListApi = new TransferFbPixelLogsJobApiClient();

function TransferJobsList ({ pixelId }: { pixelId: string }) {
	const [list, setList] = useState<any[]>([]);

	console.log({ list })

	useEffect(() => {
		if (pixelId) {
			transferJobsListApi.getJobsForPixel({pixelId})
				.then((result: any) => {
					setList(result.items)
				})
		}
	}, [pixelId]);

	if (list.length === 0) {
		return null
	}

	return (
		<Card mb="xl" withBorder>
			<Title order={4} mb="xs">
				Transfer Jobs
			</Title>
			{
				list.map((item) => (
					<Card key={item._id} component={Link} to={`/transferJobs/${item._id}`} mb="xs" variant="section">
						<Group justify="space-between">
							<Group>
								{
									item.from && (
										<Stack gap={0}>
											<Text size="xs">
												From pixel
											</Text>
											<Text>
												{item.from._id === pixelId ? 'this pixel' : `${item.from.name} (${item.from.pixel})`}
											</Text>
										</Stack>
									)
								}
								{
									item.to && (
										<Stack gap={0}>
											<Text size="xs">
												To pixel
											</Text>
											<Text>
												{item.to._id === pixelId ? 'this pixel' : `${item.to.name} (${item.to.pixel})`}
											</Text>
										</Stack>
									)
								}
								<Stack gap={0}>
									<Text>
										Date
									</Text>
									<Text>
										{item.createdAt ? new Date(item.createdAt).toLocaleString() : '-'}
									</Text>
								</Stack>
							</Group>
							<Text>
								{item.status}
							</Text>
						</Group>
					</Card>
				))
			}
		</Card>
	)
}

export function ViewFbPixels() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('fbPixels.edit.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={<Translation id={'fbPixels.edit.headerTitle'}/>}
				items={(
					<>
						<Link href={`/logs/${params.id}`}>
							<Button size={'compact-sm'} color={'green'}>
								<Translation id={'fbPixels.edit.logsButton'}/>
							</Button>
						</Link>
						<Button onClick={trySave} size={'compact-sm'}>
							<Translation id={'fbPixels.edit.saveButton'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'fbPixels.edit.deleteButton'}/>
						</Button>
					</>
				)}
			/>
			<TransferJobsList pixelId={(data as any)._id} />
			<Card component={Stack} gap="xs" withBorder>
				<TeamSelectField />
				<InputField field={'name'} label={<Translation id={'fbPixels.edit.pixelNameLabel'}/>}/>
				<InputField field={'pixel'} label={<Translation id={'fbPixels.edit.pixelIdLabel'}/>}/>
				<InputField field={'token'} label={<Translation id={'fbPixels.edit.pixelTokenLabel'}/>}/>
				<Title order={4}><Translation id={'fbPixels.edit.eventsMapping.title'}/></Title>
				<FbEventField field={'installEventName'} label={t('fbPixels.edit.eventsMapping.installEventNameLabel')}/>
				<FbEventField field={'registrationEventName'} label={t('fbPixels.edit.eventsMapping.registrationEventNameLabel')}/>
				<FbEventField field={'depositEventName'} label={t('fbPixels.edit.eventsMapping.depositEventNameLabel')}/>
				<Title order={4}><Translation id={'fbPixels.stat.title'}/></Title>
				<PixelStats id={params.id}/>
			</Card>
		</YoContextEditor>
	);
}
