import {iStateProps} from "../IStateProps.tsx";
import {Box, Group, LoadingOverlay} from "@mantine/core";
import {StatsGrid} from "@components/StatsGrid";
import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {useScopedLocale} from "../../../locale";
import {addStatsJob} from "../utils/statsQueue.ts";
import {IconRefresh} from "@tabler/icons-react";

export function ClickToActionBadges(props: iStateProps) {
	const t = useScopedLocale('pages.mainPage.cta.');
	const promise = usePromiseWithTeamDependency(() => {
		return addStatsJob(() => getKyInstance().post('/api/v1/stats/clickToAction', {
			json: {
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers
			},
			timeout: 1000 * 30
		}).json());
	}, [JSON.stringify([props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);

	const createDefault = () => ({value: -1, diff: 0, prev: 0});
	const {
		toInstall = createDefault(),
		toRegister = createDefault(),
		toDeposit = createDefault(),
	} = (promise.data || {}) as Record<string, { value: number, diff: number, prev: number }>;

	if(promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<Group grow>
				<StatsGrid
					icon={() => <IconRefresh size={'1rem'} onClick={promise.run}/>}
					value={`${toInstall.value}%`}
					title={t('install')}
					diff={Number((toInstall.value - toInstall.prev).toFixed(2))}
					diffSign={'%'}
					description={`Prev period: ${toInstall.prev}%`}
				/>

				<StatsGrid
					icon={() => <IconRefresh size={'1rem'} onClick={promise.run}/>}
					value={`${toRegister.value}%`}
					title={t('register')}
					diff={Number((toRegister.value - toRegister.prev).toFixed(2))}
					diffSign={'%'}
					description={`Prev period: ${toRegister.prev}%`}
				/>
				<StatsGrid
					icon={() => <IconRefresh size={'1rem'} onClick={promise.run}/>}
					value={`${toDeposit.value}%`}
					title={t('deposit')}
					diff={Number((toDeposit.value - toDeposit.prev).toFixed(2))}
					diffSign={'%'}
					description={`Prev period: ${toDeposit.prev}%`}
				/>
			</Group>
		</Box>

	);
}
