import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/spotlight/styles.css';
import 'mantine-react-table/styles.css';
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {UserContext, useUser} from "./engine/UserContext.tsx";
import {Shell} from "./Shell";
import {Route} from "wouter";
import {MainPage} from "./Pages/MainPage";
import {ModalsProvider} from "@mantine/modals";
import {WebAppsRouter} from "./Pages/WebApps";
import {DomainsRouter} from "./Pages/Domains";
import {LeadsRouter} from "./Pages/Leads";
import {FbPixelsRouter} from "./Pages/FbPixels";
import {LocaleProvider} from "./locale";
import {AppSpotlight} from "@components/Spotlight/AppSpotlight.tsx";
import {GoogleTrackingEntryRouter} from "./Pages/GoogleTrackingEntry";
import {PushRouter} from "./Pages/Push";
import {WhitePagesRouter} from "./Pages/WhitePages";
import {AdminApp} from "./AdminApp.tsx";
import {TeamsRouter} from "./Pages/Teams";
import {TeamsContextProvider} from "./Pages/Teams/Context/TeamsContext.tsx";
import {appTheme} from "./theme.ts";
import {AvatarGalleryRouter} from "./Pages/AvatarGallery";
import BillingRouter from "./Pages/Billing/BillingRouter.tsx";
import {withErrorBoundary} from "react-error-boundary";
import TeamDeactivationStatus from "@components/TeamDeactivationStatus";
import OnboardingRouter from "./Pages/OnBoarding/OnboardingRouter.tsx";
import {UserProfile} from "./Pages/UserProfile";
import TeamInvitesPage from "./Pages/TeamInvites/TeamInvitesPage.tsx";
import {useMemo} from "react";
import {wrapComponents} from "./utils/wrapComponents.tsx";

function App() {
	const { user } = useUser();

	if (user.shouldCompleteOnboarding) {
		return (
			<OnboardingRouter />
		)
	}

	return (
		<Shell>
			<TeamDeactivationStatus />
			<Route path={'/'} component={withErrorBoundary(MainPage, {
				fallback: <div>Something went wrong</div>
			}) as any}/>
			<Route path={'/profile'} component={withErrorBoundary(UserProfile, {
				fallback: <div>Something went wrong</div>
			}) as any}/>
			<WebAppsRouter/>
			<DomainsRouter/>
			<LeadsRouter/>
			<FbPixelsRouter/>
			<GoogleTrackingEntryRouter/>
			<PushRouter/>
			<WhitePagesRouter/>
			<AdminApp/>
			<TeamsRouter/>
			<AvatarGalleryRouter/>
			<BillingRouter/>
			<Route path='/teamInvites' component={TeamInvitesPage}/>
			<AppSpotlight/>
		</Shell>
	);
}



function AppLoader() {
	// Напрягает меня вложенность, переписал на вот такую конструкцию
	const app = useMemo(() =>  wrapComponents([
		[ModalsProvider],
		[UserContext],
		[TeamsContextProvider],
		[LocaleProvider],
		[App]
	]), []);

	return (
		<MantineProvider theme={appTheme} forceColorScheme={'dark'} withCssVariables>
			{app}
			<Notifications position={'top-center'}/>
		</MantineProvider>

	)
}

export default AppLoader
