import {
	AspectRatio,
	Button,
	Card,
	Center,
	Collapse,
	Container,
	Grid,
	Modal,
	Stack,
	Tabs,
	Text,
	Title
} from "@mantine/core";
import {IconFlag, IconLanguage, IconPrompt} from "@tabler/icons-react";
import {useParams} from "wouter";
import {useLocalStorage} from "@mantine/hooks";
import {LanguagesTab} from "./languagesTab.tsx";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {Fragment, useMemo, useState} from "react";
import {allLanguageList} from "@shared/_data/allLanguageList.ts";
import {useLocaleContext, useScopedLocale} from "../../../../locale";
import {PreviewFrameWithLoader} from "./previewFrame.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {
	WebAppsDesignLocalizationEditorTab,
	WebAppsDesignSettingsEditorTab
} from "./Components/WebAppsDesignEditorTab.tsx";
import {webAppsDesignConfig} from "@shared/_data/webAppsDesignConfig/config.ts";
import {TWebAppsDesignTemplateKeys, webAppsDesignTemplateKeys} from "@shared/_data/webAppsDesignConfig/utils.ts";
import imgPlayMarket from './Components/templateImages/playmarket.png';
import imgRouletteVerdeCasino from './Components/templateImages/rouletteVerdeCasino.png';
import imgRouletteBalls from './Components/templateImages/rouletteBalls.png';

const templateKeyImages: Record<TWebAppsDesignTemplateKeys, string> = {
	playMarket: imgPlayMarket,
	playMarketSKAK: imgPlayMarket,
	rouletteBalls: imgRouletteBalls,
	rouletteVerdeCasino: imgRouletteVerdeCasino
}

function LangName({code}: {code: string}) {
	const {selectedLocale} = useLocaleContext();
	return useMemo(() => {
		const val = allLanguageList.find(el => el.code === code);
		if(!val) return code;
		if(selectedLocale === 'ru') return val.name_ru;
		if(selectedLocale === 'uk') return val.name_ukr;
		return val.name_en;
	}, [code]);
}

function LangIcon({code}: {code: string}) {
	return useMemo(() => {
		const val = allLanguageList.find(el => el.code === code);
		if(!val) return <IconFlag />;
		return <div style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{val.emoji}</div>;
	}, [code]);
}

function SelectTemplateFromImages ({ value, onChange }: { value?: TWebAppsDesignTemplateKeys, onChange: (v: TWebAppsDesignTemplateKeys) => void }) {

	return (
		<Center>
			<Grid>
				{
					webAppsDesignTemplateKeys.map(key => (
						<Grid.Col span={{ lg: 4 }} key={key}>
							<Card
								style={{
									borderColor: value === key ? '#00bae5' : undefined,
									cursor: 'pointer'
								}}
								withBorder
								variant="section"
								onClick={() => {
									onChange(key);
								}}
							>
								<Card.Section p="sm">
									{webAppsDesignConfig[key]?.label}
								</Card.Section>
								<Card.Section style={{ maxHeight: '30rem' }}>
									<AspectRatio ratio={9 / 16}>
										<img
											style={{ width: '100%', height: '100%' }}
											src={templateKeyImages[key]}
											alt=""
										/>
									</AspectRatio>
								</Card.Section>
							</Card>
						</Grid.Col>
					))
				}
			</Grid>
		</Center>
	)
}

function SwitchTemplateModal ({ value, onChange }: { value: TWebAppsDesignTemplateKeys, onChange: (v: TWebAppsDesignTemplateKeys) => void }) {
	const [opened, setOpened] = useState(false);

	return (
		<>
			<Modal opened={opened} onClose={() => setOpened(false)} size="xl" title="switch template">
				<SelectTemplateFromImages
					value={value}
					onChange={v => {
						setOpened(false);
						onChange(v);
					}}
				/>
			</Modal>
			<Button radius={0} variant="secondary" onClick={() => setOpened(true)} style={{ justifyContent: "start", display: "flex", height: "fit-content" }}>
				<Stack gap={0} align="start">
					<Text size="xs">
						template
					</Text>
					<Text>
						{webAppsDesignConfig[value]?.label}
					</Text>
				</Stack>
			</Button>
		</>
	)
}

export function ContentTab() {
	const tt = useScopedLocale('webApps.views.ContentTab.');
	const params = useParams<{id: string}>();
	const [tab, setTab] = useLocalStorage<{tab: string, langCode?: string}>({key: `ui.webApps.${params.id}.tab.content.v2`, defaultValue: {
		tab: 'languages'
	}});
	const ctx = useYoProvider('localeAppData');
	const langs = Object.entries<{__deleted?: boolean, isDefault: boolean}>(ctx.value || {}).filter(el => !el[1]?.__deleted).map(el => ({
		code: el[0],
		isDefault: el[1].isDefault
	}));

	const baseLang = langs.find(el => el.isDefault);

	const currentLangCode = tab.langCode;

	const globalCtx = useYoProvider();
	const webappDesignEditorConfig = webAppsDesignConfig[globalCtx.value.templateKey as TWebAppsDesignTemplateKeys] || webAppsDesignConfig['playMarket'];

	if (!globalCtx.value.templateKey) {
		return (
			<div>
				<Stack align="center" gap={0} mb="sm">
					<Title order={2}>
						Select template
					</Title>
					<Text size="sm">
						...it can be changed at any time
					</Text>
				</Stack>
				<RenderFuncField<string> field={'templateKey'}>
					{ctx => (
						<SelectTemplateFromImages
							onChange={(v) => {
								ctx.onChange(v)
							}}
						/>
					)}
				</RenderFuncField>
			</div>
		)
	}

	return (
		<>
			<Tabs variant="pills" value={tab.tab} orientation={'vertical'} keepMounted={false}>
				<Tabs.List>
                    <RenderFuncField<string> field={'templateKey'}>
                        {ctx => (
                            <SwitchTemplateModal
								value={ctx.value as TWebAppsDesignTemplateKeys}
								onChange={v => ctx.onChange(v)}
							/>
                        )}
                    </RenderFuncField>
					<Tabs.Tab
						value='languages'
						leftSection={<IconLanguage />}
						onClick={() => setTab({tab: 'languages'})}
					>
						{tt('tabsList.languages')}
					</Tabs.Tab>
					{langs.map(({code}) => {
						function switchTab(name: string) {
							return setTab({tab: name!, langCode: code});
						}
						return (
							<Fragment key={code}>
								<Tabs.Tab
									value={code}
									leftSection={<LangIcon code={code}/>}
									onClick={() => switchTab('description')}
								>
									<LangName code={code}/>
								</Tabs.Tab>
								<Collapse in={tab.langCode === code}>
									{
										Object.entries(webappDesignEditorConfig.localizationTabs || { description: [] }).map(([tabKey]) => (
											<Tabs.Tab key={tabKey} value={tabKey} style={{width: '90%', marginLeft: '18px'}} onClick={() => switchTab(tabKey)}>
												{tt(`tabsList.${tabKey}` as any)}
											</Tabs.Tab>
										))
									}
								</Collapse>
							</Fragment>
						);
					})}
					<Tabs.Tab
						value='settings'
						leftSection={<IconPrompt/>}
						onClick={() => setTab({tab: 'settings'})}
					>
						{tt('tabsList.additional')}
					</Tabs.Tab>
				</Tabs.List>
				<Grid w={'100%'}>
					<Grid.Col span={currentLangCode ? 9 : 12}>
						<Tabs.Panel value="languages">
							<Container fluid>
								<LanguagesTab/>
							</Container>
						</Tabs.Panel>
						<Tabs.Panel value="settings">
							{/*<Container fluid>
								<ContentSettingsTab/>
							</Container>*/}
							<WebAppsDesignSettingsEditorTab
								webappDesignEditorConfig={webappDesignEditorConfig}
							/>
						</Tabs.Panel>
						<YoContextEditor
							name={`localeAppData.${currentLangCode || 'LNF'}`}
							key={currentLangCode}
							bypassProps={{
								lang: currentLangCode,
								isSecondaryLanguage: baseLang?.code !== currentLangCode,
								baseLang: baseLang?.code
							}}
						>
							{
								Object.keys(webappDesignEditorConfig.localizationTabs || { description: Object.keys(webappDesignEditorConfig.localization) }).map((tabKey) => (
									<Tabs.Panel value={`${tabKey}`} key={tabKey}>
										<Container fluid>
											<WebAppsDesignLocalizationEditorTab
												webappDesignEditorConfig={webappDesignEditorConfig}
												tabKey={tabKey}
												localeKey={currentLangCode!}
											/>
										</Container>
									</Tabs.Panel>
								))
							}
							{/*<Tabs.Panel value={`description`}>
								<Container fluid>
									<DescriptionTab/>
								</Container>
							</Tabs.Panel>
							<Tabs.Panel value={`images`}>
								<Container fluid>
									<ImagesTab/>
								</Container>
							</Tabs.Panel>
							<Tabs.Panel value={`reviews`} >
								<Container fluid>
									<ReviewsTab/>
								</Container>
							</Tabs.Panel>*/}
						</YoContextEditor>
					</Grid.Col>
					{currentLangCode && (
						<Grid.Col span={3} h={'100%'}>
							<PreviewFrameWithLoader locale={currentLangCode}/>
						</Grid.Col>
					)}
				</Grid>
			</Tabs>
		</>
	);
}
