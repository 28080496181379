import {Route} from "wouter";
import {ListFbPixelsLogs} from "./listFbPixelsLogs.tsx";
import {ViewFbPixelsLogs} from "./viewFbPixelsLogs.tsx";

const baseUrl = '/fbPixelsLogs';


export function FbPixelsLogsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListFbPixelsLogs}/>
			<Route path={'/v/:id'} component={ViewFbPixelsLogs}/>
		</Route>
	);
}
