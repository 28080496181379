import {Checkbox, Collapse, Flex, NumberInput, Radio, Stack, Text, Title} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";
import {useScopedLocale} from "../../../../../../locale";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'installButtonBehavior'>> = ({ value, onChange, label }) => {
    const tt = useScopedLocale('webApps.views.ContentSettingsTab.');

    console.log(value);

    return (
        <Stack gap="xs">
            <Title order={4}>{label}</Title>
                <Radio.Card
                    checked={value?.behavior === 'waitInstall'}
                    onClick={() => {
                        if (value?.behavior === 'waitInstall') return;
                        onChange({ behavior: 'waitInstall', waitInstall: { scrollToBottom: true } })
                    }}
                >
                    <Flex gap={'sm'} align={'center'} mb="sm">
                        <Radio.Indicator style={{margin: '10px'}}/>
                        <div>
                            <Text size="lg" fw={600} style={{marginTop: '10px'}}>{tt('installButtonBehavior.waitInstall.title')}</Text>
                            <Text c="dimmed" size="xs">{tt('installButtonBehavior.waitInstall.description')}</Text>
                        </div>
                    </Flex>
                    <Collapse in={value?.behavior === 'waitInstall'}>
                        {
                            value?.behavior === 'waitInstall' && (
                                <Checkbox
                                    mt={0}
                                    m="md"
                                    label={tt('installButtonBehavior.waitInstall.scrollToBottom.label')}
                                    description={tt('installButtonBehavior.waitInstall.scrollToBottom.description')}
                                    checked={value?.waitInstall.scrollToBottom}
                                    onChange={e => {
                                        onChange({
                                            ...value,
                                            waitInstall: {
                                                scrollToBottom: e.target.checked
                                            }
                                        })
                                    }}
                                />
                            )
                        }
                    </Collapse>
                </Radio.Card>
                <Radio.Card
                    checked={value?.behavior === 'scrollToBottom'}
                    onClick={() => {
                        if (value?.behavior === 'scrollToBottom') return;
                        onChange({ behavior: 'scrollToBottom', scrollToBottom: { clickCount: 1 } })
                    }}
                >
                    <Flex gap={'sm'} align={'center'} mb="sm">
                        <Radio.Indicator style={{margin: '10px'}}/>
                        <div>
                            <Text size="lg" fw={600} style={{marginTop: '10px'}}>{tt('behavior.scrollToBottom.title')}</Text>
                            <Text c="dimmed" size="xs">{tt('behavior.scrollToBottom.description')}</Text>
                        </div>
                    </Flex>
                    <Collapse in={value?.behavior === 'scrollToBottom'}>
                        {
                            value?.behavior === 'scrollToBottom' && (
                                <NumberInput
                                    mt={0}
                                    m="md"
                                    label={tt('behavior.scrollToBottom.clickCount.label')}
                                    value={value.scrollToBottom.clickCount}
                                    onChange={v => {
                                        console.log(v);
                                        if (typeof v === "number") {
                                            onChange({
                                                ...value,
                                                scrollToBottom: {
                                                    clickCount: v
                                                }
                                            })
                                        }
                                    }}
                                />
                            )
                        }
                    </Collapse>
                </Radio.Card>
                <Radio.Card
                    mb="md"
                    checked={value?.behavior === 'none'}
                    onClick={() => onChange({ behavior: 'none' })}
                >
                    <Flex gap={'sm'} align={'center'} mb="sm">
                        <Radio.Indicator style={{margin: '10px'}}/>
                        <div>
                            <Text size="lg" fw={600} style={{marginTop: '10px'}}>{tt('behavior.none.title')}</Text>
                            <Text c="dimmed" size="xs">{tt('behavior.none.description')}</Text>
                        </div>
                    </Flex>
                </Radio.Card>
        </Stack>
    );
}

export default FieldTypeString;