import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class CfAccountsApiClient extends YoApiClient {
	path = '/api/v1/system/cfAccounts';

	deployConsumer(accountId: string) {
		return this.ky.post(`${accountId}/deployConsumer`).json<{status: boolean}>();
	}

	redeployApps(accountId: string) {
		return this.ky.post(`${accountId}/redeployApps`).json<{status: boolean, appsCount: number}>();
	}

	updatePwaCloudRecords(accountId: string, all: boolean = false) {
		return this.ky.post(`${accountId}/updatePwaCloudRecords`, {
			json: {all}
		}).json<{status: boolean, appsCount: number}>();
	}
}
