import {LocaleKeys} from "../localeKeys.tsx";

const cfAccountsList = {
	'cfAccounts.list.title': 'Акаунти Cloudflare',
	'cfAccounts.list.tableHeader._id': 'ID',
	'cfAccounts.list.tableHeader.name': 'Назва акаунта',
	'cfAccounts.list.tableHeader.keyType': 'Тип ключа',
	'cfAccounts.list.tableHeader.keyType.TOKEN': 'Global API Key',
	'cfAccounts.list.tableHeader.keyType.KEY': 'API Tokens',

	'cfAccounts.list.tableHeader.actions': 'Дії',
	'cfAccounts.list.tableHeader.actions.view': 'Переглянути',
	'cfAccounts.list.create': 'Додати акаунт',
	'cfAccounts.modal.title.create': 'Додавання акаунта',
	'cfAccounts.modal.label.name': 'Назва акаунта',
	'cfAccounts.modal.label.keyType': 'Тип ключа',
	'cfAccounts.modal.label.keyType.token': 'Global API Key',
	'cfAccounts.modal.label.keyType.key': 'API Tokens',

	'cfAccounts.modal.label.apiToken': 'API токен',

	'cfAccounts.modal.label.apiKey': 'API ключ',
	'cfAccounts.modal.label.apiEmail': 'API email',

	'cfAccounts.modal.label.accountId': 'ID акаунта',
	'cfAccounts.modal.label.accountId.description': 'Де знайти id акаунта Cloudflare?',
}

const cfAccountsEdit = {
	'cfAccounts.edit.title': 'Редактор акаунта',
	'cfAccounts.edit.saveSuccess': 'Збережено успішно',
	'cfAccounts.edit.saveButton': 'Зберегти',
	'cfAccounts.edit.removeButton': 'Видалити',

	'cfAccounts.edit.deleteButton': 'Видалити',

	'cfAccounts.edit.form.name': 'Назва акаунта',
	'cfAccounts.edit.form.keyType': 'Тип ключа',
	'cfAccounts.edit.form.keyType.token': 'Global API Key',
	'cfAccounts.edit.form.keyType.key': 'API Tokens',

	'cfAccounts.edit.form.apiToken': 'API токен',

	'cfAccounts.edit.form.apiKey': 'API ключ',
	'cfAccounts.edit.form.apiEmail': 'API email',

	'cfAccounts.edit.form.accountId': 'ID акаунта',
}

const webAppDomainTab = {
	"webApps.view.DomainTab.title": "Домен",
	"webApps.view.DomainTab.subTitle": "Для роботи PWA необхідний домен. Ви можете купити домен через нас або використовувати свій",

	"webApps.view.DomainTab.buy.guide.1": "Придбати домен",
	"webApps.view.DomainTab.buy.guide.2": "Натисніть кнопку придбати домен, потім введіть бажаний домен.",
	"webApps.view.DomainTab.buy.guide.3": "Дочекайтеся підтвердження і натисніть кнопку \"Підтвердити\".",
	"webApps.view.DomainTab.buy.guide.4": "Після додавання домену до списку доступних вам доменів буде розпочато процедуру покупки.",
	"webApps.view.DomainTab.buy.openModal": "Придбати новий домен",
	"webApps.view.DomainTab.buy.modalTitle": "Придбати домен",
	"webApps.view.DomainTab.buy.confirmButton": "Придбати домен",

	"webApps.view.DomainTab.buy.registrar": "Реєстратор: {{value}}",
	"webApps.view.DomainTab.buy.price": "Вартість:",
	"webApps.view.DomainTab.buy.split": "Дозволити використання цього домену на кількох PVA (режим Split)",


	"webApps.view.DomainTab.link.guide.1": "Прив'язати домен",
	"webApps.view.DomainTab.link.guide.2": "Ви також можете прив'язати свій домен",
	"webApps.view.DomainTab.link.openModal": "Прив'язати домен",

	"webApps.view.DomainTab.link.modalTitle": "Прив'язати домен",
	"webApps.view.DomainTab.link.wizard.1.domain.label": "Домен, який ви хочете прив'язати",
	"webApps.view.DomainTab.link.wizard.1.allowSplit.label": "Дозволити використання цього домену на кількох PVA (режим Split)",
	"webApps.view.DomainTab.link.wizard.1.continue": "Продовжити",
	"webApps.view.DomainTab.link.wizard.2.nsListTitle": "Вкажіть наступні DNS записи у вашого реєстратора",
	"webApps.view.DomainTab.link.wizard.2.nsListItem": "Запис {{i}}",
	"webApps.view.DomainTab.link.wizard.2.done": "Готово",

	"webApps.view.DomainTab.linkExists.title": "Вибрати вільний домен",
	"webApps.view.DomainTab.linkExists.guide.1": "Якщо у вас є невикористані домени, ви можете використати їх",
	"webApps.view.DomainTab.linkExists.openModal": "Вибрати домен",
	"webApps.view.DomainTab.linkExists.modalTitle": "Ваші домени",

	"webApps.view.DomainTab.linkDomain.title": "Прив'язати домен",
	"webApps.view.DomainTab.linkDomain.myDomains": "Мої домени",
	"webApps.view.DomainTab.linkDomain.myDomains.list": "Список моїх доменів",

	"webApps.view.DomainTab.LinkedDomainInfo.state.OK": "Домен готовий до роботи",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE": "Очікуємо оновлення NS записів",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE.title": "Додайте наступні NS записи",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE.item": "Запис {{i}}",
	"webApps.view.DomainTab.LinkedDomainInfo.state.BUY_IN_PROGRESS": "Покупка домену",
	"webApps.view.DomainTab.LinkedDomainInfo.state.BUY_IN_PROGRESS.info": "Виконується покупка домену, будь ласка, зачекайте...",
	"webApps.view.DomainTab.LinkedDomainInfo.state.UNKNOWN": "Невідомо",
	"webApps.view.DomainTab.LinkedDomainInfo.state.ERROR": "Помилка",
	"webApps.view.DomainTab.LinkedDomainInfo.state.IN_PROGRESS": "Процес викупу",
	"webApps.view.DomainTab.LinkedDomainInfo.state.DEFAULT": "Викуплено",

	"webApps.view.DomainTab.LinkedDomainInfo.state.WAIT_ACTIVATION": "Чекаємо активацію домену",
	"webApps.view.DomainTab.LinkedDomainInfo.state.WAIT_ACTIVATION.info": "Домен вже викуплено, очікуємо оновлення всіх DNS записів",

	"webApps.view.DomainTab.LinkedDomainInfo.addARecord.title": "Додавання A запису",
	"webApps.view.DomainTab.LinkedDomainInfo.addARecord.description": "Будь ласка, додайте A запис для домену на IP: ",
	"webApps.view.DomainTab.LinkedDomainInfo.autoUpdate": "Автооновлення",
	"webApps.view.DomainTab.LinkedDomainInfo.goToDomainPage.label": "Перейти до управління доменом",
	"webApps.view.DomainTab.LinkedDomainInfo.buyJob.label": "Деталі операції викупу",
};

const webAppAnalyticsTab = {
	"webApps.view.AnalyticsTab.keitaro.title": "Інтеграція з Кейтаро",
	"webApps.view.AnalyticsTab.keitaro.description": "У цьому розділі можна налаштувати інтеграцію з Кейтаро. Увімкніть опцію, щоб активувати цю інтеграцію, і заповніть необхідні поля для налаштування API URL та ключа.",
	"webApps.view.AnalyticsTab.keitaro.enabled.label": "Увімкнено",
	"webApps.view.AnalyticsTab.keitaro.enabled.description": "Інтеграція вимкнена",
	"webApps.view.AnalyticsTab.keitaro.apiUrl.label": "Домен кейтары",
	"webApps.view.AnalyticsTab.keitaro.apiKey.label": "API ключ кейтары",

	"webApps.view.AnalyticsTab.gTag.title": "Google Analytics",
	"webApps.view.AnalyticsTab.gTag.description": "Цей розділ дозволяє налаштувати інтеграцію з Google Analytics. Увімкніть опцію для відстеження подій через Google Tag і заповніть необхідні дані, такі як ідентифікатор і назва події установки.",
	"webApps.view.AnalyticsTab.gTag.enabled": "Увімкнено",
	"webApps.view.AnalyticsTab.gTag.gTagId": "Ідентифікатор google tag",
	"webApps.view.AnalyticsTab.gTag.installEventName": "Назва події установки",

	"webApps.view.AnalyticsTab.clarity.title": "Microsoft Clarity",
	"webApps.view.AnalyticsTab.clarity.description": "Цей розділ дозволяє налаштувати інтеграцію з Microsoft Clarity. Увімкніть опцію, щоб активувати інтеграцію, і вкажіть ідентифікатор.",
	"webApps.view.AnalyticsTab.clarity.enabled": "Увімкнено",
	"webApps.view.AnalyticsTab.clarity.tagId": "Ідентифікатор",
	"webApps.view.AnalyticsTab.clarity.findInfo": "Де знайти ідентифікатор?",
	"webApps.view.AnalyticsTab.clarity.findInfo.description": "У коді, що надається claply, знайдіть виділений червоним фрагмент",

	"webApps.view.AnalyticsTab.googleTracking.title": "Google Відстеження лідів",
	"webApps.view.AnalyticsTab.googleTracking.description": "Цей розділ дозволяє налаштувати інтеграцію з Google Ads шляхом передачі лідів за посиланням на таблицю",
	"webApps.view.AnalyticsTab.googleTracking.enabled": "Увімкнено",
	"webApps.view.AnalyticsTab.googleTracking.entry.label": "Виберіть посилання, у якому будуть доступні ліди цього додатку",

	"webApps.view.AnalyticsTab.facebook.title": "Facebook",
	"webApps.view.AnalyticsTab.facebook.description": "У цьому розділі можна налаштувати інтеграцію з Facebook. Увімкніть опцію для роботи з пікселем Facebook і заповніть поля токена доступу та ідентифікатора пікселя.",
	"webApps.view.AnalyticsTab.facebook.enabled": "Увімкнено",
	"webApps.view.AnalyticsTab.facebook.mainPixel": "Основний піксель",
	"webApps.view.AnalyticsTab.facebook.mainPixel.label": "Виберіть піксель",
	"webApps.view.AnalyticsTab.facebook.requireFBP.label": "Вимагати FBP перед переходом до установника",
	"webApps.view.AnalyticsTab.facebook.requireFBP.description": "Якщо увімкнено, параметри FBP та FBC будуть отримані перед переходом до установника, інакше вони будуть отримані на сторінці установника",

	"webApps.view.AnalyticsTab.facebook.additionalPixels.title": "Додаткові пікселі",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.label": "Виберіть піксель",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.add.modalTitle": "Додати піксель",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.add.buttonLabel": "Додати додатковий піксель",

	"webApps.view.AnalyticsTab.onInstallWebHook.title": "Install webhook",
	"webApps.view.AnalyticsTab.onInstallWebHook.description": "Цей розділ дозволяє налаштувати вебхук для обробки подій установки. Увімкніть опцію, щоб активувати вебхук і вкажіть URL та метод запиту.",
	"webApps.view.AnalyticsTab.onInstallWebHook.enabled": "Увімкнено",
	"webApps.view.AnalyticsTab.onInstallWebHook.url": "URL вебхука",
	"webApps.view.AnalyticsTab.onInstallWebHook.method": "Метод запиту",

	"webApps.view.AnalyticsTab.remoteEventsMap.title": "Відстеження подій",
	"webApps.view.AnalyticsTab.remoteEventsMap.description": "Ви можете передавати нам події, які ми будемо зберігати у себе та передавати в зовнішні сервіси, які ви підключите",
	"webApps.view.AnalyticsTab.remoteEventsMap.input.label": "Передавайте запити сюди",

	"webApps.view.AnalyticsTab.remoteEventsMap.alert.title": "Зверніть увагу",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replace": "Замінити ",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replaceIdentifier": "Ідентифікатор користувача, який ви отримали в полі",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replaceForValues": "(вказані нижче) на потрібні значення.",

	"webApps.view.AnalyticsTab.remoteEventsMap.alert.info.1": "Також ви можете передати в цьому посиланні параметр",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.info.2": "для того щоб використовувати інший піксель",
	"webApps.view.AnalyticsTab.remoteEventsMap.mapping.title": "Мапінг поля eventName",
	"webApps.view.AnalyticsTab.remoteEventsMap.onRegistration": "Можливі значення для події реєстрації користувача",
	"webApps.view.AnalyticsTab.remoteEventsMap.onDeposit": "Можливі значення для події внесення депозиту користувачем"
};

const webAppPublishTab = {
	"webApps.view.PublishTab.title": "Публікація PWA",

	"webApps.view.PublishTab.platform.splitWarning.title": "Ця PWA використовується разом зі спліт-доменом, керування платформою доступне в налаштуваннях домену",
	"webApps.view.PublishTab.platform.splitWarning.link": "Перейти до налаштувань домену",


	"webApps.view.PublishTab.platform.title": "Платформа для розміщення",
	"webApps.view.PublishTab.platform.pwaCloud.title": "PWA Cloud",
	"webApps.view.PublishTab.platform.pwaCloud.description": "Стійка до зловживань розподілена платформа",
	"webApps.view.PublishTab.platform.globalEdge.title": "Global Edge",
	"webApps.view.PublishTab.platform.globalEdge.description": "Масштабована платформа для високонавантажених проєктів",
	"webApps.view.PublishTab.platform.currentLabel": "Поточна платформа",
	"webApps.view.PublishTab.platform.button.active": "Вже обрано",
	"webApps.view.PublishTab.platform.button.inactive": "Обрати",

	"webApps.view.PublishTab.publish.title": "Опублікувати мою PWA",
	"webApps.view.PublishTab.publish.checklist.domain": "Домен",
	"webApps.view.PublishTab.publish.checklist.content": "Наповнення контентом",
	"webApps.view.PublishTab.publish.checklist.analytics": "Налаштування аналітики",
	"webApps.view.PublishTab.publish.checklist.links": "Керування користувацьким потоком",

	"webApps.view.PublishTab.publish.details.domain": "Домен",
	"webApps.view.PublishTab.publish.details.content": "Наповнення контентом",
	"webApps.view.PublishTab.publish.details.analytics": "Налаштування аналітики",
	"webApps.view.PublishTab.publish.details.links": "Керування користувацьким потоком",

	"webApps.view.PublishTab.publish.details.allOk": "Все налаштовано правильно",
	"webApps.view.PublishTab.publish.details.warning": "Є не критичні помилки; виправте їх за можливістю",
	"webApps.view.PublishTab.publish.details.error": "Є критичні помилки; виправте їх",
	"webApps.view.PublishTab.publish.details.title": "Помилки або попередження",
	"webApps.view.PublishTab.publish.details.seeLang": "Перевірте {{lang}}",

	// errors
	"webApps.view.PublishTab.publish.details.DOMAIN_NOT_FOUND": "Вкажіть домен",
	"webApps.view.PublishTab.publish.details.WRONG_DOMAIN_STATE": "Неможливо використовувати цей домен",
	"webApps.view.PublishTab.publish.details.DOMAIN_NOT_READY": "Домен не готовий до роботи",
	"webApps.view.PublishTab.publish.details.NO_LANGUAGES": "Вкажіть хоча б одну мову",
	"webApps.view.PublishTab.publish.details.NO_DEFAULT_LANGUAGE": "Не обрано мову за замовчуванням",
	"webApps.view.PublishTab.publish.details.NO_ICON": "Не завантажено іконку",
	"webApps.view.PublishTab.publish.details.NO_SCREEN": "Скріншоти не завантажені",
	"webApps.view.PublishTab.publish.details.TOO_MANY_SCREEN": "Завантажено занадто багато скріншотів",
	"webApps.view.PublishTab.publish.details.NOT_ENOUGH_SCREEN": "Завантажено занадто мало скріншотів",
	"webApps.view.PublishTab.publish.details.NO_SCREEN_IMAGE": "Помилка завантаження зображення",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_NAME": "Вкажіть автора відгуку",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_AVATAR": "Завантажте або оберіть аватар для відгуку",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_RATING": "Не вказано рейтинг відгуку",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_TEXT": "Не вказано текст відгуку",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_DATE": "Не вказано дату публікації відгуку",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_OWNER_RESPONSE": "Не вказано відповідь на відгук",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_OWNER_RESPONSE_DATE": "Не вказано дату відповіді на відгук",
	"webApps.view.PublishTab.publish.details.CONTENT_RENDER_ERROR": "Невідома помилка",
	"webApps.view.PublishTab.publish.details.NO_GTAG_ID": "Вкажіть Google Analytics ID",
	"webApps.view.PublishTab.publish.details.NO_GTAG_EVENT_NAME": "Вкажіть Google Analytics подію встановлення",
	"webApps.view.PublishTab.publish.details.NO_CLARITY_ID": "Вкажіть Microsoft Clarity ID",
	"webApps.view.PublishTab.publish.details.NO_GOOGLE_TRACKING_ENTRY": "Вкажіть домен для інтеграції Google Ads",
	"webApps.view.PublishTab.publish.details.NO_FACEBOOK_AUTH_DATA": "Вкажіть Facebook Pixel",
	"webApps.view.PublishTab.publish.details.NO_ON_INSTALL_WEBHOOK_URL": "Вкажіть валідний URL для вебхука",
	"webApps.view.PublishTab.publish.details.NO_REMOTE_EVENT_ON_REGISTRATION": "Не вказано назви подій встановлення",
	"webApps.view.PublishTab.publish.details.NO_REMOTE_EVENT_ON_DEPOSIT": "Не вказано назви подій депозиту",
	"webApps.view.PublishTab.publish.details.EMPTY_PRIMARY_OFFER_LINK": "Не вказано посилання на основний оффер",
	"webApps.view.PublishTab.publish.details.NO_CONDITION_VALUES": "У умови не вказані значення",
	"webApps.view.PublishTab.publish.details.NO_THRESHOLD_VALUE": "Не вказано порогове значення",
	"webApps.view.PublishTab.publish.details.EMPTY_ALTERNATIVE_OFFER_LINK": "Одне з посилань на альтернативні оффери не вказано",
	"webApps.view.PublishTab.publish.details.EMPTY_REDIRECT_TARGET_URL": "Не вказано цільове посилання редиректу",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_REDIRECT_URL": "Не вказано посилання для клоакінгу",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_WHITE_PAGE": "Не вказано White Page для клоакінгу",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_COUNTRY_LIST": "Список дозволених країн порожній",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_OS_LIST": "Список дозволених операційних систем порожній",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_DEVICE_TYPE_LIST": "Список дозволених типів пристроїв порожній",

	"webApps.view.PublishTab.publish.details.NO_NAME": "Вкажіть назву додатку",
	"webApps.view.PublishTab.publish.details.NO_OWNER": "Вкажіть власника додатку",
	"webApps.view.PublishTab.publish.details.NO_RATING": "Вкажіть рейтинг додатку",
	"webApps.view.PublishTab.publish.details.NO_RATINGS_COUNT": "Вкажіть кількість оцінок",
	"webApps.view.PublishTab.publish.details.NO_DOWNLOADS_COUNT": "Вкажіть кількість завантажень",
	"webApps.view.PublishTab.publish.details.NO_DESCRIPTION": "Вкажіть опис додатку",
	"webApps.view.PublishTab.publish.details.NO_UPDATED_DATE": "Вкажіть дату останнього оновлення",
	"webApps.view.PublishTab.publish.details.NO_TAGS": "Вкажіть теги додатку",
	"webApps.view.PublishTab.publish.details.NO_REVIEWS": "Додайте відгуки",

	"webApps.view.PublishTab.publish.description.title": "Публікація",
	"webApps.view.PublishTab.publish.description.text": "У цьому розділі ви можете переглянути, чи все гаразд із вашою PWA. У разі критичних помилок публікація неможлива. Щоб переглянути, що не так із вашим додатком, натисніть на відповідний пункт меню.",
	"webApps.view.PublishTab.publish.button": "Опублікувати",
	"webApps.view.PublishTab.publish.quickUnPublishConfirm": "Ви дійсно хочете приховати це додаток?",
	"webApps.view.PublishTab.publish.quickPublishConfirm": "Ви дійсно хочете опублікувати це додаток?",

	"webApps.view.PublishTab.unpublish.title": "Приховати додаток",
	"webApps.view.PublishTab.unpublish.description": "Переведіть додаток у статус чернетки, якщо хочете приховати його",
	"webApps.view.PublishTab.unpublish.button": "Приховати додаток",

	"webApps.view.PublishTab.unpublish.": "",
	"webApps.view.PublishTab.unpublish.inProgress.description": "Будь ласка, зачекайте...",
	"webApps.view.PublishTab.unpublish.inProgress.title": "Ховаємо додаток",
	"webApps.view.PublishTab.unpublish.waitTask.errorMessage": "Зверніться до адміністратора",
	"webApps.view.PublishTab.unpublish.waitTask.errorTitle": "Помилка під час сховування додатка",
	"webApps.view.PublishTab.unpublish.waitTask.successTitle": "Додаток сховано",
	"webApps.view.PublishTab.unpublish.waitTask.successMessage": "Додаток сховано",
	"webApps.view.PublishTab.publish.": "",
	"webApps.view.PublishTab.publish.inProgress.title": "Публікація додатка",
	"webApps.view.PublishTab.publish.inProgress.description": "Будь ласка, зачекайте...",
	"webApps.view.PublishTab.publish.waitTask.errorMessage": "Зверніться до адміністратора",
	"webApps.view.PublishTab.publish.waitTask.errorTitle": "Помилка під час публікації додатка",
	"webApps.view.PublishTab.publish.waitTask.successMessage": "Додаток опубліковано",
	"webApps.view.PublishTab.publish.waitTask.successTitle": "Додаток опубліковано",
	"webApps.view.PublishTab.platform.": "",
	"webApps.view.PublishTab.platform.inProgress.description": "Будь ласка, зачекайте...",
	"webApps.view.PublishTab.platform.inProgress.title": "Змінюємо спосіб розміщення",
	"webApps.view.PublishTab.platform.waitTask.errorMessage": "Зверніться до адміністратора",
	"webApps.view.PublishTab.platform.waitTask.errorTitle": "Помилка під час зміни способу розміщення",
	"webApps.view.PublishTab.platform.waitTask.successMessage": "Додаток перенесено",
	"webApps.view.PublishTab.platform.waitTask.successTitle": "Додаток перенесено"


};

const mainPage = {
	"pages.mainPage.title": "Головна сторінка",
	"pages.mainPage.teamMembers.all": "Усі користувачі",
	"pages.mainPage.pwa.all": "Усі додатки",
	"pages.mainPage.generalBadges.visits": "Візити",
	"pages.mainPage.generalBadges.uniqVisits": "Унікальні візити",
	"pages.mainPage.generalBadges.installs": "Встановлення",
	"pages.mainPage.generalBadges.registrations": "Реєстрації",
	"pages.mainPage.generalBadges.deposits": "Депозити",
	"pages.mainPage.cta.register": "C2R",
	"pages.mainPage.cta.install": "C2I",
	"pages.mainPage.cta.deposit": "C2D",

	"pages.mainPage.county": "Країни",
	"pages.mainPage.county.map": "Карта",
	"pages.mainPage.county.table": "Таблиця",

	"pages.mainPage.apps": "PWA",
	"pages.mainPage.sources": "Джерела",
	"pages.mainPage.devices": "Пристрої",
	"pages.mainPage.devices.browser": "Браузери",
	"pages.mainPage.devices.os": "Операційні системи",
	"pages.mainPage.pushStats": "Push-сповіщення",
}

const pushStats = {
	'pages.pushLogs.pushStats.sent': 'Надіслано',
	'pages.pushLogs.pushStats.open': 'Відкрито',
	'pages.pushLogs.pushStats.delivered': 'Доставлено',
	'pages.pushLogs.pushStats.failed': 'Помилка доставки',
	'pages.pushLogs.pushStats.title': 'Статистика',
}


const listWebApps = {
	"webApps.list.title": "WebApps",
	"webApps.list.create": "Нова запис",
	"webApps.list.table.identifier": "Ідентифікатор",
	"webApps.list.table.name": "Назва",
	"webApps.list.table.domain": "Домен",
	"webApps.list.table.locales": "Мови",
	"webApps.list.table.domain.NONE": "Немає",
	"webApps.list.table.status": "Статус публікації",
	"webApps.list.table.status.PUBLISHED": "Опубліковано",
	"webApps.list.table.status.DRAFT": "Чернетка",
	"webApps.list.table.status.DEFAULT": "Неопубліковано",
	"webApps.list.table.statistics": "Статистика відвідувань за 24 години",
	"webApps.list.table.statistics.uniq": "Унікальних",
	"webApps.list.table.statistics.installs": "Встановлень",
	"webApps.list.table.actions": "Дії",
	"webApps.list.table.actions.edit": "Редагувати",
	"webApps.list.table.actions.clone": "Клонувати",
	"webApps.list.create.title": "Нове PWA",
	"webApps.list.create.name": "Назва PWA",
	"webApps.list.clone.title": "Клонування PWA",
	"webApps.list.clone.name": "Назва PWA"
};

const uptimeChecker = {
	"domainsPage.view.status.title": "Статус домену",

	"domainsPage.list.table.uptime.ok": "Домен працездатний",
	"domainsPage.list.table.uptime.warning": "Домен частково працездатний",
	"domainsPage.list.table.uptime.error": "Помилка перевірки",
	"domainsPage.view.uptime.title.ok": "Домен працездатний",
	"domainsPage.view.uptime.desc.ok": "Домен працездатний",
	"domainsPage.view.uptime.title.warning": "Домен частково працездатний",
	"domainsPage.view.uptime.desc.warning": "Домен працює, але застосунок не знайдено",
	"domainsPage.view.uptime.title.error": "Помилка перевірки",
	"domainsPage.view.uptime.desc.error": "Домен не працездатний",
}

const domainsPageList = {

	"domainsPage.list.title": "Домени",
	"domainsPage.list.create.label": "Додати домен",
	"domainsPage.list.create.link": "Додати домен",
	"domainsPage.list.create.purchase": "Купити домен",

	"domainsPage.list.table.identifier": "Ідентифікатор",
	"domainsPage.list.table.domainName": "Домен",
	"domainsPage.list.table.registrar": "Реєстратор",
	"domainsPage.list.table.state": "Стан",
	"domainsPage.list.table.actions": "Дії",
	"domainsPage.list.table.actions.edit": "Редагувати",
	"domainsPage.list.table.state.ADDED": "Додано",
	"domainsPage.list.table.state.WAIT": "Очікує викупу",
	"domainsPage.list.table.state.IN_PROGRESS": "В процесі викупу",
	"domainsPage.list.table.state.DONE": "Викуплено",
	"domainsPage.list.table.state.ERROR": "Помилка викупу",
	"domainsPage.list.table.state.UNKNOWN": "Невідомий стан",

	"domainsPage.list.buy.label": "Придбати",
	"domainsPage.list.link.label": "Додати",
	"domainsPage.list.buy.modal.title": "Придбання домену",
	"domainsPage.list.buy.modal.buy": "Придбати",
	"domainsPage.list.link.modal.title": "Додавання домену",

}

const domainsPageView = {
	"domainsPage.view.title": "Редактор",
	"domainsPage.view.action.delete": "Видалити",
	"domainsPage.view.form.title": "Налаштування",
	"domainsPage.view.form.domain": "Домен сайту",
	"domainsPage.view.form.comment": "Коментар",
	"domainsPage.view.status.WAIT": "Система чекає, коли працівник візьме завдання",
	"domainsPage.view.status.IN_PROGRESS": "Завдання виконується. Поточна дія:",
	"domainsPage.view.status.DONE": "Завдання повністю виконано",
	"domainsPage.view.status.ERROR": "Під час виконання завдання сталася помилка:",
	"domainsPage.view.status.link.label": "Перейти до завдання покупки цього домену",

	"domainsPage.view.form.allowSplit": "Дозволити використання цього домену кількома PWA",
	"domainsPage.view.form.split.target": "Де будуть розміщуватись PWA",
	"domainsPage.view.form.split.title": "Налаштування вагів",
	"domainsPage.view.form.split.weight": "Вага даної PWA",
	"domainsPage.view.form.split.reroll": "Скинути ключ вагів",
	"domainsPage.view.form.split.rerollSuccess": "Ключ вагів скинуто, не забудьте зберегти",

}

const webAppsTrackerTab = {
	"webApps.view.TrackerTab.title": "Оферта",
	"webApps.view.TrackerTab.description.1": "Введіть посилання на оферту, куди будуть потрапляти користувачі PWA після встановлення та запуску додатка.",
	"webApps.view.TrackerTab.description.2": "Використовуйте посилання без query параметрів, оскільки вони будуть додані автоматично на основі параметрів, за якими користувач перейшов на екран встановлення PWA.",

	"webApps.view.TrackerTab.primaryLink.title": "Основне посилання",
	"webApps.view.TrackerTab.primaryLink.label": "Посилання на оферту",

	"webApps.view.TrackerTab.primaryLink.type.label": "Тип посилання",
	"webApps.view.TrackerTab.primaryLink.type.static": "Статичне посилання",
	"webApps.view.TrackerTab.primaryLink.type.dynamic": "Динамічне посилання (підключення до keitaro)",
	"webApps.view.TrackerTab.primaryLink.kDomain": "Домен keitaro (https://)",
	"webApps.view.TrackerTab.primaryLink.token": "Ключ компанії",
	"webApps.view.TrackerTab.primaryLink.kFallback.label": "Резервне посилання",
	"webApps.view.TrackerTab.primaryLink.kFallback.description": "Якщо система не зможе підключитися до вашого keitaro, буде використане наступне посилання",

	"webApps.view.TrackerTab.rules.title": "Правила",
	"webApps.view.TrackerTab.rules.desc.1": "Ви можете додати кілька правил, які перевизначатимуть основне посилання.",
	"webApps.view.TrackerTab.rules.desc.2": "Якщо умови не спрацьовують, буде використане основне посилання.",
	"webApps.view.TrackerTab.rules.enabled": "Варіант активний?",
	"webApps.view.TrackerTab.rules.comment.label": "Коментар до варіанту",
	"webApps.view.TrackerTab.rules.comment.description": "Для вашої зручності",
	"webApps.view.TrackerTab.rules.conditions.title": "Умови спрацьовування",
	"webApps.view.TrackerTab.rules.conditions.button.add": "Додати умову",
	"webApps.view.TrackerTab.rules.offerLink.title": "Посилання",
	"webApps.view.TrackerTab.rules.offerLink.label": "Посилання для перенаправлення",
	"webApps.view.TrackerTab.rules.offerLink.button.add": "Додати посилання",

	"webApps.view.TrackerTab.rules.offerLink.type.label": "Тип посилання",
	"webApps.view.TrackerTab.rules.offerLink.type.static": "Статичне посилання",
	"webApps.view.TrackerTab.rules.offerLink.type.dynamic": "Динамічне посилання (підключення до keitaro)",
	"webApps.view.TrackerTab.rules.offerLink.kDomain": "Домен keitaro (https://)",
	"webApps.view.TrackerTab.rules.offerLink.token": "Ключ компанії",
	"webApps.view.TrackerTab.rules.offerLink.kFallback.label": "Резервне посилання",
	"webApps.view.TrackerTab.rules.offerLink.kFallback.description": "Якщо система не зможе підключитися до вашого keitaro, буде використане наступне посилання",

	"webApps.view.TrackerTab.resetClientCache.title": "Скинути кеш посилань",
	"webApps.view.TrackerTab.resetClientCache.description": "За потреби ви можете скинути кеш посилань, щоб вони оновилися.",
	"webApps.view.TrackerTab.resetClientCache.button": "Скинути",
	"webApps.view.TrackerTab.resetClientCache.success": "Кеш посилань скинуто. Не забудьте зберегти зміни!",
}


const profilePage = {
	'pages.profile.title': 'Профіль',
	'pages.profile.avatar': 'Аватар',
	'pages.profile.changePassword': 'Змінити пароль',
	'pages.profile.changePassword.success': 'Пароль успішно змінено',
	'pages.profile.changePassword.title': 'Змінити пароль',
	'pages.profile.changePassword.error.title': 'Помилка зміни пароля',
	'pages.profile.changePassword.error.INVALID_PASSWORD': 'Невірний старий пароль',
	'pages.profile.changePassword.oldPassword': 'Старий пароль',
	'pages.profile.changePassword.oldPassword.placeholder': 'Введіть старий пароль',
	'pages.profile.changePassword.newPassword': 'Новий пароль',
	'pages.profile.changePassword.newPassword.placeholder': 'Введіть новий пароль',
	'pages.profile.changePassword.change': 'Змінити',

	'pages.profile.tgConnect.success': 'Telegram успішно підключено! ({{tgId}})',
	'pages.profile.tgConnect.description': 'Підключіть Telegram: натисніть на кнопку нижче',
	'pages.profile.tgConnect.button': 'Підключити Telegram',
	'pages.profile.tgConnect.button.disconnect': 'Відв'
}

const descriptionTab = {
	"webApps.views.DescriptionTab.name.label": "Назва застосунку",
	"webApps.views.DescriptionTab.content.isVerify.label": "Застосунок верифіковано",
	"webApps.views.DescriptionTab.content.isVerify.description": "При встановленні цього параметра поряд із назвою застосунку з’являється знак верифікованого застосунку",
	"webApps.views.DescriptionTab.content.owner.label": "Розробник застосунку",
	"webApps.views.DescriptionTab.content.description.label": "Опис застосунку",
	"webApps.views.DescriptionTab.content.updatedDate.label": "Дата останнього оновлення застосунку",
	"webApps.views.DescriptionTab.content.": "",
	"webApps.views.DescriptionTab.content.relatedApps.app.rating": "Рейтинг застосунку",
	"webApps.views.DescriptionTab.content.relatedApps.app.url": "Посилання на застосунок",
	"webApps.views.DescriptionTab.content.relatedApps.app.name": "Назва застосунку",
	"webApps.views.DescriptionTab.content.relatedApps.position.bottom": "Знизу",
	"webApps.views.DescriptionTab.content.relatedApps.position.top": "Зверху",
	"webApps.views.DescriptionTab.content.relatedApps.position": "Положення блоку",
	"webApps.views.DescriptionTab.content.relatedApps.enabled": "Блок активний?",
	"webApps.views.DescriptionTab.content.relatedApps.title": "Схожі застосунки",

	"webApps.views.DescriptionTab.content.trailer.title": "Трейлер",
	"webApps.views.DescriptionTab.content.trailer.enabled": "Увімкнути трейлер",
	"webApps.views.DescriptionTab.content.trailer.url.label": "Посилання на трейлер",
	"webApps.views.DescriptionTab.content.trailer.url.decs": "Підтримуються YouTube та Facebook",
}

const webAppsTemplateEdit = {
	"webAppsTemplateEdit.playMarket.name": "Назва",
	"webAppsTemplateEdit.playMarket.isVerify": "Додаток верифіковано",
	"webAppsTemplateEdit.playMarket.owner": "Розробник додатку",
	"webAppsTemplateEdit.playMarket.rating": "Рейтинг",
	"webAppsTemplateEdit.playMarket.screens": "Екрани додатку",
	"webAppsTemplateEdit.playMarket.description": "Опис додатку",
	"webAppsTemplateEdit.playMarket.icon": "Іконка додатку",
	"webAppsTemplateEdit.playMarket.updatedDate": "Дата останнього оновлення додатку",
	"webAppsTemplateEdit.playMarket.tags": "Теги додатку",

	"webAppsTemplateEdit.rouletteBalls.icon": "Іконка PWA",
	"webAppsTemplateEdit.rouletteBalls.playButtonText": "Кнопка почати гру",
	"webAppsTemplateEdit.rouletteBalls.sign": "Знак валюти ($)",
	"webAppsTemplateEdit.rouletteBalls.mainHowToPlay": "Заголовок",
	"webAppsTemplateEdit.rouletteBalls.mainRules": "Підзаголовок",
	"webAppsTemplateEdit.rouletteBalls.mainBetSize": "Рядок 1",
	"webAppsTemplateEdit.rouletteBalls.mainLevel": "Рядок 2",
	"webAppsTemplateEdit.rouletteBalls.mainWin": "Рядок 3",
	"webAppsTemplateEdit.rouletteBalls.popup.group": "Спливаюче вікно",
	"webAppsTemplateEdit.rouletteBalls.popup.title": "Заголовок",
	"webAppsTemplateEdit.rouletteBalls.popup.winValue": "Винагорода",
	"webAppsTemplateEdit.rouletteBalls.popup.register": "Текст",
	"webAppsTemplateEdit.rouletteBalls.popup.popupButtonText": "Кнопка забрати винагороду",
	"webAppsTemplateEdit.rouletteBalls.collisionReward": "Винагорода",
	"webAppsTemplateEdit.rouletteBalls.popupTriggerValue": "Показувати попап, якщо значення досягло",
}

const pushStatsPage = {
	'pages.push.stats.menuItem': 'Статистика Push сповіщень',
	'pages.push.stats.title': 'Статистика Push сповіщень',

	"pages.push.stats.teamMembers.all": "Всі користувачі",
	"pages.push.stats.pwa.all": "Всі додатки",
	'pages.push.stats.top.sent.title': 'Топ відправлених',
	'pages.push.stats.top.opened.title': 'Топ відкритих',
	'pages.push.stats.top.notOpened.title': 'Топ не відкритих',
	'pages.push.stats.top.unsubsribed.title': 'Топ нікому не відправлено',
};



export const locale: Record<LocaleKeys, string> = {
	...pushStatsPage,
	...profilePage,
	...cfAccountsEdit,
	...cfAccountsList,
	...webAppDomainTab,
	...listWebApps,
	...webAppAnalyticsTab,
	...webAppPublishTab,
	...uptimeChecker,
	...domainsPageList,
	...domainsPageView,
	...mainPage,
	...pushStats,
	...webAppsTrackerTab,
	...descriptionTab,
	...webAppsTemplateEdit,

	// yoFormModal
	'yoFormModal.continue': 'Продовжити',

	// Auth page
	'auth.title': 'Welcome...',
	'auth.authError.title': 'Помилка авторизації',
	'auth.authError.message': 'Неправильний логін або пароль',
	'auth.login': 'Увійти',
	// Menu elements
	'menuItems.mainPage': 'Головна сторінка',
	'menuItems.webApps': 'Мої PWA',
	'menuItems.domains': 'Мої домени',
	'menuItems.leads': 'Ліди',
	'menuItems.fbPixels': 'Мої пікселі',
	'menuItems.googleTrackingEntry': 'Посилання для google ads',
	'menuItems.system': 'Система',
	'menuItems.system.adminUsers': 'Користувачі панелі',
	'menuItems.system.jobs': 'Завдання',
	'menuItems.system.adspect': 'Налаштування adspect',
	'menuItems.system.ua': 'UserAgent\'s ботiв',
	'menuItems.system.ip': 'IP блеклист',
	'menuItems.system.deeplSettings': 'Налаштування deepl',
	'menuItems.system.admSettings': 'Налаштування реєстратора admTools',
	'menuItems.system.godaddySettings':'Налаштування реєстратора GoDaddy',
	'menuItems.system.cloudflareSettings': 'Налаштування cloudflare',
	'menuItems.system.nameCheapSettings':'Налаштування реєстратора NameCheap',
	'menuItems.system.porkBunSettings':'Налаштування реєстратора PorkBun',
	"menuItems.system.openaiSettings": "Налаштування openai",
	'menuItems.system.cfAccounts':'Акаунти Cloudflare',
	'menuItems.system.exchangeRateSettings':'Налаштування ExchangeRate',
	'menuItems.system.domainRegistrarsSettings': 'Налаштування реєстраторів доменів',

	// jobs page
	'jobs.customConfirmPrompt': 'Ви дійсно хочете повторити це завдання?',
	'jobs.confirmRunningJobDelete': 'Завдання виконується в даний момент, і його видалення не рекомендується, продовжити?',
	'jobs.view.title': 'Перегляд завдання',
	'jobs.view.deleteButton': 'Видалити',
	'jobs.view.retryButton': 'Повторити завдання',
	'jobs.view.jobTypeCaption': 'Тип завдання',
	'jobs.view.jobTypeCaption.buyDomain': 'Тип завдання: Купити домен',
	'jobs.view.state.wait.title': 'Стан: Очікує',
	'jobs.view.state.wait.description': 'Система чекає, коли воркер візьме завдання',
	'jobs.view.state.inProgress.title': 'Стан: Виконується',
	'jobs.view.state.inProgress.description': 'Завдання виконується в даний момент. Поточна дія: ',
	'jobs.view.state.done.title': 'Стан: Виконано',
	'jobs.view.state.done.description': 'Завдання повністю виконано',
	'jobs.view.state.error.title': 'Стан: Помилка',
	'jobs.view.state.error.description': 'Під час виконання завдання сталася помилка: ',
	'jobs.view.logsButton': 'Логи завдання',
	'jobs.view.logsModalTitle': 'Логи завдання',

	'fbPixelsLogs.list.title': 'Логи пікселя',
	'fbPixelsLogs.list.createButton': 'Нове завдання',
	'fbPixelsLogs.list.tableHeader._id': 'Ідентифікатор',
	'fbPixelsLogs.list.tableHeader.createdAt': 'Дата запиту',
	'fbPixelsLogs.list.tableHeader.lead': 'Лід',
	'fbPixelsLogs.list.tableHeader.lead.empty': 'Лід відсутній',
	'fbPixelsLogs.list.tableHeader.event': 'Дата запиту',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully': 'Запит успішно надіслано',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully.true': 'Так',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully.false': 'Ні',
	'fbPixelsLogs.list.tableHeader.actions': 'Дії',
	'fbPixelsLogs.list.tableHeader.actions.edit': 'Редагувати',

	'fbPixelsLogs.view.title': 'Перегляд логу пікселя',
	'fbPixelsLogs.view.action.delete': 'Видалити',
	'fbPixelsLogs.view.fields.event': 'Тип події',
	'fbPixelsLogs.view.fields.sentSuccessfully': 'Подію успішно передано',
	'fbPixelsLogs.view.fields.fbTraceId': 'ID трасування Facebook',
	'fbPixelsLogs.view.fields.lead.empty': 'Лід відсутній',
	'fbPixelsLogs.view.fields.goToLead': 'Перейти до ліда',

	'fbPixelsLogs.view.fields.sentUnsuccessfully': 'Подію не передано',
	'fbPixelsLogs.view.fields.fbErrMessage': 'Повідомлення про помилку',
	'fbPixelsLogs.view.fields.fbErrCode': 'Код помилки',
	'fbPixelsLogs.view.fields.fbErrSubCode': 'Підкод помилки',
	'fbPixelsLogs.view.fields.fbErrType': 'Тип помилки',


	'jobs.list.title': 'Список завдань',
	'jobs.list.createJobButton': 'Нове завдання',
	'jobs.list.createJobTitle': 'Нове завдання',
	'jobs.list.tableHeader._id': 'Ідентифікатор',
	'jobs.list.tableHeader.type': 'Тип завдання',
	'jobs.list.tableHeader.state': 'Стан',
	'jobs.list.tableHeader.state.WAIT': 'Очікує',
	'jobs.list.tableHeader.state.IN_PROGRESS': 'В процесі',
	'jobs.list.tableHeader.state.DONE': 'Виконано',
	'jobs.list.tableHeader.state.ERROR': 'Помилка',
	'jobs.list.tableHeader.state.UNKNOWN': 'Невідомо',
	'jobs.list.tableHeader.createdAt': 'Дата створення завдання',
	'jobs.list.tableHeader.actions': 'Дії',
	'jobs.list.tableHeader.actions.edit': 'Редагувати',

	// general messages
	'settings.general.savedMessage': 'Успішно збережено',
	'settings.general.saveBth': 'Зберегти',

	// adm settings
	'settings.adm.title': 'Налаштування реєстратора доменів AdmTools',
	'settings.adm.apiKey': 'Токен доступу',
	'settings.adm.limitDomainPriceUAN': 'Максимальна ціна домену (UAH)',

	// godaddy settings
	'settings.godaddy.title': 'Налаштування реєстратора доменів GoDaddy',
	'settings.godaddy.apiKey': 'Api key',
	'settings.godaddy.secret': 'secret',

	// nameCheap settings
	'settings.nameCheap.title': 'Налаштування реєстратора доменів NameCheap',
	'settings.nameCheap.apiKey': 'Api key',
	'settings.nameCheap.baseUrl': 'baseUrl',
	'settings.nameCheap.apiUser': 'apiUser',
	'settings.nameCheap.clientIP': 'clientIP',

	// porkBun settings
	'settings.porkBun.title': 'Налаштування реєстратора доменів PorkBun',
	'settings.porkBun.apiKey': 'Api key',
	'settings.porkBun.secret': 'secret',

	// exchangeRate settings
	'settings.exchangeRate.title': 'Налаштування ExchangeRate',
	'settings.exchangeRate.apiKey': 'Api key',

	'leads.list.title': 'Ліди',
	'leads.list.onlyUniqs': 'Показувати лише унікальні ліди',
	'leads.list.tableHeader._id': 'Ідентифікатор',
	'leads.list.tableHeader.clientId': 'Ідентифікатор сесії клієнта',
	'leads.list.tableHeader.clientId.log': 'Лог цієї сесії',
	'leads.list.tableHeader.runId': 'ID сесії клієнта',
	'leads.list.tableHeader.runId.log': 'Лог цієї сесії',
	'leads.list.tableHeader.clientIp': 'IP адреса',
	'leads.list.tableHeader.browserName': 'Браузер',
	'leads.list.tableHeader.osName': 'Операційна система',
	'leads.list.tableHeader.deviceType': 'Тип пристрою',
	'leads.list.tableHeader.tryAppInstall': 'Спроба установки?',
	'leads.list.tableHeader.tryAppInstall.true': 'Так',
	'leads.list.tableHeader.tryAppInstall.false': 'Ні',
	'leads.list.tableHeader.appInstalled': 'Встановлено успішно?',
	'leads.list.tableHeader.appInstalled.true': 'Так',
	'leads.list.tableHeader.appInstalled.false': 'Ні',
	'leads.list.tableHeader.userRegistered': 'Користувач зареєстрований?',
	'leads.list.tableHeader.userRegistered.true': 'Так',
	'leads.list.tableHeader.userRegistered.false': 'Ні',
	'leads.list.tableHeader.userDeposited': 'Депозит внесено',
	'leads.list.tableHeader.userDeposited.true': 'Так',
	'leads.list.tableHeader.userDeposited.false': 'Ні',
	'leads.list.tableHeader.createdAt': 'Дата створення задачі',
	'leads.list.tableHeader.actions': 'Дії',
	'leads.list.tableHeader.actions.view': 'Переглянути',

	"leads.list.tableHeader.fireCloaking": "Клоака спрацювала?",
	"leads.list.tableHeader.fireCloaking.true": "Так",
	"leads.list.tableHeader.fireCloaking.false": "Ні",
	"leads.list.tableHeader.fireCloakingReason": "Причина клоаки",
	"leads.list.tableHeader.fireCloakingReason.badOS": "Непідходяща ОС",
	"leads.list.tableHeader.fireCloakingReason.badIp": "IP з чорного списку",
	"leads.list.tableHeader.fireCloakingReason.adspect": "adspect фільтр",
	"leads.list.tableHeader.fireCloakingReason.badUserAgent": "UserAgent з чорного списку",
	"leads.list.tableHeader.fireCloakingReason.noCountryCode": "cf-ipcountry недоступний",
	"leads.list.tableHeader.fireCloakingReason.badCountryCode": "Непідходяща країна",
	"leads.list.tableHeader.fireCloakingReason.badDeviceType": "Непідходящий тип пристрою",

	'leads.list.customFilters.leadSource': 'Джерело лідів',
	'leads.list.customFilters.leadSource.any': 'Всі',
	'leads.list.customFilters.leadSource.facebook': 'Facebook',
	'leads.list.customFilters.leadSource.google': 'Google',
	'leads.list.customFilters.refferer':'Реферер',

	"leads.view.title": "Перегляд ліда",
	"leads.view.delete": "Видалити",
	"leads.view.goToApp": "Перейти до застосунку",
	"leads.view.allLeadsForClient": "Усі ліди цього клієнта",
	"leads.view.clientLogs": "Логи клієнта",
	"leads.view.sessionLogs": "Логи цієї сесії",
	"leads.view.clientStatus": "Статус клієнта",
	"leads.view.appInstalled": "Застосунок встановлено",
	"leads.view.userRegistered": "Користувач зареєстрований",
	"leads.view.userDeposited": "Користувач поповнив баланс",
	"leads.view.clientIp": "IP клієнта",
	"leads.view.otherClientIps": "Інші відомі IP-адреси клієнтів",
	"leads.view.browserInfo": "Інформація про браузер",
	"leads.view.userAgent": "User agent",
	"leads.view.browserName": "Браузер",
	"leads.view.browserVersion": "Версія браузера",
	"leads.view.osName": "Операційна система",
	"leads.view.osVersion": "Версія операційної системи",
	"leads.view.deviceType": "Тип пристрою",
	"leads.view.analytics": "Аналітика",
	"leads.view.primary": "Основні",
	"leads.view.facebook": "Facebook",
	"leads.view.fbc": "FBC",
	"leads.view.fbp": "FBP",
	"leads.view.google": "Google",
	"leads.view.gclid": "GCLID",
	"leads.view.rawRequestHeaders": "Сирі заголовки запиту",
	"leads.view.rawQueryParams": "Сирі query параметри",
	"leads.view.showAll": "Показати все",
	"leads.view.hide": "Сховати",
	"leads.view.time": "Час",
	"leads.view.unknown": "Невідомо",
	"leads.view.referer": "Реферер",


	'components.timeRangeSelect.today': 'Сьогодні',
	'components.timeRangeSelect.yesterday': 'Вчора',
	'components.timeRangeSelect.currentWeek': 'Поточній тиждень',
	'components.timeRangeSelect.last7Days': 'Останні 7 днів',
	'components.timeRangeSelect.currentMonth': 'Поточний місяць',
	'components.timeRangeSelect.lastMonth': 'Попередній місяць',
	'components.timeRangeSelect.last30Days': 'Останні 30 днів',
	'components.timeRangeSelect.currentYear': 'Поточний рік',
	'components.timeRangeSelect.allTime': 'Увесь час',
	'components.timeRangeSelect.custom': 'Інтервал дат',

	'fbPixels.list.tableHeader._id': 'Ідентифікатор',
	'fbPixels.list.tableHeader.name': 'Назва',
	'fbPixels.list.tableHeader.pixel': 'Піксель',
	'fbPixels.list.tableHeader.actions': 'Дії',
	'fbPixels.modal.title': 'Новий піксель',
	'fbPixels.modal.label.name': 'Назва пікселя',
	'fbPixels.modal.label.pixel': 'Ідентифікатор пікселя',
	'fbPixels.modal.label.token': 'Токен для пікселя',
	'fbPixels.header.title': 'Мої пікселі',
	'fbPixels.header.button.newPixel': 'Новий піксель',

	'fbPixels.stat.title': 'Статистика пікселя',

	'fbPixels.edit.headerTitle': 'Редактор пікселя',
	'fbPixels.edit.logsButton': 'Логи',
	'fbPixels.edit.saveButton': 'Зберегти',
	'fbPixels.edit.deleteButton': 'Видалити',
	'fbPixels.edit.pixelNameLabel': 'Назва пікселя',
	'fbPixels.edit.pixelIdLabel': 'Ідентифікатор пікселя',
	'fbPixels.edit.pixelTokenLabel': 'Токен для пікселя',
	'fbPixels.edit.saveSuccess': 'Збереження успішне',

	'fbPixels.edit.eventsMapping.title': 'Маппнг подій',
	'fbPixels.edit.eventsMapping.installEventNameLabel': 'Передавати подію встановлення PWA як',
	'fbPixels.edit.eventsMapping.registrationEventNameLabel': 'Передавати подію реєстрації як',
	'fbPixels.edit.eventsMapping.depositEventNameLabel': 'Передавати подію внесення депозиту як',

	'fbPixels.stat.selectWebApp': 'Оберіть PWA',
	'fbPixels.stat.timeRange': 'Часовий період',
	'fbPixels.stat.installs': 'Передано подій встановлення',
	'fbPixels.stat.registrations': 'Передано подій реєстрації',
	'fbPixels.stat.deposits': 'Передано подій депозиту',
	'fbPixels.stat.installCount': 'Кількість встановлень',
	'fbPixels.stat.registrationCount': 'Кількість реєстрацій',
	'fbPixels.stat.depositCount': 'Кількість депозитів',

	'settings.openai.saveSuccess': 'Налаштування успішно збережено',
	'settings.openai.headerTitle': 'Налаштування OpenAI',
	'settings.openai.saveButton': 'Зберегти налаштування',
	'settings.openai.tokenLabel': 'Токен доступу до OpenAI',
	'settings.openai.endpointLabel': 'Ендпоінт для доступу до OpenAI (Необов\'язково)',
	'settings.openai.modelLabel': 'Модель OpenAI',
	'settings.openai.promptsTitle': 'Промпти',
	'settings.openai.generateDescriptionLabel': 'Генерація опису програми',
	'settings.openai.translateLabel': 'Переклад',
	'settings.openai.generateCommentsLabel': 'Генерація коментарів',

	'leads.list.tableHeader.isSubscribed': 'Підписка на push-сповіщення',
	'leads.list.tableHeader.isSubscribed.true': 'Підписаний',
	'leads.list.tableHeader.isSubscribed.false': 'Не підписаний',

	'leads.view.sendPush.title': 'Відправити push-сповіщення',
	'leads.view.sendPush.form.title': 'Заголовок',
	'leads.view.sendPush.form.message': 'Повідомлення',
	'leads.view.sendPush.success': 'Сповіщення успішно відправлено',

	'leads.view.push.sendPush': 'Відправити push-сповіщення',
	'leads.view.push.notSubscribed': 'Не підписаний на push-сповіщення',
	'leads.view.push.subscribed': 'Підписаний на push-сповіщення',

	"leads.view.sendPush.form.openApp": "Відкрити додаток",
	"leads.view.sendPush.form.openAppDescription": "Відкрити додаток при натисканні на сповіщення",
	"leads.view.sendPush.form.targetLink": "Посилання, куди буде направлений користувач після натискання на сповіщення",
	"leads.view.sendPush.form.image": "Зображення",


	"menuItems.pushJobs": "PUSH розсилки",
	"sendPush.list.tableHeader.appId": "Застосунок",
	"sendPush.list.tableHeader.appId.notSet": "Не задано",
	"sendPush.list.tableHeader.title": "Заголовок",
	"sendPush.list.tableHeader.createdAt": "Створено",
	"sendPush.list.tableHeader.actions.view": "Переглянути",
	"sendPush.list.createJob.title": "Створення PUSH розсилки",
	"sendPush.list.createJob.form.appId": "Застосунок",
	"sendPush.list.createJob.form.title": "Заголовок",
	"sendPush.list.createJob.form.message": "Повідомлення",
	"sendPush.list.createJob.form.openApp": "Відкрити застосунок",
	"sendPush.list.createJob.form.openAppDescription": "Відкрити застосунок при кліку на сповіщення",
	"sendPush.list.createJob.form.targetLink": "Посилання, куди буде спрямований користувач після кліку на сповіщення",
	"sendPush.list.createJob.form.image": "Зображення",
	"sendPush.list.title": "PUSH розсилки",
	"sendPush.list.actions.create": "Створити PUSH розсилку",

	"pushJobs.edit.title": "Перегляд PUSH розсилки",
	"pushJobs.edit.actions.remove": "Видалити",
	"pushJobs.edit.actions.retry": "Повторити PUSH розсилку",
	"pushJobs.edit.actions.retryConfirm": "Ви дійсно хочете повторити PUSH розсилку?",
	"pushJobs.edit.form.appId": "Додаток",
	"pushJobs.edit.form.title": "Заголовок",
	"pushJobs.edit.form.message": "Повідомлення",
	"pushJobs.edit.form.openApp": "Відкрити додаток",
	"pushJobs.edit.form.openAppDescription": "Відкрити додаток при натисканні на сповіщення",
	"pushJobs.edit.form.targetLink": "Посилання, куди буде направлений користувач після натискання на сповіщення",
	"pushJobs.edit.form.image": "Зображення",
	"pushJobs.edit.stats.details.hide": "Приховати",
	"pushJobs.edit.form.details": "Показати всі",
	"pushJobs.edit.stats.title": "Статистика PUSH розсилки",
	"pushJobs.edit.stats.details.title": "Детальна статистика PUSH розсилки",

	'menuItems.push': 'PUSH повідомлення',
	'menuItems.push.Jobs': 'Розсилки',
	'menuItems.push.Segments': 'Сегменти',
	'menuItems.push.Messages': 'Повідомлення',

	'push.messages.list.table.id': 'ID',
	'push.messages.list.table.comment': 'Коментар',
	'push.messages.list.table.view': 'Переглянути',
	'push.messages.list.title': 'Шаблони PUSH повідомлень',
	'push.messages.list.create': 'Створити шаблон',

	'push.messages.view.title': 'Шаблон PUSH повідомлення',
	'push.messages.view.save': 'Зберегти',
	'push.messages.view.remove': 'Видалити',
	'push.messages.view.fields.comment': 'Коментар',
	'push.messages.view.fields.comment.description': 'Коментар для вашої зручності',
	'push.messages.view.items': 'Повідомлення',
	'push.messages.view.items.add': 'Додати повідомлення',
	'push.messages.view.fields.language': 'Мова',
	'push.messages.view.items.title': 'Заголовок',
	'push.messages.view.items.message': 'Повідомлення',
	'push.messages.view.items.image': 'Зображення',
	'push.messages.view.fallbackLanguage': 'Мова за замовчуванням',
	'push.messages.view.fallbackLanguage.description': 'Якщо жодна з вказаних мов не підходить для користувача, то буде використана ця мова',
	'push.messages.view.fields.fallbackLanguage': 'Мова за замовчуванням',
	'push.messages.view.fields.autoTranslate': 'Автоматично перекладити повідомлення на мову користувача',
	'push.messages.view.save.invalid': 'Дані не пройшли перевірку, зберегти все одно?',

	'push.segments.list.table.id': 'ID',
	'push.segments.list.table.comment': 'Коментар',
	'push.segments.list.table.actions.view': 'Переглянути',
	'push.segments.list.title': 'Сегменти користувачів',
	'push.segments.list.create': 'Створити сегмент',

	'push.segments.edit.saveSuccess': 'Сегмент успішно збережено',
	'push.segments.edit.title': 'Перегляд сегмента',
	'push.segments.edit.actions.save': 'Зберегти',
	'push.segments.edit.actions.remove': 'Видалити',

	'push.segments.edit.fields.comment': 'Коментар',
	'push.segments.edit.fields.comment.description': 'Коментар для вашої зручності',
	'push.segments.edit.fields.webApps': 'Додатки',
	'push.segments.edit.flags': 'Статус клієнта',
	'push.segments.edit.flags.isTryAppInstall': 'Користувач намагався встановити додаток',
	'push.segments.edit.flags.isAppInstalled': 'Додаток встановлено',
	'push.segments.edit.flags.isAppRunned': 'Додаток запущено',
	'push.segments.edit.flags.isRegistered': 'Користувач зареєстрований',
	'push.segments.edit.flags.isDeposited': 'Користувач вніс депозит',

	'push.jobs.list.table.id': 'ID',
	'push.jobs.list.table.enabled': 'Увімкнено',
	'push.jobs.list.table.enabled.true': 'Так',
	'push.jobs.list.table.enabled.false': 'Ні',
	'push.jobs.list.table.comment': 'Коментар',
	'push.jobs.list.table.runMode': 'Режим запуску',
	'push.jobs.list.table.runMode.manual': 'Вручну',
	'push.jobs.list.table.runMode.schedule': 'За розкладом',
	'push.jobs.list.table.runMode.trigger': 'За тригером',
	'push.jobs.list.table.actions': 'Дії',
	'push.jobs.list.table.actions.view': 'Переглянути',
	'push.jobs.list.title': 'Розсилки PUSH',
	'push.jobs.list.create': 'Створити розсилку',

	'push.jobs.edit.saveSuccess': 'Розсилку успішно збережено',
	'push.jobs.edit.title': 'Перегляд розсилки',
	'push.jobs.edit.actions.save': 'Зберегти',
	'push.jobs.edit.actions.remove': 'Видалити',
	'push.jobs.edit.fields.comment': 'Коментар',
	'push.jobs.edit.fields.comment.description': 'Коментар для вашої зручності',
	'push.jobs.edit.fields.enabled': 'Увімкнути розсилку',
	'push.jobs.edit.fields.enabled.description': 'Увімкнути або вимкнути розсилку',
	'push.jobs.edit.fields.messages': 'Повідомлення',
	'push.jobs.edit.fields.messages.description': 'Повідомлення для розсилки, якщо вибрати кілька, буде використано одне з них',
	'push.jobs.edit.fields.segments': 'Сегменти користувачів',
	'push.jobs.edit.fields.segments.description': 'Сегменти користувачів',
	'push.jobs.edit.fields.runMode': 'Режим запуску',
	'push.jobs.edit.fields.runMode.description': 'Режим запуску розсилки',
	'push.jobs.edit.fields.runMode.manual': 'Вручну',
	'push.jobs.edit.fields.runMode.schedule': 'За розкладом',
	'push.jobs.edit.fields.runMode.trigger': 'За тригером',
	'push.jobs.edit.fields.scheduleProps.nextRun': 'Наступний запуск',
	'push.jobs.edit.fields.scheduleProps.repeat': 'Повторити',
	'push.jobs.edit.fields.scheduleProps.repeatPeriod': 'Період повтору',
	'push.jobs.edit.fields.triggerProps.triggers.add': 'Додати тригер',

	'push.jobs.edit.addTrigger.title': 'Додати тригер',

	'push.jobs.edit.addTrigger.variants.onTryAppInstall': 'Користувач почав установку програми',
	'push.jobs.edit.addTrigger.variants.onAppInstalled': 'Додаток успішно встановлено користувачем',
	'push.jobs.edit.addTrigger.variants.appNotInstalled': 'Додаток не встановлено користувачем',
	'push.jobs.edit.addTrigger.variants.onAppRunned': 'Користувач запустив програму',
	'push.jobs.edit.addTrigger.variants.appNotRunned': 'Користувач не запустив програму',
	'push.jobs.edit.addTrigger.variants.onRegister': 'Користувач зареєструвався',
	'push.jobs.edit.addTrigger.variants.notRegister': 'Користувач не зареєструвався',
	'push.jobs.edit.addTrigger.variants.onDeposit': 'Користувач здійснив депозит',
	'push.jobs.edit.addTrigger.variants.notDeposit': 'Користувач не здійснив депозит',

	'push.jobs.edit.addTrigger.for': 'Час пройшло',
	'push.jobs.edit.addTrigger.triggerType': 'Тип тригера',

	'doc.periodInput.title': 'Приклади',
	'doc.periodInput.30s': '"30s" = 30 секунд',
	'doc.periodInput.15m': '"15m" = 15 хвилин',
	'doc.periodInput.2h': '"2h" = 2 години',
	'doc.periodInput.1d': '"1d" = 1 день',
	'doc.periodInput.1w': '"1w" = 1 тиждень',
	'doc.periodInput.1M': '"1M" = 1 місяць',

	'menuItems.whitePages': 'Білі сторінки',
	'whitePages.list.table.id': 'ID',
	'whitePages.list.table.name': 'Назва',
	'whitePages.list.table.actions': 'Дії',
	'whitePages.list.table.actions.view': 'Переглянути',
	'whitePages.list.createItem.title': 'Завантажити лендинг',
	'whitePages.list.createItem.label.name': 'Назва лендинга',
	'whitePages.list.createItem.description.name': 'Не обов\'язково, для вашої зручності',
	'whitePages.list.createItem.label.file': 'Лендинг',
	'whitePages.list.createItem.actions': 'Дії',
	'whitePages.list.description': 'Завантажуйте лендинги для захисту ваших PWA',
	'whitePages.list.createItem.action': 'Завантажити лендинг',

	'whitePages.view.saveSuccess': 'Лендинг успішно збережено',
	'whitePages.view.title': 'Лендинг',
	'whitePages.view.landingInfo.title': 'Інформація про лендинг',
	'whitePages.view.landingInfo.notFoundIndex': 'index.html не знайдено',
	'whitePages.view.landingInfo.assetsFolderWarning': 'Не використовуйте папку assets, вона може конфліктувати з конфігурацією PWA',
	'whitePages.view.landingInfo.swWarning': 'Не використовуйте sw.js, вона може конфліктувати з конфігурацією PWA',
	'whitePages.view.landingInfo.stripLevel': 'index.html не знаходиться в корені архіву, рекомендується перепакувати, щоб файли сайту не лежали в підпапці',
	'whitePages.view.landingInfo.type': 'Тип',
	'whitePages.view.landingInfo.path': 'Шлях',
	'whitePages.view.landingInfo.size': 'Розмір',
	'whitePages.view.saveButton': 'Зберегти',
	'whitePages.view.removeButton': 'Видалити',
	'whitePages.view.label.name': 'Назва',
	'whitePages.view.landingInfo.site': 'Сайт',

	"webApps.view.title": "Налаштування PWA",
	"webApps.view.saved": "Збереження успішне",
	"webApps.view.unnamed": "Без назви",
	"webApps.view.header.leads": "Ліди",
	"webApps.view.header.save": "Зберегти",
	"webApps.view.header.delete": "Видалити",

	"webApps.view.tabs.list.base": "Загальні налаштування",
	"webApps.view.tabs.list.domain": "Домен",
	"webApps.view.tabs.list.tracker": "Трекер",
	"webApps.view.tabs.list.content": "Контент",
	"webApps.view.tabs.list.analytics": "Аналітика",
	"webApps.view.tabs.list.push": "PUSH сповіщення",
	"webApps.view.tabs.list.ab": "A/B тестування",
	"webApps.view.tabs.list.cloaking": "Клоакінг",
	"webApps.view.tabs.list.publish": "Публікація",

	"webApps.view.tabs.list.stats": "Статистика",
	"webApps.view.tabs.list.links": "Посилання",

	"webApps.view.GeneralTab.tryAddNewUser.title": "Новий користувач",
	"webApps.view.GeneralTab.tryAddNewUser.button": "Додати нового користувача",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.label": "Роль",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.editor": "Редактор",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.viewer": "Глядач",

	"webApps.view.GeneralTab.FindUserByName.description.user": "Користувач",

	"webApps.view.GeneralTab.form.name": "Назва PWA",
	"webApps.view.GeneralTab.form.sharing.label": "Шеринг PWA",

	"webApps.views.ContentTab.tabsList.languages": "Мови",
	"webApps.views.ContentTab.tabsList.description": "Опис",
	"webApps.views.ContentTab.tabsList.images": "Зображення",
	"webApps.views.ContentTab.tabsList.reviews": "Відгуки та оцінки",
	"webApps.views.ContentTab.tabsList.additional": "Додаткові налаштування",
	"webApps.views.ContentTab.LanguagesTab.activeLanguages.title": "Активні мови",
	"webApps.views.ContentTab.LanguagesTab.isDefault.label": "Основна мова?",
	"webApps.views.ContentTab.LanguagesTab.translateEngine.label": "Платформа перекладу",
	"webApps.views.ContentTab.LanguagesTab.addLanguage.buttonLabel": "Додати мову",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.search.label": "Введіть назву мови",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.submit.label": "Додати мову",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.copyAndTranslate.label": "Копіювати і перекласти з {{lang}}",
	"webApps.views.ContentTab.LanguagesTab.DeleteLangButton.cancel": "Скасувати",
	"webApps.views.ContentTab.LanguagesTab.DeleteLangButton.delete": "Видалити мову",
	"webApps.views.ContentSettingsTab.security.title": "Параметри обробки персональних даних",
	"webApps.views.ContentSettingsTab.security.noDataCollected": "Додаток не збирає дані",
	"webApps.views.ContentSettingsTab.security.noDataShare": "Додаток не передає дані третім особам",

	"webApps.views.ContentSettingsTab.installButtonBehavior.title": "Поведіянка кнопки Встановити",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.title": "Очікування події встановлення",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.description": "Після натискання кнопки встановити PWA, браузер чекатиме події встановлення.",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.scrollToBottom.label": "Прокрутити вниз після натискання",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.scrollToBottom.description": "Спонукати користувача вручну прокрутити вгору, тим самим викликаючи подію встановлення",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.title": "Прокрутити вниз",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.description": "Після натискання кнопки встановити PWA, браузер прокрутить вниз.",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.clickCount.label": "Кількість кліків, необхідна для прокрутки",
	"webApps.views.ContentSettingsTab.behavior.none.title": "Немає",
	"webApps.views.ContentSettingsTab.behavior.none.description": "Жодні шаблони для викликання події встановлення не будуть використані.",
	"webApps.views.ContentSettingsTab.tweaks.title": "Твіки PWA",
	"webApps.views.ContentSettingsTab.tweaks.label": "Приховати заголовок додатка",
	"webApps.views.ContentSettingsTab.tweaks.description": "При встановленні цього параметра заголовок додатка буде приховано",
	"webApps.views.ContentSettingsTab.tweaks.reloadPageIfInstallNotAllowed": "Перезавантажити сторінку, якщо встановлення PWA не дозволено",
	"webApps.views.ContentSettingsTab.tweaks.installButtonAnimSpeed": "Швидкість анімації кнопки встановлення",

	"webApps.views.DescriptionTab.name.label": "Назва додатка",

	"webApps.view.GenerateReviewsModal.title": "Згенерувати відгуки",
	"webApps.view.GenerateReviewsModal.prompt.label": "Промпт",
	"webApps.view.GenerateReviewsModal.prompt.description": "Напишіть промпт для генерації контенту",
	"webApps.view.GenerateReviewsModal.amount.label": "Кількість відгуків",
	"webApps.view.GenerateReviewsModal.submitButton": "Генерувати",
	"webApps.view.GenerateReviewsModal.reviews.title": "Відгуки",
	"webApps.view.GenerateReviewsModal.reviews.showTranslated": "Показати переклад",
	"webApps.view.GenerateReviewsModal.reviews.row.name": "Ім'я",
	"webApps.view.GenerateReviewsModal.reviews.row.rating": "Рейтинг",
	"webApps.view.GenerateReviewsModal.reviews.row.text": "Текст",
	"webApps.view.GenerateReviewsModal.reviews.row.reviewRating": "Рейтинг відгуку",
	"webApps.view.GenerateReviewsModal.reviews.row.commentDate": "Дата коментаря",
	"webApps.view.GenerateReviewsModal.reviews.row.addToReviews": "Додати до відгуків",
	"webApps.view.GenerateReviewsModal.reviews.row.delete": "Видалити",

	"webApps.view.ImagesTab.icon.title": "Іконка додатка",
	"webApps.view.ImagesTab.useIconFromBaseLanguage": "Використовувати іконку з базової мови",
	"webApps.view.ImagesTab.useScreensFromBaseLanguage.title": "Екрани додатка",
	"webApps.view.ImagesTab.useScreensFromBaseLanguage.label": "Використовувати екрани з базової мови",

	"webApps.view.ReviewForm.avatar": "Аватар",
	"webApps.view.ReviewForm.name": "Ім'я",
	"webApps.view.ReviewForm.text": "Текст відгуку",
	"webApps.view.ReviewForm.rating": "Рейтинг",
	"webApps.view.ReviewForm.reviewRating": "Скільки людей вважають відгук корисним",
	"webApps.view.ReviewForm.commentDate": "Дата коментаря",
	"webApps.view.ReviewsTab.tryAddComment.title": "Додати відгук",
	"webApps.view.ReviewsTab.title": "Оцінки",
	"webApps.view.ReviewsTab.useRatingFromBaseLanguage": "Використовувати рейтинг з базової мови",
	"webApps.view.ReviewsTab.rating.legend": "Загальні оцінки",
	"webApps.view.ReviewsTab.rating": "Рейтинг додатка",
	"webApps.view.ReviewsTab.ratingsCount": "Кількість оцінок додатка",
	"webApps.view.ReviewsTab.downloadsCount": "Кількість завантажень додатка",
	"webApps.view.ReviewsTab.ratingValues.legend": "Оцінки докладно (використовуються для відгуків)",
	"webApps.view.ReviewsTab.reviews.title": "Відгуки",
	"webApps.view.ReviewsTab.reviews.button.add": "Додати коментар",
	"webApps.view.ReviewsTab.reviews.button.generate": "Згенерувати коментарі",


	"webApps.view.PushTab.title": "Push повідомлення",
	"webApps.view.PushTab.selfPush.enabled": "Увімкнено",
	"webApps.view.PushTab.selfPush.alert": "Для роботи push повідомлень необхідно, щоб користувач підписався на них. Будь ласка, оберіть один або кілька варіантів, як він буде це робити.",
	"webApps.view.PushTab.selfPush.promptOnInstall": "Показувати повідомлення при встановленні",
	"webApps.view.PushTab.selfPush.promptOnStart.label": "Показувати повідомлення при запуску PWA",
	"webApps.view.PushTab.selfPush.promptOnStart.description": "Рекомендується",

	"webApps.view.PushTab.oneSignal.title": "Інтеграція з oneSignal",
	"webApps.view.PushTab.oneSignal.description1": "Налаштуйте інтеграцію з oneSignal для відправки push-повідомлень користувачам вашого сайту.",
	"webApps.view.PushTab.oneSignal.description2": "Увімкніть опцію і вкажіть ідентифікатор сайту для активації цієї функції.",
	"webApps.view.PushTab.oneSignal.enabled": "Увімкнено",
	"webApps.view.PushTab.oneSignal.appId.label": "Ідентифікатор сайту в oneSignal",
	"webApps.view.PushTab.oneSignal.alert.t1": "У розділі",
	"webApps.view.PushTab.oneSignal.alert.t2": "увімкніть опцію",
	"webApps.view.PushTab.oneSignal.alert.replace.title": "Заповніть поля наступними значеннями:",

	"webApps.view.ABTab.title": "A/B тестування",
	"webApps.view.ABTab.enabled": "Увімкнути A/B тестування",
	"webApps.view.ABTab.rules.header": "Правило",
	"webApps.view.ABTab.rules.enabled": "Правило активне?",
	"webApps.view.ABTab.rules.comment.label": "Коментар до правила",
	"webApps.view.ABTab.rules.comment.description": "Для вашої зручності",
	"webApps.view.ABTab.rules.conditions.title": "Умови спрацьовування",
	"webApps.view.ABTab.rules.conditions.header": "Умова",
	"webApps.view.ABTab.rules.conditions.add": "Додати умову",
	"webApps.view.ABTab.rules.actionType.label": "Дія",
	"webApps.view.ABTab.rules.actionType.redirect": "Перенаправлення",
	"webApps.view.ABTab.rules.actionType.blankPage": "Показати порожню сторінку",
	"webApps.view.ABTab.rules.actionType.showRawContent": "Показати сторінку HTML",
	"webApps.view.ABTab.rules.actionType.rawContent.content.label": "Сторінка у форматі HTML",

	"webApps.view.ABTab.rules.actionType.redirect.targetUrl.label": "Посилання на перенаправлення",
	"webApps.view.ABTab.rules.actionType.saveOriginalQueryString": "Зберігати оригінальні query параметри (UTM мітки)",
	"webApps.view.ABTab.rules.add": "Додати нове правило",

	"webApps.view.CloakingTab.title": "Ai Cloaking",
	"webApps.view.CloakingTab.enabled": "AI cloaking увімкнено",
	"webApps.view.CloakingTab.useIpBlacklist.legend": "Перевірка IP адреси",
	"webApps.view.CloakingTab.useIpBlacklist.label": "Перевірка IP у чорному списку",
	"webApps.view.CloakingTab.useIpBlacklist.description": "Перевіряти IP адресу на наявність у локальному чорному списку",

	"webApps.view.CloakingTab.useUaBlacklist.legend": "Перевірка User Agent",
	"webApps.view.CloakingTab.useUaBlacklist.label": "Перевірка User Agent у чорному списку",
	"webApps.view.CloakingTab.useUaBlacklist.description": "Перевіряти User Agent на наявність у локальному чорному списку",

	"webApps.view.CloakingTab.countryList.legend": "Перевірка GEO",
	"webApps.view.CloakingTab.countryListMode.label": "Режим перевірки",
	"webApps.view.CloakingTab.countryListMode.WHITELIST": "Дозволити вказані країни",
	"webApps.view.CloakingTab.countryListMode.BLACKLIST": "Заборонити вказані країни",

	"webApps.view.CloakingTab.useDeviceType.legend": "Перевірка типу пристрою",
	"webApps.view.CloakingTab.useDeviceType.label": "Перевірка увімкнена",
	"webApps.view.CloakingTab.deviceTypeListMode.label": "Режим перевірки",
	"webApps.view.CloakingTab.deviceTypeListMode.WHITELIST": "Дозволити вказані типи пристроїв",
	"webApps.view.CloakingTab.deviceTypeListMode.BLACKLIST": "Заборонити вказані типи пристроїв",

	"webApps.view.CloakingTab.useOSList.legend": "Перевірка типу OS",
	"webApps.view.CloakingTab.useOSList.label": "Перевірка увімкнена",
	"webApps.view.CloakingTab.osListMode.label": "Режим перевірки",
	"webApps.view.CloakingTab.osListMode.WHITELIST": "Дозволити вказані типи OS",
	"webApps.view.CloakingTab.osListMode.BLACKLIST": "Заборонити вказані типи OS",

	"webApps.view.CloakingTab.useAdspect.legend": "Додаткова перевірка за базами adspect",
	"webApps.view.CloakingTab.useAdspect.label": "Перевірка увімкнена",
	"webApps.view.CloakingTab.adspectStreamId.label": "Ваш stream ID",
	"webApps.view.CloakingTab.adspectStreamId.placeholder": "Не обов'язково",

	"webApps.view.CloakingTab.rejectAction.legend": "Дії при спрацьовуванні фільтра",
	"webApps.view.CloakingTab.rejectAction.label": "Режим перевірки",
	"webApps.view.CloakingTab.rejectAction.SHOW_200": "Показати порожню сторінку з кодом 200",
	"webApps.view.CloakingTab.rejectAction.SHOW_404": "Показати порожню сторінку з кодом 404",
	"webApps.view.CloakingTab.rejectAction.REDIRECT": "Виконати перенаправлення",
	"webApps.view.CloakingTab.rejectAction.WHITE_PAGE": "Відобразити лендинг (white page)",

	"webApps.view.CloakingTab.redirectUrl.label": "URL перенаправлення",
	"webApps.view.CloakingTab.whitePageSelect.label": "Виберіть лендинг для відображення",

	"webApps.view.PublishTab.title": "Публікація PWA",

	"leads.view.timezone": "Часовий пояс",
	"push.jobs.edit.isDefault": "Це правило за замовчуванням",
	"push.jobs.edit.isDefault.description": "Це правило є правилом за замовчуванням. Ви можете його розблокувати, і воно буде доступне для всіх ваших PWA",
	"push.jobs.edit.isDefault.unlock": "Розблокувати",
	"push.jobs.edit.addTrigger.delay": "Затримка перед запуском",
	"push.jobs.edit.addTrigger.variants.pushSubscribe": "Користувач підписався на розсилку",
	"webApps.view.PushTab.selfPush.useDefaultPush.label": "Використовувати правила за замовчуванням",
	"webApps.view.PushTab.selfPush.useDefaultPush.description": "Для розсилки пушів будуть використовуватися тільки правила за замовчуванням, позначені міткою DEFAULT",


	"webApps.archive.title": "Архів PWA",
	"webApps.archive.goBack": "Назад",
	"webApps.list.archive": "Архів",

	"leads.view.appLaunched": "Додаток запущено",

	"webApps.views.DescriptionTab.content.whatsNew.title": "Розділ 'Що нового'",
	"webApps.views.DescriptionTab.content.whatsNew.enabled": "Відображати розділ",
	"webApps.views.DescriptionTab.content.whatsNew.content": "Вміст розділу",

	"webApps.view.ReviewForm.ownerResponse.show": "Є відповідь розробника?",
	"webApps.view.ReviewForm.ownerResponse.content": "Відповідь розробника",
	"webApps.view.ReviewForm.ownerResponse.date": "Дата відповіді розробника",

	"avatarGallery.select.gender.label": "Стать",
	"avatarGallery.select.gender.MALE": "Чоловіча",
	"avatarGallery.select.gender.FEMALE": "Жіноча",

	"avatarGallery.select.age.label": "Вік",
	"avatarGallery.select.age.YOUNG_ADULT": "Молодий (18-30 років)",
	"avatarGallery.select.age.ADULT": "Дорослий (31-60 років)",
	"avatarGallery.select.age.ELDERLY": "Старший (60 років і більше)",

	"avatarGallery.select.race.label": "Раса",
	"avatarGallery.select.race.ASIAN": "Азіатська",
	"avatarGallery.select.race.EUROPEAN": "Європейська",
	"avatarGallery.select.race.AFRICAN_AMERICAN": "Африканська",
	"avatarGallery.select.race.LATINO": "Латиноамериканська",
	"avatarGallery.select.race.ARAB": "Арабська",
	"avatarGallery.select.race.INDIAN": "Індійська",

	'whitePagesTemplates.list.table.name': 'Назва',
	'whitePagesTemplates.list.table.actions': 'Дії',
	'whitePagesTemplates.list.table.actions.view': 'Переглянути',
	'whitePagesTemplates.list.createItem.title': 'Завантажити шаблон',
	'whitePagesTemplates.list.createItem.label.name': 'Назва шаблону',
	'whitePagesTemplates.list.createItem.description.name': 'Опис шаблону',
	'whitePagesTemplates.list.createItem.label.file': 'Шаблон',
	'whitePagesTemplates.list.createItem': 'Завантажити шаблон',
	'whitePagesTemplates.list.description': 'Завантажуйте шаблони для генерації лендингів',

	'whitePagesTemplates.view.landingInfo.title': 'Інформація про шаблон',
	'whitePagesTemplates.view.landingInfo.notFoundIndex': 'index.html не знайдено',
	'whitePagesTemplates.view.landingInfo.assetsFolderWarning': 'В архіві є папка assets, це може конфліктувати з конфігурацією PWA',
	'whitePagesTemplates.view.landingInfo.swWarning': 'В архіві є sw.js, це може конфліктувати з конфігурацією PWA',
	'whitePagesTemplates.view.landingInfo.stripLevel': 'index.html не знаходиться в корені архіву, рекомендується перепакувати, щоб файли сайту не були в підпапці',
	'whitePagesTemplates.view.landingInfo.actions.addPrompt.title': 'Додати промт-модифікатор',
	'whitePagesTemplates.view.landingInfo.actions.addPrompt.fields.prompt': 'Промт-модифікатор',
	'whitePagesTemplates.view.saveSuccess': 'Шаблон збережено',
	'whitePagesTemplates.view.title': 'Шаблон лендингу',
	'whitePagesTemplates.view.saveButton': 'Зберегти',
	'whitePagesTemplates.view.removeButton': 'Видалити',
	'whitePagesTemplates.view.label.name': 'Назва',
	'whitePagesTemplates.view.label.description': 'Опис',
	'whitePagesTemplates.view.landingInfo.site': 'Шаблон',
	'whitePagesTemplates.view.landingInfo.image': 'Зображення-превʼю',

	'whitePages.view.generateStatus.wait.title': 'Очікування генерації',
	'whitePages.view.generateStatus.wait.message': 'Зачекайте, вже скоро ми почнемо створювати ваш вайтпейдж',
	'whitePages.view.generateStatus.inProgress.message': 'Ваш вайтпейдж генерується',
	'whitePages.view.generateStatus.error.title': 'Помилка генерації',
	'whitePages.view.generateStatus.error.message': 'Під час генерації вашого вайтпейджа сталася помилка',
	'whitePages.view.generateStatus.success.title': 'Генерація завершена',
	'whitePages.view.generateStatus.success.message': 'Ваш вайтпейдж згенеровано',

	'webApps.views.ContentTab.LanguagesTab.AddLanguageModal.wait.label': 'Генерація перекладу',
	'webApps.views.ContentTab.LanguagesTab.AddLanguageModal.wait.description': 'Будь ласка, очікуємо, це може зайняти деякий час',

	'webApps.views.ContentTab.LanguagesTab.noLanguages.title': 'Немає доданих мов',
	'webApps.views.ContentTab.LanguagesTab.noLanguages.label': 'Додати мову використовуючи кнопку "Додати мову"',

}
