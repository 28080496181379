import {Route} from "wouter";
import ListFbPixelsImportEventsJob from "./ListFbPixelsImportEventsJob.tsx";
import ViewFbPixelsImportEventsJob from "./ViewFbPixelsImportEventsJob.tsx";
/*import {ListFbPixelsLogs} from "./listFbPixelsLogs.tsx";
import {ViewFbPixelsLogs} from "./viewFbPixelsLogs.tsx";*/

const baseUrl = '/fbPixelsImportEventsJob';


export function FbPixelsImportEventsJobRouter(props: { basePath?: string }) {
    return (
        <Route path={props.basePath || baseUrl} nest>
            <Route path={'/'} component={ListFbPixelsImportEventsJob}/>
            <Route path={'/v/:id'} component={ViewFbPixelsImportEventsJob}/>
        </Route>
    );
}
