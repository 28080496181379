import {rem} from '@mantine/core';
import {Spotlight, SpotlightActionData, SpotlightActionGroupData} from '@mantine/spotlight';
import {IconLanguageHiragana, IconSearch} from '@tabler/icons-react';
import {useState} from "react";

interface SpotlightActionGroupDataWithFilter extends SpotlightActionGroupData {
	visibleFilter?: (query: string) => boolean
}

interface SpotlightActionDataWithFilter extends SpotlightActionData {
	visibleFilter?: (query: string) => boolean
}

const actions: (SpotlightActionGroupDataWithFilter | SpotlightActionDataWithFilter)[] = [
	{
		group: 'Languages',
		actions: [{
			id: 'changeLanguage',
			label: 'Сменить язык',
			description: 'Сменить язык на русский',
			onClick: () => {
				localStorage.setItem('engine.locale', 'ru');
				window.location.reload();
			},
			leftSection: <IconLanguageHiragana style={{width: rem(24), height: rem(24)}} stroke={1.5}/>,
		}, {
			id: 'changeLanguage:urk',
			label: 'Змінити мову',
			description: 'Змінити мову на українську',
			onClick: () => {
				localStorage.setItem('engine.locale', 'uk');
				window.location.reload();
			},
			leftSection: <IconLanguageHiragana style={{width: rem(24), height: rem(24)}} stroke={1.5}/>,
		}, {
			id: 'changeLanguage:en',
			label: 'Change language',
			description: 'Change language to English',
			onClick: () => {
				localStorage.setItem('engine.locale', 'en');
				window.location.reload();
			},
			leftSection: <IconLanguageHiragana style={{width: rem(24), height: rem(24)}} stroke={1.5}/>,
		}, {
			id: 'navbar:toggle',
			label: 'Toggle navbar',
			description: 'Toggle navbar',
			onClick: () => {
				localStorage.setItem('ui.mobileOpened', localStorage.getItem('ui.mobileOpened') === 'true' ? 'false' : 'true');
				localStorage.setItem('ui.desktopOpened', localStorage.getItem('ui.desktopOpened') === 'true' ? 'false' : 'true');
				window.location.reload();
			},
			leftSection: <IconLanguageHiragana style={{width: rem(24), height: rem(24)}} stroke={1.5}/>,
		}] as SpotlightActionDataWithFilter[],
	}
];


export function AppSpotlight() {
	const [query, setQuery] = useState('');

	const actionsList = actions.map(group => {
		if(group.visibleFilter && !group.visibleFilter(query)) return false;
		if(!group.group) return group;
		return {
			...group,
			// @ts-ignore
			actions: group.actions.filter(action => action.visibleFilter ? action.visibleFilter(query) : true),
		}
	}).filter(Boolean);

	return (
		<Spotlight
			query={query}
			onQueryChange={setQuery}
			actions={actionsList}
			nothingFound="Nothing found..."
			highlightQuery
			shortcut={'shift + tab'}
			searchProps={{
				leftSection: <IconSearch style={{width: rem(20), height: rem(20)}} stroke={1.5}/>,
				placeholder: 'Search...',
			}}
		/>
	);
}
