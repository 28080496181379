import {Route} from "wouter";
import {ListGoogleTrackingEntry} from "./listGoogleTrackingEntry.tsx";
import {ViewGoogleTrackingEntry} from "./viewGoogleTrackingEntry.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconBrandGoogle} from "@tabler/icons-react";

const baseUrl = '/googleTrackingEntry';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'GoogleTrackingEntry',
	name: 'Ссылки для google ads',
	nameKey: 'menuItems.googleTrackingEntry',
	icon: <IconBrandGoogle/>,
	requiredRights: ['googleAds']
})

export function GoogleTrackingEntryRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListGoogleTrackingEntry}/>
			<Route path={'/v/:id'} component={ViewGoogleTrackingEntry}/>
		</Route>
	);
}
