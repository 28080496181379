import {MultiSelect, MultiSelectProps} from "@mantine/core";
import useTeams from "./Context/useTeams.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {TeamsApiClient} from "./api.ts";

const teamApi = new TeamsApiClient();

export function TeamMembersMultiSelect(p: MultiSelectProps) {
	const {activeTeam} = useTeams();
	const promise = usePromise(() => teamApi.getTeamsUsers(activeTeam!).then(res => {
		return res.items.map(el => ({
			label: el.username,
			value: el._id
		}));
	}), [activeTeam!]);

	return (
		<MultiSelect
			disabled={promise.isLoading}
			placeholder={promise.isError ? 'Error' : p.placeholder}
			c={promise.isError ? 'red' : undefined}
			{...p}
			data={promise.data ?? []}
		/>
	)
}
