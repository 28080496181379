import {createElement, ReactNode, useEffect, useState} from "react";
import {randomId} from "@mantine/hooks";
import {modals} from "@mantine/modals";
import {YoContextEditor} from "@components/YoContextEditor";
import {Button} from "@mantine/core";
import {useLocale} from "../../locale";

interface iYoFormModalProps<T = Record<string, any>> {
	title?: string;
	body: ReactNode;
	required?: Array<keyof T>;
	defaultValue?: Partial<T>;
	validateFn?: (data: T) => boolean;
	size?: string;
	hideContinueButton?: boolean;
}

export function buildCustomConfirmPrompt<T = Record<string, any>>(body: any) {
	const store = {} as T;

	const component = createElement(() => {
		const [data, setData] = useState<T>({} as T);
		const [changes, setChanges] = useState<T>({} as T);

		useEffect(() => {
			Object.assign(store as any, {...data});
		}, [JSON.stringify(data)]);

		return (
			<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
				{body}
			</YoContextEditor>
		)
	}, {});


	return {component, getStore: () => store}
}

export async function yoFormModal<T = Record<string, any>>({title, body, defaultValue = {}, required = [], validateFn, hideContinueButton = false, ...modalSettings}: iYoFormModalProps<T>) {
	return new Promise<T>((done, reject) => {
		const modalId = randomId();

		const component = createElement(() => {
			const t = useLocale();

			const [data, setData] = useState<T>({...defaultValue} as T);
			const [changes, setChanges] = useState<T>({} as T);

			const allowDone = validateFn ? validateFn(data) : required.every((el: keyof T) => !!data[el]);

			function onDone() {
				modals.close(modalId);
				return done(data);
			}

			return (
				<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges} bypassProps={{onDone}}>
					{body}
					{!hideContinueButton ? (
						<>
							<br/>
							<Button
								fullWidth
								onClick={onDone}
								disabled={!allowDone}
							>
								{t('yoFormModal.continue')}
							</Button>
						</>
					) : null}

				</YoContextEditor>
			)
		}, {});

		modals.open({
			title,
			modalId,
			children: component,
			onClose: () => reject('MODAL_CLOSED'),
			...modalSettings,
		})
	});
}
