import {Select, SelectProps} from "@mantine/core";
import {useScopedLocale} from "../../locale";

export function AvatarGenderSelect({label, ...props}: SelectProps) {
	const t = useScopedLocale('avatarGallery.select.gender.');

	const data = [{
		label: t('MALE'),
		value: 'MALE'
	}, {
		label: t('FEMALE'),
		value: 'FEMALE'
	}]

	return (
		<Select label={label ?? t('label')} {...props} data={data}/>
	)
}

export function AvatarRaceSelect({label, ...props}: SelectProps) {
	const t = useScopedLocale('avatarGallery.select.race.');

	const data = [{
		label: t('ASIAN'),
		value: 'ASIAN'
	}, {
		label: t('EUROPEAN'),
		value: 'EUROPEAN'
	}, {
		label: t('AFRICAN_AMERICAN'),
		value: 'AFRICAN_AMERICAN'
	}, {
		label: t('LATINO'),
		value: 'LATINO'
	}, {
		label: t('ARAB'),
		value: 'ARAB'
	}, {
		label: t('INDIAN'),
		value: 'INDIAN'
	}];

	return (
		<Select label={label ?? t('label')} {...props} data={data}/>
	)
}

export function AvatarAgeSelect({label, ...props}: SelectProps) {
	const t = useScopedLocale('avatarGallery.select.age.');

	const data = [{
		label: t('YOUNG_ADULT'),
		value: 'YOUNG_ADULT'
	}, {
		label: t('ADULT'),
		value: 'ADULT'
	}, {
		label: t('ELDERLY'),
		value: 'ELDERLY'
	}];

	return (
		<Select label={label ?? t('label')} {...props} data={data}/>
	)
}
