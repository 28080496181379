import {Alert, Button, Fieldset, Flex, Grid} from "@mantine/core";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {ruleTypesValues} from "./RuleTypesValues.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {RuleTypeValueField} from "./RuleTypeValueField.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {notifications} from "@mantine/notifications";

export function RuleEditorField() {
	return (
		<Grid>
			<Grid.Col span={6}>
				<SelectField
					field={'ruleType'}
					label={'Если'}
					data={ruleTypesValues}
				/>
			</Grid.Col>
			<Grid.Col span={6}>
				<CollapseField
					field={'ruleType'}
					eqFn={v => ['osEquals', 'browserEquals', 'language'].includes(v)}
				>
					<RuleTypeValueField/>
				</CollapseField>
				<CollapseField field={'ruleType'} eqFn={eqString('ab')}>
					<Alert title={'Как это работает?'} color={'yellow'}>
						<p>Устанавливайте здесь пороговое значение от 0 до 100</p>
						<p>При переходе пользователя в PWA генерируется рандомное число от 0 до
							100</p>
						<p>Если включён режим сохранения значения в куках то вместо рандомного числа
							будет сохранённое состояние</p>
						<p>Если это число больше порогового значения, то правило срабатывает</p>
					</Alert>
					<NumberField
						field={'thresholdValue'}
						label={'Пороговое значение'}
						min={0}
						max={100}
						allowDecimal
					/>
					<br/>
					<Fieldset legend={'Сохранение порогового значения в куки'}>
						<Flex justify={'space-between'} align={'center'}>
							<CheckboxField
								field={'saveAbKey'}
								label={'Сохранять результат в куки'}
							/>
							<CollapseField field={'saveAbKey'}>
								<RenderFuncField field={'cookieKey'}>
									{ctx => (
										<Button
											size={'compact-sm'}
											color={'yellow'}
											onClick={() => {
												ctx.onChange(Math.random().toString(36).substring(2));
												notifications.show({
													message: 'Значение успешно сброшено, не забудьте сохранить изменения',
													color: 'green'
												});
											}}
										>
											Сбросить куки клиентов
										</Button>
									)}
								</RenderFuncField>
							</CollapseField>
						</Flex>
					</Fieldset>
				</CollapseField>
			</Grid.Col>
		</Grid>
	);
}
