import {useMemo, useState} from "react";
import {useLocaleContext} from "../../../locale";
import {allLanguageList} from "@shared/_data/allLanguageList.ts";
import {Badge} from "@mantine/core";

export function RenderPwaLangList(props: { items: Array<string> }) {
	const [showAll, setShowAll] = useState(false);
	const {selectedLocale} = useLocaleContext();
	const elements = useMemo(() => {
		const values = allLanguageList.filter(el => props.items.includes(el.code));
		return values.map(el => {
			const name = selectedLocale === 'ru' ? el.name_ru : selectedLocale === 'uk' ? el.name_ukr : el.name_en;
			return (
				<Badge color={'dark'} title={name}>
					{el.emoji} {name}
				</Badge>
			);
		})
	}, [...props.items]);

	const firstElements = elements.slice(0, 3);
	const allElementsCount = elements.length - firstElements.length;

	if (showAll) {
		return <div onClick={() => setShowAll(false)}>{elements}</div>
	}

	return <div>{firstElements}{allElementsCount ?
		<Badge style={{cursor: 'pointer'}} onClick={() => setShowAll(true)}>+{allElementsCount}</Badge> : null}</div>
}
