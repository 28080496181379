import {Route} from "wouter";
import MenuManager from "@engine/MenuManager.ts";
import {PushMessagesRouter} from "./PushMessages";
import {PushJobsRouter} from "./PushJobs";
import {PushSegmentsRouter} from "./PushSegments";
import {PushStatsPage} from "./PushStats";

const baseUrl = '/push/';

MenuManager.RegisterGroup({
	key: 'push',
	nameKey: 'menuItems.push',
	requiredRights: ['push']
})

export function PushRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<PushMessagesRouter/>
			<PushSegmentsRouter/>
			<PushJobsRouter/>
			<Route path={'/stats'} component={PushStatsPage}/>
		</Route>
	);
}
