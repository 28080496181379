import {Card, Center, Grid, Image, Title} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {SimpleDragHandle} from "@components/SimpleSorting/simpleDragHandle.tsx";
import {IconTrash} from "@tabler/icons-react";
import {getFileLink} from "../../../../../../utils/getFileLink.tsx";
import {ImageUploader} from "@YoUi/imageUploader.tsx";
import {mongoObjectId} from "../../../../../../utils/mongoObjectId.ts";
import {useScopedLocale} from "../../../../../../locale";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'images'>> = () => {
    const tt = useScopedLocale('webApps.view.ImagesTab.');
    return (
        <div>
            <Title order={5} mb="xs">
                {tt('useScreensFromBaseLanguage.title')}
            </Title>
            <Card variant="section">
                <Grid>
                    <YoContextArrayMap field={'screens'} withSort>
                        <Grid.Col span={'content'}>
                            <RenderFuncField<string> field={'image'}>
                                {ctx => (
                                    <>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <SimpleDragHandle/>
                                            <IconTrash onClick={ctx.bypassProps.onRemove}/>
                                        </div>
                                        <Center maw={400} h={300} bg="var(--mantine-color-gray-light)">
                                            <Image
                                                src={getFileLink(ctx.value)}
                                                radius="md"
                                                h={300}
                                                w={300}
                                                fit="contain"
                                            />
                                        </Center>
                                    </>
                                )}
                            </RenderFuncField>
                        </Grid.Col>
                    </YoContextArrayMap>
                    <Grid.Col span={12}>
                        <RenderFuncField field={'screens'}>
                            {ctx => <ImageUploader
                                onDropAll={files => {
                                    ctx.push(...files.map(file => ({_id: mongoObjectId(), image: file._id})));
                                }}/>}
                        </RenderFuncField>
                    </Grid.Col>
                </Grid>
            </Card>
        </div>
    );
}

export default FieldTypeString;