import {createContext, ReactNode, useContext} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

const Context = createContext<any>({});

export function SimpleSortItemProvider({sortId, children}: { sortId: string, children: ReactNode }) {
	const sort = useSortable({
		id: sortId,
		animateLayoutChanges: () => false
	})

	const sortInlineStyles = {
		transform: CSS.Transform.toString(sort.transform),
		transition: sort.transition,
	};

	return (
		<div ref={sort.setNodeRef} style={sortInlineStyles} {...sort.attributes}>
			<Context.Provider value={sort}>
				{children}
			</Context.Provider>
		</div>
	)
}

export function useSimpleSortItemDragHandle() {
	const sort = useContext(Context);
	return {ref: sort.setActivatorNodeRef, ...sort.listeners};
}

