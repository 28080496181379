import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";
import type {RawDocumentTypeTeamInvites, RawDocumentTypeTeams} from "@shared/models";
import type { TeamUserRoleKeys, TeamUserRoleRights } from "@shared/_data/teamUserRoles.ts";
import {TariffKey} from "@shared/_data/tariffs/tariffsConfig.ts";

type ApiResult <T> = ({
	status: true,
} & T) | ({
	status: false,
	message: string
});

export type TMyTeam = {
	_id: string,
	name: string,
	rights: TeamUserRoleRights[],
	role: TeamUserRoleKeys,
	balance: number,
	ownerId: string,
	tariff: TariffKey | undefined,
	tariffPayedTill?: string,
	teamMembersCount: number,
	deactivated?: boolean,
	willBeDeactivated?: boolean,
};
export type TTeamUser = { _id: string, username: string; role: string };

@singleton
export class TeamsApiClient extends YoApiClient<RawDocumentTypeTeams> {
	path = '/api/v1/teams';

	async inviteUser (teamId: string, email: string, role: TeamUserRoleKeys) {
		return this.ky.post(`${teamId}/invite`, { json: { email, role } }).json();
	}

	async createNewUser (teamId: string, email: string, role: TeamUserRoleKeys) {
		return this.ky.post(`${teamId}/createNewUser`, { json: { email, role } }).json<{ username: string, password: string }>();
	}

	async getMyTeams() {
		return this.ky.get('getMyTeams').json<ApiResult<{ items: TMyTeam[] }>>();
	}

	async getTeamsUsers(id: string) {
		return this.ky.get(`${id}/getTeamsUsers`).json<{ items: TTeamUser[] }>()
	}

	async updateUserRole(teamId: string, teamUserId: string, role: string) {
		return this.ky.post(`${teamId}/updateUserRole`, { json: { role, teamUserId } });
	}

	async deleteTeamUser(teamId: string, teamUserId: string) {
		return this.ky.post(`${teamId}/deleteTeamUser`, { json: { teamUserId } });
	}

	async switchTariff(teamId: string, data: { tariff: string }) {
		return this.ky.post(`${teamId}/switchTariff`, { json: data }).json();
	}

	async _superAdminAddBalance (teamId: string, data: { amount: number }) {
		return this.ky.post(`${teamId}/_superAdminAddBalance`, { json: data }).json();
	}

	async sendInviteEmail (teamId: string, email: string, role: TeamUserRoleKeys) {
		return this.ky.post(`${teamId}/sendInviteEmail`, { json: { email, role } }).json<{ status: true }>();
	}

	async getTeamInvites(teamId: string) {
		return this.ky.get(`${teamId}/getTeamInvites`).json<{ items: RawDocumentTypeTeamInvites[] }>();
	}

	async completeOnboarding() {
		return this.ky.post('completeOnboarding').json();
	}
}
