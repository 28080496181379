import {useState} from "react";
import {
	MRT_ColumnDef,
	MRT_ColumnFiltersState,
	MRT_PaginationState,
	MRT_RowData,
	MRT_SortingState
} from "mantine-react-table";
import {useLocalStorage} from "@mantine/hooks";
import {VisibilityState} from "@tanstack/react-table";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {Button} from "@mantine/core";
import {UseDefaultsTableStateStore} from "./useDefaultsTableState.tsx";
import {MRT_Localization_RU} from 'mantine-react-table/locales/ru/index.esm.mjs';
import {useLocaleContext} from "../../locale";
import {MRT_Localization_EN} from "mantine-react-table/locales/en/index.esm.mjs";
import {MRT_Localization_UK} from "mantine-react-table/locales/uk/index.esm.mjs";

interface YoMRT_ColumnDef<T extends MRT_RowData> extends MRT_ColumnDef<T> {
	yoFilerType?: string;
}
export function useMRTState<T extends MRT_RowData>(props: { visibilityLocalStorageKey: string, defaults?: UseDefaultsTableStateStore, defaultHiddenColumns?: string[] }) {
	const { selectedLocale } = useLocaleContext();
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(props.defaults?.query ?? []);
	const [sorting, setSorting] = useState<MRT_SortingState>([]);
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 20,
	});
	const hiddenColumns = props.defaultHiddenColumns ? Object.fromEntries(props.defaultHiddenColumns.map(key => [key, false])) : {};
	const [columnVisibility, onColumnVisibilityChange] = useLocalStorage<VisibilityState>({
		key: props.visibilityLocalStorageKey,
		defaultValue: hiddenColumns as VisibilityState,
	});

	const getLocale = () => {
		switch (selectedLocale) {
			case 'uk': return MRT_Localization_UK;
			case "ru": return MRT_Localization_RU;
			case "en":
			default:
				return MRT_Localization_EN
		}
	}

	return ({data, columns, ...otherProps}: { data: ReturnType<typeof useYoApi>, columns: YoMRT_ColumnDef<T>[], [key: string]: any }) => {
		return {
			columns,
			data: data.items,
			enableGlobalFilter: false,
			getRowId: (row: { _id: string }) => row._id,
			manualFiltering: true,
			manualPagination: true,
			manualSorting: true,
			rowVirtualizerProps: { overscan: 5 },
			columnVirtualizerProps: { overscan: 2 },
			enableColumnResizing: false,
			enableColumnVirtualization: true,
			enableRowVirtualization: true,
			rowCount: data.count,
			onColumnFiltersChange: setColumnFilters,
			onPaginationChange: setPagination,
			onSortingChange: setSorting,
			onColumnVisibilityChange,
			state: {
				columnFilters,
				columnVisibility,
				isLoading: data.isLoading && !data.count,
				pagination,
				showAlertBanner: data.isError,
				showProgressBars: data.isLoading && !!data.count,
				sorting,
			},
			mantineToolbarAlertBannerProps: data.isError ? {
				color: 'red',
				style: {display: 'flex', justifyContent: 'space-between'},
				children: (
					<>
						<p>{data.errData?.message}</p>
						<Button size={'compact-sm'} onClick={data.fetch}>Повторить</Button>
					</>
				)}: undefined,
			adapterProps: {
				columnFilters: columnFilters.map(el => {
					const colMeta = columns.find(col => col.accessorKey === el.id);
					return {
						filterType: colMeta?.yoFilerType as string,
						...el,
					}
				}),
				pagination,
				setPagination,
				sorting
			},
			localization: getLocale(),
			...otherProps
		}
	}
}
