import {ReactNode} from "react";
import {useYoProvider} from "@components/YoContextEditor";
import {SimpleDragHandle} from "@components/SimpleSorting/simpleDragHandle.tsx";
import {Badge, MantineColor} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";

export function RulesHeaderField(props: { badgeColor?: MantineColor, getBadgeContent: (ctx: any) => ReactNode, allowDrag?: boolean, allowDelete?: boolean }) {
	const {getBadgeContent, allowDrag = true, allowDelete = true} = props;
	const ctx = useYoProvider('');
	return (
		<div style={{display: 'flex', justifyContent: 'space-between'}}>
			{allowDrag && <SimpleDragHandle/>}
			<Badge color={props.badgeColor} style={{width: '98%'}}>
				{getBadgeContent(ctx)}
			</Badge>
			{allowDelete && <IconTrash onClick={ctx.bypassProps.onRemove}/>}
		</div>
	);
}
