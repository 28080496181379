import {Card, Title} from "@mantine/core";
import {useScopedLocale} from "../../../../../../locale";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import { CollapseField } from "@components/YoContextEditor/components/CollapseField";

export default function FieldTypeTrailer() {
    const tt = useScopedLocale('webApps.views.DescriptionTab.');

    return (
        <div>
            <Title order={5} mb="xs">
                {tt('content.trailer.title')}
            </Title>
            <Card variant="section">
                <CheckboxField field={'trailer.enabled'} label={tt('content.trailer.enabled')}/>
                <CollapseField field={'trailer.enabled'}>
                    <InputField
                        field={'trailer.url'}
                        label={tt('content.trailer.url.label')}
                        description={tt('content.trailer.url.decs')}
                    />
                </CollapseField>
            </Card>
        </div>
    );
}
