import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {
	Alert,
	Box,
	Button,
	Card,
	Center,
	Fieldset,
	Flex,
	LoadingOverlay,
	Modal,
	SimpleGrid,
	Stack,
	Table,
	Text,
	Title
} from "@mantine/core";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {ReadonlyAreaField} from "@components/YoContextEditor/ReadonlyAreaField.tsx";
import {CollapseField, eqString, notEq} from "@components/YoContextEditor/components/CollapseField.tsx";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {RuleEditorField} from "./components/rules/ruleEditorField.tsx";
import {RulesHeaderField} from "./components/rules/RulesHeaderField.tsx";
import {useScopedLocale} from "../../../locale";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {notifications} from "@mantine/notifications";
import {IconPlus} from "@tabler/icons-react";
import {LeadsApiClient} from "../../Leads/api.ts";
import {useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";

const leadsApi = new LeadsApiClient();

function LinksStats(props: { appId: string }) {
	const promise = usePromise(() => leadsApi.getOfferLinksStats(props.appId), [props.appId]);
	if(promise.isError) return displayTSMatchGeneralError(promise);

	const items = promise.data?.items || [];
	return (
		<Box pos={'relative'} mih={'70vh'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<Alert color={'yellow'}>
				Displays data for the last 14 days
			</Alert>
			<Table>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>
							Link
						</Table.Th>
						<Table.Th>
							First at
						</Table.Th>
						<Table.Th>
							Last at
						</Table.Th>
						<Table.Th>
							Count
						</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{!promise.isLoading && items.length === 0 && (
						<Table.Tr>
							<Table.Td colSpan={4}>
								<Center mih={'70vh'}>
									<Text size="xl" color="dimmed">
										No links found
									</Text>
								</Center>
							</Table.Td>
						</Table.Tr>
					)}
					{items.map((item) => (
						<Table.Tr key={item._id}>
							<Table.Td>
								{item.link}
							</Table.Td>
							<Table.Td>
								{new Date(item.firstSeen).toLocaleString()}
							</Table.Td>
							<Table.Td>
								{new Date(item.lastSeen).toLocaleString()}
							</Table.Td>
							<Table.Td>
								{item.count}
							</Table.Td>
						</Table.Tr>
					))}
				</Table.Tbody>
			</Table>
		</Box>
	);
}

export function TrackerTab() {
	const tt = useScopedLocale('webApps.view.TrackerTab.');
	const params = useParams<{id: string}>();
	const [showLeadsStats, setShowLeadsStats] = useState(false);

	return (
		<>
			<Stack>
				<Flex justify={'space-between'} align={'center'}>
					<Title order={3}>{tt('title')}</Title>
					<Button size={'compact-sm'} onClick={() => setShowLeadsStats(true)}>
						View links stats
					</Button>
				</Flex>
				<Card variant="section">
					<Title order={5}>{tt('primaryLink.title')}</Title>
					<Text mb="xs" size="sm">{tt('description.1')}</Text>
					<Text mb="md" size="sm">{tt('description.2')}</Text>
					<br/>
					<SelectField field={'offerLinkType'} label={tt('primaryLink.type.label')} data={[
						{value: 'static', label: tt('primaryLink.type.static')},
						{value: 'dynamic', label: tt('primaryLink.type.dynamic')}
					]}/>
					<CollapseField field={'offerLinkType'} eqFn={eqString('static')}>
						<InputField field={'offerLink'} label={tt('primaryLink.label')}/>
					</CollapseField>
					<CollapseField field={'offerLinkType'} eqFn={eqString('dynamic')}>
						<InputField field={'offerDynamicSource.kDomain'} label={tt('primaryLink.kDomain')}/>
						<InputField field={'offerDynamicSource.token'} label={tt('primaryLink.token')}/>
						<InputField
							field={'offerLink'}
							label={tt('primaryLink.kFallback.label')}
							description={tt('primaryLink.kFallback.description')}
						/>
					</CollapseField>
				</Card>
				<Title order={3}>{tt('rules.title')}</Title>
				<Card variant="section" mb="lg">
					<Text mb="xs" size="sm">{tt('rules.desc.1')}</Text>
					<Text mb="md" size="sm">{tt('rules.desc.2')}</Text>
					<YoContextArrayMap field={'alternativeOffersRules'}>
						<Fieldset>
							<RulesHeaderField badgeColor={'gray'} getBadgeContent={ctx => `Вариант №${ctx.bypassProps.__index + 1}`}/>
							<br/>
							<SimpleGrid cols={2}>
								<Flex align={'center'}>
									<CheckboxField field={'enabled'} label={tt('rules.enabled')}/>
								</Flex>
								<InputField field={'comment'} label={tt('rules.comment.label')} description={tt('rules.comment.description')}/>
							</SimpleGrid>
							<ReadonlyAreaField field={'enabled'} eqFn={notEq}>
								<Title order={4}>{tt('rules.conditions.title')}</Title>
								<YoContextArrayMap field={'conditions'}>
									<RulesHeaderField badgeColor={'teal'} getBadgeContent={ctx => `Условие №${ctx.bypassProps.__index + 1}`}/>
									<RuleEditorField/>
									<hr/>
								</YoContextArrayMap>
								<RenderFuncField field={'conditions'}>
									{ctx => (
										<Button variant="secondary" fullWidth disabled={ctx.bypassProps.readOnly} size={'compact-sm'} onClick={() => ctx.push({_id: mongoObjectId(), ruleType: 'language'})}>
											{tt('rules.conditions.button.add')}
										</Button>
									)}
								</RenderFuncField>
								<Title order={4}>{tt('rules.offerLink.title')}</Title>
								<SelectField field={'linkType'} label={tt('rules.offerLink.type.label')} data={[
									{value: 'static', label: tt('rules.offerLink.type.static')},
									{value: 'dynamic', label: tt('rules.offerLink.type.dynamic')}
								]}/>
								<CollapseField field={'linkType'} eqFn={eqString('static')}>
									<InputField field={'offerLink'} label={tt('rules.offerLink.label')}/>
								</CollapseField>
								<CollapseField field={'linkType'} eqFn={eqString('dynamic')}>
									<InputField field={'dynamicSource.kDomain'} label={tt('rules.offerLink.kDomain')}/>
									<InputField field={'dynamicSource.token'} label={tt('rules.offerLink.token')}/>
									<InputField
										field={'offerLink'}
										label={tt('rules.offerLink.kFallback.label')}
										description={tt('rules.offerLink.kFallback.description')}/>
								</CollapseField>
								<hr/>
							</ReadonlyAreaField>
						</Fieldset>
					</YoContextArrayMap>
					<br/>
					<div>
						<Center mt={'md'}>
							<RenderFuncField field={'alternativeOffersRules'}>
								{ctx => (
									<Button leftSection={<IconPlus/>} size={'md'} miw={'100%'} variant="default" onClick={() => ctx.push({_id: mongoObjectId(), enabled: true})}>
										{tt('rules.offerLink.button.add')}
									</Button>
								)}
							</RenderFuncField>
						</Center>
					</div>
				</Card>
				<Title order={3}>{tt('resetClientCache.title')}</Title>
				<Card variant="section">
					<p>{tt('resetClientCache.description')}</p>
					<RenderFuncField<string> field={'offerCacheKey'}>
						{ctx => (
							<Button
								size={'compact-sm'}
								color={'yellow'}
								onClick={() => {
									ctx.onChange(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
									notifications.show({message: tt('resetClientCache.success')});
								}}
							>
								{tt('resetClientCache.button')}
							</Button>
						)}
					</RenderFuncField>
				</Card>

			</Stack>
			<Modal keepMounted={false} opened={showLeadsStats} onClose={()=> setShowLeadsStats(false)} title={'Links'} size={'lg'}>
				<LinksStats appId={params.id}/>
			</Modal>
		</>
	);
}
