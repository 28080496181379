import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {Alert, Button, Card, Divider, Grid, Modal, Stack, Text, Timeline} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {JobsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal, confirmModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {buildCustomConfirmPrompt} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {getLocaleString, Translation, useLocale} from "../../locale";

const customConfirmPrompt = buildCustomConfirmPrompt(
	<>
		<p>{getLocaleString('jobs.customConfirmPrompt')}</p>
	</>
)

const api = new JobsApiClient();
export function ViewJobs() {
	const t = useLocale();
	const navigate = useNavigate();
	const [showLogs, setShowLogs] = useState(false);
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => {
		if(promise.isLoading) return;
		setData(promise.data?.item ?? {});
	}, [promise.isLoading]);

	useEffect(() => {
		if(!promise.isLoading) {
			const timer = setTimeout(() => promise.run(), 5000);
			return () => clearTimeout(timer);
		}
	}, [promise.isLoading]);


	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		if(data.state === 'IN_PROGRESS' && !await confirmModal({message: t('jobs.confirmRunningJobDelete')})) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	async function tryRetryJob() {
		if(!await confirmModal({message: customConfirmPrompt.component, positive: true})) return;
		await api.saveItem(params.id, {state: 'WAIT', errReason: '' }).then(() => notifications.show({message: 'Задача сброшена'}));
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={t('jobs.view.title')}
				items={(
					<>
						<Button onClick={tryRemove} size={'compact-sm'} color={'red'}>
							<Translation id={'jobs.view.deleteButton'}/>
						</Button>
						<Button disabled={!['DONE', 'ERROR'].includes(data.state)} onClick={tryRetryJob} size={'compact-sm'} color={'yellow'}>
							<Translation id={'jobs.view.retryButton'}/>
						</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
			<Grid>
				<Grid.Col span={6}>
					<Stack>
						<InputField field={'type'} label={t('jobs.view.jobTypeCaption')} readOnly/>
							<Divider />
						<CollapseField field={'type'} eqFn={eqString('buyDomain')}>
							<Alert color={'yellow'} title={t('jobs.view.jobTypeCaption.buyDomain')}/>
						</CollapseField>
					</Stack>
				</Grid.Col>
				<Grid.Col span={6}>
					<Stack>
						{data.state === 'WAIT' && (
							<Alert color={'yellow'} title={t('jobs.view.state.wait.title')}>
								<Translation id={'jobs.view.state.wait.description'}/>
							</Alert>
						)}
						{data.state === 'IN_PROGRESS' && (
							<Alert color={'blue'} title={t('jobs.view.state.inProgress.title')}>
								<Translation id={'jobs.view.state.inProgress.description'}/> {data.subStatus}
							</Alert>
						)}
						{data.state === 'DONE' && (
							<Alert color={'green'} title={t('jobs.view.state.done.title')}>
								<Translation id={'jobs.view.state.done.description'}/>
							</Alert>
						)}
						{data.state === 'ERROR' && (
							<Alert color={'red'} title={t('jobs.view.state.error.title')}>
								<Translation id={'jobs.view.state.error.description'}/> {data.errReason}
							</Alert>
						)}
						<Button variant="secondary" fullWidth onClick={() => setShowLogs(true)}>
							<Translation id={'jobs.view.logsButton'}/>
						</Button>
					</Stack>
				</Grid.Col>
			</Grid>
			<Modal opened={showLogs} onClose={() => setShowLogs(false)} title={t('jobs.view.logsModalTitle')} size={'lg'}>
				<Timeline>
					{data.logs?.map((log: any, i: number) => (
						<Timeline.Item key={i}>
							<Text lh="1.2rem">{log.message}</Text>
							<Text c="dimmed" size="xs" mt={4}>{new Date(log.date).toLocaleString()}</Text>
						</Timeline.Item>
					))}
				</Timeline>
			</Modal>
			</Card>
		</YoContextEditor>
	);
}
