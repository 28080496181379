import {TeamUserRoleKeys, teamUserRoles} from "@shared/_data/teamUserRoles.ts";
import {Select, SelectProps} from "@mantine/core";

type TValue = TeamUserRoleKeys;

function TeamUserRoleSelect({ value, onChange, ...restProps }: { value: TValue, onChange: (value: TValue) => void } & Omit<SelectProps, 'value' | 'onChange' | 'data'>) {
    return (
        <Select
            value={value}
            onChange={v => {
                if (v !== null) {
                    onChange(v as TValue);
                }
            }}
            data={Object.entries(teamUserRoles).map(([key, item]) => ({ label: item.label, value: key }))}
            {...restProps}
        />
    );
}

export default TeamUserRoleSelect;