import {Route} from "wouter";
import {AdminUsersRouter} from "./AdminUsers";
import {IconManualGearbox} from "@tabler/icons-react";
import {SettingsExamplePage} from "./Settings/SettingsExamplePage.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {DeeplSettingsPage} from "./Settings/DeeplSettings.tsx";
import {OpenAiSettings} from "./Settings/OpenAiSettings.tsx";
import {ListIpBlacklist} from "./IpBlacklist";
import {ListUaBlacklist} from "./uaBlacklist";
import {AdspectSettings} from "./Settings/adspectSettings.tsx";
import {ExchangeRateSettingsPage} from "./Settings/exchangeRateSettingsPage.tsx";
import DomainRegistrarSettings from "./Settings/DomainRegistrarSettings";
import {ShkeeperSettings} from "./Settings/shkeeperSettings.tsx";

MenuManager.RegisterGroup({
	key: 'system',
	name: 'Система',
	nameKey: 'menuItems.system',
	icon: <IconManualGearbox/>,
	onlySuperAdmin: true,
	position: 1000
});

export function SystemRouter() {
	return (
		<Route path={'/system'} nest>
			<AdminUsersRouter basePath={'/users'}/>
			<Route path={'/blacklist/ip'} component={ListIpBlacklist}/>
			<Route path={'/blacklist/ua'} component={ListUaBlacklist}/>
			<Route path={'/settings/example'} component={SettingsExamplePage}/>
			<Route path={'/settings/deepl'} component={DeeplSettingsPage}/>
			<Route path={'/settings/openai'} component={OpenAiSettings}/>
			<Route path={'/settings/adspect'} component={AdspectSettings}/>
			<Route path={'/settings/shkeeper'} component={ShkeeperSettings}/>
			<Route path={'/settings/exchangeRate'} component={ExchangeRateSettingsPage}/>
			<Route path={'/settings/domainRegistrars'} component={DomainRegistrarSettings}/>
		</Route>
	);
}
