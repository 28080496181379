import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {Box, Group, LoadingOverlay} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {StatsGrid} from "@components/StatsGrid";
import {iStateProps} from "../IStateProps.tsx";
import {useScopedLocale} from "../../../locale";
import {addStatsJob} from "../utils/statsQueue.ts";
import {calculatePercentDiff} from "../utils/CalculatePercentDiff.tsx";

export function GeneralBadges(props: iStateProps & {value: string, onChange: any}) {
	const t = useScopedLocale('pages.mainPage.generalBadges.');
	const promise = usePromiseWithTeamDependency(() => {
		return addStatsJob(() => getKyInstance().post('/api/v1/stats/general', {
			json: {
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers
			},
			timeout: 1000 * 30
		}).json());
	}, [JSON.stringify([props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);

	const createDefault = () => ({value: -1, diff: 0, prev: -1})
	const {
		visits = createDefault(),
		uniqVisits = createDefault(),
		installs = createDefault(),
		registrations = createDefault(),
		deposits = createDefault()
	} = (promise.data || {}) as Record<string, { value: number, diff: number, prev: number }>;

	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<Group grow>
				{promise.isError ? displayTSMatchGeneralError(promise) : (
					<>
						<StatsGrid
							value={visits.value}
							title={t('visits')}

							cardStyle={{cursor: 'pointer', backgroundColor: props.value === 'visits' ? 'darkblue' : undefined}}
							onClick={() => props.onChange('visits')}

							description={`Prev period: ${visits.prev}`}
							diff={calculatePercentDiff(visits.prev, visits.value)}
							diffSign={'%'}
						/>
						<StatsGrid
							value={uniqVisits.value ?? -1}
							title={t('uniqVisits')}
							cardStyle={{cursor: 'pointer', backgroundColor: props.value === 'uniqVisits' ? 'darkblue' : undefined}}
							onClick={() => props.onChange('uniqVisits')}

							description={`Prev period: ${uniqVisits.prev}`}
							diff={calculatePercentDiff(uniqVisits.prev, uniqVisits.value)}
							diffSign={'%'}
						/>
						<StatsGrid
							value={installs.value ?? -1}
							title={t('installs')}
							cardStyle={{cursor: 'pointer', backgroundColor: props.value === 'installs' ? 'darkblue' : undefined}}
							onClick={() => props.onChange('installs')}

							description={`Prev period: ${installs.prev}`}
							diff={calculatePercentDiff(installs.prev, installs.value)}
							diffSign={'%'}
						/>
						<StatsGrid
							value={registrations.value ?? -1}
							title={t('registrations')}
							cardStyle={{cursor: 'pointer', backgroundColor: props.value === 'registrations' ? 'darkblue' : undefined}}
							onClick={() => props.onChange('registrations')}

							description={`Prev period: ${registrations.prev}`}
							diff={calculatePercentDiff(registrations.prev, registrations.value)}
							diffSign={'%'}
						/>
						<StatsGrid
							value={deposits.value ?? -1}
							title={t('deposits')}
							cardStyle={{cursor: 'pointer', backgroundColor: props.value === 'deposits' ? 'darkblue' : undefined}}
							onClick={() => props.onChange('deposits')}

							description={`Prev period: ${deposits.prev}`}
							diff={calculatePercentDiff(deposits.prev, deposits.value)}
							diffSign={'%'}
						/>
					</>
				)}
			</Group>
		</Box>

	);
}
