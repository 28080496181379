import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {Alert, Button, Card, Stack} from "@mantine/core";

import {FbPixelsLogsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {Translation, useLocale} from "../../../locale";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {CollapseField, notEq} from "@components/YoContextEditor/components/CollapseField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";

const api = new FbPixelsLogsApiClient();
export function ViewFbPixelsLogs() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={t('fbPixelsLogs.view.title')}
				items={(
					<>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'fbPixelsLogs.view.action.delete'}/>
						</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
				<InputField field={'event'} label={t('fbPixelsLogs.view.fields.event')} readOnly/>
				<CollapseField field={'sentSuccessfully'}>
					<Alert color={'green'} title={t('fbPixelsLogs.view.fields.sentSuccessfully')}>
						<InputField field={'fbTraceId'} label={t('fbPixelsLogs.view.fields.fbTraceId')} readOnly/>
						<br/>
						<RenderFuncField<{_id: string}> field={'lead'}>
							{({value}) => {
								if(!value) return (
									<Button color={'red'}>
										<Translation id={'fbPixelsLogs.view.fields.lead.empty'} />
									</Button>
								);
								return (
									<Button component={Link} href={`~/leads/v/${value._id}`}>
										<Translation id={'fbPixelsLogs.view.fields.goToLead'}/>
									</Button>
								)
							}}
						</RenderFuncField>

					</Alert>
				</CollapseField>
				<CollapseField field={'sentSuccessfully'} eqFn={notEq}>
					<Alert color={'red'} title={t('fbPixelsLogs.view.fields.sentUnsuccessfully')}>
						<InputField field={'fbTraceId'} label={t('fbPixelsLogs.view.fields.fbTraceId')} readOnly/>
						<br/>
						<RenderFuncField<{_id: string}> field={'lead'}>
							{({value}) => {
								if(!value) return (
									<Button color={'red'}>
										<Translation id={'fbPixelsLogs.view.fields.lead.empty'} />
									</Button>
								);
								return (
									<Button component={Link} href={`~/leads/v/${value._id}`}>
										<Translation id={'fbPixelsLogs.view.fields.goToLead'}/>
									</Button>
								)
							}}
						</RenderFuncField>
						<br/>
						<br/>
						<TextareaField autosize field={'fbErrMessage'} label={t('fbPixelsLogs.view.fields.fbErrMessage')} readOnly/>
						<InputField field={'fbErrCode'} label={t('fbPixelsLogs.view.fields.fbErrCode')} readOnly/>
						<InputField field={'fbErrSubCode'} label={t('fbPixelsLogs.view.fields.fbErrSubCode')} readOnly/>
						<InputField field={'fbErrType'} label={t('fbPixelsLogs.view.fields.fbErrType')} readOnly/>
					</Alert>
				</CollapseField>
			</Card>
		</YoContextEditor>
	);
}
