import {Menu} from "@mantine/core";
import {IconBrandOpenai} from "@tabler/icons-react";
import {useGenerateContent} from "@components/YoAi/yoGenerateContentProvider.tsx";

export function GenerateFieldMenuItemTrigger() {
	const genCtx = useGenerateContent();
	return (
		<>
			<Menu.Item onClick={() => genCtx.open()} leftSection={<IconBrandOpenai/>}>
				Сгенерировать контент
			</Menu.Item>
		</>

	);
}
