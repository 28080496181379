import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button, Card, Stack, Title} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconBrandOpenai} from "@tabler/icons-react";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {Translation, useLocale} from "../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

MenuManager.RegisterItem({
	path: '/system/settings/openai',
	parent: 'system',
	key: 'openaiSettings',
	name: 'Настройки OpenAI',
	nameKey: 'menuItems.system.openaiSettings',
	icon: <IconBrandOpenai/>
})

export function OpenAiSettings() {
	const controller = useSiteSettings('ai.openai.');
	const t = useLocale();

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({ message: t('settings.openai.saveSuccess') }))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header
				title={t('settings.openai.headerTitle')}
				items={(
					<>
						<Button variant="primary" onClick={saveSettings}>
							<Translation id={'settings.openai.saveButton'} />
						</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
				<InputField field={'token'} label={t('settings.openai.tokenLabel')} />
				<InputField field={'endpoint'} label={t('settings.openai.endpointLabel')} />
				<InputField field={'model'} label={t('settings.openai.modelLabel')} />
				<Title order={4}><Translation id={'settings.openai.promptsTitle'} /></Title>
				<TextareaField
					autosize
					minRows={2}
					field={'prompts:generateDescription'}
					label={t('settings.openai.generateDescriptionLabel')}
				/>
				<TextareaField
					autosize
					minRows={2}
					field={'prompts:translate'}
					label={t('settings.openai.translateLabel')}
				/>
				<TextareaField
					autosize
					minRows={2}
					field={'prompts:generateComments'}
					label={t('settings.openai.generateCommentsLabel')}
				/>
			</Card>
		</YoContextEditor>
	);
}
