import {Alert, Title} from "@mantine/core";
import useTeams from "../../Pages/Teams/Context/useTeams";
import {IconInboxOff} from "@tabler/icons-react";

function TeamDeactivationStatus() {
    const { team } = useTeams()

    if (!team) return null

    if (team.deactivated) {
        return (
            <Alert color="red" icon={<IconInboxOff />}>
                <Title order={3}>your team has been deactivated due to debt</Title>
            </Alert>
        )
    }

    if (team.willBeDeactivated) {
        return (
            <Alert>
                <Title order={3}>your team will be deactivated soon, to avoid this - top up your balance</Title>
            </Alert>
        )
    }
}

export default TeamDeactivationStatus;