import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

interface iStatByCountry {
	countryFlag: string,
	country_ru: string,
	country_en: string,
	country_uk: string

	installs: number,
	visits: number,
	uniqVisits: number
}

@singleton
export class WebAppsApiClient extends YoApiClient {
	path = '/api/v1/webApps';

	fetchStats(id: string, params: {from?: Date, to?: Date, filters?: any[]}) {
		return this.ky.post(`${id}/stats`, {json: params}).json<any>();
	}

	fetchList(props: { offset?: number; sort?: string; query?: any; limit?: number }): Promise<{ status: boolean; items: any[]; count: number }> {
		return super.fetchList(props);
	}

	fetchStatsByPeriod(id: string, period: string) {
		return this.ky.get(`${id}/stats/byPeriod/${period}`).json<any>();
	}

	forceDeleteItem(id: string) {
		return this.ky.delete(`${id}/force`).json();
	}

	fetchStatsChart(id: string, chartType: "visits" | "uniqVisits" | "installs", params: {filters: Array<any>; from: Date; to: Date}) {
		return this.ky.post(`${id}/stats/chart/${chartType}`, {json: params}).json<any>();
	}

	fetchStatsByCountry(id: string, params: {filters: Array<any>; from: Date; to: Date}) {
		return this.ky.post(`${id}/stats/country`, {json: params}).json<{status: boolean, items: iStatByCountry[] }>();
	}

	async tryChangePlatform(id: string, platform: string) {
		return this.ky.post(`${id}/changePlatform`, {json: {platform}}).json<{status: boolean, taskId: string}>();
	}

	async hideApp(id: string) {
		return this.ky.post(`${id}/hide`, {}).json<{status: boolean, taskId: string}>();
	}

	async validateApp(id: string) {
		return this.ky.post(`${id}/validate`, {}).json<{status: boolean, checklist: {key: string, state: 'OK' | 'WARN' | 'ERROR', details: any[]}[]}>();
	}

	async publishApp(id: string) {
		return this.ky.post(`${id}/publish`, {}).json<{status: boolean, taskId: string}>();
	}

	async getQuickPublishStat(id: string) {
		return this.ky.get(`${id}/quickPublish`).json<{state: 'PUBLISHED' | 'DRAFT', allowPublish: boolean, error?: any, code: string}>();
	}
}
