/* eslint-disable @typescript-eslint/no-explicit-any */
import {createContext, createElement, ReactNode, useContext} from "react";
import {get, pick, set} from "lodash";
import {Unpacked} from "@shared/types/unpacked.ts";

interface YoContextEditorCtx {
	item: any;
	setItem: (o: any) => void;
	changes: any;
	setChanges: (o: any) => void;
	getField: (s?: string) => any;
	setField: (k: string, v: any) => void;
	untrackedSetField: (k: string, v: any) => void;
	batchSetField: (props: {key: string, value: any, clientOnlyValue?: any}[]) => void;
	parentProvider?: YoContextEditorCtx
	bypassProps?: any;
}

const context = createContext<YoContextEditorCtx>({} as YoContextEditorCtx);

export type YoContextEditorProps = Readonly<{ item: any, setItem: any, changes: any, setChanges: any }>;
export type YoContextEditorNestedProps = Readonly<{name: string, index?: number }>;
export type YoContextEditorBaseProps = Readonly<{children: ReactNode, bypassProps?: any}>

function isYoContextEditorProps(obj: any): obj is YoContextEditorProps {
	return 'item' in obj && 'setItem' in obj;
}
export function YoContextEditor(props: (YoContextEditorProps | YoContextEditorNestedProps) & YoContextEditorBaseProps) {
	let setItem: (v: any) => void;
	let setChanges: (v: any) => void;

	let item: any;
	let changes: any;

	const parent = useContext(context);
	if(isYoContextEditorProps(props)) {
		item = props.item;
		changes = props.changes;

		setItem = props.setItem;
		setChanges = props.setChanges;
	} else {
		const isArrayLike = typeof props.index === 'number';
		if(isArrayLike) {
			item = get(parent.item, props.name)[props.index] ?? {};
			changes = get(parent.changes, props.name)?.[props.index] ?? {};
		} else {
			item = get(parent.item, props.name) ?? {};
			changes = get(parent.changes, props.name) ?? {};
		}


		setItem = (v: any) => {
			const newItem = {...parent.item};
			if(isArrayLike) {
				let arr = get(newItem, props.name);
				if(!arr) arr = set(newItem, props.name, []);
				arr[props.index] = v;
			} else {
				set(newItem, props.name, v);
			}

			parent.setItem(newItem);
		}

		setChanges = (v: any) => {
			const newItem = {...parent.changes};
			if(isArrayLike) {
				let arr = get(newItem, props.name);
				if(!arr) {
					const originalArray =  get(parent.item, props.name).slice().map((el: any) => pick(el, ['_id']));
					set(newItem, props.name, originalArray);
					arr = get(newItem, props.name);
				}
				arr[props.index] = {_id: arr[props.index]._id,...v};
			} else {
				set(newItem, props.name, v);
			}
			parent.setChanges(newItem);
		}
	}

	const setField = (key: string, value: any, clientOnlyValue?: any) => {
		if(get(item,key) === value) return;

		const clonedItem = {...item};
		const clonedChanges = {...changes};

		set(clonedItem, key,clientOnlyValue ?? value);
		set(clonedChanges, key,value);

		setItem(clonedItem);
		setChanges(clonedChanges);
	}

	const untrackedSetField = (key: string, value: any) => {
		if(get(item,key) === value) return;
		const clonedItem = {...item};
		set(clonedItem, key,value);
		setItem(clonedItem);
	}

	const batchSetField = (props: {key: string, value: any, clientOnlyValue?: any}[]) => {
		const clonedItem = {...item};
		const clonedChanges = {...changes};

		for (const prop of props) {
			set(clonedItem, prop.key,prop.clientOnlyValue ?? prop.value);
			set(clonedChanges, prop.key,prop.value);
		}

		setItem(clonedItem);
		setChanges(clonedChanges);
	}



	return createElement(context.Provider, {
		value: {
			item,
			setItem,
			changes,
			setChanges,
			getField: (key?: string) => key ? get(item, key): item,
			setField,
			untrackedSetField,
			batchSetField,
			parentProvider: parent,
			bypassProps: {...parent.bypassProps ?? {}, ...props.bypassProps ?? {}},
		},
		children: props.children
	});
}

export function useYoProvider<T = any>(key?: string, props?: {upProvider?: number, defaultValue?: T}) {
	let ctx = useContext(context);
	if(props?.upProvider) {
		for (let i = props.upProvider || 0; i > 0; i--) {
			if(ctx.parentProvider) {
				ctx = ctx.parentProvider;
			} else {
				break;
			}
		}
	}
	const value: T = ctx.getField(key) ?? props?.defaultValue;
	const onChange = (val: T | null | undefined) => ctx.setField(key || '', val);
	const untrackedOnChange = (val: T | null | undefined) => ctx.untrackedSetField(key || '', val);
	const push = (...val: Unpacked<T>[]) => {
		if(Array.isArray(value)) {
			ctx.setField(key || '', [...value, ...val]);
		} else if(!value) {
			return ctx.setField(key || '', [...val]);
		}
	}

	const concat = (val: T) => {
		if(Array.isArray(value) && Array.isArray(val)) {
			ctx.setField(key || '', [...value, ...val]);
		}
	}

	return {
		value,
		onChange,
		untrackedOnChange,
		push,
		concat,
		bypassProps: ctx.bypassProps,
	}
}
export {
	context as YoRawContext
}
