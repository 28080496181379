//import { LocaleKeys } from "../../../dashboard/frontend/src/locale/localeKeys";

export const webAppsDesignTemplateKeys = [
    'playMarket',
    'playMarketSKAK',
    'rouletteBalls',
    'rouletteVerdeCasino'
] as const;

export type TWebAppsDesignTemplateKeys = typeof webAppsDesignTemplateKeys[number];

export const webAppsDesignConfigEditorFieldsKeys = [
    'string',
    'textarea',
    'date',
    'number',
    'description',
    'image',
    'images',
    'reviews',
    'icon',
    'tags',
    'checkbox',
    'sliderNumber',
    'rating',
    'whatsNew',
    'relatedApps',
    'installButtonBehavior',
    'trailer'
] as const;

export type TWebAppsDesignConfigEditorFieldKeys = typeof webAppsDesignConfigEditorFieldsKeys[number];

type InstallButtonBehavior = ({
    behavior: 'none'
} | {
    behavior: 'waitInstall',
    waitInstall: {
        scrollToBottom: boolean
    }
} | {
    behavior: 'scrollToBottom',
    scrollToBottom: {
        clickCount: number
    }
})

type Rating = {
    useRatingFromBaseLanguage: boolean,
    rating: string
    ratingsCount: string
    downloadsCount: string
    ratingValues: {
        five: number,
        four: number,
        three: number,
        two: number,
        one: number,
    }
}

type TWebAppReview = {
    name: string,
    avatar: string,
    rating: number,
    text: string,
    reviewRating: number,
    commentDate: string,
    commentDateRaw: string,
    showOwnerResponse: boolean,
    ownerResponse: string,
    ownerResponseDate: string,
}

export type TWebAppsDesignConfigEditorFieldsKeysMap = {
    string: string;
    textarea: string;
    date: string;
    number: number;
    description: string;
    image: string;
    images: string[];
    reviews: TWebAppReview[];
    tags: string[];
    rating: Rating;
    sliderNumber: number;
    checkbox: boolean;
    whatsNew: { enabled: boolean, content: string };
    installButtonBehavior: InstallButtonBehavior;
    relatedApps: {
        enabled: boolean,
        apps: { name: string, url: string, icon: string, rating: number }[]
        position: 'top' | 'bottom'
    };
    trailer: {enabled: boolean, url: string}
};

export type TWebAppsDesignConfigEditorFieldsKeysMapType<T extends TWebAppsDesignConfigEditorFieldKeys> =
    T extends keyof TWebAppsDesignConfigEditorFieldsKeysMap ? TWebAppsDesignConfigEditorFieldsKeysMap[T] : never;


export type TWebAppDesignFieldConfig<T extends TWebAppsDesignConfigEditorFieldKeys = TWebAppsDesignConfigEditorFieldKeys> = {
    label: string /*| LocaleKeys*/,
    type: T,
    aiGenerationAvailable?: boolean,
    default?: TWebAppsDesignConfigEditorFieldsKeysMapType<T>
};

export type TWebAppDesignGroupFieldConfig = { label: string /*| LocaleKeys*/, fields: Record<string, TWebAppDesignFieldConfig> };

export const createWebAppDesignConfig = <
    TAdditionalData extends Record<string, TWebAppDesignGroupFieldConfig | TWebAppDesignFieldConfig>,
    TLocalizationData extends Record<string, TWebAppDesignGroupFieldConfig | TWebAppDesignFieldConfig>,
>(data: {
    label: string,
    legacy?: boolean,
    localization: TLocalizationData,
    additional: TAdditionalData,
    localizationTabs?: Record<string, (keyof TLocalizationData)[]>
}) => {
    return data
}

export type TWebAppDesignConfig = ReturnType<typeof createWebAppDesignConfig>;

export type ExtractTypesFromObject<TObj extends Record<string, TWebAppDesignGroupFieldConfig | TWebAppDesignFieldConfig>> = {
    [TKey in keyof TObj]: TObj[TKey] extends TWebAppDesignFieldConfig
        ? TWebAppsDesignConfigEditorFieldsKeysMapType<TObj[TKey]['type']>
        : TObj[TKey] extends TWebAppDesignGroupFieldConfig
            ? { [TField in keyof TObj[TKey]['fields']]: TWebAppsDesignConfigEditorFieldsKeysMapType<TObj[TKey]['fields'][TField]['type']>  }
            : never
}

