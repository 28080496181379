import {Card, Divider, Group, Stack, Text} from "@mantine/core";
import TariffsGrid from "./Components/TariffsGrid.tsx";
import useTeams from "../../Teams/Context/useTeams.ts";
import {tariffsConfig} from "@shared/_data/tariffs/tariffsConfig.ts";
import { formatDate } from "date-fns/format";

function CurrentTariffInfo () {
    const { team } = useTeams();
    if (!team) return null;
    const teamTariff = team.tariff;

    if (!teamTariff) return null;

    return (
        <Card withBorder mb="sm" mt="xs">
            <Group>
                <Stack gap={0}>
                    <Text size="xs">
                        Current tariff:
                    </Text>
                    <Text>
                        {tariffsConfig[teamTariff]?.label}
                    </Text>
                </Stack>
                {
                    tariffsConfig[teamTariff].type === 'monthly' && (
                        <>
                            <Divider orientation="vertical" />
                            <Stack gap={0}>
                                <Text size="xs" c="dimmed">
                                    {`Next payment (${tariffsConfig[teamTariff].price}$)`}
                                </Text>
                                <Text>
                                    {team.tariffPayedTill ? formatDate(team.tariffPayedTill, 'yyyy.MM.dd') : '-'}
                                </Text>
                            </Stack>
                        </>
                    )
                }
                {
                    (tariffsConfig[teamTariff].maxTeamMembers > 0) ? (
                        <>
                            <Divider orientation="vertical" />
                            <Stack gap={0}>
                                <Text size="xs" c="dimmed">
                                    Team members:
                                </Text>
                                <Text>
                                    {`${team.teamMembersCount} / ${tariffsConfig[teamTariff].maxTeamMembers}`}
                                </Text>
                            </Stack>
                        </>
                    ) : null
                }
            </Group>
        </Card>
    )
}

function PageTariffs() {

    return (
        <>
            <CurrentTariffInfo/>
            <Card>
                <TariffsGrid />
            </Card>
        </>
    );
}

export default PageTariffs;