import {webAppsDesignConfigPlayMarket} from "./playMarket";
import {createWebAppDesignConfig, ExtractTypesFromObject, TWebAppsDesignTemplateKeys} from "./utils";
import {webAppsDesignConfigPlayMarketSKAK} from "./playMarketSKAK";

export const webAppsDesignConfig = {
    // legacy
    playMarket: webAppsDesignConfigPlayMarket,
    playMarketSKAK: webAppsDesignConfigPlayMarketSKAK,
    // https://play.google.con.winmx.mx/ch_H5MX77/010000/landingpage.html
    /*playMarket2: createWebAppDesignConfig({
        label: 'Play Market v2',
        localization: {},
        localizationTabs: {},
        additional: {}
    }),*/
    // https://21.dvlp16new.skin/
    rouletteBalls: createWebAppDesignConfig({
        label: 'Plinko',
        localization: {
            name: {
                type: 'string',
                label: 'webAppsTemplateEdit.playMarket.name',
            },
            icon: {
                label: 'webAppsTemplateEdit.rouletteBalls.icon',
                type: 'image'
            },
            playButtonText: {
                label: 'webAppsTemplateEdit.rouletteBalls.playButtonText',
                type: 'string'
            },
            sign: {
                label: 'webAppsTemplateEdit.rouletteBalls.sign',
                type: 'string'
            },
            mainHowToPlay: {
                label: 'webAppsTemplateEdit.rouletteBalls.mainHowToPlay',
                type: 'string'
            },
            mainRules: {
                label: 'webAppsTemplateEdit.rouletteBalls.mainRules',
                type: 'string'
            },
            mainBetSize: {
                label: 'webAppsTemplateEdit.rouletteBalls.mainBetSize',
                type: 'string'
            },
            mainLevel: {
                label: 'webAppsTemplateEdit.rouletteBalls.mainLevel',
                type: 'string'
            },
            mainWin: {
                label: 'webAppsTemplateEdit.rouletteBalls.mainWin',
                type: 'string'
            },
            popup: {
                label: 'webAppsTemplateEdit.rouletteBalls.popup.group',
                fields: {
                    title: {
                        label: 'webAppsTemplateEdit.rouletteBalls.popup.title',
                        type: 'string'
                    },
                    winValue: {
                        label: 'webAppsTemplateEdit.rouletteBalls.popup.winValue',
                        type: 'string'
                    },
                    register: {
                        label: 'webAppsTemplateEdit.rouletteBalls.popup.register',
                        type: 'string'
                    },
                    popupButtonText: {
                        label: 'webAppsTemplateEdit.rouletteBalls.popup.popupButtonText',
                        type: 'string'
                    },
                }
            },
            collisionReward: {
                label: 'webAppsTemplateEdit.rouletteBalls.collisionReward',
                type: 'number'
            },
            popupTriggerValue: {
                label: 'webAppsTemplateEdit.rouletteBalls.popupTriggerValue',
                type: 'number'
            },
        },
        additional: {}
    }),
    // https://vagttonenahaues.com/verde/p6009?atp=4_%7Bt5%7D&goto=sitereg&click_id=674735a0608ec00001391379&plid=18216&bnid=28536&lang=uk&cc=SE
    rouletteVerdeCasino: createWebAppDesignConfig({
        label: 'Roulette Verde Casino',
        localization: {
            name: {
                type: 'string',
                label: 'webAppsTemplateEdit.playMarket.name',
            },
            icon: {
                label: 'PWA icon',
                type: 'image'
            },
        },
        additional: {}
    })
} as const;

export type TWebAppsDesignConfig = typeof webAppsDesignConfig;

export type TWebAppTemplateData<
    TemplateKey extends TWebAppsDesignTemplateKeys,
    TemplateConfig extends TWebAppsDesignConfig[TemplateKey] = TWebAppsDesignConfig[TemplateKey]
> = {
    locale: ExtractTypesFromObject<TemplateConfig['localization']>,
    additionalData: ExtractTypesFromObject<TemplateConfig['additional']>,
}
