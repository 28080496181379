import {Alert, Card, Grid, Title} from "@mantine/core";

import {useYoProvider} from "@components/YoContextEditor";
import {IconInfoCircle} from "@tabler/icons-react";
import {LinkDomain} from "./linkDomain.tsx";
import {useScopedLocale} from "../../../../locale";
import {LinkedDomainInfo} from "./linkedDomainInfo.tsx";
import {BuyDomain} from "./buyDomain.tsx";
import {LinkExistsDomain} from "./linkExistsDomain.tsx";
import {useParams} from "wouter";


export function DomainsTab() {
	const params = useParams<{id: string}>();
	const tt = useScopedLocale('webApps.view.DomainTab.');
	const domainCtr = useYoProvider('domain');


	return (
		<Card withBorder>
			<Title order={3} mb="xs">{tt('title')}</Title>
			<Alert
				mb="lg"
				icon={<IconInfoCircle/>}
				variant="info"
				title={tt('subTitle')}
			/>
			<Grid align={'stretch'}>
				<Grid.Col span={{lg: 6}}>
					<Title order={3} mb="xs">{tt('buy.guide.1')}</Title>
					<BuyDomain pwaId={params.id}/>
				</Grid.Col>
				<Grid.Col span={{lg: 3}}>
					<Title order={3} mb="xs">{tt('link.guide.1')}</Title>
					<LinkDomain/>
				</Grid.Col>
				<Grid.Col span={{lg: 3}}>
					<Title order={3} mb="xs">{tt('linkExists.title')}</Title>
					<LinkExistsDomain/>
				</Grid.Col>
			</Grid>
			<Grid>
				<Grid.Col span={{lg: 12}}>
					<Title order={3}>{tt('linkDomain.myDomains')}</Title>
					<Alert
						variant="info"
					>
						<LinkedDomainInfo domain={domainCtr.value as string}/>
					</Alert>
				</Grid.Col>
			</Grid>

		</Card>
	);
}
