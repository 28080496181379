import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";
import {ShkeeperApi} from "@shared/lib/ShkeeperApi";
import type {RawDocumentTypePayments} from "@shared/models";
//import {PaymentsController} from "@serverComponents/Payments/PaymentsController.ts";

type ApiResult <T> = ({
	status: true,
	result: T
}) | ({
	status: false,
	message: string
});

export type PaymentData = {
	amount: string,
	display_name: string,
	exchange_rate: string,
	recalculate_after: number,
	status: string,
	id: number,
	wallet: string
}

@singleton
export class PaymentsApiClient extends YoApiClient {
	path = '/api/v1/payments';


	listAvailableCrypto() {
		return this.ky.get(`listAvailableCrypto`).json<ApiResult<Awaited<ReturnType<ShkeeperApi['listAvailableCrypto']>>>>();
	}

	createPayment(params: { teamId: string, amount: number, cryptoName: string }) {
		return this.ky.post('createPayment', { json: params }).json<ApiResult<{ payData: PaymentData, payment: RawDocumentTypePayments }>>();
	}

	checkStatus(paymentId: string) {
		return this.ky.get(`${paymentId}/checkStatus`).json<ApiResult<{ status: string }>>();
	}

	cancelPayment(paymentId: string) {
		return this.ky.post(`${paymentId}/cancelPayment`).json<ApiResult<boolean>>();
	}


	getPaymentConfig () {
		return this.ky.get('getPaymentConfig').json<ApiResult<{ minimumPaymentAmount: number }>>()
	}
}
