import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";
import {ImageUploader} from "@YoUi/imageUploader.tsx";
import {Card, Title} from "@mantine/core";
import {useScopedLocale} from "../../../../../../locale";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'image'>> = (props) => {
    const tt = useScopedLocale('webApps.view.ImagesTab.');
    return (
        <div>
            <Title order={5} mb="xs">
                {tt('icon.title')}
            </Title>
            <Card variant="section" mb="lg">
                <ImageUploader
                    onDrop={({ _id }) => {
                        props.onChange(_id);
                    }}
                    imageId={props.value}
                />
            </Card>
        </div>
    );
}

export default FieldTypeString;