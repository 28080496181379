import {TagsInput, TagsInputProps} from "@mantine/core";
import {useYoProvider} from "@components/YoContextEditor";

export function TagsInputField({field, ...otherProps}: { field: string } & Omit<TagsInputProps, 'value' | 'onChange'>) {
	const ctx = useYoProvider<string[]>(field);
	const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;

	let arr = ctx.value ?? [];
	if(!Array.isArray(arr)) arr = Object.values(arr);

	return <TagsInput value={arr} onChange={e => ctx.onChange(e)} {...otherProps} readOnly={readOnly}/>;
}

