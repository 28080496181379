import {createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState} from "react";
import {Loader} from "@mantine/core";
import {TeamsApiClient, type TMyTeam} from "../api.ts";
import type {TeamUserRoleKeys} from "@shared/_data/teamUserRoles.ts";
import OnboardingRouter from "../../OnBoarding/OnboardingRouter.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

type TTeamsContext = {
    myTeams: TMyTeam[],
    activeTeam: string | null,
    activeTeamRole: TeamUserRoleKeys | null,
    team: TMyTeam | null,
    setActiveTeam: Dispatch<SetStateAction<string | null>>,
    loadMyTeams: () => Promise<void>,
}

export const TeamsContext = createContext<TTeamsContext>({} as TTeamsContext);

const api = new TeamsApiClient();

export function TeamsContextProvider({ children }: { children: ReactNode }) {
    const [activeTeam, setActiveTeam] = useState<string | null>(localStorage.getItem("activeTeam") || null);
    const [myTeams, setMyTeams] = useState<TMyTeam[] | null>(null);

    // Убрал try catch ибо если с бэка прилетает ошибка то, отображается предложение создать новую команду. По логике тут лучше упасть
    const loadMyTeams = async () => {
        const res = await api.getMyTeams();
        if (!res.status) {
            void displayErrorNotification(res.message);
            throw new Error(res.message);
        }

        setMyTeams(res.items);
        const newActiveTeam = res.items.find(e => e._id === activeTeam) || res.items[0];
        if (newActiveTeam) setActiveTeam(newActiveTeam._id);
    }

    useEffect(() => {
        void loadMyTeams();
    }, []);

    useEffect(() => {
        if (activeTeam) {
            localStorage.setItem("activeTeam", activeTeam);
        } else {
            localStorage.removeItem("activeTeam");
        }
    }, [activeTeam]);

    const value: TTeamsContext = useMemo(() => {
        const currentTeam = (myTeams || []).find(e => e._id === activeTeam);
        return {
            myTeams: myTeams as TMyTeam[],
            activeTeam,
            setActiveTeam,
            team: currentTeam || null,
            activeTeamRole: currentTeam ? currentTeam.role : null,
            loadMyTeams
        };
    }, [activeTeam, setActiveTeam, myTeams]);

    if (!myTeams) {
        return <Loader />
    }

    if (myTeams.length === 0) return (
        <TeamsContext.Provider value={value}>
            <OnboardingRouter />
        </TeamsContext.Provider>
        /*<Center h="60dvh">
            <Card>
                <Title order={3} mb="sm">
                    Create team
                </Title>
                <CreateTeamForm onSuccess={loadMyTeams} />
            </Card>
        </Center>*/
    );

    return (
        <TeamsContext.Provider value={value}>
            {children}
        </TeamsContext.Provider>
    );
}

