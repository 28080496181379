import {useState} from "react";
import {useYoProvider} from "@components/YoContextEditor";
import ky from "ky";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {Menu} from "@mantine/core";
import {IconLanguageHiragana} from "@tabler/icons-react";

export function TranslateFieldMenuItem(props: {
	field: string,
	upProvider?: number,
	engine?: string,
	langField?: string
}) {
	const [disabled, setDisabled] = useState(false);
	const ctx = useYoProvider(props.field);
	const translateEngineCtx = useYoProvider( 'translateEngine', {upProvider: props.upProvider});
	const lang = ctx.bypassProps.lang;
	const engine = props.engine || translateEngineCtx.value;

	function runTranslate() {
		setDisabled(true);
		return ky.post('/api/v1/utils/translate', {
			json: {
				text: ctx.value,
				language: lang,
				engine: engine
			}
		}).json<{ status: boolean, text: string }>()
			.then(res => {
				ctx.onChange(res.text);
			}).catch(displayErrorNotification).finally(() => setDisabled(false));
	}

	return (
		<Menu.Item disabled={!lang || disabled} onClick={runTranslate} leftSection={<IconLanguageHiragana/>}>
			{lang ? `Перевести на ${lang.toLowerCase()} язык через ${engine}` : 'Укажите язык приложения'}
		</Menu.Item>
	);
}
