import {MultiSelect, MultiSelectProps, Select, SelectProps} from "@mantine/core";
import {deeplLanguagesList} from "@shared/_data/deeplLanguagesList.ts";
import {allLanguageList as openaiLanguagesList} from "@shared/_data/allLanguageList.ts";
import {useMemo} from "react";
import {useLocaleContext} from "../../../locale";
import {ILocaleItem} from "@shared/interfaces/iLocaleItem.ts";

interface ILanguageSelectProps extends SelectProps {
	platform: 'openai' | 'deepl';
	whitelist?: string[];
}
interface ILanguageMultiSelectProps extends MultiSelectProps {
	platform: 'openai' | 'deepl';
	whitelist?: string[];
}

function useValues({platform, whitelist}: { platform: "openai" | "deepl", whitelist?: string[] }) {
	const {selectedLocale} = useLocaleContext();
	return useMemo(() => {
		function mapFn(el: ILocaleItem) {
			//if(selectedLocale === 'uk' && el.code === 'RU') return null;
			const name = selectedLocale === 'ru' ? el.name_ru : selectedLocale === 'uk' ? el.name_ukr : el.name_en;
			return {
				label: `${el.emoji} ${name}`,
				value: el.code
			}
		}

		function whitelistFn(el: ILocaleItem) {
			if(!whitelist) return true;
			return whitelist.includes(el.code);
		}

		if(platform === 'openai') {
			return openaiLanguagesList.filter(whitelistFn).map(mapFn).filter(Boolean);
		} else if(platform === 'deepl') {
			return deeplLanguagesList.filter(whitelistFn).map(mapFn).filter(Boolean);
		}
		return [];
	}, [platform, selectedLocale, JSON.stringify(whitelist ?? [])])
}

export function LanguageSelect(props: ILanguageSelectProps) {
	const items = useValues({
		platform: props.platform,
		whitelist: props.whitelist
	});
	return (
		<Select
			data={items}
			{...props}
		/>
	)
}

export function LanguageMultiSelect(props: ILanguageMultiSelectProps) {
	const items = useValues({
		platform: props.platform,
		whitelist: props.whitelist
	});
	return (
		<MultiSelect
			data={items}
			{...props}
		/>
	)
}
