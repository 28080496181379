import {useEffect} from "react";
import {PaymentData, PaymentsApiClient} from "../api.ts";
import {Box, Group, Stack, Text, Title} from "@mantine/core";
import {IconTimeline} from "@tabler/icons-react";
import {CopyButtonWithTooltip} from "../../../../utils/copyButtonWithTooltip.tsx";

type AwaitingPaymentProps = {
    id: string;
    paymentData: PaymentData;
    onSuccess: () => void;
    onFailure: (message: string) => void;
}

const api = new PaymentsApiClient();

function AwaitingPayment(props: AwaitingPaymentProps) {

    useEffect(() => {
        const interval = setInterval(() => {
            api.checkStatus(props.id).then((data) => {
                if (data.status) {
                    if (data.result.status === 'completed') {
                        props.onSuccess();
                    }
                    if (data.result.status === 'error') {
                        props.onFailure('Error');
                    }
                    if (data.result.status === 'canceled') {
                        props.onFailure('Payment cancelled');
                    }
                } else {
                    props.onFailure(data.message);
                }
            });
        }, 10_000 /* 10 sec */);
        return () => clearInterval(interval);
    }, [props.id]);

    return (
        <Box
            style={{
                width: '100%',
                height: '75vh',
            }}
        >
            <Stack align="center">
                <Group>
                    <IconTimeline />
                    <Title>
                        Awaiting payment
                    </Title>
                </Group>
                <Text size="xl" fw={600}>
                    {props.paymentData.display_name}
                </Text>
                <Group>
                    <Text>
                        {props.paymentData.wallet}
                    </Text>
                    <CopyButtonWithTooltip value={props.paymentData.wallet}/>
                </Group>
                <Group>
                    <Text>
                        Send exact amount:
                    </Text>
                    <Text c="green" fw={600}>
                        {props.paymentData.amount}
                    </Text>
                    <CopyButtonWithTooltip value={props.paymentData.amount}/>
                </Group>
            </Stack>
        </Box>
    );
}

export default AwaitingPayment;