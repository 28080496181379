import {useEffect, useState} from "react";
import {usePromise} from "@hooks/usePromise.ts";
import ky from "ky";

export function useSiteSettings(namespace: string) {
	const [changes, setChanges] = useState({});
	const promise = usePromise<{ data: any }>(async () => {
		const {data} = await ky.get(`/api/v1/system/siteSettings/${namespace}`).json<{ data: any }>();

		return Object.entries(data).reduce<any>((store, [key, value]) => {
			store[key.replaceAll('.', ':')] = value;
			return store;
		}, {})
	}, [namespace]);
	useEffect(() => setChanges({}), [namespace]);

	const controller = {
		item: promise.data ?? {},
		setItem: promise.setData,
		changes,
		setChanges
	}

	function saveChanges() {
		const payload = Object.entries(promise.data as any).reduce<any>((store, [key, value]) => {
			store[namespace + key.replaceAll(':', '.')] = value;
			return store;
		}, {});
		return ky.post(`/api/v1/system/siteSettings`, {json: payload}).json().then(() => setChanges({}));
	}

	return {
		...promise,
		controller,
		saveChanges
	}
}
