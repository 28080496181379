import {useEffect, useRef, useState} from "react";
import {useYoProvider} from "@components/YoContextEditor";
import {Alert, Box, Center, LoadingOverlay} from "@mantine/core";
import {useUser} from "@engine/UserContext.tsx";
import {createDateFormater} from "@shared/utils/dataFormatter";
import {startOfWeek, subDays, subWeeks} from "date-fns";
import {withErrorBoundary} from "react-error-boundary";
import * as Sentry from "@sentry/react";

function randomInt(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}


function injectTimestamps(dataMap: any, dateFormatFn: (d: Date) => string): any {
	let offset = 1;
	if(!dataMap) return {};

	let reviews = (dataMap?.reviews || []);
	if(!Array.isArray(reviews) && reviews) reviews = Object.values(reviews);


	return {
		...dataMap,
		reviews: reviews.map((el: any, index: number) => {
			let date = new Date();
			if (index === 0) {
				date = new Date()
			} else if (index === 1) {
				date = subDays(new Date(), randomInt(0, 1))
			} else {
				offset = randomInt(offset, 3);
				date = subDays(new Date(), offset);
			}
			return {
				...el,
				date: dateFormatFn(date),
				ownerResponseDate: dateFormatFn(date)
			}
		})
	}
}

function makeData({data, security, templateKey, globalCtx, langCode}: {
	data: any,
	security: any,
	templateKey: any,
	globalCtx: any,
	langCode: string
}): any {
	const {content} = data;
	const {screens = [], ratingValues = {}, whatsNew = {},  ...contentData} = content;

	const dataFormater = createDateFormater(langCode);
	const updatedDate = startOfWeek(subWeeks(new Date(), 1), {weekStartsOn: 1});

	return {
		...contentData,
		updatedDate: dataFormater(updatedDate),
		screens: screens.map((el: any) => ({url: el.image})),
		ratingCharts: {
			5: ratingValues.five ?? 0,
			4: ratingValues.four ?? 0,
			3: ratingValues.three ?? 0,
			2: ratingValues.two ?? 0,
			1: ratingValues.one ?? 0,
		},
		appSecurity: {
			noCollect: security.noDataCollected,
			noShare: security.noDataShare,
		},
		whatsNewContent: whatsNew.enabled ? whatsNew.content : '',
		templateKey,
		dataMap: {
			locale: injectTimestamps(content.dataMap, dataFormater),
			additionalData: globalCtx.value.dataMap
		}
	};
}

export function PreviewFrame(props: { locale: string }) {
	const {user, settings = {}} = useUser();
	const ref = useRef<HTMLIFrameElement>(null);
	const bridgeReceivedFn = useRef<((e: MessageEvent) => void) | null>(null);
	const [loading, setLoading] = useState(true);
	const globalCtx = useYoProvider();
	const securityCtx = useYoProvider(`security`, {defaultValue: {noDataCollected: false, noDataShare: false}});
	const ctx = useYoProvider(`localeAppData.${props.locale}`);

	useEffect(() => {
		if (ref.current) {
			ref.current.contentWindow?.postMessage({type: 'update', data: makeData({
					data: ctx.value,
					security: securityCtx.value,
					templateKey: globalCtx.value.templateKey,
					globalCtx: globalCtx,
					langCode: props.locale
				})}, '*');
		}
	}, [JSON.stringify(ctx.value), globalCtx.value.templateKey]);

	useEffect(() => {
		return () => {
			if(bridgeReceivedFn.current) {
				console.log(`remove global 'message' event listener`);
				window.removeEventListener('message', bridgeReceivedFn.current);
			}
		}
	}, []);

	function initFrame(frameElement: HTMLIFrameElement) {
		setLoading(false);

		function onMessage(e: any) {
			if(e.origin !== settings.previewUrl) return;
			console.log(`A message was received from a preview frame`, e.data);
			if(e.data.type === 'onReady') {
				frameElement.contentWindow?.postMessage({type: 'init', data: makeData({
						data: ctx.value,
						security: securityCtx.value,
						templateKey: globalCtx.value.templateKey,
						globalCtx: globalCtx,
						langCode: props.locale
					})}, '*');
			}
		}

		window.addEventListener('message', onMessage);
		bridgeReceivedFn.current = onMessage;
		frameElement.contentWindow?.postMessage({type: 'init', data: makeData({
				data: ctx.value,
				security: securityCtx.value,
				templateKey: globalCtx.value.templateKey,
				globalCtx: globalCtx,
				langCode: props.locale
			})}, '*');
	}

	return (
		<Box pos="relative" h={'80vh'}>
			<LoadingOverlay visible={loading}/>
			{ctx.value && (
				<iframe
					key={globalCtx.value.templateKey}
					onLoadStart={() => setLoading(true)}
					onLoad={e => initFrame(e.target as HTMLIFrameElement)}
					ref={ref}
					src={`${settings.previewUrl}/?lang=${props.locale}&uid=${user._id}`}
					style={{width: '100%', height: '100%'}}
				/>
			)}
		</Box>
	);
}
export const PreviewFrameWithLoader = withErrorBoundary(PreviewFrame, {
	fallback: (
		<Box pos="relative" h={'80vh'}>
			<Alert color={'red'} h={'100%'}>
				<Center>
					Something went wrong :(
				</Center>
			</Alert>
		</Box>
	),
	onError: (error: any) => {
		Sentry.captureException(error);
	}
});
