import {DependencyList, useEffect} from 'react';

export function useDebounceEffect(callback: () => void, delay: number, dependencies: DependencyList) {
	useEffect(() => {
		const handler = setTimeout(() => {
			callback();
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [...dependencies, delay]); // Обновляем эффект при изменении зависимостей и задержки

}
