import {iStateProps} from "../IStateProps.tsx";
import {Box, LoadingOverlay, Table} from "@mantine/core";
import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {addStatsJob} from "../utils/statsQueue.ts";

export function AppTop(props: iStateProps) {
	const promise = usePromiseWithTeamDependency(() => {
		return addStatsJob(() => getKyInstance().post('/api/v1/stats/apps', {
			json: {
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers
			},
			timeout: 1000 * 30
		}).json<{items: any[]}>());
	}, [JSON.stringify([props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);

	if(promise.isError) return displayTSMatchGeneralError(promise);

	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<div style={{
				height: '300px',
				overflow: 'scroll'
			}}>
				<Table stickyHeader style={{overflow: 'scroll'}} data={{
					head: ['Owner', 'Name', 'Visits', 'Installs', 'Registrations', 'Deposits'],
					body: promise.data?.items.map(data => [
						data.owner,
						data.name,
						data.visits,
						data.installs,
						data.registrations,
						data.deposits
					])
				}}/>
			</div>
		</Box>
	);
}
