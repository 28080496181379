import {usePromise} from "@hooks/usePromise.ts";
import {useEffect, useState} from "react";
import {TransferFbPixelLogsJobApiClient} from "./api.ts";
import {useParams} from "wouter";
import {Button, Card, Group, Loader, Stack, Text} from "@mantine/core";
import {Header} from "@components/Header";
import {VisibleOnlyAdmin} from "../../../utils/visibleOnlyAdmin.tsx";
import {showNotification} from "@mantine/notifications";

const api = new TransferFbPixelLogsJobApiClient();

function ViewTransferFbPixelLogsJob() {
    const params = useParams<{id: string}>();
    const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
    const [data, setData] = useState<any | null>(null);
    useEffect(() => setData(promise.data?.item ?? null), [promise.isLoading]);

    useEffect(() => {
        let interval: any;
        if (data && data.status === 'progress') {
            interval = setInterval(() => {
                api.fetchItem(params.id).then(res => {
                    setData(res.item);
                })
            }, 5000);
        }

        return () => {
            interval && clearInterval(interval);
        }
    }, [params.id]);

    if (!data) return (
        <Loader />
    )

    return (
        <>
            <Header
                backButtonUrl=""
                title={`Transfer events`}
                items={(
                    <VisibleOnlyAdmin>
                        <Button
                            onClick={() => {
                                api.proceedJob(params.id).then(() => {
                                    showNotification({
                                        title: 'task restarted',
                                        message: ''
                                    })
                                })
                            }}
                        >
                            Rerun task
                        </Button>
                    </VisibleOnlyAdmin>
                )}
            />
            <Card component={Stack}>
                <Group>
                    <Stack gap={0}>
                        <Text size="xs">
                            Status
                        </Text>
                        <Text size='lg' fw={600}>
                            {data.status}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text size="xs">
                            Sent events
                        </Text>
                        <Text>
                            {data.alreadySent}
                        </Text>
                    </Stack>
                </Group>
                <Card variant="section">
                    <Group>
                        <Stack gap={0}>
                            <Text size="xs">
                                Initiator
                            </Text>
                            <Text>
                                {data.owner}
                            </Text>
                        </Stack>
                        <Stack gap={0}>
                            <Text size="xs">
                                Created at
                            </Text>
                            <Text>
                                {data.createdAt ? new Date(data.createdAt).toLocaleString() : '-'}
                            </Text>
                        </Stack>
                        <Stack gap={0}>
                            <Text size="xs">
                                Updated at
                            </Text>
                            <Text>
                                {data.updatedAt ? new Date(data.updatedAt).toLocaleString() : '-'}
                            </Text>
                        </Stack>
                    </Group>
                </Card>
                <div>
                    <Text fw={600}>
                        Pixels
                    </Text>
                    <Group>
                        <Card variant="section">
                            <Stack gap={0}>
                                <Text size="xs">From</Text>
                                <Text>
                                    {data.from}
                                </Text>
                            </Stack>
                        </Card>
                        <Card variant="section">
                            <Stack gap={0}>
                                <Text size="xs">To</Text>
                                <Text>
                                    {data.to}
                                </Text>
                            </Stack>
                        </Card>
                    </Group>
                </div>
                <div>
                    <Text fw={600}>Settings</Text>
                    <Card variant="section">
                        <Group>
                            <Stack gap={0}>
                                <Text>
                                    {`batch size: ${data.config.batchSize}`}
                                </Text>
                                <Text>
                                    {`Interval: ${data.config.delayInterval}`}
                                </Text>
                            </Stack>
                            <Stack gap={0}>
                                <Text size="xs">
                                    Countries
                                </Text>
                                <Text>
                                    {(data.countries || []).length === 0 ? 'All' : data.countries.join(',')}
                                </Text>
                            </Stack>
                        </Group>
                    </Card>
                </div>
            </Card>
        </>
    );
}

export default ViewTransferFbPixelLogsJob;