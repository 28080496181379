import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Box, Button, Grid, LoadingOverlay, Table, TextInput} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

import {RegisterInvitesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {DatePickerInput} from "@mantine/dates";
import {addDays, endOfDay} from "date-fns";
import {AdminUsersApiClient} from "../api.ts";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {CopyButtonWithTooltip} from "../../../../utils/copyButtonWithTooltip.tsx";

const api = new RegisterInvitesApiClient();
const usersApi = new AdminUsersApiClient();

function InvitedUsersByCode(props: { codeId: string }) {
	const promise = usePromise(() => {
		return usersApi.fetchList({
			query: {
				inviteCode: {
					type: 'equals',
					value: props.codeId
				}
			}
		}).then(res => res.items);
	});
	const data = promise.data ?? [];
	if(promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<Table
				data={{
					head: ['Имя', 'Email', 'Дата регистрации'],
					body: data.map(user => [user.username, user.email, new Date(user.createdAt).toLocaleString()])
				}}
			/>
		</Box>
	);
}

export function ViewRegisterInvites() {
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: 'Сохранение успешно'});
			setChanges({});
		}).catch(displayErrorNotification);
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={'Редактор'}
				items={(
					<>
						<Button onClick={trySave} size={'compact-sm'}>Сохранить</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>Удалить</Button>
					</>
				)}
			/>
			<Grid>
				<Grid.Col span={6}>
					<InputField
						label={'Invite code'}
						field={'inviteCode'}
						required
						rightSection={(
							<RenderFuncField<string> field={'inviteCode'}>
								{ctx => (
									<CopyButtonWithTooltip value={ctx.value}/>
								)}
							</RenderFuncField>
						)}
					/>
					<RenderFuncField<string> field={'inviteCode'}>
						{ctx => (
							<TextInput
								label={'Invite link'}
								readOnly
								value={`${window.location.origin}/register?inviteCode=${ctx.value}`}
								rightSection={(
									<RenderFuncField<string> field={'inviteCode'}>
										{ctx => (
											<CopyButtonWithTooltip value={`${window.location.origin}/register?inviteCode=${ctx.value}`}/>
										)}
									</RenderFuncField>
								)}
							/>
						)}
					</RenderFuncField>
					<NumberField field={'maxInvites'} label={'Max invites'}/>
					<RenderFuncField<string> field={'disableDate'}>
						{ctx => (
							<DatePickerInput
								label={'Disable date'}
								value={ctx.value ? new Date(ctx.value) : null}
								onChange={v => ctx.onChange(endOfDay(v as Date) as any)}
								minDate={addDays(new Date(), 1)}
							/>
						)}
					</RenderFuncField>
				</Grid.Col>
				<Grid.Col span={6}>
					<InvitedUsersByCode codeId={params.id} />
				</Grid.Col>
			</Grid>
		</YoContextEditor>
	);
}
