import {Button} from "@mantine/core";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {IpBlacklistApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useMemo} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {IconAddressBook, IconTrash} from "@tabler/icons-react";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import MenuManager from "@engine/MenuManager.ts";

const api = new IpBlacklistApiClient()

interface IItem {
	_id: string;
}

MenuManager.RegisterItem({
	path: `/system/blacklist/ip`,
	key: 'IpBlacklist',
	name: 'ip блэклист',
	nameKey: 'menuItems.system.ip',
	parent: 'system',
	icon: <IconAddressBook/>
})


export function ListIpBlacklist() {
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.IpBlacklist.columnVisibility',
		defaults
	});

	async function tryDeleteItem(_id: string) {
		await api.deleteItem(_id);
		return data.fetch();
	}

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: 'value',
		header: 'ip адрес',
	},{
		accessorKey: 'actions',
		header: 'Действия',
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Button color={'red'} onClick={() => tryDeleteItem(props.row.original._id)} size={'compact-sm'}>
					<IconTrash/>
				</Button>
			</>
		)
	}], []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const {stringList} = await yoFormModal<{stringList: string}>({
			title: 'Новая запись',
			required: ['stringList'],
			body: (
				<>
					<TextareaField autosize minRows={4} maxRows={30} field={'stringList'} label={'ip адреса'} description={'По одному ip адресу на строку'}/>
				</>
			)
		});
		const items = stringList.split('\n').map((item: string) => item.trim()).filter(Boolean);
		await api.bulkAdd(items).catch(displayErrorNotification);
		return data.fetch();
	}

	return <>
		<Header
			title={'Заблокированные ip адреса'}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate}>Новая запись</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
