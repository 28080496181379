import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class LeadsApiClient extends YoApiClient {
	path = '/api/v1/leads';

	sendPush(id: string, data: {title: string, message: string}) {
		return this.ky.post(`${id}/push`, {
			json: data
		}).json<{status: boolean}>();
	}

	sendPushByMessageId(id: string, msgId: string) {
		return this.ky.post(`${id}/push`, {
			json: {messageId: msgId}
		}).json<{status: boolean}>();
	}

	getOfferLinksStats(appId: string) {
		return this.ky.post(`offerLinksStats`, {
			json: {appId}
		}).json<{items: any[]}>();
	}
}
