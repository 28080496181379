import {PushSegmentsApiClient} from "./api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {MultiSelect, MultiSelectProps, Select, SelectProps} from "@mantine/core";

const api = new PushSegmentsApiClient();


function useData() {
	const promise = usePromise(() => api.fetchList({limit: 1000}).then(res => res.items.map((el: any) => {
		return {
			label: `${el.comment ?? el._id}`,
			value: el._id
		}
	})), []);

	const data = (promise.data ?? []);

	return {
		items: data,
	}
}

export function SelectPushSegment(props: Omit<SelectProps, 'data'>) {
	const {items} = useData();

	return (
		<Select
			data={items}
			{...props}
		/>
	);
}

export function MultiSelectPushSegments(props: Omit<MultiSelectProps, 'data'>) {
	const {items} = useData();

	return (
		<MultiSelect
			data={items}
			{...props}
		/>
	)
}
