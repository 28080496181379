import {JSX, useMemo} from "react";
import MenuManager from "@engine/MenuManager.ts";
import {useUser} from "@engine/UserContext.tsx";
import {useLocale, useLocaleContext} from "../locale";
import {LocaleKeys} from "../locale/localeKeys.tsx";
import useTeams from "../Pages/Teams/Context/useTeams.ts";
import {TeamUserRoleRights} from "@shared/_data/teamUserRoles.ts";

const mm = new MenuManager();
interface IItem {
	onlySuperAdmin?: boolean;
	parent?: string;
	name?: string;
	nameKey?: LocaleKeys;
	position: number;
	path: string;
	icon?: JSX.Element
	requiredRights?: TeamUserRoleRights[]
}

interface IGroup {
	onlySuperAdmin?: boolean;
	key: string;
	name?: LocaleKeys;
	nameKey?: string;
	children: Array<IItem>;
	position?: number;
	icon?: JSX.Element
	requiredRights?: TeamUserRoleRights[]
}

type BaseMenuItem = {
	type: 'item';
	item: IItem;
	position: number;
}

export type GroupMenuItem = {
	type: 'group';
	item: IGroup;
	position: number;
}

type MenuItem = BaseMenuItem | GroupMenuItem;

export function useShellMenuItems() {
	const {user} = useUser();
	const { activeTeam, myTeams } = useTeams();
	const rights: TeamUserRoleRights[] = useMemo(() => myTeams.find(e => e._id === activeTeam)?.rights || ['all'], [activeTeam, myTeams]);
	const t = useLocale();
	const {selectedLocale} = useLocaleContext();

	return useMemo(() => {
		let { groups, items }: { groups: Array<IGroup>, items: Array<IItem> } = mm;
		groups = groups.filter(e => e.onlySuperAdmin ? user?.isSuperAdmin : true).map((group: IGroup) => {
			group.children = items.filter((e) => e.parent === group.key).sort((a, b) => (a.position > b.position ? 1 : -1)).map(el => {
				if(el.nameKey) el.name = t(el.nameKey as any);
				return el;
			});
			return group;
		});
		items = items
			.filter((e) => !e.parent)
			.filter(e => e.onlySuperAdmin ? user?.isSuperAdmin : true)
			.filter(e => {
				if (!activeTeam) return true;
				if (user.isSuperAdmin) return true;
				if (rights.includes('all')) return true;
				if (!e.requiredRights) return false;
				return e.requiredRights.every(e => rights.includes(e));
			});

		const menuItems: Array<MenuItem> = [
			...items.map<BaseMenuItem>((item: IItem) => ({
				type: 'item',
				item,
				position: item.position || 0
			})),
			...groups.map<GroupMenuItem>((group: IGroup) => ({
				type: 'group',
				item: group,
				position: group.position || 0
			}))
		].sort((a, b) => (a.position > b.position ? 1 : -1));

		return menuItems.map(el => {
			if(el.item.nameKey) el.item.name = t(el.item.nameKey as any);
			return el;
		});
	}, [user?.isSuperAdmin, rights, selectedLocale])


}
