import {Route} from "wouter";
import MenuManager from "@engine/MenuManager.ts";
import {IconUsersGroup} from "@tabler/icons-react";
import {ListTeams} from "./listTeams.tsx";
import {ViewTeams} from "./view";

const baseUrl = '/teams';



MenuManager.RegisterItem({
    path: baseUrl,
    key: 'Teams',
    name: 'Командная работа',
    // nameKey: 'menuItems.teams',
    icon: <IconUsersGroup />,
    requiredRights: ['teams']
})

export function TeamsRouter(props: { basePath?: string }) {
    return (
        <Route path={props.basePath || baseUrl} nest>
            <Route path={'/'} component={ListTeams}/>
            <Route path={'/v/:id'} component={ViewTeams}/>
        </Route>
    );
}
