import ky from "ky";

export function getKyInstance() {
	return ky.create({headers: {'yo-current-team': localStorage.getItem('activeTeam') || ''} });
}

export abstract class YoApiClient<T = any, TList = T> {
	path: string
	private _ky: typeof ky;

	get ky() {
		if(this._ky) return this._ky;
		return this._ky = ky.create({
			prefixUrl: this.path,
			hooks: {
				beforeRequest: [
					req => req.headers.set(`yo-current-team`, localStorage.getItem('activeTeam') || '')
				]
			}
		});
	}

	fetchList(props: { offset?: number, sort?: string, query?: any, limit?: number, requestParams?: any }): Promise<{status: boolean, items: TList[], count: number}> {
		return this.ky.post('', {json: props}).json();
	}

	fetchItem(id: string): Promise<{ status: boolean, item: T}> {
		return this.ky.get(id).json();
	}

	saveItem(id: string, changes: any): Promise<{status: boolean}> {
		return this.ky.post(id, {json: changes}).json();
	}

	cloneItem(id: string, override?: any): Promise<{status: boolean, item: T}> {
		return this.ky.post(`${id}/clone`, {json: override}).json();
	}

	createItem(payload?: any): Promise<{status: boolean, item: T}> {
		return this.ky.put('', {json: payload || {}}).json();
	}

	deleteItem(id: string): Promise<{status: boolean}> {
		return this.ky.delete(id).json();
	}

	fetchDistinct(field: string): Promise<{status: boolean, items: string[]}>{
		return this.ky.get('distinct', {searchParams: {field}}).json();
	}
}


