import {useScopedLocale} from "../../../../locale";
import {useYoProvider} from "@components/YoContextEditor";
import {notifications} from "@mantine/notifications";
import {parseApiError} from "../../../../utils/parseApiError.tsx";
import {Alert, Button, Modal} from "@mantine/core";
import {IconInfoCircle} from "@tabler/icons-react";
import {DomainsApiClient} from "../../../Domains/api.ts";
import {useState} from "react";
import SuggestDomains, {DomainSuggestMetadata} from "../../../Domains/suggestDomains.tsx";
import {useDisclosure} from "@mantine/hooks";

const domainsApi = new DomainsApiClient();


export function BuyDomain({pwaId}: {pwaId: string}) {
	const [isOpen, {toggle}] = useDisclosure(false);
	const tt = useScopedLocale('webApps.view.DomainTab.');
	const [disabled, setDisabled] = useState(false);
	const domainCtr = useYoProvider('domain');
	const [selectedDomain, setSelectedDomain] = useState<DomainSuggestMetadata | undefined>()

	async function tryBuyDomain() {
		if(!selectedDomain) return;
		setDisabled(true);
		try {
			const result = await domainsApi.buyDomain({ ...selectedDomain, pwaId });
			domainCtr.onChange(result.item._id);
			toggle();
		} catch (err) {
			notifications.show({color: 'red', message: parseApiError(err)});
		} finally {
			setDisabled(false);
		}
	}

	return (
		<>
			<Alert
				icon={<IconInfoCircle/>}
				variant="info"
				title={(
					<div>
						<div>{tt('buy.guide.2')}</div>
						<div>{tt('buy.guide.3')}</div>
						<div>{tt('buy.guide.4')}</div>
					</div>
				)}
				mb="lg"
			>
				<Button disabled={disabled} fullWidth variant="secondary" onClick={toggle}>{tt('buy.openModal')}</Button>
			</Alert>
			<Modal opened={isOpen} onClose={disabled ? () => {} : toggle} title={tt('buy.modalTitle')}>
				<SuggestDomains value={selectedDomain} onChange={setSelectedDomain} disabled={disabled}/>
				{selectedDomain && (
					<>
						<hr/>
						<Button fullWidth disabled={disabled} onClick={tryBuyDomain}>
							{tt('buy.confirmButton')}
						</Button>
					</>
				)}
			</Modal>
		</>
	);
}
