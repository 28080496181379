import {ActionIcon, CopyButton, CopyButtonProps, rem, Tooltip} from "@mantine/core";
import {IconCheck, IconCopy} from "@tabler/icons-react";

export function CopyButtonWithTooltip(props: Omit<CopyButtonProps, 'children'>) {
	return (
		<CopyButton {...props}>
			{({copied, copy}) => (
				<Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
					<ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
						{copied ? (
							<IconCheck style={{width: rem(16)}}/>
						) : (
							<IconCopy style={{width: rem(16)}}/>
						)}
					</ActionIcon>
				</Tooltip>
			)}
		</CopyButton>
	)
}
