import {Textarea} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'textarea'>> = (props) => {
    return (
        <Textarea
            label={props.label}
            value={props.value}
            onChange={e => {
                props.onChange(e.target.value)
            }}
            autosize
            minRows={3}
            rightSection={props.rightSection}
        />
    );
}

export default FieldTypeString;