import {iStateProps} from "../IStateProps.tsx";
import {Box, Flex, LoadingOverlay, Table, Tabs} from "@mantine/core";
import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {useLocaleContext, useScopedLocale} from "../../../locale";
import {addStatsJob} from "../utils/statsQueue.ts";

export function CountryTop(props: iStateProps) {
	const t = useScopedLocale('pages.mainPage.county.');
	const {selectedLocale} = useLocaleContext();
	const promise = usePromiseWithTeamDependency(() => {
		return addStatsJob(() => getKyInstance().post('/api/v1/stats/country', {
			json: {
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers
			},
			timeout: 1000 * 30
		}).json<{items: any[]}>());
	}, [JSON.stringify([props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);

	function renderCountryName(data: any) {
		if(selectedLocale === 'ru') return data.country_ru;
		if(selectedLocale === 'uk') return data.country_uk;
		return data.country_en;
	}

	if(promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<Tabs defaultValue="table">
				<Tabs.List>
					<Tabs.Tab value="map" disabled>
						{t('map')}
					</Tabs.Tab>
					<Tabs.Tab value="table">
						{t('table')}
					</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="map">
					...
				</Tabs.Panel>

				<Tabs.Panel value="table">
					<div style={{
						height: '300px',
						overflow: 'scroll'
					}}>
						<Table stickyHeader style={{overflow: 'scroll'}} data={{
							head: ['Country', 'Visits', 'Uniq Visits', 'Installs'],
							body: promise.data?.items.map(data => [
								<Flex align={'center'}>
									<img style={{marginRight: '6px'}} width={'25px'} src={data.countryFlag}/> {renderCountryName(data)}
								</Flex>,
								data.visits,
								data.uniqVisits,
								data.installs
							])
						}}/>
					</div>

				</Tabs.Panel>
			</Tabs>
		</Box>
	);
}
