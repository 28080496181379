import {ILocaleItem} from "../interfaces/iLocaleItem";

export const allLanguageList: ILocaleItem[] = [
	{"code": "AA","name_en": "Afar","name_ru": "Афар","name_ukr": "Афар","emoji": "🇦🇫"},
	{"code": "AB","name_en": "Abkhazian","name_ru": "Абхазский","name_ukr": "Абхазька","emoji": "🇦🇲"},
	{"code": "AF","name_en": "Afrikaans","name_ru": "Африкаанс","name_ukr": "Африкаанс","emoji": "🇿🇦"},
	{"code": "AK","name_en": "Akan","name_ru": "Акан","name_ukr": "Акан","emoji": "🇬🇭"},
	{"code": "AM","name_en": "Amharic","name_ru": "Амхарский","name_ukr": "Амхарська","emoji": "🇪🇹"},
	{"code": "AN","name_en": "Aragonese","name_ru": "Арагонский","name_ukr": "Арагонська","emoji": "🇪🇸"},
	{"code": "AR","name_en": "Arabic","name_ru": "Арабский","name_ukr": "Арабська","emoji": "🇸🇦"},
	{"code": "AS","name_en": "Assamese","name_ru": "Ассамский","name_ukr": "Ассамська","emoji": "🇮🇳"},
	//{"code": "AV","name_en": "Avar","name_ru": "Аварский","name_ukr": "Аварська","emoji": "🇷🇺"},
	{"code": "AY","name_en": "Aymara","name_ru": "Аймара","name_ukr": "Аймара","emoji": "🇪🇪"},
	{"code": "AZ","name_en": "Azerbaijani","name_ru": "Азербайджанский","name_ukr": "Азербайджанська","emoji": "🇦🇿"},
	//{"code": "BA","name_en": "Bashkir","name_ru": "Башкирский","name_ukr": "Башкирська","emoji": "🇷🇺"},
	{"code": "BE","name_en": "Belarusian","name_ru": "Белорусский","name_ukr": "Білоруська","emoji": "🇧🇾"},
	{"code": "BG","name_en": "Bulgarian","name_ru": "Болгарский","name_ukr": "Болгарська","emoji": "🇧🇬"},
	{"code": "BH","name_en": "Bihari","name_ru": "Бихари","name_ukr": "Біхарська","emoji": "🇮🇳"},
	{"code": "BI","name_en": "Bislama","name_ru": "Бислама","name_ukr": "Біслама","emoji": "🇻🇺"},
	{"code": "BM","name_en": "Bambara","name_ru": "Бамбара","name_ukr": "Бамбара","emoji": "🇲🇱"},
	{"code": "BN","name_en": "Bengali","name_ru": "Бенгальский","name_ukr": "Бенгальська","emoji": "🇧🇩"},
	{"code": "BO","name_en": "Tibetan","name_ru": "Тибетский","name_ukr": "Тибетська","emoji": "🇲🇳"},
	{"code": "BR","name_en": "Breton","name_ru": "Бретонский","name_ukr": "Бретонська","emoji": "🇫🇷"},
	{"code": "BS","name_en": "Bosnian","name_ru": "Боснийский","name_ukr": "Боснійська","emoji": "🇧🇦"},
	{"code": "CA","name_en": "Catalan","name_ru": "Каталанский","name_ukr": "Каталонська","emoji": "🇪🇸"},
	//{"code": "CE","name_en": "Chechen","name_ru": "Чеченский","name_ukr": "Чеченська","emoji": "🇷🇺"},
	{"code": "CH","name_en": "Chamorro","name_ru": "Чаморро","name_ukr": "Чаморро","emoji": "🇲🇵"},
	{"code": "CO","name_en": "Corsican","name_ru": "Корсиканский","name_ukr": "Корсиканська","emoji": "🇫🇷"},
	{"code": "CR","name_en": "Cree","name_ru": "Кри","name_ukr": "Крі","emoji": "🇨🇦"},
	{"code": "CS","name_en": "Czech","name_ru": "Чешский","name_ukr": "Чеська","emoji": "🇨🇿"},
	{"code": "CU","name_en": "Old Church Slavonic / Old Bulgarian","name_ru": "Церковнославянский / Староболгарский","name_ukr": "Церковнослов'янська / Староболгарська","emoji": "🇧🇬"},
	//{"code": "CV","name_en": "Chuvash","name_ru": "Чувашский","name_ukr": "Чувашська","emoji": "🇷🇺"},
	{"code": "CY","name_en": "Welsh","name_ru": "Уэльский","name_ukr": "Уельська","emoji": "🇬🇧"},
	{"code": "DA","name_en": "Danish","name_ru": "Датский","name_ukr": "Данська","emoji": "🇩🇰"},
	{"code": "DE","name_en": "German","name_ru": "Немецкий","name_ukr": "Німецька","emoji": "🇩🇪"},
	{"code": "DV","name_en": "Divehi","name_ru": "Дивехи","name_ukr": "Дивехі","emoji": "🇲🇻"},
	{"code": "DZ","name_en": "Dzongkha","name_ru": "Дзонгхка","name_ukr": "Дзонгкха","emoji": "🇧🇹"},
	{"code": "EE","name_en": "Ewe","name_ru": "Эве","name_ukr": "Еве","emoji": "🇬🇭"},
	{"code": "EL","name_en": "Greek","name_ru": "Греческий","name_ukr": "Грецька","emoji": "🇬🇷"},
	{"code": "EN","name_en": "English","name_ru": "Английский","name_ukr": "Англійська","emoji": "🇬🇧"},
	{"code": "EO","name_en": "Esperanto","name_ru": "Эсперанто","name_ukr": "Есперанто","emoji": "🇲🇰"},
	{"code": "ES","name_en": "Spanish","name_ru": "Испанский","name_ukr": "Іспанська","emoji": "🇪🇸"},
	{"code": "ET","name_en": "Estonian","name_ru": "Эстонский","name_ukr": "Естонська","emoji": "🇪🇪"},
	{"code": "EU","name_en": "Basque","name_ru": "Баскский","name_ukr": "Баскська","emoji": "🇪🇸"},
	{"code": "FA","name_en": "Persian","name_ru": "Персидский","name_ukr": "Перська","emoji": "🇮🇷"},
	{"code": "FF","name_en": "Peul","name_ru": "Пул","name_ukr": "Пул","emoji": "🇲🇱"},
	{"code": "FI","name_en": "Finnish","name_ru": "Финский","name_ukr": "Фінська","emoji": "🇫🇮"},
	{"code": "FJ","name_en": "Fijian","name_ru": "Фиджийский","name_ukr": "Фіджійська","emoji": "🇫🇯"},
	{"code": "FO","name_en": "Faroese","name_ru": "Фарерский","name_ukr": "Фарерська","emoji": "🇫🇴"},
	{"code": "FR","name_en": "French","name_ru": "Французский","name_ukr": "Французька","emoji": "🇫🇷"},
	{"code": "FY","name_en": "West Frisian","name_ru": "Западнофризский","name_ukr": "Західнофризька","emoji": "🇳🇱"},
	{"code": "GA","name_en": "Irish","name_ru": "Ирландский","name_ukr": "Ірландська","emoji": "🇮🇪"},
	{"code": "GD","name_en": "Scottish Gaelic","name_ru": "Шотландский гэльский","name_ukr": "Шотландська гельська","emoji": "🏴"},
	{"code": "GL","name_en": "Galician","name_ru": "Галисийский","name_ukr": "Галисійська","emoji": "🇪🇸"},
	{"code": "GN","name_en": "Guarani","name_ru": "Гуарани","name_ukr": "Гуарані","emoji": "🇵🇾"},
	{"code": "GU","name_en": "Gujarati","name_ru": "Гуджарати","name_ukr": "Гуджараті","emoji": "🇮🇳"},
	{"code": "GV","name_en": "Manx","name_ru": "Манкс","name_ukr": "Манкс","emoji": "🇲🇪"},
	{"code": "HA","name_en": "Hausa","name_ru": "Хауса","name_ukr": "Хауса","emoji": "🇳🇬"},
	{"code": "HE","name_en": "Hebrew","name_ru": "Иврит","name_ukr": "Іврит","emoji": "🇮🇱"},
	{"code": "HI","name_en": "Hindi","name_ru": "Хинди","name_ukr": "Хінді","emoji": "🇮🇳"},
	{"code": "HO","name_en": "Hiri Motu","name_ru": "Хири Моту","name_ukr": "Хіри Моту","emoji": "🇵🇬"},
	{"code": "HR","name_en": "Croatian","name_ru": "Хорватский","name_ukr": "Хорватська","emoji": "🇭🇷"},
	{"code": "HT","name_en": "Haitian","name_ru": "Гаитянский","name_ukr": "Гаїтянська","emoji": "🇭🇹"},
	{"code": "HU","name_en": "Hungarian","name_ru": "Венгерский","name_ukr": "Угорська","emoji": "🇭🇺"},
	{"code": "HY","name_en": "Armenian","name_ru": "Армянский","name_ukr": "Вірменська","emoji": "🇦🇲"},
	{"code": "HZ","name_en": "Herero","name_ru": "Хереро","name_ukr": "Хереро","emoji": "🇳🇦"},
	{"code": "IA","name_en": "Interlingua","name_ru": "Интерлингва","name_ukr": "Інтерлінгва","emoji": "🌍"},
	{"code": "ID","name_en": "Indonesian","name_ru": "Индонезийский","name_ukr": "Індонезійська","emoji": "🇮🇩"},
	{"code": "IE","name_en": "Interlingue","name_ru": "Интерлингве","name_ukr": "Інтерлінгве","emoji": "🌍"},
	{"code": "IG","name_en": "Igbo","name_ru": "Игбо","name_ukr": "Ігбо","emoji": "🇳🇬"},
	{"code": "II","name_en": "Sichuan Yi","name_ru": "Сычуаньский и","name_ukr": "Сичуаньське І","emoji": "🇨🇳"},
	{"code": "IK","name_en": "Inupiak","name_ru": "Инуипиак","name_ukr": "Інуїпіак","emoji": "🇺🇸"},
	{"code": "IO","name_en": "Ido","name_ru": "Идо","name_ukr": "Ідо","emoji": "🌍"},
	{"code": "IS","name_en": "Icelandic","name_ru": "Исландский","name_ukr": "Ісландська","emoji": "🇮🇸"},
	{"code": "IT","name_en": "Italian","name_ru": "Итальянский","name_ukr": "Італійська","emoji": "🇮🇹"},
	{"code": "IU","name_en": "Inuktitut","name_ru": "Инуктитут","name_ukr": "Інукстітут","emoji": "🇨🇦"},
	{"code": "JA","name_en": "Japanese","name_ru": "Японский","name_ukr": "Японська","emoji": "🇯🇵"},
	{"code": "JV","name_en": "Javanese","name_ru": "Яванский","name_ukr": "Яванська","emoji": "🇮🇩"},
	{"code": "KA","name_en": "Georgian","name_ru": "Грузинский","name_ukr": "Грузинська","emoji": "🇬🇪"},
	{"code": "KG","name_en": "Kongo","name_ru": "Конго","name_ukr": "Конго","emoji": "🇨🇩"},
	{"code": "KI","name_en": "Kikuyu","name_ru": "Кикуйю","name_ukr": "Кікуйю","emoji": "🇰🇪"},
	{"code": "KJ","name_en": "Kuanyama","name_ru": "Куанияма","name_ukr": "Куаньяма","emoji": "🇲🇸"},
	{"code": "KK","name_en": "Kazakh","name_ru": "Казахский","name_ukr": "Казахська","emoji": "🇰🇿"},
	{"code": "KL","name_en": "Greenlandic","name_ru": "Гренландский","name_ukr": "Гренландська","emoji": "🇬🇱"},
	{"code": "KM","name_en": "Cambodian","name_ru": "Кхмерский","name_ukr": "Хмерська","emoji": "🇰🇭"},
	{"code": "KN","name_en": "Kannada","name_ru": "Каннада","name_ukr": "Каннада","emoji": "🇮🇳"},
	{"code": "KO","name_en": "Korean","name_ru": "Корейский","name_ukr": "Корейська","emoji": "🇰🇷"},
	{"code": "KR","name_en": "Kanuri","name_ru": "Канури","name_ukr": "Канури","emoji": "🇳🇬"},
	{"code": "KS","name_en": "Kashmiri","name_ru": "Кашмири","name_ukr": "Кашмірська","emoji": "🇮🇳"},
	{"code": "KU","name_en": "Kurdish","name_ru": "Курдский","name_ukr": "Курдська","emoji": "🇹🇷"},
	//{"code": "KV","name_en": "Komi","name_ru": "Коми","name_ukr": "Комі","emoji": "🇷🇺"},
	{"code": "KW","name_en": "Cornish","name_ru": "Корнский","name_ukr": "Корнський","emoji": "🇬🇧"},
	{"code": "KY","name_en": "Kirghiz","name_ru": "Киргизский","name_ukr": "Киргизька","emoji": "🇰🇲"},
	{"code": "LA","name_en": "Latin","name_ru": "Латинский","name_ukr": "Латинська","emoji": "🇲🇹"},
	{"code": "LB","name_en": "Luxembourgish","name_ru": "Люксембургский","name_ukr": "Люксембурзька","emoji": "🇱🇺"},
	{"code": "LG","name_en": "Ganda","name_ru": "Ганда","name_ukr": "Ганда","emoji": "🇺🇬"},
	{"code": "LI","name_en": "Limburgian","name_ru": "Лимбургский","name_ukr": "Лімбурзька","emoji": "🇳🇱"},
	{"code": "LN","name_en": "Lingala","name_ru": "Лингала","name_ukr": "Лінгала","emoji": "🇨🇩"},
	{"code": "LO","name_en": "Laotian","name_ru": "Лао","name_ukr": "Лаоська","emoji": "🇱🇦"},
	{"code": "LT","name_en": "Lithuanian","name_ru": "Литовский","name_ukr": "Литовська","emoji": "🇱🇹"},
	{"code": "LU","name_en": "Luba-Katanga","name_ru": "Луба-Катанга","name_ukr": "Луба-Катанга","emoji": "🇨🇩"},
	{"code": "LV","name_en": "Latvian","name_ru": "Латышский","name_ukr": "Латвійська","emoji": "🇱🇻"},
	{"code": "MG","name_en": "Malagasy","name_ru": "Малагасийский","name_ukr": "Малагасійська","emoji": "🇲🇬"},
	{"code": "MH","name_en": "Marshallese","name_ru": "Маршальский","name_ukr": "Маршальська","emoji": "🇲🇭"},
	{"code": "MI","name_en": "Maori","name_ru": "Маори","name_ukr": "Маорі","emoji": "🇳🇿"},
	{"code": "MK","name_en": "Macedonian","name_ru": "Македонский","name_ukr": "Македонська","emoji": "🇲🇰"},
	{"code": "ML","name_en": "Malayalam","name_ru": "Малаялам","name_ukr": "Малаялам","emoji": "🇮🇳"},
	{"code": "MN","name_en": "Mongolian","name_ru": "Монгол","name_ukr": "Монгол","emoji": "🇲🇳"},
	{"code": "MO","name_en": "Moldovan","name_ru": "Молдавский","name_ukr": "Молдовська","emoji": "🇲🇩"},
	{"code": "MR","name_en": "Marathi","name_ru": "Маратхи","name_ukr": "Маратхі","emoji": "🇮🇳"},
	{"code": "MS","name_en": "Malay","name_ru": "Малайский","name_ukr": "Малайська","emoji": "🇲🇾"},
	{"code": "MT","name_en": "Maltese","name_ru": "Мальтийский","name_ukr": "Мальтійська","emoji": "🇲🇹"},
	{"code": "MY","name_en": "Burmese","name_ru": "Бирманский","name_ukr": "Бирманська","emoji": "🇲🇲"},
	{"code": "NA","name_en": "Nauruan","name_ru": "Науруанский","name_ukr": "Науруанська","emoji": "🇳🇺"},
	{"code": "NB","name_en": "Norwegian Bokmål","name_ru": "Норвежский бокмаль","name_ukr": "Норвезька (бокмаль)","emoji": "🇳🇴"},
	{"code": "ND","name_en": "North Ndebele","name_ru": "Северный Ндебеле","name_ukr": "Північний Ндебеле","emoji": "🇿🇦"},
	{"code": "NE","name_en": "Nepali","name_ru": "Непальский","name_ukr": "Непальська","emoji": "🇳🇵"},
	{"code": "NG","name_en": "Ndonga","name_ru": "Ндонга","name_ukr": "Ндонга","emoji": "🇦🇴"},
	{"code": "NL","name_en": "Dutch","name_ru": "Голландский","name_ukr": "Голландська","emoji": "🇳🇱"},
	{"code": "NN","name_en": "Norwegian Nynorsk","name_ru": "Норвежский нинорск","name_ukr": "Норвезька (нинорск)","emoji": "🇳🇴"},
	{"code": "NO","name_en": "Norwegian","name_ru": "Норвежский","name_ukr": "Норвезька","emoji": "🇳🇴"},
	{"code": "NR","name_en": "South Ndebele","name_ru": "Южный Ндебеле","name_ukr": "Південний Ндебеле","emoji": "🇿🇦"},
	{"code": "NV","name_en": "Navajo","name_ru": "Навохо","name_ukr": "Навахо","emoji": "🇺🇸"},
	{"code": "NY","name_en": "Chichewa","name_ru": "Чичева","name_ukr": "Чичева","emoji": "🇲🇼"},
	{"code": "OC","name_en": "Occitan","name_ru": "Окситанский","name_ukr": "Окситанська","emoji": "🇫🇷"},
	{"code": "OJ","name_en": "Ojibwa","name_ru": "Оджибва","name_ukr": "Оджибва","emoji": "🇺🇸"},
	{"code": "OM","name_en": "Oromo","name_ru": "Оромо","name_ukr": "Оромо","emoji": "🇪🇹"},
	{"code": "OR","name_en": "Oriya","name_ru": "Одиа","name_ukr": "Одія","emoji": "🇮🇳"},
	//{"code": "OS","name_en": "Ossetian / Ossetic","name_ru": "Осетинский","name_ukr": "Осетинська","emoji": "🇷🇺"},
	{"code": "PA","name_en": "Panjabi / Punjabi","name_ru": "Панджаби / Пенджаби","name_ukr": "Панджабі / Пенджабі","emoji": "🇮🇳"},
	{"code": "PI","name_en": "Pali","name_ru": "Пали","name_ukr": "Палі","emoji": "🇮🇳"},
	{"code": "PL","name_en": "Polish","name_ru": "Польский","name_ukr": "Польська","emoji": "🇵🇱"},
	{"code": "PS","name_en": "Pashto","name_ru": "Пушту","name_ukr": "Пушту","emoji": "🇦🇫"},
	{"code": "PT","name_en": "Portuguese","name_ru": "Португальский","name_ukr": "Португальська","emoji": "🇵🇹"},
	{"code": "QU","name_en": "Quechua","name_ru": "Кечуа","name_ukr": "Кечуа","emoji": "🇵🇪"},
	{"code": "RM","name_en": "Raeto Romance","name_ru": "Ретороманский","name_ukr": "Ретороманська","emoji": "🇨🇭"},
	{"code": "RN","name_en": "Kirundi","name_ru": "Кирунди","name_ukr": "Кирунді","emoji": "🇧🇮"},
	{"code": "RO","name_en": "Romanian","name_ru": "Румынский","name_ukr": "Румунська","emoji": "🇷🇴"},
	{"code":"RU","name_en":"Russian","name_ru":"Русский","name_ukr":"Російська","emoji":"🔤"},
	{"code": "RW","name_en": "Rwandi","name_ru": "Рвандийский","name_ukr": "Руандійська","emoji": "🇷🇼"},
	{"code": "SA","name_en": "Sanskrit","name_ru": "Санскрит","name_ukr": "Санскрит","emoji": "🇮🇳"},
	{"code": "SC","name_en": "Sardinian","name_ru": "Сардинский","name_ukr": "Сардинська","emoji": "🇮🇹"},
	{"code": "SD","name_en": "Sindhi","name_ru": "Синди","name_ukr": "Сіндхі","emoji": "🇵🇰"},
	{"code": "SE","name_en": "Northern Sami","name_ru": "Северный Саами","name_ukr": "Північний Саамі","emoji": "🇫🇮"},
	{"code": "SG","name_en": "Sango","name_ru": "Санго","name_ukr": "Санго","emoji": "🇨🇫"},
	{"code": "SH","name_en": "Serbo-Croatian","name_ru": "Сербо-хорватский","name_ukr": "Сербохорватська","emoji": "🇷🇸"},
	{"code": "SI","name_en": "Sinhalese","name_ru": "Сингальский","name_ukr": "Сингальська","emoji": "🇱🇰"},
	{"code": "SK","name_en": "Slovak","name_ru": "Словацкий","name_ukr": "Словацька","emoji": "🇸🇰"},
	{"code": "SL","name_en": "Slovenian","name_ru": "Словенский","name_ukr": "Словенська","emoji": "🇸🇮"},
	{"code": "SM","name_en": "Samoan","name_ru": "Самоанский","name_ukr": "Самоанська","emoji": "🇦🇸"},
	{"code": "SN","name_en": "Shona","name_ru": "Шона","name_ukr": "Шона","emoji": "🇿🇼"},
	{"code": "SO","name_en": "Somalia","name_ru": "Сомали","name_ukr": "Сомалі","emoji": "🇸🇴"},
	{"code": "SQ","name_en": "Albanian","name_ru": "Албанский","name_ukr": "Албанська","emoji": "🇦🇱"},
	{"code": "SR","name_en": "Serbian","name_ru": "Сербский","name_ukr": "Сербська","emoji": "🇷🇸"},
	{"code": "SS","name_en": "Swati","name_ru": "Свати","name_ukr": "Свати","emoji": "🇿🇦"},
	{"code": "ST","name_en": "Southern Sotho","name_ru": "Южный Сото","name_ukr": "Південний Сото","emoji": "🇱🇸"},
	{"code": "SU","name_en": "Sundanese","name_ru": "Сунданский","name_ukr": "Сунданська","emoji": "🇮🇩"},
	{"code": "SV","name_en": "Swedish","name_ru": "Шведский","name_ukr": "Шведська","emoji": "🇸🇪"},
	{"code": "SW","name_en": "Swahili","name_ru": "Суахили","name_ukr": "Суахілі","emoji": "🇰🇪"},
	{"code": "TA","name_en": "Tamil","name_ru": "Тамильский","name_ukr": "Тамільська","emoji": "🇮🇳"},
	{"code": "TE","name_en": "Telugu","name_ru": "Телугу","name_ukr": "Телугу","emoji": "🇮🇳"},
	{"code": "TG","name_en": "Tajik","name_ru": "Таджикский","name_ukr": "Таджицька","emoji": "🇹🇯"},
	{"code": "TH","name_en": "Thai","name_ru": "Тайский","name_ukr": "Тайська","emoji": "🇹🇭"},
	{"code": "TI","name_en": "Tigrinya","name_ru": "Тигринья","name_ukr": "Тигринья","emoji": "🇪🇹"},
	{"code": "TK","name_en": "Turkmen","name_ru": "Туркменский","name_ukr": "Туркменська","emoji": "🇹🇲"},
	{"code": "TL","name_en": "Tagalog / Filipino","name_ru": "Тагалог / Филиппинский","name_ukr": "Тагалог / Філіппінська","emoji": "🇵🇭"},
	{"code": "TN","name_en": "Tswana","name_ru": "Цвана","name_ukr": "Цвана","emoji": "🇧🇼"},
	{"code": "TO","name_en": "Tonga","name_ru": "Тонга","name_ukr": "Тонга","emoji": "🇹🇴"},
	{"code": "TR","name_en": "Turkish","name_ru": "Турецкий","name_ukr": "Турецька","emoji": "🇹🇷"},
	{"code": "TS","name_en": "Tsonga","name_ru": "Цонга","name_ukr": "Цонга","emoji": "🇿🇦"},
	//{"code": "TT","name_en": "Tatar","name_ru": "Татарский","name_ukr": "Татарська","emoji": "🇷🇺"},
	{"code": "TW","name_en": "Twi","name_ru": "Тви","name_ukr": "Тві","emoji": "🇬🇭"},
	{"code": "TY","name_en": "Tahitian","name_ru": "Таитянский","name_ukr": "Таїтянська","emoji": "🇵🇫"},
	{"code": "UG","name_en": "Uyghur","name_ru": "Уйгурский","name_ukr": "Уйгурська","emoji": "🇨🇳"},
	{"code": "UK","name_en": "Ukrainian","name_ru": "Украинский","name_ukr": "Українська","emoji": "🇺🇦"},
	{"code": "UR","name_en": "Urdu","name_ru": "Урду","name_ukr": "Урду","emoji": "🇵🇰"},
	{"code": "UZ","name_en": "Uzbek","name_ru": "Узбекский","name_ukr": "Узбекська","emoji": "🇺🇿"},
	{"code": "VE","name_en": "Venda","name_ru": "Венда","name_ukr": "Венда","emoji": "🇿🇦"},
	{"code": "VI","name_en": "Vietnamese","name_ru": "Вьетнамский","name_ukr": "В'єтнамська","emoji": "🇻🇳"},
	{"code": "VO","name_en": "Volapük","name_ru": "Волапюк","name_ukr": "Волапюк","emoji": "🌍"},
	{"code": "WA","name_en": "Walloon","name_ru": "Валлонский","name_ukr": "Валлонська","emoji": "🇧🇪"},
	{"code": "WO","name_en": "Wolof","name_ru": "Волоф","name_ukr": "Волоф","emoji": "🇸🇳"},
	{"code": "XH","name_en": "Xhosa","name_ru": "Ксоса","name_ukr": "Ксоса","emoji": "🇿🇦"},
	{"code": "YI","name_en": "Yiddish","name_ru": "Идиш","name_ukr": "Їдиш","emoji": "🇮🇱"},
	{"code": "YO","name_en": "Yoruba","name_ru": "Йоруба","name_ukr": "Йоруба","emoji": "🇳🇬"},
	{"code": "ZA","name_en": "Zhuang","name_ru": "Чжуан","name_ukr": "Чжуан","emoji": "🇨🇳"},
	{"code": "ZH","name_en": "Chinese","name_ru": "Китайский","name_ukr": "Китайська","emoji": "🇨🇳"},
	{"code": "ZU","name_en": "Zulu","name_ru": "Зулу","name_ukr": "Зулу","emoji": "🇿🇦"}
]
