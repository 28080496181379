import {Anchor, Badge, Button, Modal} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {DomainsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {useLocale} from "../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../utils/viewOwnerColl.tsx";
import SuggestDomains, {DomainSuggestMetadata} from "./suggestDomains.tsx";
import {notifications} from "@mantine/notifications";
import {parseApiError} from "../../utils/parseApiError.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {LinkDomanWizard} from "../WebApps/view/domainsTab/linkDomain.tsx";

const api = new DomainsApiClient()

interface IItem {
	latestUptimeCheck?: any;
	_id: string;
	comment: string;
}

export function ListDomains() {
	const {user} = useUser();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();
	const t = useLocale();
	const [showBuyModal, setShowBuyModal] = useState(false);
	const [showLinkModal, setShowLinkModal] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [selectedDomain, setSelectedDomain] = useState<DomainSuggestMetadata | undefined>();

	async function tryBuyDomain() {
		if(!selectedDomain) return;
		setDisabled(true);
		try {
			const result = await api.buyDomain({ ...selectedDomain });
			navigate(`~/domains/v/${result.item._id}`);
		} catch (err) {
			notifications.show({color: 'red', message: parseApiError(err)});
		} finally {
			setDisabled(false);
		}
	}

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.Domains.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		const cols =  [{
			accessorKey: 'domain',
			header: t('domainsPage.list.table.domainName'),
			Cell: (props) => (
				<Anchor
					target="_blank"
					href={`https://${props.cell.getValue<string>()}`}
				>
					{`${props.cell.getValue<string>()} ${props.row.original.comment ? ` (${props.row.original.comment})` : ''}`}
				</Anchor>
			)
		}, {
			accessorKey: 'registrar',
			header: t('domainsPage.list.table.registrar'),
		}, {
			accessorKey: 'buyJob',
			header: t('domainsPage.list.table.state'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => {
				let uptimeChecker: any = null;
				const latestUptimeCheck = props.row.original.latestUptimeCheck;
				if (latestUptimeCheck) {
					if(latestUptimeCheck.status) {
						if(latestUptimeCheck.code === 'OK') {
							uptimeChecker = <Badge color={'green'}>{t('domainsPage.list.table.uptime.ok')}</Badge>;
						} else {
							uptimeChecker = <Badge color={'yellow'}>{t('domainsPage.list.table.uptime.warning')} ({latestUptimeCheck.code})</Badge>;
						}
					} else {
						uptimeChecker = <Badge color={'red'}>{t('domainsPage.list.table.uptime.error')} ({latestUptimeCheck.code})</Badge>;
					}
				}


				if (!props.cell.getValue()) return (
					<>
						<Badge color={'green'}>{t('domainsPage.list.table.state.ADDED')}</Badge>
						{uptimeChecker}
					</>
				);
				const {state} = props.cell.getValue<{ state: string }>();
				if (state === 'WAIT') return <Badge color={'red'}>{t('domainsPage.list.table.state.ERROR')}</Badge>;
				if (state === 'IN_PROGRESS') return <Badge
					color={'blue'}>{t('domainsPage.list.table.state.IN_PROGRESS')}</Badge>;
				if (state === 'DONE') return <><Badge
					color={'green'}>{t('domainsPage.list.table.state.DONE')}</Badge>{uptimeChecker}</>;
				if (state === 'ERROR') return <Badge color={'red'}>{t('domainsPage.list.table.state.ERROR')}</Badge>;
				return <Badge color={'red'}>{t('domainsPage.list.table.state.UNKNOWN')}</Badge>;
			}
		}, {
			accessorKey: 'actions',
			header: t('domainsPage.list.table.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<>
					<Button component={Link} href={`/v/${props.row.original._id}`} variant="primary" size={'compact-sm'}>{t('domainsPage.list.table.actions.edit')}</Button>
				</>
			)
		}] as MRT_ColumnDef<IItem>[];
		if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);
		return cols;
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	return <>
		<Header
			title={t('domainsPage.list.title')}
			items={(
				<>
					<Button onClick={() => setShowBuyModal(true)}>
						{t('domainsPage.list.buy.label')}
					</Button>
					<Button onClick={() => setShowLinkModal(true)}>
						{t('domainsPage.list.link.label')}
					</Button>

				</>
			)}
		/>
		<MantineReactTable
			table={table}
		/>
		<Modal opened={showBuyModal} onClose={() => !disabled && setShowBuyModal(false)} title={t('domainsPage.list.buy.modal.title')}>
			<SuggestDomains value={selectedDomain} onChange={setSelectedDomain} disabled={disabled}/>
			{selectedDomain && (
				<>
					<hr/>
					<Button fullWidth disabled={disabled} onClick={tryBuyDomain}>
						{t('domainsPage.list.buy.modal.buy')}
					</Button>
				</>
			)}
		</Modal>
		<Modal opened={showLinkModal} onClose={() => !disabled && setShowLinkModal(false)} title={t('domainsPage.list.link.modal.title')} size={'lg'}>
			<LinkDomanWizard onDone={(domain) => navigate(`~/domains/v/${domain._id}`)}/>
		</Modal>
	</>;
}
