import {Button, Group} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {FbPixelsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {IconCubeSend, IconEdit, IconPaperBag} from "@tabler/icons-react";
import {Translation, useLocale} from "../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../utils/viewOwnerColl.tsx";
import useTeams from "../Teams/Context/useTeams.ts";
import {FbPixelsSelect} from "./FBPixelsSelect.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {CountryMultiSelect} from "@components/Utils/Country/CountrySelect.tsx";

const api = new FbPixelsApiClient()

interface IItem {
	_id: string;
}

export function ListFbPixels() {
	const t = useLocale();
	const {user} = useUser();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();
	const { activeTeam } = useTeams();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.FbPixels.columnVisibility',
		defaults
	});

	async function tryTransferEvents (from: string) {
		const data = await yoFormModal<any>({
			title: "Transfer events",
			required: ['from', 'to'],
			defaultValue: {
				from
			},
			size: 'lg',
			body: (
				<>
					<RenderFuncField<string[]> field="countries">
						{ctx => (
							<CountryMultiSelect
								label={'Select countries (optional)'}
								value={ctx.value}
								onChange={ctx.onChange}
								searchable
								cca2
							/>
						)}
					</RenderFuncField>
					<RenderFuncField<string> field="from">
						{(ctx) => {
							return (
								<FbPixelsSelect onChange={ctx.onChange} value={ctx.value} label="From" />
							)
						}}
					</RenderFuncField>
					<RenderFuncField<string> field="to">
						{(ctx) => {
							return (
								<FbPixelsSelect onChange={ctx.onChange} value={ctx.value} label="To" />
							)
						}}
					</RenderFuncField>
				</>
			)
		})

		const availabilityCheck = await api.checkIfTransferAvailable(data.from, data.to);
		if (availabilityCheck.result.available) {
			return api.transferEvents(data).then(res => {
				if (res.status) {
					navigate(`/transferJobs/${res.jobId}`)
				} else {
					displayErrorNotification(res.message);
				}
			})
		} else {
			void displayErrorNotification('Cant start new transfer job while it already has jobs in progress.');
		}
	}

	const columns = useMemo(() => {
		const cols =  [{
			accessorKey: 'name',
			header: t('fbPixels.list.tableHeader.name'),
		}, {
			accessorKey: 'pixel',
			header: t('fbPixels.list.tableHeader.pixel'),
		}, {
			accessorKey: 'actions',
			header: t('fbPixels.list.tableHeader.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<Group>
					<Button<any> title="Edit" component={Link} href={`/v/${props.row.original._id}`} variant="primary" size={'compact-sm'}>
						<IconEdit/>
					</Button>
					<Button<any> title="Logs" component={Link} href={`/logs/${props.row.original._id}`} size={'compact-sm'} variant="secondary">
						<IconPaperBag/>
					</Button>
					<Button title="Transfer events" size={'compact-sm'} radius="md" variant="secondary" onClick={() => tryTransferEvents(props.row.original._id)}>
						<IconCubeSend />
					</Button>
				</Group>
			)
		}] as MRT_ColumnDef<IItem>[];

		if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);

		return cols;
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const data = await yoFormModal<any>({
			title: t('fbPixels.modal.title'),
			required: ['pixel', 'token'],
			defaultValue: {},
			size: 'lg',
			body: (
				<>
					<InputField field={'name'} label={t('fbPixels.modal.label.name')} />
					<InputField field={'pixel'} label={t('fbPixels.modal.label.pixel')} />
					<InputField field={'token'} label={t('fbPixels.modal.label.token')} />
				</>
			)
		})
		return api.createItem({ ...data, team: activeTeam }).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={t('fbPixels.header.title')}
			items={(
				<>
					<Button variant="light" size={'compact-sm'} component={Link} to={'/fbPixelsImportEventsJob'} mr="xl">
						Import csv
					</Button>
					<Button variant="primary" size={'compact-sm'} onClick={tryCreate}>
						<Translation id={'fbPixels.header.button.newPixel'}/>
					</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}

