import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Alert, Button, Card, Grid, Timeline, Title} from "@mantine/core";

import {DomainsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {useLocale} from "../../locale";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";
import {notifications} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {modals} from "@mantine/modals";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";
import {RulesHeaderField} from "../WebApps/view/components/rules/RulesHeaderField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";

const api = new DomainsApiClient();

function BuyJobCard() {
	const t = useLocale();
	const ctx = useYoProvider('buyJob');
	if (!ctx.value) return null;
	const { state, subStatus, errReason } = ctx.value;
	if (state === 'WAIT') {
		return (
			<Alert color={'yellow'} title={t('domainsPage.view.status.title')}>
				{t('domainsPage.view.status.WAIT')}
				<br/>
				<Link href={`~/jobs/v/${ctx.value._id}`}>
					<Button>{t('domainsPage.view.status.link.label')}</Button>
				</Link>
			</Alert>
		);
	} else if (state === 'IN_PROGRESS') {
		return (
			<Alert color={'blue'}  title={t('domainsPage.view.status.title')}>
				{t('domainsPage.view.status.IN_PROGRESS')}{subStatus}
				<br/>
				<Link href={`~/jobs/v/${ctx.value._id}`}>
					<Button>{t('domainsPage.view.status.link.label')}</Button>
				</Link>
			</Alert>
		);
	} else if (state === 'DONE') {
		return (
			<Alert color={'green'} title={t('domainsPage.view.status.title')}>
				{t('domainsPage.view.status.DONE')}
				<br/>
				<Link href={`~/jobs/v/${ctx.value._id}`}>
					<Button>{t('domainsPage.view.status.link.label')}</Button>
				</Link>
			</Alert>
		);
	} else if (state === 'ERROR') {
		return (
			<Alert color={'red'} title={t('domainsPage.view.status.title')}>
				{t('domainsPage.view.status.ERROR')}{errReason}
				<br/>
				<Link href={`~/jobs/v/${ctx.value._id}`}>
					<Button>{t('domainsPage.view.status.link.label')}</Button>
				</Link>
			</Alert>
		);
	}
	return null;
}

function UptimeCheckerCard() {
	const eventsCtx = useYoProvider('uptimeEvents', {defaultValue: []});
	const t = useLocale();
	const latestEventCtx = useYoProvider<any>('latestUptimeCheck');

	function showLatestEvents() {
		modals.open({
			title: 'Latest events',
			size: 'xl',
			children: (
				<>
					<Timeline bulletSize={24}>
						{eventsCtx.value.map((event: any) => (
							<Timeline.Item title={event.code}>
								<p>status code: <b>{event.code}</b></p>
								<p>worker type: <b>{event.workerType}</b></p>
								<p>last check: <b>{new Date(event.timestamp).toLocaleString()}</b></p>
							</Timeline.Item>
						))}

					</Timeline>
				</>
			)
		})
	}

	if (!latestEventCtx.value) return null;

	if (latestEventCtx.value.status) {
		if (latestEventCtx.value.code === 'OK') {
			return (
				<Alert color={'green'} title={t('domainsPage.view.uptime.title.ok')}>
					{t('domainsPage.view.uptime.desc.ok')}
					<p>worker type: <b>{latestEventCtx.value.workerType}</b></p>
					<p>last check: <b>{new Date(latestEventCtx.value.timestamp).toLocaleString()}</b></p>
					<Button size={'compact-sm'} fullWidth onClick={showLatestEvents}>
						Latest events
					</Button>
				</Alert>
			);
		} else {
			return (
				<Alert color={'yellow'} title={t('domainsPage.view.uptime.title.warning')}>
					{t('domainsPage.view.uptime.desc.warning')}
					<p>status code: <b>{latestEventCtx.value.code}</b></p>
					<p>worker type: <b>{latestEventCtx.value.workerType}</b></p>
					<p>last check: <b>{new Date(latestEventCtx.value.timestamp).toLocaleString()}</b></p>
					<Button size={'compact-sm'} fullWidth onClick={showLatestEvents}>
						Latest events
					</Button>
				</Alert>
			);
		}
	}
	return (
		<Alert color={'red'} title={t('domainsPage.view.uptime.title.error')}>
			{t('domainsPage.view.uptime.desc.error')}
			<p>status code: <b>{latestEventCtx.value.code}</b></p>
			<p>last check: <b>{new Date(latestEventCtx.value.timestamp).toLocaleString()}</b></p>
			<Button size={'compact-sm'} fullWidth onClick={showLatestEvents}>
				Latest events
			</Button>
		</Alert>
	);
}

export function ViewDomains() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	async function trySave () {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: 'Сохранение успешно'});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={t('domainsPage.view.title')}
				items={(
					<>
						<Button onClick={trySave} variant="primary" size={'compact-sm'}>
							Save
						</Button>
						<Button variant="outline" c="red" size={'compact-sm'} onClick={tryRemove}>{t('domainsPage.view.action.delete')}</Button>
					</>
				)}
				backButtonUrl=""
			/>
			<Grid>
				<Grid.Col span={6}>
					<Card variant="section">
						<Title order={4} mb="xs">{t('domainsPage.view.form.title')}</Title>
						<TeamSelectField />
						<InputField field={'domain'} label={t('domainsPage.view.form.domain')} readOnly/>
						<TextareaField field={'comment'} label={t('domainsPage.view.form.comment')} autosize/>
						<div>
							<br/>
							<CheckboxField field={'allowSplit'} label={t('domainsPage.view.form.allowSplit')}/>
						</div>
						<CollapseField field={'allowSplit'}>
							<br/>
							<SelectField field={'appsDeploymentTarget'} data={[
								{label: 'pwa cloud', value: 'pwaCloud'},
								{label: 'Global edge', value: 'globalEdge'},
							]} label={t('domainsPage.view.form.split.target')}/>
							<h4>
								{t('domainsPage.view.form.split.title')}
							</h4>
							<YoContextArrayMap field={'webApps'}>
								<Card mb="sm">
									<RulesHeaderField
										allowDrag={false}
										allowDelete={false}
										badgeColor={'teal'}
										getBadgeContent={ctx => (
											<Link href={`~/webApps/v/${ctx.value._id}`}>
												{ctx.value.name}
											</Link>
										)}
									/>
									<InputField field={'weight'} label={t('domainsPage.view.form.split.weight')}/>
								</Card>
							</YoContextArrayMap>
							<RenderFuncField field={'splitKey'}>
								{ctx => (
									<Button onClick={() => {
										ctx.onChange(Math.random().toString(36).slice(2));
										notifications.show({message: t('domainsPage.view.form.split.rerollSuccess')});
									}}>
										{t('domainsPage.view.form.split.reroll')}
									</Button>
								)}
							</RenderFuncField>
						</CollapseField>
					</Card>
				</Grid.Col>
				<Grid.Col span={6}>
					<Card variant="section" >
						<Title order={4} mb="xs">
							{t('domainsPage.view.status.title')}
						</Title>
						<BuyJobCard/>
						<UptimeCheckerCard/>
					</Card>
				</Grid.Col>
			</Grid>

		</YoContextEditor>
	);
}
