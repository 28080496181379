import {Anchor, Button, Container, Paper, PasswordInput, TextInput, Title} from "@mantine/core";
import classes from './style.module.css';
import {useEffect, useMemo, useState} from "react";
import ky from "ky";
import {notifications} from "@mantine/notifications";
import {useSetField} from "@hooks/useSetField.ts";
import {Translation, useLocale} from "../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {confirmModal} from "@modals/confirmModal.tsx";
import {Link} from "wouter";

export function AuthPage({onAuth}: { onAuth: any }) {
	const t = useLocale();
	const [form, setField] = useSetField<any>({});
	const [disabled, setDisabled] = useState(false);
	const isStage = useMemo(() => {
		return import.meta.env.DEV || location.host.includes('stage');
	}, []);

	useEffect(() => {
		if(isStage) {
			document.body.style.backgroundImage = "url('https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Lcars_wallpaper.svg/1280px-Lcars_wallpaper.svg.png')";
			document.body.style.backgroundSize = "cover"; // Заставляет картинку растянуться на весь экран
			document.body.style.backgroundRepeat = "no-repeat"; // Убирает повторение
			return () => {
				document.body.style.backgroundImage = "";
				document.body.style.backgroundSize = "";
				document.body.style.backgroundRepeat = "";
			}
		}
	}, []);

	async function tryLogin() {
		if(isStage && !localStorage.getItem('stage-consent')) {
			const result = await confirmModal({message: 'This is a test environment, are you sure you want to continue?', positive: true});
			if(!result) return;
			localStorage.setItem('stage-consent', 'true');
		}
		setDisabled(true);
		ky.post(`/api/v1/auth`, {json: form}).json<{ status: boolean, user?: any, settings?: any }>().then(res => {
			if (!res.status) {
				notifications.show({
					color: 'yellow',
					title: t('auth.authError.title'),
					message: t('auth.authError.message'),
				});
			} else {
				onAuth({auth: true, user: res.user, settings: res.settings})
			}
		}).catch(displayErrorNotification).finally(() => setDisabled(false))
	}

	return (
		<Container size={420} my={40} c={'yellow'}>
			<Title ta="center" className={classes.title}>
				{isStage ? `Abandon all hope, ye who enter the test environment.` : <Translation id={'auth.title'}/>}
			</Title>
			<Paper withBorder shadow="md" p={30} mt={30} radius="md">
				<TextInput
					disabled={disabled}
					value={form.username}
					onChange={e => setField('username', e.currentTarget.value)}
					label="Email"
					placeholder="you@7k.wtf"
					required
				/>
				<PasswordInput
					disabled={disabled}
					value={form.password}
					onChange={e => setField('password', e.currentTarget.value)} label="Password"
					placeholder="Your password" required mt="md"/>
				<Button fullWidth mt="xl" disabled={disabled} onClick={tryLogin}>
					<Translation id={'auth.login'}/>
				</Button>
				<br/>
				<Anchor component={Link} href={'~/register'} c="dimmed" size="xs">
					Don't have an account? Register
				</Anchor>
			</Paper>
		</Container>
	);
}
