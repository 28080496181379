import {JSX} from 'react';
import classes from './style.module.pcss';
import {ActionIcon, Group, Title} from "@mantine/core";
import {IconArrowLeft} from "@tabler/icons-react";
import {Link} from "wouter";

export function Header({
	title,
	items,
	backButtonUrl
}: {
	title: JSX.Element | string,
	items?: JSX.Element,
	backButtonUrl?: string
}) {
	return (
		<header className={classes.header}>
			{
				(backButtonUrl !== undefined) && (
					<ActionIcon
						variant="subtle"
						color="white"
						component={Link}
						to={backButtonUrl}
						size="lg"
					>
						<IconArrowLeft />
					</ActionIcon>
				)
			}
			<Group justify={'space-between'} align={'center'} flex={1}>
				<Title order={3}>{title}</Title>
				<div className={classes.innerItems}>
					{items}
				</div>
			</Group>
		</header>
	);
}
