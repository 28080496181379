import {useYoProvider} from "@components/YoContextEditor";
import {ReactNode} from "react";

export function RenderFuncField<T>({field, defaultValue, children, upProvider}: {
	field?: string,
	defaultValue?: T
	children: (ctx: ReturnType<typeof useYoProvider<T>>) => ReactNode
	upProvider?: number
}) {
	const ctx = useYoProvider<T>(field, {
		defaultValue,
		upProvider
	});
	return children(ctx);
}
