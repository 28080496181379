import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Alert, Box, Button, Flex, LoadingOverlay, Progress, Table} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {WhitePagesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {FileUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {Translation, useLocale} from "../../locale";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";
import {bytesToSize} from "./utils/bytesToSize.ts";
import {FileIcon} from "./utils/fileIcon.tsx";

const api = new WhitePagesApiClient();


function LandingInfo(props: { fileId: string, landingId: string }) {
	const t = useLocale();
	const promise = usePromise<any>(() => {
		if (!props.fileId) return Promise.resolve(undefined);
		return api.fetchLandingAnalysis(props.landingId, props.fileId);
	}, [props.fileId]);
	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			{!promise.isLoading && promise.data ? (
				<>
					{!promise.data.status ? (
						<Alert color={'red'}>{promise.data.message}</Alert>
					) : (
						<>
							<h4>
								<Translation id={'whitePages.view.landingInfo.title'}/>
							</h4>
							{!promise.data.foundIndex && (
								<Alert color={'red'}>
									<Translation id={'whitePages.view.landingInfo.notFoundIndex'}/>
								</Alert>
							)}
							{promise.data.assetsFolderWarning && (
								<Alert color={'yellow'}>
									<Translation id={'whitePages.view.landingInfo.assetsFolderWarning'}/>
								</Alert>
							)}
							{promise.data.swWarning && (
								<Alert color={'yellow'}>
									<Translation id={'whitePages.view.landingInfo.swWarning'}/>
								</Alert>
							)}
							{!!promise.data.stripLevel && (
								<Alert color={'yellow'}>
									<Translation id={'whitePages.view.landingInfo.stripLevel'}/>
								</Alert>
							)}
							<Table data={{
								head: [t('whitePages.view.landingInfo.type'), t('whitePages.view.landingInfo.path')],
								body: promise.data.structure.map((el: any) => {
									return [
										el.type,
										<Flex align={'center'}><FileIcon fileType={el.path}/><div style={{marginLeft: '5px'}}>{el.path}</div></Flex>,
										el.type === 'dir' ? '-' : bytesToSize(el.size)
									];
								}),
							}}/>
						</>
					)}
				</>
			) : undefined}
		</Box>
	);
}


function convertToProgress(value: number, maxValue: number): number {
	if (maxValue === 0) return 1;
	return (value / maxValue) * 100;
}

export function ViewWhitePages() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('whitePages.view.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification);
	}

	async function tryRemove() {
		if (!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	const enableLoader = !data.generated || data.generateStatus === 'DONE' || data.generateStatus === 'ERROR';

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={t('whitePages.view.title')}
				items={(
					<>
						<Button onClick={trySave} size={'compact-sm'}>
							<Translation id={'whitePages.view.saveButton'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'whitePages.view.removeButton'}/>
						</Button>
					</>
				)}
			/>
			{data?.generated && data.generateStatus === 'WAIT' ? (
				<Alert title={t('whitePages.view.generateStatus.wait.title')}>
					<Translation id={'whitePages.view.generateStatus.wait.message'}/>
				</Alert>
			): data.generateStatus === 'IN_PROGRESS' ? (
				<Alert title={t('whitePages.view.generateStatus.wait.title')}>
					{t('whitePages.view.generateStatus.inProgress.message')
						.replace('{{current}}', String(data.generateProgressCurrent))
						.replace('{{max}}', String(data.generateProgressMax))}
					<Progress value={convertToProgress(data.generateProgressCurrent, data.generateProgressMax)}/>
				</Alert>
			): data.generateStatus === 'ERROR' ? (
				<Alert title={t('whitePages.view.generateStatus.error.title')}>
					<Translation id={'whitePages.view.generateStatus.error.message'}/>
				</Alert>
			): data.generateStatus === 'DONE' ? (
				<Alert title={t('whitePages.view.generateStatus.success.title')}>
					<Translation id={'whitePages.view.generateStatus.success.message'}/>
				</Alert>
			): null}
			<InputField field={'name'} label={t('whitePages.view.label.name')}/>
			<TeamSelectField />
			{enableLoader ? (
				<>
					<h4>
						<Translation id={'whitePages.view.landingInfo.site'}/>
					</h4>
					<FileUploaderField field={'file'} accept={['application/zip']}/>
				</>
			) : null}
			<LandingInfo fileId={data.file} landingId={params.id}/>
		</YoContextEditor>
	);
}
