import {ReactNode} from "react";
import {useUser} from "@engine/UserContext.tsx";
import {Fieldset} from "@mantine/core";

export function VisibleOnlyAdmin(props: { children: ReactNode, debug?: boolean }) {
	const {user} = useUser();
	if (user?.isSuperAdmin) {
		if(props.debug) {
			return <Fieldset style={{ backgroundColor: "#12162D" }} legend={'[Только для админа]'} c={'yellow'}>{props.children}</Fieldset>
		}
		return props.children;
	}
	return null;
}
