import {usePromise} from "@hooks/usePromise.ts";
import {ActionIcon, HoverCard} from "@mantine/core";
import {IconCloudPause, IconCloudUp, IconExclamationCircle, IconLoader} from "@tabler/icons-react";
import {WebAppsApiClient} from "../api.ts";
import PQueue from "p-queue";
import {match} from "ts-pattern";
import {parseApiError} from "../../../utils/parseApiError.tsx";
import {confirmModal} from "@modals/confirmModal.tsx";
import {useWaitTask} from "../view/useWaitTask.tsx";
import {useState} from "react";
import {Link} from "wouter";
import {useScopedLocale} from "../../../locale";

const api = new WebAppsApiClient();
const queue = new PQueue({concurrency: 1});

export function DeploymentControl(props: { id: string, onStateUpdate: (s: string) => void }) {
	const tt = useScopedLocale('webApps.view.PublishTab.');
	const [op, setOp] = useState('DRAFT');
	const promise = usePromise(() => queue.add(() => api.getQuickPublishStat(props.id)), [props.id]);
	const [disabled, setDisabled] = useState(false);
	const waitTack = useWaitTask({
		onResolved: () => {
			promise.run();
			props.onStateUpdate(op);
		}
	});

	async function tryUnpublish() {
		if(!await confirmModal({message: tt('publish.quickUnPublishConfirm')})) return;
		try {
			setDisabled(true);
			const result = await api.hideApp(props.id);
			setOp('DRAFT');
			waitTack.setTack(result.taskId);
		} finally {
			setDisabled(false);
		}
	}

	async function tryPublish() {
		if(!await confirmModal({message: tt('publish.quickPublishConfirm'), positive: true})) return;
		try {
			setDisabled(true);
			const result = await api.publishApp(props.id);
			setOp('PUBLISHED');
			waitTack.setTack(result.taskId);
		} finally {
			setDisabled(false);
		}
	}

	return match({...promise, isLoading: promise.isLoading || disabled || waitTack.locked})
		.with({isLoading: true}, () => (
			<ActionIcon variant="subtle" c="white" disabled>
				<IconLoader/>
			</ActionIcon>
		)).with({isError: true}, () => (
			<ActionIcon variant="subtle" c="red" title={parseApiError(promise.errData)} onClick={promise.run}>
				<IconExclamationCircle/>
			</ActionIcon>
		)).with({
			isLoading: false,
			isError: false,
			data: {state: 'PUBLISHED'}
		}, () => (
			<ActionIcon variant="subtle" c="white" onClick={tryUnpublish}>
				<IconCloudPause/>
			</ActionIcon>
		)).with({
			isLoading: false,
			isError: false,
			data: {state: 'DRAFT', allowPublish: true}
		}, () => (
			<ActionIcon variant="subtle" c="white" onClick={tryPublish}>
				<IconCloudUp/>
			</ActionIcon>
		)).with({
			isLoading: false,
			isError: false,
			data: {state: 'DRAFT', allowPublish: false}
		}, () => (
			<HoverCard  position={'left'}>
				<HoverCard.Target>
					<ActionIcon component={Link} variant="subtle" c="red" href={`~/webApps/v/${props.id}?tab=publish`}>
						<IconCloudUp/>
					</ActionIcon>
				</HoverCard.Target>
				<HoverCard.Dropdown>
					{tt(`publish.details.${promise.data!.code}` as any)}
				</HoverCard.Dropdown>
			</HoverCard>

		)).otherwise(() => (
			<ActionIcon variant="subtle" c="red">
				<IconCloudUp/>
			</ActionIcon>
		));
}
