import {createContext, createElement, ReactNode, useContext} from "react";
import {usePromise} from "@hooks/usePromise.ts";
import ky from "ky";
import {match, P} from "ts-pattern";
import {Alert, LoadingOverlay} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {AuthPage} from "../Pages/AuthPage";
import {Route, Router, Switch} from "wouter";
import {RegisterPage} from "../Pages/AuthPage/RegisterPage.tsx";

interface PubUser {
	_id: string;
	username: string;
	email?: string;
	isSuperAdmin: boolean,
	avatar?: string;
	shouldCompleteOnboarding?: boolean
	tgId: string;
}

const Context = createContext<{
	user: PubUser, settings: any, logout: () => void, updateUser: (data: any) => void}>({} as any);

export function useUser() {
	return useContext(Context);
}


function userStore({data, setData, children}: {data: any, setData: any, children: any}) {
	function logout() {
		setData({status: true, auth: false})
		return ky.delete('/api/v1/auth').catch(() => {});
	}
	return (
		<Context.Provider
			value={{
				user: data.user,
				settings: data.settings,
				logout,
				updateUser: (data: any) => setData((p: any) => ({...p, user: {...p.user, ...data}}))
			}}
		>
			{children}
		</Context.Provider>
	)
}

export function UserContext({children}: {children: ReactNode}) {
	const p = usePromise(() => {
		const sp = new URLSearchParams(window.location.search);
		if(sp.has('tgWebAppOneTimeAuthToken')) {
			return ky.post('/api/v1/auth/tgLogin', {json: {tgWebAppOneTimeAuthToken: sp.get('tgWebAppOneTimeAuthToken')}}).json<{ status: boolean, auth: boolean, user: any }>();
		}

		return ky.get('/api/v1/auth', {}).json<{ status: boolean, auth: boolean, user: any, settings: any }>();
	}, [])
	return match(p)
		.with({isLoading: true}, () => <LoadingOverlay visible/>)
		.with({isLoading:false, data: {auth: true, user: P.any}}, (data) => createElement(userStore, data as any, children))
		.with({isLoading: false, data: {auth: false}}, ({setData}) => (
			<Router>
				<Switch>
					<Route path={'/register'} component={RegisterPage}/>
					<Route>
						<AuthPage onAuth={setData}/>
					</Route>
				</Switch>
			</Router>
		))
		.with({isError: true}, displayTSMatchGeneralError)
		.otherwise((data) => <Alert color={'red'}><pre>{JSON.stringify(data,null,4)}</pre></Alert>)
}
