import {
	Alert,
	Badge,
	Box,
	Button,
	Card,
	Checkbox, Flex,
	Grid,
	Group, Loader,
	LoadingOverlay,
	Space,
	Stack,
	Text,
	Title
} from "@mantine/core";
import {useScopedLocale} from "../../../locale";
import {Link, useParams} from "wouter";
import {useYoProvider} from "@components/YoContextEditor";
import {WebAppsApiClient} from "../api.ts";
import {useState} from "react";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {IconAlertCircle, IconAlertTriangle, IconRefresh} from "@tabler/icons-react";
import {usePromise} from "@hooks/usePromise.ts";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {modals} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import {useWaitTask} from "./useWaitTask.tsx";
import {DomainsApiClient} from "../../Domains/api.ts";
import { match } from "ts-pattern";

const api = new WebAppsApiClient();
const domainsApi = new DomainsApiClient();

interface iPlatformCardProps {
	title: string,
	isCurrent: boolean,
	description: string,
	onSelect: () => void,
	disabled?: boolean
}

function PlatformCard({title, isCurrent, description, onSelect, disabled}: iPlatformCardProps) {
	const tt = useScopedLocale('webApps.view.PublishTab.platform.');
	return (
		<Card
			shadow="sm"
			padding="lg"
			radius="md"
			withBorder
			w={'100%'}
			style={{display: 'flex', flexDirection: 'column', height: '100%'}}
		>
			<Group justify="space-between" mb="xs" preventGrowOverflow={false} wrap={'nowrap'}>
				<Text fw={500}>
					{title}
				</Text>
				{isCurrent && (
					<Badge color="green">
						{tt('currentLabel')}
					</Badge>
				)}
			</Group>

			<Text size="sm" c="dimmed">
				{description}
			</Text>
			<Space h={'md'}/>
			<Button
				disabled={isCurrent || disabled}
				color="blue"
				fullWidth
				mt="auto"
				radius="md"
				onClick={onSelect}
			>
				{isCurrent ? tt('button.active') : tt('button.inactive')}
			</Button>
		</Card>
	)
}

function PlatformSelector() {
	const [disabled, setDisabled] = useState(false);
	const params = useParams<{ id: string }>();
	const platformCtx = useYoProvider('deployMode', {defaultValue: 'pwaCloud'});
	const tt = useScopedLocale('webApps.view.PublishTab.platform.');

	const waitTack = useWaitTask({
		onResolved: (task) => {
			if(task.isOk) {
				notifications.show({
					title: tt('waitTask.successTitle'),
					message: tt('waitTask.successMessage'),
					color: 'green',
					icon: <IconAlertCircle/>
				});
			} else {
				notifications.show({
					title: tt('waitTask.errorTitle'),
					message: tt('waitTask.errorMessage'),
					color: 'red',
					icon: <IconAlertCircle/>
				});
			}
		}
	});

	async function trySwitchPlatform(platform: string) {
		setDisabled(true);
		try {
			const result = await api.tryChangePlatform(params.id, platform);
			if (result.status) {
				waitTack.setTack(result.taskId);
				platformCtx.untrackedOnChange(platform);
			}
		} catch (e) {
			await displayErrorNotification(e);
		} finally {
			setDisabled(false);
		}
	}

	return (
		<Grid align={'stretch'}>
			{waitTack.locked ? (
				<Grid.Col span={12}>
					<Alert
						mb="lg"
						icon={<IconAlertTriangle/>}
						variant="warning"
						title={tt('inProgress.title')}
					>
						{tt('inProgress.description')}
					</Alert>
				</Grid.Col>
			): (
				<>
					<Grid.Col span={6}>
						<PlatformCard
							title={tt('pwaCloud.title')}
							isCurrent={platformCtx.value === 'pwaCloud'}
							description={tt('pwaCloud.description')}
							onSelect={() => trySwitchPlatform('pwaCloud')}
							disabled={disabled}
						/>
					</Grid.Col>
					<Grid.Col span={6}>
						<PlatformCard
							title={tt('globalEdge.title')}
							isCurrent={platformCtx.value === 'globalEdge'}
							description={tt('globalEdge.description')}
							onSelect={() => trySwitchPlatform('globalEdge')}
							disabled={disabled}
						/>
					</Grid.Col>
				</>
			)}
		</Grid>
	)
}

function UnpublishController() {
	const [disabled, setDisabled] = useState(false);
	const params = useParams<{ id: string }>();
	const stateCtr = useYoProvider('state', {defaultValue: 'DRAFT'});
	const tt = useScopedLocale('webApps.view.PublishTab.unpublish.');

	const waitTack = useWaitTask({
		onResolved: (task) => {
			if(task.isOk) {
				notifications.show({
					title: tt('waitTask.successTitle'),
					message: tt('waitTask.successMessage'),
					color: 'green',
					icon: <IconAlertCircle/>
				});
				stateCtr.untrackedOnChange('DRAFT');
			} else {
				notifications.show({
					title: tt('waitTask.errorTitle'),
					message: tt('waitTask.errorMessage'),
					color: 'red',
					icon: <IconAlertCircle/>
				});
			}
		}
	});

	async function tryHide() {
		setDisabled(true);
		try {
			const result = await api.hideApp(params.id);
			waitTack.setTack(result.taskId);
		} catch (err) {
			await displayErrorNotification(err);
		} finally {
			setDisabled(false);
		}


	}
	return (
		<Card
			shadow="sm"
			padding="lg"
			radius="md"
			withBorder
			w={'100%'}
			style={{display: 'flex', flexDirection: 'column', height: '100%'}}
		>
			{waitTack.locked ? (
				<>
					<Alert
						mb="lg"
						icon={<IconAlertTriangle/>}
						variant="warning"
						title={tt('inProgress.title')}
					>
						{tt('inProgress.description')}
					</Alert>
				</>
			): (
				<>
					<Group justify="space-between" mb="xs" preventGrowOverflow={false} wrap={'nowrap'}>
						<Text fw={500}>
							{tt('title')}
						</Text>
					</Group>

					<Text size="sm" c="dimmed">
						{tt('description')}
					</Text>
					<Space h={'md'}/>
					<div>
						<Button
							disabled={disabled}
							color="red"
							mt="auto"
							radius="md"
							onClick={tryHide}
						>
							{tt('button')}
						</Button>
					</div>
				</>
			)}
		</Card>
	);
}

function PublishController() {
	const tt = useScopedLocale('webApps.view.PublishTab.publish.');

	const params = useParams<{ id: string }>();
	const stateCtr = useYoProvider('state', {defaultValue: 'DRAFT'});
	const promise = usePromise(() => api.validateApp(params.id), []);
	const [disabled, setDisabled] = useState(false);

	const waitTack = useWaitTask({
		onResolved: (task) => {
			if(task.isOk) {
				notifications.show({
					title: tt('waitTask.successTitle'),
					message: tt('waitTask.successMessage'),
					color: 'green',
					icon: <IconAlertCircle/>
				});
				stateCtr.untrackedOnChange('PUBLISHED');
			} else {
				notifications.show({
					title: tt('waitTask.errorTitle'),
					message: tt('waitTask.errorMessage'),
					color: 'red',
					icon: <IconAlertCircle/>
				});
			}
		}
	});

	const items  = promise.data?.checklist ?? [];

	const allowPublish = !items.some(el => el.state === 'ERROR');

	async function showDetails(el: { details: Array<any>; key: string; state: "OK" | "WARN" | "ERROR" }) {
		modals.open({
			title: tt(`details.${el.key}` as any),
			size: 'xl',
			children: (
				<>
					{el.state === 'OK' ? (
						<Alert color={'green'}>
							{tt(`details.allOk`)}
						</Alert>
					) : el.state === 'WARN' ? (
						<Alert color={'yellow'}>
							{tt(`details.warning`)}
						</Alert>
					): (
						<Alert color={'red'}>
							{tt(`details.error`)}
						</Alert>
					)}
					<h4>
						{tt(`details.title`)}
					</h4>
					<Stack>
						{el.details.map(problem => {
							return (
								<Alert color={el.state === 'ERROR' ? 'red' : 'yellow'} title={tt(`details.${problem.error}` as any)}>
									{problem.language ? tt(`details.seeLang`).replace('{{lang}}', problem.language) : null}
								</Alert>
							)
						})}
					</Stack>
				</>
			)
		})
	}

	async function tryPublish() {
		setDisabled(true);
		try {
			const result = await api.publishApp(params.id);
			waitTack.setTack(result.taskId);
		} catch (err) {
			await displayErrorNotification(err);
		} finally {
			setDisabled(false);
		}
	}

	return (
		<>
			<Grid>
				{waitTack.locked ? (
					<Grid.Col span={12}>
						<Alert
							mb="lg"
							icon={<IconAlertTriangle/>}
							variant="warning"
							title={tt('inProgress.title')}
						>
							{tt('inProgress.description')}
						</Alert>
					</Grid.Col>
				) : (
					<>
						<Grid.Col span={6}>
							<Flex justify={'space-between'} align={'center'}>
								<h4>{tt('title')}</h4>
								<IconRefresh size={'1rem'} onClick={promise.run}/>
							</Flex>
							<Box pos={'relative'}>
								<LoadingOverlay visible={promise.isLoading}/>
								{promise.isError ? displayTSMatchGeneralError(promise) : null}
								{items.map(el => {
									return (
										<Card
											withBorder
											onClick={() => showDetails(el)}
										>
											<Checkbox
												checked
												color={el.state === 'OK' ? 'green' : el.state === 'WARN' ? 'yellow' : 'red'}
												icon={el.state === 'OK' ? undefined : (p) => el.state === 'ERROR' ?
													<IconAlertCircle {...p}/> : el.state === 'WARN' ?
														<IconAlertTriangle {...p}/> : null}
												label={tt(`checklist.${el.key}` as any)}
											/>
										</Card>
									)
								})}
								<br/>
								<Button disabled={!allowPublish || disabled || promise.isLoading || promise.isError}
										fullWidth onClick={() => tryPublish()}>
									{tt('button')}
								</Button>
							</Box>
						</Grid.Col>
						<Grid.Col span={6}>
							<Flex justify={'space-between'} align={'center'}>
								<h4>{tt('description.title')}</h4>
							</Flex>
							<Card withBorder>
								{tt('description.text').split('\n').map((line, i) => <p key={i}>{line}</p>)}
							</Card>
						</Grid.Col>
					</>
				)}
			</Grid>
		</>
	);
}

export function PublishTab() {
	const tt = useScopedLocale('webApps.view.PublishTab.');
	const stateCtr = useYoProvider('state', {defaultValue: 'DRAFT'});
	const domainCtx = useYoProvider<string>('domain');

	const domainPromise = usePromise(() => {
		if(!domainCtx.value) return Promise.resolve();
		return domainsApi.fetchItem(domainCtx.value).then(res => res.item);
	}, [domainCtx.value]);

	return (
		<Card withBorder>
			<Title order={4}>{tt('title')}</Title>
			<Stack>
				<Card variant="section">
					<h4>
						{tt('platform.title')}
					</h4>
					{match(domainPromise)
						.with({isError: true}, () => displayTSMatchGeneralError(domainPromise))
						.with({isLoading: true}, () => <Loader/>)
						.with({data: {allowSplit: true}}, () => (
							<Alert color={'yellow'} title={tt('platform.splitWarning.title')}>
								<Button component={Link} href={`~/domains/v/${domainPromise.data._id}`}>
									{tt('platform.splitWarning.link')}
								</Button>
							</Alert>
						))
						.otherwise(() => <PlatformSelector/>)
					}

				</Card>
				<Card variant="section">
					{stateCtr.value === 'PUBLISHED' ? <UnpublishController/> : <PublishController/>}
				</Card>
			</Stack>
		</Card>
	);
}
