import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Alert, Box, Button, Flex, Grid, LoadingOverlay, Table, Textarea} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {WhitePagesTemplatesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {FileUploaderField, ImageUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {Translation, useLocale} from "../../../locale";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {bytesToSize} from "../utils/bytesToSize.ts";
import {FileIcon} from "../utils/fileIcon.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";

const api = new WhitePagesTemplatesApiClient();



function RenderLandingInfo(props: { foundIndex: boolean, assetsFolderWarning: boolean, swWarning: boolean, stripLevel: number, structure: any[] }) {
	const t = useLocale();
	const transformCtx = useYoProvider<{filePath: string, prompt: string, _id: string}[]>('transformActions', {defaultValue: []})
	return (
		<>
			<h4>
				<Translation id={'whitePagesTemplates.view.landingInfo.title'}/>
			</h4>
			{!props.foundIndex && (
				<Alert color={'red'}>
					<Translation id={'whitePagesTemplates.view.landingInfo.notFoundIndex'}/>
				</Alert>
			)}
			{props.assetsFolderWarning && (
				<Alert color={'yellow'}>
					<Translation id={'whitePagesTemplates.view.landingInfo.assetsFolderWarning'}/>
				</Alert>
			)}
			{props.swWarning && (
				<Alert color={'yellow'}>
					<Translation id={'whitePagesTemplates.view.landingInfo.swWarning'}/>
				</Alert>
			)}
			{!!props.stripLevel && (
				<Alert color={'yellow'}>
					<Translation id={'whitePagesTemplates.view.landingInfo.stripLevel'}/>
				</Alert>
			)}
			<Table>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>
							File type
						</Table.Th>
						<Table.Th>
							Path
						</Table.Th>
						<Table.Th>
							Size
						</Table.Th>
						<Table.Th>
							Actions
						</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{props.structure.map(el => {
						const action = transformCtx.value.find(a => a.filePath === el.path);

						async function addPrompt() {
							const value = await yoFormModal({
								title: t('whitePagesTemplates.view.landingInfo.actions.addPrompt.title'),
								defaultValue: {prompt: action?.prompt || ''},
								size: 'xl',
								body: (
									<Grid>
										<Grid.Col span={6}>
											<Textarea readOnly autosize maxRows={25} value={el.content}/>
										</Grid.Col>
										<Grid.Col span={6}>
											<TextareaField
												autosize
												minRows={3}
												field={'prompt'}
												label={t('whitePagesTemplates.view.landingInfo.actions.addPrompt.fields.prompt')}
											/>
										</Grid.Col>

									</Grid>
								)
							});
							if(action) {
								transformCtx.onChange(transformCtx.value.map(el => {
									if(el.filePath === action.filePath) {
										if(!value.prompt) return false;
										return {...el, prompt: value.prompt};
									}
									return {...el};
								}).filter(Boolean))
							} else {
								transformCtx.onChange([...transformCtx.value, {_id: mongoObjectId(), filePath: el.path, prompt: value.prompt}]);
							}
						}

						return (
							<Table.Tr key={el.path}>
								<Table.Td>
									{el.type}
								</Table.Td>
								<Table.Td>
									<Flex align={'center'} style={{color: action ? 'yellow': ''}} title={action ? action.prompt : ''}>
										<FileIcon fileType={el.path}/>
										<div style={{marginLeft: '5px'}}>{el.path}</div>
									</Flex>
								</Table.Td>
								<Table.Td>
									{el.type === 'dir' ? '-' : bytesToSize(el.size)}
								</Table.Td>
								<Table.Td>
									{el.path.endsWith('.html') && (
										<Button size={'compact-sm'} onClick={addPrompt}>
											Add modify prompt
										</Button>
									)}
								</Table.Td>
							</Table.Tr>
						);
					})}
				</Table.Tbody>
			</Table>
			<pre>{JSON.stringify(transformCtx.value, null, 4)}</pre>
		</>
	);
}

function LandingInfo(props: { fileId: string }) {
	const promise = usePromise<any>(() => {
		if (!props.fileId) return Promise.resolve({status: false, message: 'provide file'});
		return api.fetchLandingAnalysis(props.fileId);
	}, [props.fileId]);

	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			{promise.isError && displayTSMatchGeneralError(promise)}
			{!promise.isLoading && !promise.data.status && <Alert color={'red'}>{promise.data.message}</Alert>}
			{!promise.isLoading && !promise.isError && promise.data.status && (
				<RenderLandingInfo {...promise.data}/>
			)}
			{!promise.isLoading && promise.data ? (
				<>
					{!promise.data.status ? (
						<Alert color={'red'}>{promise.data.message}</Alert>
					) : (
						<>

						</>
					)}
				</>
			) : undefined}
		</Box>
	);
}

export function ViewWhitePagesTemplates() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('whitePagesTemplates.view.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification);
	}

	async function tryRemove() {
		if (!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={t('whitePagesTemplates.view.title')}
				items={(
					<>
						<Button onClick={trySave} size={'compact-sm'}>
							<Translation id={'whitePagesTemplates.view.saveButton'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'whitePagesTemplates.view.removeButton'}/>
						</Button>
					</>
				)}
			/>
			<Grid>
				<Grid.Col span={4}>
					<InputField field={'name'} label={t('whitePagesTemplates.view.label.name')}/>
					<TextareaField
						autosize
						minRows={3}
						field={'description'}
						label={t('whitePagesTemplates.view.label.description')}
					/>
					<h4>
						<Translation id={'whitePagesTemplates.view.landingInfo.site'}/>
					</h4>
					<FileUploaderField field={'file'} accept={['application/zip']}/>
					<h4>
						<Translation id={'whitePagesTemplates.view.landingInfo.image'}/>
					</h4>
					<ImageUploaderField field={'image'}/>
				</Grid.Col>
				<Grid.Col span={8}>
					<LandingInfo fileId={data.file}/>
				</Grid.Col>
			</Grid>
		</YoContextEditor>
	);
}
