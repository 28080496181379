
export const metaPixelEvents = {
	AddPaymentInfo: {
		en: 'Add Payment Info',
		ru: 'Добавление платежной информации',
		ua: 'Додавання платіжної інформації',
		description: {
			en: 'Adding billing information during the checkout process. The user clicks to save billing details.',
			ru: 'Добавление платежной информации в процессе оформления заказа. Пользователь нажимает для сохранения данных.',
			ua: 'Додавання платіжної інформації під час оформлення замовлення. Користувач натискає для збереження даних.'
		}
	},
	AddToCart: {
		en: 'Add to Cart',
		ru: 'Добавление в корзину',
		ua: 'Додавання до кошика',
		description: {
			en: 'Adding a product to the shopping cart. The user clicks to add an item to the cart.',
			ru: 'Добавление товара в корзину. Пользователь нажимает для добавления товара.',
			ua: 'Додавання товару до кошика. Користувач натискає для додавання товару.'
		}
	},
	AddToWishlist: {
		en: 'Add to Wishlist',
		ru: 'Добавление в список желаний',
		ua: 'Додавання до списку бажань',
		description: {
			en: 'Adding a product to a wishlist. The user clicks to add an item to the wishlist.',
			ru: 'Добавление товара в список желаний. Пользователь нажимает для добавления товара.',
			ua: 'Додавання товару до списку бажань. Користувач натискає для додавання товару.'
		}
	},
	CompleteRegistration: {
		en: 'Complete Registration',
		ru: 'Завершение регистрации',
		ua: 'Завершення реєстрації',
		description: {
			en: 'Submitting a form for subscription or registration.',
			ru: 'Отправка формы для подписки или регистрации.',
			ua: 'Надсилання форми для підписки або реєстрації.'
		}
	},
	Contact: {
		en: 'Contact',
		ru: 'Связаться',
		ua: 'Зв\'язатися',
		description: {
			en: 'Reaching out to your business via phone, SMS, email, or chat to ask about a product.',
			ru: 'Обращение в вашу компанию по телефону, SMS, email или чату для запроса информации о товаре.',
			ua: 'Звернення до вашої компанії телефоном, через SMS, електронну пошту або чат з питанням про товар.'
		}
	},
	CustomizeProduct: {
		en: 'Customize Product',
		ru: 'Настройка продукта',
		ua: 'Налаштування продукту',
		description: {
			en: 'Selecting product details such as color or size.',
			ru: 'Выбор деталей продукта, таких как цвет или размер.',
			ua: 'Вибір деталей продукту, таких як колір або розмір.'
		}
	},
	Donate: {
		en: 'Donate',
		ru: 'Пожертвование',
		ua: 'Пожертва',
		description: {
			en: 'Donating funds to your organization or cause.',
			ru: 'Пожертвование средств в пользу вашей организации или интересующей стороны.',
			ua: 'Пожертва коштів на користь вашої організації чи зацікавленої сторони.'
		}
	},
	FindLocation: {
		en: 'Find Location',
		ru: 'Поиск местоположения',
		ua: 'Пошук місцезнаходження',
		description: {
			en: 'Searching for a store location on your website or app with the intent to visit.',
			ru: 'Поиск местоположения магазина на сайте или в приложении с намерением посетить его.',
			ua: 'Пошук місця магазину на вашому сайті чи в додатку з наміром відвідати його.'
		}
	},
	InitiateCheckout: {
		en: 'Initiate Checkout',
		ru: 'Начало оформления заказа',
		ua: 'Початок оформлення замовлення',
		description: {
			en: 'Beginning the checkout process.',
			ru: 'Человек нажимает кнопку оформления заказа.',
			ua: 'Користувач натискає кнопку для початку оформлення замовлення.'
		}
	},
	Lead: {
		en: 'Lead',
		ru: 'Лид',
		ua: 'Лід',
		description: {
			en: 'Submitting contact information in exchange for a lead.',
			ru: 'Предоставление контактной информации для лида.',
			ua: 'Надання контактної інформації для отримання ліда.'
		}
	},
	Purchase: {
		en: 'Purchase',
		ru: 'Покупка',
		ua: 'Покупка',
		description: {
			en: 'Completing a purchase or order.',
			ru: 'Завершение покупки или оформления заказа.',
			ua: 'Завершення покупки або оформлення замовлення.'
		}
	},
	Schedule: {
		en: 'Schedule',
		ru: 'Запись на прием',
		ua: 'Запис на прийом',
		description: {
			en: 'Scheduling an appointment or class.',
			ru: 'Запись на прием или занятие.',
			ua: 'Запис на прийом або заняття.'
		}
	},
	Search: {
		en: 'Search',
		ru: 'Поиск',
		ua: 'Пошук',
		description: {
			en: 'Performing a search on your website.',
			ru: 'Выполнение поиска на вашем сайте.',
			ua: 'Виконання пошуку на вашому сайті.'
		}
	},
	StartTrial: {
		en: 'Start Trial',
		ru: 'Начать пробную версию',
		ua: 'Почати пробну версію',
		description: {
			en: 'Starting a free trial for a product or service.',
			ru: 'Начало бесплатной пробной версии.',
			ua: 'Початок безкоштовної пробної версії.'
		}
	},
	SubmitApplication: {
		en: 'Submit Application',
		ru: 'Отправка заявки',
		ua: 'Надіслати заявку',
		description: {
			en: 'Submitting an application for a product or service.',
			ru: 'Отправка заявки на продукт или услугу.',
			ua: 'Надсилання заявки на продукт або послугу.'
		}
	},
	Subscribe: {
		en: 'Subscribe',
		ru: 'Подписка',
		ua: 'Підписка',
		description: {
			en: 'Subscribing to a paid service or product.',
			ru: 'Оформление платной подписки на услугу или продукт.',
			ua: 'Оформлення платної підписки на послугу або продукт.'
		}
	},
	ViewContent: {
		en: 'View Content',
		ru: 'Просмотр контента',
		ua: 'Перегляд контенту',
		description: {
			en: 'Viewing a product or landing page.',
			ru: 'Просмотр страницы с товаром или целевой страницы.',
			ua: 'Перегляд сторінки з товаром або цільової сторінки.'
		}
	}
};



export type metaPixelEventKeys = keyof typeof metaPixelEvents;
