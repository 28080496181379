import {Alert, Button} from "@mantine/core";

export function displayTSMatchGeneralError({errData, run}: {errData: any, run: () => void}) {
	return (
		<Alert color={'red'} title={'An API request error occurred'}>
			<p>{errData.message}</p>
			<Button onClick={run}>Retry</Button>
		</Alert>
	)
}
