import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button, Card, Divider, Stack} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconCreditCardPay} from "@tabler/icons-react";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";

MenuManager.RegisterItem({
	path: '/system/settings/shkeeper',
	name: 'Shkeeper',
	// nameKey: 'menuItems.system.shkeeper',
	parent: 'system',
	onlySuperAdmin: true,
	icon: <IconCreditCardPay />
})


export function ShkeeperSettings() {
	const controller = useSiteSettings('payment.shkeeper.');

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: 'Настройки успешно сохранены'}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={'Настройки Shkeeper'} items={(
				<>
					<Button variant="primary" onClick={saveSettings}>Сохранить настройки</Button>
				</>
			)}/>
			<Card withBorder component={Stack}>
				<InputField field={'baseUrl'} label={'Base Url'} />
				<InputField field={'apiKey'} label={'apiKey'} />
				<Divider />
				<NumberField field={'minimumPaymentAmount'} label={'Минамальная сумма пополнения $'} />
			</Card>
		</YoContextEditor>
	);
}
