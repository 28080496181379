import {WebAppsApiClient} from "../api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {ActionIcon, Badge, Card, Flex, Loader, Menu, SimpleGrid, Table, Tabs, Title} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {StatsGrid} from "@components/StatsGrid";
import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {endOfToday, startOfToday} from "date-fns";
import {IconFilter, IconX} from "@tabler/icons-react";
import {useState} from "react";
import {AreaChart} from "@mantine/charts";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {CountrySelect} from "@components/Utils/Country/CountrySelect.tsx";
import {useLocaleContext} from "../../../locale";

const api = new WebAppsApiClient();

function StatChart(props: { from: Date, id: string, to: Date, filters: Array<any>, chartType: 'visits' | 'uniqVisits' | 'installs' }) {
	const promise = usePromise(() => api.fetchStatsChart(props.id, props.chartType, {
		from: props.from,
		to: props.to,
		filters: props.filters
	}), [props.id, props.from.getTime(), props.to.getTime(), props.chartType, JSON.stringify(props.filters)]);

	if (promise.isLoading) return <Loader/>;
	if (promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<AreaChart
			h={300}
			data={promise.data.chart}
			dataKey="l"
			series={promise.data.series}
			curveType="linear"
		/>
	);
}

function WorldStat(props: { from: Date, id: string, to: Date, filters: Array<any>, chartType: 'visits' | 'uniqVisits' | 'installs' }) {
	const {selectedLocale} = useLocaleContext();
	const promise = usePromise(() => api.fetchStatsByCountry(props.id, {
		from: props.from,
		to: props.to,
		filters: props.filters
	}), [props.id, props.from.getTime(), props.to.getTime(), JSON.stringify(props.filters)]);

	if (promise.isLoading) return <Loader/>;
	if (promise.isError) return displayTSMatchGeneralError(promise);

	if(!promise.data!.status) return null;

	function renderCountryName(data: any) {
		if(selectedLocale === 'ru') return data.country_ru;
		if(selectedLocale === 'uk') return data.country_uk;
		return data.country_en;
	}

	return (
		<>
			<Tabs defaultValue="table">
				<Tabs.List>
					<Tabs.Tab value="map" disabled>
						Map
					</Tabs.Tab>
					<Tabs.Tab value="table">
						Table
					</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="map">
					...
				</Tabs.Panel>

				<Tabs.Panel value="table">
					<div style={{
						height: '300px',
						overflow: 'scroll'
					}}>
						<Table style={{overflow: 'scroll'}} data={{
							head: ['Country', 'Visits', 'Uniq Visits', 'Installs'],
							body: promise.data!.items.map(data => [
								<Flex align={'center'}>
									<img style={{marginRight: '6px'}} width={'25px'} src={data.countryFlag}/> {renderCountryName(data)}
								</Flex>,
								data.visits,
								data.uniqVisits,
								data.installs
							])
						}}/>
					</div>

				</Tabs.Panel>
			</Tabs>
		</>
	)
}

function View(props: { id: string, from: Date, to: Date, filters: any[] }) {
	const [chartType, setChartType] = useState<'visits' | 'uniqVisits' | 'installs'>('visits');
	const promise = usePromise(() => api.fetchStats(props.id, {
		from: props.from,
		to: props.to,
		filters: props.filters
	}), [props.id, props.from.getTime(), props.to.getTime(), JSON.stringify(props.filters)]);

	if (promise.isLoading) return <Loader/>;
	if (promise.isError) return displayTSMatchGeneralError(promise);
	const {visits, uniqVisits, installs} = promise.data as any;
	return (
		<>
			<Flex>
				<SimpleGrid cols={3}>
					<StatsGrid
						value={visits.value}
						title={'Визитов'}
						diff={visits.diff}
						onClick={() => setChartType('visits')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'visits' ? 'darkblue' : undefined}}
					/>
					<StatsGrid
						value={uniqVisits.value}
						title={'Уникальных визитов'}
						diff={uniqVisits.diff}
						onClick={() => setChartType('uniqVisits')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'uniqVisits' ? 'darkblue' : undefined}}
					/>
					<StatsGrid
						value={installs.value}
						title={'Установок'}
						diff={installs.diff}
						onClick={() => setChartType('installs')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'installs' ? 'darkblue' : undefined}}
					/>
				</SimpleGrid>
			</Flex>
			<br/>
			<SimpleGrid cols={2}>
				<div>
					<h4>Chart</h4>
					<StatChart
						id={props.id}
						from={props.from}
						to={props.to}
						filters={props.filters}
						chartType={chartType}
					/>
				</div>
				<div>
					<h4>By country</h4>
					<WorldStat
						id={props.id}
						from={props.from}
						to={props.to}
						filters={props.filters}
						chartType={chartType}
					/>
				</div>
			</SimpleGrid>
			<br/>
		</>
	);
}

export function WebAppStat(props: { id: string }) {
	const [filters, setFilters] = useState<{ type: string, value: string, mode: string}[]>([]);
	const timeControls = useTimeState(startOfToday(), endOfToday());

	async function tryAddGeoFilter() {
		const {value} = await yoFormModal<{value: string}>({
			title: 'Добавить фильтр',
			required: ['value'],
			body: (
				<>
					<RenderFuncField<string> field={'value'}>
						{ctx => <CountrySelect
							label={'Выберите страну'}
							value={ctx.value}
							onChange={ctx.onChange}
							searchable
							cca2
						/>}
					</RenderFuncField>
				</>
			)
		});

		setFilters(prev => {
			return [...prev,{type: 'geo', value: value, mode: 'equals'}]
		})
	}


	return (
		<>
			<Flex justify={'space-between'}>
				<Title order={4}>Статистика</Title>
				<Flex>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon>
								<IconFilter/>
							</ActionIcon>
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Item onClick={tryAddGeoFilter}>
								Страна
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
					<TimeRangeSelect onChange={timeControls.setValue} defaultMode={'today'}/>
				</Flex>
			</Flex>
			<div>
				{filters.map((fValue, index) => {
					function onRemove() {
						setFilters(prev => {
							const arr = prev.slice();
							arr.splice(index,1);
							return arr;
						});
					}

					return (
						<Badge mr={'sm'}>
							<Flex align={'center'} justify={'space-between'}>
								{fValue.type} {fValue.mode} {fValue.value}
								<IconX onClick={onRemove} style={{cursor: 'pointer', color: 'red'}} size={'18px'}/>
							</Flex>
						</Badge>
					)
				})}
			</div>
			<Card variant="section">
				<View id={props.id} from={timeControls.value[0]!} to={timeControls.value[1]!} filters={filters}/>
			</Card>
		</>
	)


}
