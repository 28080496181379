export function mongoObjectId(): string {
	const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
	return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
				return (Math.random() * 16 | 0).toString(16);
		}).toLowerCase();
}


export function StringObjectId (id: string): string {
	return `ObjectId(${id})`;
}

export function extractObjectIdFromString (str = ''): string | null {
	const regex = /ObjectId\((.*)\)/gm;
	return regex.exec(str)?.[1] || null;
}
