import {singleton} from "@ood/singleton";
import {ReactNode} from "react";
import {LocaleKeys} from "../locale/localeKeys.tsx";
import type { TeamUserRoleRights} from "@shared/_data/teamUserRoles.ts";

interface IGroupParams {
	key: string;
	name?: string;
	nameKey?: LocaleKeys;
	icon?: ReactNode,
	requiredRights?: TeamUserRoleRights[]
	[key: string]: any;
}

interface IItemParams {
	path: string;
	key?: string;
	parent?: string;
	name?: string;
	nameKey?: LocaleKeys;
	icon?: ReactNode,
	onlySuperAdmin?: boolean
	requiredRights?: TeamUserRoleRights[]
	[key: string]: any;
}

@singleton
class MenuManager {

	groups: Array<any> = [];

	items: Array<any> = [];

	static RegisterGroup(params: IGroupParams, childrens: any[] = []) {
		if (!params.key) throw new Error(`Для группы обязательно требуется key`);
		const instance = new MenuManager();
		const menuItem = Object.assign({}, params);
		const index = instance.groups.findIndex(e => e.key === menuItem.key);
		if (index === -1) {
			instance.groups.push(menuItem);
		} else {
			instance.groups[index] = menuItem;
		}
		childrens.map(e => ({
			...e,
			parent: params.key
		})).forEach(MenuManager.RegisterItem);
	}

	static RegisterItem(item: IItemParams) {
		if (!item.path) throw new Error(`Для элемента меню обязательно требуется path`);
		const instance = new MenuManager();
		const menuItem = Object.assign({}, item);
		menuItem.key = menuItem.key || menuItem.path;
		if (!menuItem.key) throw new Error(`Для элемента меню обязательно требуется key или path`);
		const index = instance.items.findIndex(e => e.key === menuItem.key);
		if (index === -1) {
			instance.items.push(menuItem);
		} else {
			instance.items[index] = menuItem;
		}
	}
}

export default MenuManager;
