import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {ActionIcon, Button, Card, Flex, Modal, Stack, Table, Title} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {PushMessagesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal, confirmModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {Translation, useLocale} from "../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {LanguageSelect} from "@components/Utils/Languages/LanguageSelect.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {ImageUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {Type} from "@sinclair/typebox";
import {useAjvWithTypebox} from "../../../utils/ajv/useAjvWithTypebox.ts";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {useDisclosure} from "@mantine/hooks";
import {IconEdit, IconLanguageHiragana, IconTrash} from "@tabler/icons-react";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";
import {PushStatsContainer} from "../PushLogs/pushStats";

const dataSchema = Type.Object({
	items: Type.Array(Type.Object({
		language: Type.String(),
		title: Type.String(),
		message: Type.String(),
		image: Type.Optional(Type.String()),
	}), {minItems: 1}),
	fallbackLanguage: Type.String(),
});

const api = new PushMessagesApiClient();

function EditMessageForm() {
	const t = useLocale();
	return (
		<>
			<RenderFuncField<string> field={'language'}>
				{ctx => (
					<LanguageSelect
						label={t('push.messages.view.fields.language')}
						platform={'openai'}
						value={ctx.value}
						onChange={ctx.onChange as any}
						searchable
					/>
				)}
			</RenderFuncField>
			<InputField
				field={'title'}
				label={t('push.messages.view.items.title')}
			/>
			<TextareaField
				autosize
				field={'message'}
				label={t('push.messages.view.items.message')}
			/>
			<h5>
				<Translation id={'push.messages.view.items.image'}/>
			</h5>
			<ImageUploaderField field={'image'}/>
		</>
	);
}

function MessageTableItem() {
	const [isOpen, {toggle}] = useDisclosure(false);
	const ctx = useYoProvider();

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return ctx.bypassProps.onRemove();
	}

	return (
		<>
			<Table.Tr>
				<Table.Td>
					<Flex align={'center'}>
						<RenderFuncField<boolean> field={'autoGenerated'}>
							{ctx => ctx.value ? <IconLanguageHiragana style={{marginRight: '0.2rem'}} title={'auto created'} size={'1rem'}/> : null}
						</RenderFuncField>
						<RenderFuncField<string> field={'language'}>
							{ctx => ctx.value}
						</RenderFuncField>
					</Flex>
				</Table.Td>
				<Table.Td>
					<RenderFuncField<string> field={'title'}>
						{ctx => ctx.value}
					</RenderFuncField>
				</Table.Td>
				<Table.Td>
					<RenderFuncField<string> field={'message'}>
						{ctx => ctx.value}
					</RenderFuncField>
				</Table.Td>
				<Table.Td>
					<ActionIcon mr={'sm'}>
						<IconEdit onClick={toggle}/>
					</ActionIcon>
					<ActionIcon color={'red'} mr={'sm'}>
						<IconTrash onClick={tryRemove}/>
					</ActionIcon>
				</Table.Td>
			</Table.Tr>
			<Modal opened={isOpen} onClose={toggle} title={'Редактирование сообщения'}>
				<EditMessageForm/>
			</Modal>
		</>
	);
}

export function ViewPushMessages() {
	const navigate = useNavigate();
	const t = useLocale();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);
	const valid = useAjvWithTypebox(dataSchema, data);
	async function trySave() {
		if(!valid.isValid) {
			const forceSave = await confirmModal({
				message: (
					<>
						<Translation id={'push.messages.view.save.invalid'}/>
						<ul>
							{valid.errors.map(el => <li>{el.message}</li>)}
						</ul>
					</>
				),
			});
			if(!forceSave) return false;
		}
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: 'Сохранение успешно'});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	const languages = (data.items?.map((item: any) => item.language) || []).filter(Boolean);

	function tryCreateMessage(cb: (data: any) => void) {
		const defaultLanguage = data.items && data.items.length ? data.items[data.items.length - 1].language : data.fallbackLanguage ?? 'EN';
		return yoFormModal<any>({
			title: 'Создание сообщения',
			defaultValue: {language: defaultLanguage},
			body: <EditMessageForm/>,
			required: ['language', 'title', 'message'],
		}).then(cb);
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={t('push.messages.view.title')}
				items={(
					<>
						<Button variant="primary" color={!valid.isValid ? 'yellow' : 'green'} onClick={trySave} size={'compact-sm'}>
							<Translation id={'push.messages.view.save'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'push.messages.view.remove'}/>
						</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
				<TeamSelectField />
				<InputField
					field={'comment'}
					label={t('push.messages.view.fields.comment')}
					description={t('push.messages.view.fields.comment.description')}
				/>
				<Title order={4} mb="sm">
					<Translation id={'push.messages.view.items'}/>
				</Title>
				<Table>
					<Table.Thead>
						<Table.Tr>
							<Table.Th>
								{t('push.messages.view.fields.language')}
							</Table.Th>
							<Table.Th>
								{t('push.messages.view.items.title')}
							</Table.Th>
							<Table.Th>
								{t('push.messages.view.items.message')}
							</Table.Th>
							<Table.Th>
								Actions
							</Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody>
						<YoContextArrayMap field={'items'}>
							<MessageTableItem/>
						</YoContextArrayMap>
					</Table.Tbody>
				</Table>
				<hr/>
				<RenderFuncField field={'items'}>
					{ctx => (
						<Button variant="secondary" size={'compact-sm'} fullWidth onClick={() => tryCreateMessage(data => ctx.push({_id: mongoObjectId(), ...data}))}>
							<Translation id={'push.messages.view.items.add'}/>
						</Button>
					)}
				</RenderFuncField>
				<h4>
					<Translation id={'push.messages.view.fallbackLanguage'}/>
				</h4>
				<p>
					<Translation id={'push.messages.view.fallbackLanguage.description'}/>
				</p>
				<RenderFuncField<string> field={'fallbackLanguage'}>
					{ctx => (
						<LanguageSelect
							label={t('push.messages.view.fields.fallbackLanguage')}
							platform={'openai'}
							value={ctx.value}
							whitelist={languages}
							onChange={ctx.onChange as any}
							searchable
						/>
					)}
				</RenderFuncField>
				<br/>
				<CheckboxField field={'autoTranslate'} label={t('push.messages.view.fields.autoTranslate')}/>
				<br/>
				<PushStatsContainer selector={{messageId: params.id}}/>
			</Card>
		</YoContextEditor>
	);
}
