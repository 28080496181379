import {modals} from "@mantine/modals";
import {Text} from '@mantine/core';
import {JSX} from "react";

export function ConfirmDeleteModal(message?: string | JSX.Element) {
	return new Promise<boolean>(done => {
		modals.openConfirmModal({
			title: 'Подтвердите удаление',
			children: (
				<Text size="sm">
					{message || 'Данное действие необратимо, продолжить?'}
				</Text>
			),
			labels: {confirm: 'Да', cancel: 'Отменить'},
			confirmProps: { color: 'red' },
			onCancel: () => done(false),
			onConfirm: () => done(true),
			onClose: () => done(false)
		})
	})
}

export function confirmModal({message, positive, confirmLabel, cancelLabel}: { message: string | React.JSX.Element, positive?: boolean, confirmLabel?: string, cancelLabel?: string }) {
	return new Promise<boolean>(done => {
		modals.openConfirmModal({
			title: 'Подтвердите действие',
			children: (
				<Text size="sm">
					{message}
				</Text>
			),
			labels: {confirm: confirmLabel || 'Да', cancel: cancelLabel || 'Отменить'},
			confirmProps: !positive ? { color: 'red' } : undefined,
			cancelProps: positive ? { color: 'red' } : undefined,
			onCancel: () => done(false),
			onConfirm: () => done(true),
			onClose: () => done(false)
		})
	})
}
