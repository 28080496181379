import {useMemo} from "react";
import {createAjv} from "./index.ts";
import {TObject} from "@sinclair/typebox";

export function useAjvWithTypebox(schema:TObject, data: Record<string, any>) {
	const validateFunction = useMemo(() => {
		return createAjv();
	}, []);

	return useMemo(() => {
		const validateResult = validateFunction.validate(schema,data);
		const errors = validateFunction.errors ?? [];

		function getErrorCaption(path: string, localize ?: Record<string, string>) {
			const err = errors.find(el => {
				if(el.keyword === 'required') return el.params.missingProperty === path;
				return el.instancePath === `/${path}`;
			});
			if(!err) return false;
			return localize ? localize[err.keyword] || err.keyword : err.message;
		}

		return {
			isValid: validateResult,
			getErrorCaption,
			errors
		}
	}, [JSON.stringify(data)])
}
