import {TagsInput} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'tags'>> = (props) => {
    let arr = props.value ?? [];
    if(!Array.isArray(arr)) arr = Object.values(arr);
    return (
        <TagsInput
            label={props.label}
            value={arr}
            onChange={v => {
                props.onChange(v)
            }}
        />
    );
}

export default FieldTypeString;
