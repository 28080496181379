import {Route} from "wouter";
import {ListAvatarGallery} from "./listAvatarGallery.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconUserCircle} from "@tabler/icons-react";

const baseUrl = '/system/avatars';

MenuManager.RegisterItem({
	parent: 'system',
	path: baseUrl,
	key: 'AvatarGallery',
	name: 'Avatar gallery',
	icon: <IconUserCircle/>
})

export function AvatarGalleryRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListAvatarGallery}/>
		</Route>
	);
}
