import {Card, Stack} from "@mantine/core";
import {WebAppStat} from "./webAppStat.tsx";
import {useParams} from "wouter";

export function StatistsTab() {
	const params = useParams<{id: string}>();
	return (
		<Card withBorder>
			<Stack>
				<WebAppStat id={params.id}/>
			</Stack>
		</Card>
	);
}
