import {ILocaleItem} from "../interfaces/iLocaleItem";

export const deeplLanguagesList: ILocaleItem[] =[
	{"code": "AR", "name_en": "Arabic", "name_ru": "Арабский", "name_ukr": "Арабська", "emoji": "🇸🇦"},
	{"code": "BG", "name_en": "Bulgarian", "name_ru": "Болгарский", "name_ukr": "Болгарська", "emoji": "🇧🇬"},
	{"code": "CS", "name_en": "Czech", "name_ru": "Чешский", "name_ukr": "Чеська", "emoji": "🇨🇿"},
	{"code": "DA", "name_en": "Danish", "name_ru": "Датский", "name_ukr": "Данська", "emoji": "🇩🇰"},
	{"code": "DE", "name_en": "German", "name_ru": "Немецкий", "name_ukr": "Німецька", "emoji": "🇩🇪"},
	{"code": "EL", "name_en": "Greek", "name_ru": "Греческий", "name_ukr": "Грецька", "emoji": "🇬🇷"},
	{"code": "EN", "name_en": "English", "name_ru": "Английский", "name_ukr": "Англійська", "emoji": "🇬🇧"},
	{"code": "EN-GB", "name_en": "English (British)", "name_ru": "Английский (британский)", "name_ukr": "Англійська (британська)", "emoji": "🇬🇧"},
	{"code": "EN-US", "name_en": "English (American)", "name_ru": "Английский (американский)", "name_ukr": "Англійська (американська)", "emoji": "🇺🇸"},
	{"code": "ES", "name_en": "Spanish", "name_ru": "Испанский", "name_ukr": "Іспанська", "emoji": "🇪🇸"},
	{"code": "ET", "name_en": "Estonian", "name_ru": "Эстонский", "name_ukr": "Естонська", "emoji": "🇪🇪"},
	{"code": "FI", "name_en": "Finnish", "name_ru": "Финский", "name_ukr": "Фінська", "emoji": "🇫🇮"},
	{"code": "FR", "name_en": "French", "name_ru": "Французский", "name_ukr": "Французька", "emoji": "🇫🇷"},
	{"code": "HU", "name_en": "Hungarian", "name_ru": "Венгерский", "name_ukr": "Угорська", "emoji": "🇭🇺"},
	{"code": "ID", "name_en": "Indonesian", "name_ru": "Индонезийский", "name_ukr": "Індонезійська", "emoji": "🇮🇩"},
	{"code": "IT", "name_en": "Italian", "name_ru": "Итальянский", "name_ukr": "Італійська", "emoji": "🇮🇹"},
	{"code": "JA", "name_en": "Japanese", "name_ru": "Японский", "name_ukr": "Японська", "emoji": "🇯🇵"},
	{"code": "KO", "name_en": "Korean", "name_ru": "Корейский", "name_ukr": "Корейська", "emoji": "🇰🇷"},
	{"code": "LT", "name_en": "Lithuanian", "name_ru": "Литовский", "name_ukr": "Литовська", "emoji": "🇱🇹"},
	{"code": "LV", "name_en": "Latvian", "name_ru": "Латышский", "name_ukr": "Латвійська", "emoji": "🇱🇻"},
	{"code": "NB", "name_en": "Norwegian (Bokmål)", "name_ru": "Норвежский (букмол)", "name_ukr": "Норвезька (букмол)", "emoji": "🇳🇴"},
	{"code": "NL", "name_en": "Dutch", "name_ru": "Нидерландский", "name_ukr": "Нідерландська", "emoji": "🇳🇱"},
	{"code": "PL", "name_en": "Polish", "name_ru": "Польский", "name_ukr": "Польська", "emoji": "🇵🇱"},
	{"code": "PT", "name_en": "Portuguese", "name_ru": "Португальский", "name_ukr": "Португальська", "emoji": "🇵🇹"},
	{"code": "PT-BR", "name_en": "Portuguese (Brazilian)", "name_ru": "Португальский (бразильский)", "name_ukr": "Португальська (бразильська)", "emoji": "🇧🇷"},
	{"code": "PT-PT", "name_en": "Portuguese (European)", "name_ru": "Португальский (европейский)", "name_ukr": "Португальська (європейська)", "emoji": "🇵🇹"},
	{"code": "RO", "name_en": "Romanian", "name_ru": "Румынский", "name_ukr": "Румунська", "emoji": "🇷🇴"},
	{"code":"RU","name_en":"Russian","name_ru":"Русский","name_ukr":"Російська","emoji":"🔤"},
	{"code": "SK", "name_en": "Slovak", "name_ru": "Словацкий", "name_ukr": "Словацька", "emoji": "🇸🇰"},
	{"code": "SL", "name_en": "Slovenian", "name_ru": "Словенский", "name_ukr": "Словенська", "emoji": "🇸🇮"},
	{"code": "SV", "name_en": "Swedish", "name_ru": "Шведский", "name_ukr": "Шведська", "emoji": "🇸🇪"},
	{"code": "TR", "name_en": "Turkish", "name_ru": "Турецкий", "name_ukr": "Турецька", "emoji": "🇹🇷"},
	{"code": "UK", "name_en": "Ukrainian", "name_ru": "Украинский", "name_ukr": "Українська", "emoji": "🇺🇦"},
	{"code": "ZH", "name_en": "Chinese", "name_ru": "Китайский", "name_ukr": "Китайська", "emoji": "🇨🇳"},
	{"code": "ZH-HANS", "name_en": "Chinese (Simplified)", "name_ru": "Китайский (упрощенный)", "name_ukr": "Китайська (спрощена)", "emoji": "🇨🇳"},
	{"code": "ZH-HANT", "name_en": "Chinese (Traditional)", "name_ru": "Китайский (традиционный)", "name_ukr": "Китайська (традиційна)", "emoji": "🇹🇼"}
]
