import {Route} from "wouter";
import {ListCfAccounts} from "./listCfAccounts.tsx";
import {ViewCfAccounts} from "./viewCfAccounts.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconBrandCloudflare} from "@tabler/icons-react";

const baseUrl = '/system/cfAccounts';

MenuManager.RegisterItem({
	path: baseUrl,
	parent: 'system',
	onlySuperAdmin: true,
	key: 'cfAccounts',
	name: 'Cf Accounts',
	nameKey: 'menuItems.system.cfAccounts',
	icon: <IconBrandCloudflare/>
})

export function CfAccountsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListCfAccounts}/>
			<Route path={'/v/:id'} component={ViewCfAccounts}/>
		</Route>
	);
}
