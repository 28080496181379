import {WebAppsApiClient} from "../api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {MultiSelect, MultiSelectProps, Select, SelectProps} from "@mantine/core";

const api = new WebAppsApiClient();

interface iSelectWebAppProps extends SelectProps {
	hideAll?: boolean;
}

function useData(props: {hideAll?: boolean, query?: any}) {
	return usePromise(async () => {
		const result = await api.fetchList({limit: 1000, query: props.query});
		const items = result.items.map((el: any) => {
			return {
				label: `${el.name} - ${el.state}`,
				value: el._id
			}
		});
		if(!props.hideAll) items.unshift({label: 'Все', value: 'any'});
		return items;
	}, [JSON.stringify(props.query)]);
}

export function SelectWebApp({hideAll, ...otherProps}: iSelectWebAppProps) {
	const {data, isLoading, isError} = useData({hideAll});

	return (
		<Select
			data={data ?? []}
			disabled={isLoading || isError}
			placeholder={isError ? 'Error' : otherProps.placeholder}
			{...otherProps}
		/>
	);
}

export function MultiSelectWebApp({hideAll, query = {},  ...otherProps}: {hideAll?: boolean, query?: any} & MultiSelectProps) {
	const {data, isLoading, isError} = useData({hideAll, query});

	return (
		<MultiSelect
			data={data ?? []}
			disabled={isLoading || isError}
			placeholder={isError ? 'Error' : otherProps.placeholder}
			{...otherProps}
		/>
	)
}
