import {useYoProvider, YoRawContext} from "@components/YoContextEditor";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {allLanguageList} from "@shared/_data/allLanguageList.ts";
import {
	ActionIcon,
	Alert,
	Badge,
	Button,
	Center,
	Flex,
	Grid,
	Group,
	Loader,
	Modal,
	Radio,
	Stack,
	Text,
	TextInput,
	Title
} from "@mantine/core";
import {useContext, useState} from "react";
import {ILocaleItem} from "@shared/interfaces/iLocaleItem.ts";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {deeplLanguagesList} from "@shared/_data/deeplLanguagesList.ts";
import {useLocaleContext, useScopedLocale} from "../../../../locale";
import {IconArrowBackUp, IconLanguageKatakana, IconTrash} from "@tabler/icons-react";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import ky from "ky";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";


function AddLanguageModal(props: { onClose: () => void, isOpen: boolean }) {
	const [modalState, setModalState] = useState<'SELECT' | 'WAIT_FOR_TRANSLATION'>('SELECT');
	const {selectedLocale} = useLocaleContext();
	const tt = useScopedLocale('webApps.views.ContentTab.LanguagesTab.AddLanguageModal.');
	const ctx = useYoProvider<Record<string, any>>('localeAppData', {defaultValue: {}});
	const [search, setSearch] = useState('');
	const existsLanguages = Object.keys(ctx.value || {});
	const langLists = allLanguageList.filter(el => !existsLanguages.includes(el.code));
	const defaultLangCode = Object.entries<any>(ctx.value).find(([,langData]) => langData.isDefault)?.[0];
	const defaultLanguage = defaultLangCode ? ctx.value[defaultLangCode] : undefined;
	const defaultLangMeta = defaultLanguage ? allLanguageList.find(el => el.code === defaultLangCode) : undefined;

	function searchFilter(el: ILocaleItem) {
		const lowerSearch = search.toLowerCase();
		return el.name_ru.toLowerCase().includes(lowerSearch) || el.name_ukr.toLowerCase().includes(lowerSearch) || el.name_en.toLowerCase().includes(lowerSearch);
	}

	function addLanguage(el: ILocaleItem) {
		ctx.onChange({
			...ctx.value, [el.code]: {
				isDefault: !defaultLanguage,
				translateEngine: 'openai',
				reviews: JSON.parse(JSON.stringify(defaultLanguage?.reviews ?? [])),
				content: JSON.parse(JSON.stringify(defaultLanguage?.content ?? {})),
			}
		});
		setSearch('');
		props.onClose();
	}

	async function translateLanguage(el: ILocaleItem) {
		setModalState('WAIT_FOR_TRANSLATION');

		try {
			const result = await ky.post(`/api/v1/utils/translate/object`, {
				json: {obj: {content: defaultLanguage.content?.dataMap}, language: el.code},
				timeout: 1000 * 60
			}).json<{status: boolean, object: {content: any}}>();

			ctx.onChange({
				...ctx.value, [el.code]: {
					isDefault: !defaultLanguage,
					translateEngine: 'openai',
					content: { dataMap: { ...defaultLanguage.content.dataMap, ...result.object.content } },
				}
			});
			setSearch('');
			props.onClose();
		} catch (e) {
			await displayErrorNotification(e);
		} finally {
			setModalState('SELECT');
		}
	}

	return (
		<Modal opened={props.isOpen} onClose={modalState === 'WAIT_FOR_TRANSLATION' ? () => {} : props.onClose} size={'xl'} withCloseButton={modalState === 'SELECT'}>
			{modalState === 'SELECT' ? (
				<>
					<TextInput value={search} onChange={e => setSearch(e.target.value)} label={tt('search.label')}/>
					<br/>
					{langLists.filter(searchFilter).slice(0, 15).map(lang => {
						const isOpenaiSupport = true; // Да он всё сцуко знает
						const isDeeplSupport = deeplLanguagesList.some(el => el.code === lang.code);
						return (
							<Alert mb='xs' key={lang.code} icon={lang.emoji} title={<>{getLangName(lang,selectedLocale)} {isOpenaiSupport ? <Badge>OpenAI</Badge> : null} {isDeeplSupport ? <Badge>Deepl</Badge> : null}</>}>
								<br/>
								<Grid>
									<Grid.Col span={defaultLangCode ? 4 : 12}>
										<Button fullWidth variant="secondary" onClick={() => addLanguage(lang)}>{tt('submit.label')}</Button>
									</Grid.Col>

									{defaultLangCode ? (
										<Grid.Col span={8}>
											<Button fullWidth style={{overflow: 'hidden'}} variant="secondary" onClick={() => translateLanguage(lang)}>
												<IconLanguageKatakana/>
												{tt('copyAndTranslate.label').replaceAll('{{lang}}', getLangName(defaultLangMeta!, selectedLocale))}</Button>
										</Grid.Col>
									): null}
								</Grid>
							</Alert>
						);
					})}
				</>
			): modalState === 'WAIT_FOR_TRANSLATION' ? (
				<Stack>
					<Center>
						<h1>{tt('wait.label')}</h1>
					</Center>
					<Center>
						<p>{tt('wait.description')}</p>
					</Center>
					<Center>
						<Loader/>
					</Center>
				</Stack>
			) : null}

		</Modal>
	)
}

function DeleteLangButton(props: { code: string }) {
	const tt = useScopedLocale('webApps.views.ContentTab.LanguagesTab.DeleteLangButton.');
	const yoContext = useContext(YoRawContext);
	const otherLang = Object.keys(yoContext.getField('localeAppData') || {}).filter(el => !yoContext.getField(`localeAppData.${el}.__deleted`)).find(el => el !== props.code);

	async function deleteWithPrompt() {
		if(!await ConfirmDeleteModal()) return;
		const isDefault = yoContext.getField(`localeAppData.${props.code}.isDefault`);
		yoContext.batchSetField([{
			key: `localeAppData.${props.code}.__deleted`,
			value: true
		}, isDefault ? {
			key: `localeAppData.${props.code}.isDefault`,
			value: false
		} : undefined, isDefault && otherLang ? {
			key: `localeAppData.${otherLang}.isDefault`,
			value: true
		} : undefined].filter(Boolean));
	}

	function restoreLang() {
		yoContext.batchSetField([{
			key: `localeAppData.${props.code}.__deleted`,
			value: false
		}, !otherLang ? {
			key: `localeAppData.${props.code}.isDefault`,
			value: true
		}: undefined].filter(Boolean));
	}

	if(yoContext.getField(`localeAppData.${props.code}.__deleted`)) {
		return (
			<ActionIcon color={'blue'}>
				<IconArrowBackUp title={tt('cancel')} style={{cursor: 'pointer'}} onClick={restoreLang}/>
			</ActionIcon>
		);
	}
	return (
		<ActionIcon color={'red'}>
			<IconTrash title={tt('delete')} onClick={deleteWithPrompt} style={{cursor: 'pointer'}}/>
		</ActionIcon>
	);
}

function getLangName(el: ILocaleItem, userLang: 'ru' | 'uk' | 'en') {
	return userLang === 'ru' ? el.name_ru : userLang === 'uk' ? el.name_ukr : el.name_en;

}

export function LanguagesTab() {
	const {selectedLocale} = useLocaleContext();
	const tt = useScopedLocale('webApps.views.ContentTab.LanguagesTab.');
	const [isOpen, setOpen] = useState(false);
	const ctx = useYoProvider('localeAppData');
	const yoContext = useContext(YoRawContext);
	const activeLanguages = Object.keys(ctx.value || {}).map((code) => {
		return allLanguageList.find(el => el.code === code);
	}).filter(Boolean);

	function setDefaultLanguage(code: string) {
		yoContext.batchSetField(activeLanguages.map(el => ({
			key: `localeAppData.${el.code}.isDefault`,
			value: el.code === code
		})));
	}

	return (
		<>
			<Title order={4} mb="lg">{tt('activeLanguages.title')}</Title>
			{(!activeLanguages.length || activeLanguages.every(el => yoContext.getField(`localeAppData.${el.code}.__deleted`))) && (
				<Alert
					color={'yellow'}
					title={tt('noLanguages.title')}
				>
					{tt('noLanguages.label')}
				</Alert>
			)}
			<Grid>
				{activeLanguages.map(el => {
					const isDeleted = yoContext.getField(`localeAppData.${el.code}.__deleted`);
					return (
						<Grid.Col span={{lg: 6}} key={el.code}>
							<Group>
								{el.emoji}
								<Text fw={600} size="md">
									{getLangName(el, selectedLocale)}
								</Text>
							</Group>
							<Alert variant="info2">
								<Stack>
									<Flex justify={'space-between'} align={'center'}>
										<RenderFuncField<boolean> field={`localeAppData.${el.code}.isDefault`}>
											{ctx => (
												<Radio
													disabled={isDeleted}
													checked={ctx.value}
													onChange={() => setDefaultLanguage(el.code)}
													label={tt('isDefault.label')}
												/>
											)}
										</RenderFuncField>
										<DeleteLangButton code={el.code}/>
									</Flex>
									<SelectField
										disabled={isDeleted}
										label={tt('translateEngine.label')}
										field={`localeAppData.${el.code}.translateEngine`}
										data={[{label: 'Deepl', value: 'deepl'}, {label: 'OpenAI', value: 'openai'}]}
									/>

								</Stack>
							</Alert>
							<br/>
						</Grid.Col>
					);
				})}
			</Grid>
			<br/>
			<Button variant="secondary" onClick={() => setOpen(true)}>{tt('addLanguage.buttonLabel')}</Button>
			<AddLanguageModal
				isOpen={isOpen}
				onClose={() => setOpen(false)}
			/>
		</>
	);
}
