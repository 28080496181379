import {Card, Group, Loader, Stack, Text } from "@mantine/core";
import {FbPixelsImportEventsJobApiClient} from "./api.ts";
import { useParams } from "wouter";
import {usePromise } from "@hooks/usePromise.ts";
import { useEffect, useState } from "react";
import { Header } from "@components/Header/index.tsx";

const api = new FbPixelsImportEventsJobApiClient();

function ViewFbPixelsImportEventsJob() {
    const params = useParams<{id: string}>();
    const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
    const [data, setData] = useState<any | null>(null);
    useEffect(() => setData(promise.data?.item ?? null), [promise.isLoading]);

    useEffect(() => {
        let interval: any;
        if (data && data.status === 'progress') {
            interval = setInterval(() => {
                api.fetchItem(params.id).then(res => {
                    setData(res.item);
                })
            }, 5000);
        }

        return () => {
            interval && clearInterval(interval);
        }
    }, [params.id]);

    if (!data) return (
        <Loader />
    )

    return (
        <>
            <Header
                backButtonUrl=""
                title={`Import events`}
            />
            <Card component={Stack}>
                <Group>
                    <Stack gap={0}>
                        <Text size="xs">
                            Status
                        </Text>
                        <Text size='lg' fw={600}>
                            {data.status}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text size="xs">
                            Sent events
                        </Text>
                        <Text>
                            {data.alreadySent}
                        </Text>
                    </Stack>
                </Group>
                <Card variant="section">
                    <Group>
                        <Stack gap={0}>
                            <Text size="xs">
                                Initiator
                            </Text>
                            <Text>
                                {data.owner}
                            </Text>
                        </Stack>
                        <Stack gap={0}>
                            <Text size="xs">
                                Created at
                            </Text>
                            <Text>
                                {data.createdAt ? new Date(data.createdAt).toLocaleString() : '-'}
                            </Text>
                        </Stack>
                        <Stack gap={0}>
                            <Text size="xs">
                                Updated at
                            </Text>
                            <Text>
                                {data.updatedAt ? new Date(data.updatedAt).toLocaleString() : '-'}
                            </Text>
                        </Stack>
                    </Group>
                </Card>
                <div>
                    <Group>
                        <Card variant="section">
                            <Stack gap={0}>
                                <Text size="xs">Pixel</Text>
                                <Text>
                                    {data.pixel}
                                </Text>
                            </Stack>
                        </Card>
                        <Card variant="section">
                            <Stack gap={0}>
                                <Text size="xs">File</Text>
                                <Text>
                                    {data.sourceFile}
                                </Text>
                            </Stack>
                        </Card>
                    </Group>
                </div>
            </Card>
        </>
    );
}

export default ViewFbPixelsImportEventsJob;