import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {Button, Tabs} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {WebAppsApiClient} from "../api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {
	IconActivityHeartbeat,
	IconAnalyze,
	IconBrandPushbullet,
	IconChartArea,
	IconHome,
	IconLetterCase,
	IconShare,
	IconWorldWww
} from "@tabler/icons-react";
import {TrackerTab} from "./trackerTab.tsx";
import {AnalyticsTab} from "./analyticsTab.tsx";
import {PushTab} from "./pushTab.tsx";
import {ContentTab} from "./contentTab";
import {PublishTab} from "./publishTab.tsx";
import {useLocalStorage} from "@mantine/hooks";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {GeneralTab} from "./generalTab.tsx";
import {ABTab} from "./ABTab.tsx";
import {CloakingSettingsPanel} from "./cloakingSettingsTab.tsx";
import {useScopedLocale} from "../../../locale";
import {DomainsTab} from "./domainsTab";
import {useUser} from "@engine/UserContext.tsx";
import {StatistsTab} from "./statistsTab.tsx";
import {LinksTab} from "./linksTab.tsx";
import {useSearchParams} from "@hooks/useSearchParams.ts";

const api = new WebAppsApiClient();

export function ViewWebApps() {
	const tt = useScopedLocale('webApps.view.');

	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const [sp] = useSearchParams();
	const {user} = useUser();
	const [tab, setTab] = useLocalStorage({key: `ui.webApps.${params.id}.tab`, defaultValue: 'domain', });
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);
	useEffect(() => {
		if(sp.has('tab')) setTab(sp.get('tab')!);
	}, []);


	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: tt('saved')});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}
	async function tryForceRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.forceDeleteItem(params.id).then(() => navigate('/archive'))
	}

	function saveChangesInUnpublishedApp(newTab: string | null) {
		if(newTab) setTab(newTab);
		if(data.state === 'PUBLISHED') return;
		api.saveItem(params.id, changes).then(() => {
			setChanges({});
		}).catch(displayErrorNotification);
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges} bypassProps={{hasUnsavedChanges: Object.keys(changes).length, readOnly: false}}>
			<Header
				backButtonUrl=""
				title={`${tt('title')} «${data.name || tt('unnamed')}»`}
				items={(
					<>
						<Link href={`~/leads?appId=${params.id}`}>
							<Button variant="primary" size={'compact-sm'} color={'green'}>{tt('header.leads')}</Button>
						</Link>
						<Button
							variant="primary"
							size="compact-sm"
							onClick={trySave}
						>
							{tt('header.save')}
						</Button>
						{!data.archive?.isArchived ? (
							<Button variant="outline" size="compact-sm"  color="white" onClick={tryRemove}>{tt('header.delete')}</Button>
						): user.isSuperAdmin ? (
							<Button variant="outline" size="compact-sm"  color="red" onClick={tryForceRemove}>{tt('header.delete')} (Force)</Button>
						): null}
					</>
				)}
			/>
			<Tabs value={tab} keepMounted={false} onChange={saveChangesInUnpublishedApp} variant="pills">
				<Tabs.List grow mb="md">
					<Tabs.Tab value="base" leftSection={<IconHome />}>
						{tt('tabs.list.base')}
					</Tabs.Tab>
					<Tabs.Tab value="domain" leftSection={<IconWorldWww />}>
						{tt('tabs.list.domain')}
					</Tabs.Tab>
					<Tabs.Tab value="content" leftSection={<IconLetterCase />}>
						{tt('tabs.list.content')}
					</Tabs.Tab>
					<Tabs.Tab value="analytics" leftSection={<IconAnalyze />}>
						{tt('tabs.list.analytics')}
					</Tabs.Tab>
					<Tabs.Tab value={'push'} leftSection={<IconBrandPushbullet/>}>
						{tt('tabs.list.push')}
					</Tabs.Tab>
					<Tabs.Tab value={'links'} leftSection={<IconShare/>}>
						{tt('tabs.list.links')}
					</Tabs.Tab>
					<Tabs.Tab value={'publish'} leftSection={<IconActivityHeartbeat/>}>
						{tt('tabs.list.publish')}
					</Tabs.Tab>
					<Tabs.Tab value={'stats'} leftSection={<IconChartArea/>}>
						{tt('tabs.list.stats')}
					</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="base">
					<GeneralTab/>
				</Tabs.Panel>
				<Tabs.Panel value="domain">
					<DomainsTab/>
				</Tabs.Panel>
				<Tabs.Panel value="tracker">
					<TrackerTab/>
				</Tabs.Panel>
				<Tabs.Panel value="analytics">
					<AnalyticsTab/>
				</Tabs.Panel>
				<Tabs.Panel value='push'>
					<PushTab/>
				</Tabs.Panel>
				<Tabs.Panel value="content">
					<ContentTab/>
				</Tabs.Panel>
				<Tabs.Panel value="publish">
					<PublishTab/>
				</Tabs.Panel>
				<Tabs.Panel value="ab">
					<ABTab/>
				</Tabs.Panel>
				<Tabs.Panel value="cloaking">
					<CloakingSettingsPanel/>
				</Tabs.Panel>
				<Tabs.Panel value="stats">
					<StatistsTab/>
				</Tabs.Panel>
				<Tabs.Panel value="links">
					<LinksTab/>
				</Tabs.Panel>
			</Tabs>
		</YoContextEditor>
	);
}
