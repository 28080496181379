import {useYoApi} from "@hooks/useYoApi.tsx";
import {MRT_PaginationState, MRT_SortingState} from "mantine-react-table";
import {useUpdateEffect} from "usehooks-ts";
import {Dispatch, SetStateAction, useEffect} from "react";
import {PaginationState} from "@tanstack/react-table";
import {ColumnFilterWithFilterType} from "../columnFilterWithFilterType.tsx";
import {UseDefaultsTableStateStore} from "./useDefaultsTableState.tsx";

interface ITableParams {
	setPagination: Dispatch<SetStateAction<PaginationState>>;
	pagination: MRT_PaginationState;
	sorting: MRT_SortingState;
	columnFilters: ColumnFilterWithFilterType[];
	//columnFilterFns: MRT_ColumnFilterFnsState;
}

interface iProps {
	apiClient: ReturnType<typeof useYoApi>,
	tableParams: ITableParams,
	defaults?: UseDefaultsTableStateStore;
	query?: Record<string, { type: string, value: any }>
}

export function useMRTYoApiDataAdapter({apiClient, tableParams, defaults, query: externalQuery = {}}: iProps) {
	useUpdateEffect(() => {
		apiClient.setOffset(tableParams.pagination.pageIndex * tableParams.pagination.pageSize);
		apiClient.setLimit(tableParams.pagination.pageSize);
		apiClient.setSort(tableParams.sorting.map(el => `${el.desc ? '-': ''}${el.id}`).join(' '));
		const query = tableParams.columnFilters.reduce((acc,curr) => {
			acc[curr.id] = {
				type: curr.filterType ?? 'contains',
				value: curr.value
			}
			return acc;
		}, {} as any);

		Object.assign(query, externalQuery);

		apiClient.setQuery(query);
		defaults?.storeQuery(query);
	}, [
		JSON.stringify(tableParams.columnFilters),
		JSON.stringify(externalQuery),
		JSON.stringify(tableParams.sorting),
		tableParams.pagination.pageIndex,
		tableParams.pagination.pageSize
	]);
	useEffect(() => {
		tableParams.setPagination({
			pageSize: apiClient.limit,
			pageIndex: Math.floor(apiClient.offset / apiClient.limit)
		});
	}, []);
}
