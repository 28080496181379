import {createWebAppDesignConfig} from "./utils";

export const webAppsDesignConfigPlayMarketSKAK =  createWebAppDesignConfig({
    legacy: true,
    label: 'Play Market (SKAK)',
    localization: {
        reviews: {
            type: 'reviews',
            label: 'Reviews',
        },
        name: {
            type: 'string',
            label: 'webAppsTemplateEdit.playMarket.name',
        },
        isVerify: {
            type: 'checkbox',
            default: true,
            label: 'webAppsTemplateEdit.playMarket.isVerify',
        },
        useIconFromBaseLanguage: {
            type: 'checkbox',
            default: true,
            label: 'Use Icon from base language',
        },
        icon: {
            type: 'image',
            label: 'App Icon'
        },
        owner: {
            type: 'string',
            label: 'webAppsTemplateEdit.playMarket.owner',
        },
        rating: {
            type: 'rating',
            label: 'webAppsTemplateEdit.playMarket.rating',
        },
        useScreensFromBaseLanguage: {
            type: 'checkbox',
            label: 'use Screens from base language',
            default: true,
        },
        screens: {
            type: 'images',
            label: 'webAppsTemplateEdit.playMarket.screens',
        },
        description: {
            type: 'textarea',
            label: 'webAppsTemplateEdit.playMarket.description',
        },
        tags: {
            type: 'tags',
            label: 'webAppsTemplateEdit.playMarket.tags',
        },
        relatedApps: {
            type: 'relatedApps',
            label: 'Related Apps',
        },
        whatsNew: {
            label: 'Section \'What\'s New\'',
            type: 'whatsNew'
        },
        trailer: {
            label: 'Trailer',
            type: 'trailer'
        }
    },
    localizationTabs: {
        description: [
            'name',
            'isVerify',
            'owner',
            'description',
            'tags',
            'relatedApps',
            'whatsNew',
        ],
        images: [
            'icon',
            'screens',
            'trailer'
        ],
        reviews: [
            'rating',
            'reviews'
        ]
    },
    additional: {
        security: {
            label: 'Personal Data Processing Settings\n',
            fields: {
                noDataCollected: {
                    type: 'checkbox',
                    label: 'The app does not collect data',
                },
                noDataShare: {
                    type: 'checkbox',
                    label: 'The app does not share data with third parties',
                }
            }
        },
        installButtonBehavior: {
            label: 'Install Button Behavior',
            type: 'installButtonBehavior'
        },
        tweaks: {
            label: 'PWA Tweaks',
            fields: {
                hideHeader: {
                    type: 'checkbox',
                    label: 'Hide Header',
                },
                reloadPageIfInstallNotAllowed: {
                    type: 'checkbox',
                    label: 'Reload Page if install NotAllowed',
                },
                installButtonAnimSpeed: {
                    type: 'sliderNumber',
                    label: 'Install Button Anim Speed',
                    default: 50
                },
            }
        },
    }
})
