import {useClipboard} from "@mantine/hooks";
import {ActionIcon, Menu} from "@mantine/core";
import {IconCheck, IconCopy, IconExclamationCircle} from "@tabler/icons-react";

export function CopyDomainMenu(props: { domain: string }) {
	const clipboard = useClipboard({timeout: 1500});
	return (
		<Menu shadow="md" width={200}>
			<Menu.Target>
				<ActionIcon size="sm" variant="subtle" c="white">
					{
						clipboard.copied ? (
							<IconCheck/>
						) : clipboard.error ? (
							<IconExclamationCircle color={'red'} title={clipboard.error.name}/>
						) : (
							<IconCopy title={'Copy'}/>
						)
					}
				</ActionIcon>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item onClick={() => clipboard.copy(`https://${props.domain}?fromDashboard`)}>
					Copy base link
				</Menu.Item>
				<Menu.Item
					onClick={() => clipboard.copy(`https://${props.domain}?fbclid={{fbclid}}&utm_campaign={{campaign.name}}&utm_source={{site_source_name}}&utm_placement={{placement}}&campaign_id={{campaign.id}}&adset_id={{adset.id}}&ad_id={{ad.id}}&adset_name={{adset.name}}&ad_name={{ad.name}}`)}>
					Copy url with UTM
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
