import {GroupMenuItem, useShellMenuItems} from "../useShellMenuItems.tsx";
import {match} from "ts-pattern";
import {NavLink} from "@mantine/core";
import {Link, useLocation} from "wouter";
import classes from "./styles.module.css";
import {useState} from "react";

export default function NavLinksList () {
    const items = useShellMenuItems();

    const [location] = useLocation();
    const [opened, setOpened] = useState<string[]>([
        (items.find((e) =>
            e.type === 'group'
            && e.item.children.find(a =>
                location.startsWith(a.path)
            )
        ) as GroupMenuItem | undefined)?.item.key
    ].filter(Boolean))

    return (
        items.map(item => match(item)
            .with({type: 'group'}, ({item}) => (
                <NavLink
                    key={item.key}
                    label={item.name}
                    leftSection={item.icon}
                    classNames={classes}
                    onClick={() => setOpened(_v =>  _v.includes(item.key) ? _v.filter((e) => e !== item.key) : [..._v, item.key])}
                    opened={opened.includes(item.key)}
                >
                    {item.children!.map(childItem => {
                        return (
                            <NavLink
                                key={childItem.path}
                                active={location.startsWith(childItem.path)}
                                component={Link}
                                href={childItem.path}
                                label={childItem.name}
                                leftSection={childItem.icon}
                                classNames={classes}
                            />
                        )
                    })}
                </NavLink>
            )).with({
                type: 'item'
            }, ({item}) => (
                <NavLink
                    key={item.path}
                    active={item.path === '/' ? location === '/' : location.startsWith(item.path)}
                    component={Link}
                    href={item.path}
                    label={item.name}
                    leftSection={item.icon}
                    classNames={classes}
                />
            )).otherwise(() => null)
        )
    );
}
