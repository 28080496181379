import {ActionIcon, Button, Group, Loader, Modal, Stack, Table, Text, TextInput, Title} from "@mantine/core";
import {IconMail, IconPlus, IconTrash} from "@tabler/icons-react";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {TeamsApiClient} from "../api.ts";
import {useState} from "react";
import {TeamUserRoleKeys} from "@shared/_data/teamUserRoles.ts";
import {openConfirmModal} from "@mantine/modals";
import TeamUserRoleSelect from "../Components/TeamUserRoleSelect.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {VisibleOnlyAdmin} from "../../../utils/visibleOnlyAdmin.tsx";
import {usePromise} from "@hooks/usePromise.ts";

const api = new TeamsApiClient();

function CreateNewUser({teamId}: { teamId: string }) {
	const [email, setEmail] = useState("");
	const [role, setRole] = useState<TeamUserRoleKeys | null>(null);
	const [createdUser, setCreatedUser] = useState<{ username: string, password: string } | null>(null)
	const [pending, setPending] = useState(false);
	const [opened, setOpened] = useState(false);

	async function tryCreateNewUser() {
		try {
			setPending(true);
			const created = await api.createNewUser(teamId, email, role || 'admin');
			setCreatedUser(created);
			setPending(false);
		} catch (e) {
			setPending(false);
		}
	}

	return (
		<>
			<Modal title="create a new user" opened={opened} onClose={() => !pending && setOpened(false)}>
				{
					createdUser ? (
						<Stack>
							<Title>
								Created user!
							</Title>
							<Group>
								<Text>
									Email:
								</Text>
								<Text>
									{createdUser.username}
								</Text>
							</Group>
							<Group>
								<Text>
									password:
								</Text>
								<Text>
									{createdUser.password}
								</Text>
							</Group>
						</Stack>
					) : (
						<form onSubmit={(e) => {
							e.preventDefault();
							void tryCreateNewUser();
						}}>
							<Stack>
								<TextInput
									required
									type="email"
									label="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<TeamUserRoleSelect
									label="role"
									value={role as TeamUserRoleKeys}
									onChange={v => {
										if (v !== null) {
											setRole(v);
										}
									}}
								/>
								<Button type="submit">
									Create
								</Button>
							</Stack>
						</form>
					)
				}
			</Modal>
			<Button
				leftSection={<IconPlus/>}
				onClick={() => {
					setPending(false);
					setOpened(true);
					setCreatedUser(null);
					setEmail("");
				}}
				size={'compact-sm'} variant="primary"
			>
				Create New Team User
			</Button>
		</>
	)
}

function SendInviteEmail({teamId, loadTeamInvites}: { teamId: string, loadTeamInvites: () => void }) {
	const [opened, setOpened] = useState(false);
	const [email, setEmail] = useState("");
	const [role, setRole] = useState<TeamUserRoleKeys | null>(null);

	async function trySend() {
		if (!role || !email) return;
		api.sendInviteEmail(teamId, email, role).then(() => {
			setOpened(false);
			setEmail("");
			void loadTeamInvites();
		})
	}

	return (
		<>
			<Modal title="send invite email" opened={opened} onClose={() => setOpened(false)}>
				<form onSubmit={(e) => {
					e.preventDefault();
					void trySend()
				}}>
					<Stack>
						<TextInput
							required
							type="email"
							label="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<TeamUserRoleSelect
							label="role"
							value={role as TeamUserRoleKeys}
							onChange={v => {
								if (v !== null) {
									setRole(v);
								}
							}}
						/>
						<Button type="submit">
							Send
						</Button>
					</Stack>
				</form>
			</Modal>
			<Button leftSection={<IconMail/>} onClick={() => setOpened(true)}>
				Send invite email
			</Button>
		</>
	)
}

function MembersList({teamId}: { teamId: string }) {
	// const t = useScopedLocale('teamsPage.view.membersList.');

	const usersPromise = usePromise(() => {
		return api.getTeamsUsers(teamId).then(res => res.items);
	}, [teamId]);

	const usersList = usersPromise.data;

	const teamInvitesPromise = usePromise(() => {
		return api.getTeamInvites(teamId).then(res => res.items);
	});

	const teamInvites = teamInvitesPromise.data ?? [];



	async function updateUserRole(userId: string, role: string) {
		api.updateUserRole(teamId, userId, role).then(() => usersPromise.run())
	}

	async function deleteTeamUser(teamUserId: string) {
		openConfirmModal({
			title: "Are you sure?",
			labels: {
				cancel: 'Cancel',
				confirm: 'Delete user',
			},
			onConfirm: () => {
				api.deleteTeamUser(teamId, teamUserId)
					.then(() => usersPromise.run())
			}
		})
	}

	async function tryInvite() {
		const data = await yoFormModal<any>({
			title: 'Invite user',
			required: ['email', 'role'],
			defaultValue: {},
			body: (
				<>
					<InputField field={'email'} label="email"/>
					<RenderFuncField field={'role'}>
						{ctx => (
							<TeamUserRoleSelect
								label="role"
								value={ctx.value as TeamUserRoleKeys}
								onChange={ctx.onChange}
							/>
						)}
					</RenderFuncField>
				</>
			)
		})
		return api.inviteUser(teamId, data.email, data.role as TeamUserRoleKeys).then(() => usersPromise.run())
	}

	return (
		<div>
			<div>
				<Text fw="bold">
					Team Members
				</Text>
				<Table withTableBorder mb="md">
					<Table.Thead>
						<Table.Tr>
							<Table.Th>email</Table.Th>
							<Table.Th>role</Table.Th>
							<Table.Th>
								<Group gap="xs">
									<VisibleOnlyAdmin>
										<Button leftSection={<IconPlus/>} onClick={tryInvite} size={'compact-sm'}
												variant="primary">
											Invite
										</Button>
									</VisibleOnlyAdmin>
									<CreateNewUser teamId={teamId}/>
									<SendInviteEmail teamId={teamId} loadTeamInvites={teamInvitesPromise.run}/>
								</Group>
							</Table.Th>
						</Table.Tr>
					</Table.Thead>
					{
						!usersList ? (
							<Loader/>
						) : (
							<Table.Tbody>
								{
									usersList.map(item => (
										<Table.Tr key={item._id}>
											<Table.Td>
												{item.username}
											</Table.Td>
											<Table.Td>
												<TeamUserRoleSelect
													value={item.role as TeamUserRoleKeys}
													onChange={v => {
														if (v !== null) {
															void updateUserRole(item._id, v);
														}
													}}
												/>
											</Table.Td>
											<Table.Td>
												<ActionIcon
													color="red"
													variant="outline"
													onClick={() => deleteTeamUser(item._id)}
												>
													<IconTrash/>
												</ActionIcon>
											</Table.Td>
										</Table.Tr>
									))
								}
							</Table.Tbody>
						)
					}
				</Table>
			</div>
			<div>
				<Text fw="bold">
					Invites
				</Text>
				<Table withTableBorder mb="md">
					<Table.Thead>
						<Table.Tr>
							<Table.Th>Email</Table.Th>
							<Table.Th>Role</Table.Th>
							<Table.Th>Status</Table.Th>
							<Table.Th>Date</Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody>
						{(teamInvites || []).map(invite => (
							<Table.Tr key={invite._id}>
								<Table.Td>{invite.email}</Table.Td>
								<Table.Td>{invite.role}</Table.Td>
								<Table.Td>{invite.status}</Table.Td>
								<Table.Td>{invite.createdAt ? new Date(invite.createdAt).toLocaleString() : '-'}</Table.Td>
							</Table.Tr>
						))}
					</Table.Tbody>
				</Table>
			</div>
		</div>
	);
}

export default MembersList;
