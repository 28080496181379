import {useYoProvider} from "@components/YoContextEditor";
import {ImageUploader} from "@YoUi/imageUploader.tsx";
import {FileUploader, IFileUploaderProps} from "@YoUi/FileUploader.tsx";



export function ImageUploaderField(props: { field: string, hideDescriptionWithImage?: boolean }) {
	const ctx = useYoProvider<string>(props.field);
	function onDrop(file: {_id: string, url: string}) {
		ctx.onChange(file._id)
	}
	return (
		<ImageUploader onDrop={onDrop} imageId={ctx.value} hideDescriptionWithImage={props.hideDescriptionWithImage}/>
	);
}
export function FileUploaderField(props: { field: string } & Omit<IFileUploaderProps, 'onDrop' | 'fileId'>) {
	const ctx = useYoProvider<string>(props.field);
	function onDrop(file: {_id: string, url: string}) {
		ctx.onChange(file._id)
	}
	return (
		<FileUploader onDrop={onDrop} fileId={ctx.value} {...props}/>
	);
}
