import {useState} from "react";
import {Dropzone, DropzoneProps, FileWithPath} from "@mantine/dropzone";
import ky from "ky";
import {IconFile, IconFileCheck, IconUpload, IconX} from "@tabler/icons-react";
import {notifications} from "@mantine/notifications";
import {Group, Loader, rem, Text} from "@mantine/core";
import {parseApiError} from "../../utils/parseApiError.tsx";
import {usePromise} from "@hooks/usePromise.ts";

export interface IFileUploaderProps extends Omit<DropzoneProps, 'loading' | 'onDrop' | 'disabled'> {
	onDrop: (file: {_id: string, url: string}) => void;
	fileId?: string;
}


export function FileUploader(props: IFileUploaderProps) {
	const [disabled, setDisabled] = useState(false);
	const fileDataPromise = usePromise(() => {
		if(!props.fileId) return Promise.resolve(false);
		return ky.get( `/api/v1/files/${props.fileId}`).json<{ item: any }>().then(res => res.item);
	}, [props.fileId as string]);

	function onDrop([file]: Array<FileWithPath>) {
		setDisabled(true);
		const fd = new FormData();
		fd.append('file', file as File);
		ky.put('/api/v1/files', {
			body: fd,
			timeout: false,
		}).json<{item: {_id: string, url: string}}>()
			.then(res => props.onDrop(res.item))
			.catch(err => notifications.show({ color:'red', message: parseApiError(err)}))
			.finally(() => setDisabled(false));

	}

	return (
		<Dropzone
			maxFiles={1}
			{...props}
			loading={disabled}
			onDrop={onDrop}
			disabled={disabled}

		>
			<Group justify="center" gap="xl" mih={220} style={{pointerEvents: 'none'}}>
				<Dropzone.Accept>
					<IconUpload
						style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)'}}
						stroke={1.5}
					/>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<IconX
						style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)'}}
						stroke={1.5}
					/>
				</Dropzone.Reject>
				<Dropzone.Idle>
					{props.fileId ? (
						<IconFileCheck
							style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)'}}
							stroke={1.5}
						/>
					) : (
						<IconFile
							style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)'}}
							stroke={1.5}
						/>
					)}
				</Dropzone.Idle>

				<div>
					<Text size="xl" inline>
						Перетащите файл сюда или нажмите, чтобы выбрать файлы
					</Text>
					<Text size="sm" c="dimmed" inline mt={7}>
						{fileDataPromise.isLoading ? <Loader/> : null}
						{!fileDataPromise.isLoading && !fileDataPromise.isError && fileDataPromise.data ? (
							<div style={{pointerEvents: 'all'}}>
								<p>Загружен файл: {fileDataPromise.data.originalName}</p>
								<a href={fileDataPromise.data.url} target={'_blank'}>Скачать</a>
							</div>
						): null}
					</Text>
				</div>
			</Group>
		</Dropzone>
	);
}
