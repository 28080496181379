import {Checkbox} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'checkbox'>> = (props) => {
    return (
        <Checkbox
            label={props.label}
            checked={props.value}
            onChange={e => {
                props.onChange(e.target.checked)
            }}
        />
    );
}

export default FieldTypeString;