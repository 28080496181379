import {iStatsSelector, PushLogsApiClient, statsTypes} from "../api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {Box, Card, Flex, Loader, LoadingOverlay, SimpleGrid, Title} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {StatsGrid} from "@components/StatsGrid";
import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {endOfToday, startOfToday} from "date-fns";
import {useState} from "react";
import {AreaChart} from "@mantine/charts";
import { useScopedLocale } from "../../../../locale/index.tsx";

const api = new PushLogsApiClient();

export function PushStatsChart(props: { from: Date, to: Date, chartType: statsTypes, selector: iStatsSelector }) {
	const promise = usePromise(() => api.fetchStatsChart(props.chartType, {
		from: props.from,
		to: props.to,
	}, props.selector), [props.from.getTime(), props.to.getTime(), props.chartType, JSON.stringify(props.selector)]);

	if (promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<AreaChart
				h={300}
				data={promise.data?.chart ?? []}
				dataKey="l"
				series={promise.data?.series ?? []}
				curveType="linear"
			/>
		</Box>
	);
}

export function PushStatsCards(props: { from: Date, to: Date, selector: iStatsSelector, selectedType: statsTypes, setType: (type: statsTypes) => void }) {
	const t = useScopedLocale('pages.pushLogs.pushStats.');
	const promise = usePromise(() => api.fetchStats({
		from: props.from,
		to: props.to,
	}, props.selector), [props.from.getTime(), props.to.getTime(), JSON.stringify(props.selector)]);

	if (promise.isLoading) return <Loader/>;
	if (promise.isError) return displayTSMatchGeneralError(promise);
	const {sent, opened, delivered, failed} = promise.data!;
	return (
		<>
			<SimpleGrid cols={4}>
				<StatsGrid
					value={sent.value}
					title={t('sent')}
					diff={sent.diff}
					onClick={() => props.setType('sent')}
					cardStyle={{cursor: 'pointer', backgroundColor: props.selectedType === 'sent' ? 'darkblue' : undefined}}
				/>
				<StatsGrid
					value={opened.value}
					title={t('open')}
					diff={opened.diff}
					onClick={() => props.setType('opened')}
					cardStyle={{cursor: 'pointer', backgroundColor: props.selectedType === 'opened' ? 'darkblue' : undefined}}
				/>
				<StatsGrid
					value={delivered.value}
					title={t('delivered')}
					diff={delivered.diff}
					onClick={() => props.setType('delivered')}
					cardStyle={{cursor: 'pointer', backgroundColor: props.selectedType === 'delivered' ? 'darkblue' : undefined}}
				/>
				<StatsGrid
					value={failed.value}
					title={t('failed')}
					diff={failed.diff}
					onClick={() => props.setType('failed')}
					cardStyle={{cursor: 'pointer', backgroundColor: props.selectedType === 'failed' ? 'darkblue' : undefined}}
				/>

			</SimpleGrid>
		</>
	);
}

export function PushStatsContainer(props: { selector: iStatsSelector }) {
	const t = useScopedLocale('pages.pushLogs.pushStats.');
	const timeControls = useTimeState(startOfToday(), endOfToday());
	const [chartType, setChartType] = useState<statsTypes>('opened');

	return (
		<>
			<Flex justify={'space-between'}>
				<Title order={4}>
					{t('title')}
				</Title>
				<TimeRangeSelect onChange={timeControls.setValue} defaultMode={'today'}/>
			</Flex>

			<Card variant="section">
				<>
					<PushStatsCards
						from={timeControls.value[0]!}
						to={timeControls.value[1]!}
						selector={props.selector}
						selectedType={chartType}
						setType={setChartType}
					/>
					<br/>
					<SimpleGrid cols={2}>
						<div>
							<h4>Chart</h4>
							<PushStatsChart
								from={timeControls.value[0]!}
								to={timeControls.value[1]!}
								chartType={chartType}
								selector={props.selector}
							/>
						</div>
						<div/>
					</SimpleGrid>
					<br/>
				</>
			</Card>
		</>
	)
}
