import {ReactNode} from "react";
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import {
	SortableContext,
	rectSortingStrategy,
} from "@dnd-kit/sortable";

export function SimpleSorting(props: {
	onDragEnd: (p: { oldIndex: number, newIndex: number }) => void,
	arr: Array<{ _id: string }>,
	children: ReactNode
}) {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(TouchSensor)
	);

	function onDragEnd(evt: DragEndEvent) {
		const arr = props.arr.slice();
		const {active, over} = evt;

		const oldIndex = arr.findIndex(el => el._id === active.id);
		const newIndex = arr.findIndex(el => el._id === over?.id);


		return props.onDragEnd({oldIndex, newIndex})
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={onDragEnd}
		>
			<SortableContext
				items={props.arr as any}
				strategy={rectSortingStrategy}
			>
				{props.children}
			</SortableContext>
		</DndContext>
	)
}
