import {useLocalStorage} from "@mantine/hooks";
import {Container, Grid, Tabs} from "@mantine/core";
import {IconLink, IconShieldCheck, IconTestPipe} from "@tabler/icons-react";
import {useScopedLocale} from "../../../locale";
import {useParams} from "wouter";
import {TrackerTab} from "./trackerTab.tsx";
import {ABTab} from "./ABTab.tsx";
import {CloakingSettingsPanel} from "./cloakingSettingsTab.tsx";

export function LinksTab() {
	const params = useParams<{id: string}>();
	const [tab, setTab] = useLocalStorage<string>({key: `ui.webApps.${params.id}.tab.links`, defaultValue: 'tracker'});
	const tt = useScopedLocale('webApps.view.');

	return (
		<Tabs variant="pills" value={tab} orientation={'vertical'} keepMounted={false}>
			<Tabs.List>
				<Tabs.Tab
					value='tracker'
					leftSection={<IconLink />}
					onClick={() => setTab('tracker')}
				>
					{tt('tabs.list.tracker')}
				</Tabs.Tab>
				<Tabs.Tab
					value='ab'
					leftSection={<IconTestPipe />}
					onClick={() => setTab('ab')}
				>
					{tt('tabs.list.ab')}
				</Tabs.Tab>
				<Tabs.Tab
					value='cloaking'
					leftSection={<IconShieldCheck />}
					onClick={() => setTab('cloaking')}
				>
					{tt('tabs.list.cloaking')}
				</Tabs.Tab>
			</Tabs.List>
			<Grid w={'100%'}>
				<Grid.Col span={12}>
					<Tabs.Panel value="tracker">
						<Container fluid>
							<TrackerTab/>
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="ab">
						<Container fluid>
							<ABTab/>
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="cloaking">
						<Container fluid>
							<CloakingSettingsPanel/>
						</Container>
					</Tabs.Panel>
				</Grid.Col>
			</Grid>
		</Tabs>
	);
}
