import {usePromise} from "@hooks/usePromise.ts";
import {FbPixelsApiClient} from "./api.ts";
import {Select, SelectProps} from "@mantine/core";
import useTeams from "../Teams/Context/useTeams.ts";

const api = new FbPixelsApiClient();

interface iFbPixelsSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
	onChange: (el: string | null) => void;
	value: string | null;
	teamId?: string | null;
}

export function FbPixelsSelect({onChange, value, teamId,...otherProps}: iFbPixelsSelectProps) {
	const { activeTeam } = useTeams();
	const promise = usePromise(() => api.fetchList({limit: 1000, query: { team: teamId || activeTeam } }).then(res => res.items.map((el: any) => {
		return {
			label: `${el.pixel} ${el.name ? `(${el.name})` : ''}`,
			value: el._id
		}
	})), [value]);

	return (
		<Select
			value={value}
			onChange={onChange}
			data={promise.data ?? []}
			{...otherProps}
		/>
	);
}
