import BNB from "./icons/BNB.png";
import BTC from "./icons/BTC.png";
import DOGE from "./icons/DOGE.png";
import ETH from "./icons/ETH.png";
import LTC from "./icons/LTC.png";
import TRX from "./icons/TRX.png";
import USDC from "./icons/USDC.png";
import USDT from "./icons/USDT.png";
import XRP from "./icons/XRP.png";

export type AvailableCrypto = {
    name: string;
    displayName: string,
    icon?: string;
}

export const availableCrypto: AvailableCrypto[] = [
    {
        "displayName": "TRC20 USDT",
        "name": "USDT",
        icon: USDT
    },
    {
        "displayName": "BEP20 USDC",
        "name": "BNB-USDC",
        icon: USDC
    },
    {
        "displayName": "ERC20 USDC",
        "name": "ETH-USDC",
        icon: USDC
    },
    {
        "displayName": "ERC20 USDT",
        "name": "ETH-USDT",
        icon: USDT
    },
    {
        "displayName": "BEP20 USDT",
        "name": "BNB-USDT",
        icon: USDT
    },
    {
        "displayName": "TRC20 USDC",
        "name": "USDC",
        icon: USDC
    },
    {
        "displayName": "Bitcoin",
        "name": "BTC",
        icon: BTC
    },
    {
        "displayName": "Ethereum",
        "name": "ETH",
        icon: ETH
    },
    {
        "displayName": "Litecoin",
        "name": "LTC",
        icon: LTC
    },
    {
        "displayName": "BNB",
        "name": "BNB",
        icon: BNB
    },
    {
        "displayName": "XRP",
        "name": "XRP",
        icon: XRP
    },
    {
        "displayName": "Tron TRX",
        "name": "TRX",
        icon: TRX
    },
    {
        "displayName": "Dogecoin",
        "name": "DOGE",
        icon: DOGE
    },

]