import {Badge, Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {PushJobsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {Translation, useLocale} from "../../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../../utils/viewOwnerColl.tsx";
import useTeams from "../../Teams/Context/useTeams.ts";

const api = new PushJobsApiClient()

interface IItem {
	isDefault: boolean;
	_id: string;
}

export function ListPushJobs() {
	const navigate = useNavigate();
	const t = useLocale();
	const defaults = useDefaultsTableState();
	const {user} = useUser();
	const { activeTeam } = useTeams();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.pushJobs.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		const cols = [{
			accessorKey: '_id',
			header: t('push.jobs.list.table.id'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<div>
					{props.cell.getValue<string>()}
					{props.row.original.isDefault ? <><br/><Badge>Default</Badge></> : null}
				</div>
			)
		}, {
			accessorKey: 'enabled',
			header: t('push.jobs.list.table.enabled'),
			Cell: (props) => props.cell.getValue() ? (
				<Badge color={'green'}>
					<Translation id={'push.jobs.list.table.enabled.true'}/>
				</Badge>
			) : (
				<Badge color={'red'}>
					<Translation id={'push.jobs.list.table.enabled.false'}/>
				</Badge>
			),
		}, {
			accessorKey: 'runMode',
			header: t('push.jobs.list.table.runMode'),
			Cell: (props) => {
				const val = props.cell.getValue<string>();
				if (val === 'manual') return t('push.jobs.list.table.runMode.manual');
				if (val === 'schedule') return t('push.jobs.list.table.runMode.schedule');
				if (val === 'trigger') return t('push.jobs.list.table.runMode.trigger');
				return val || 'unknown';
			}
		}, {
			accessorKey: 'comment',
			header: t('push.jobs.list.table.comment'),
		}, {
			accessorKey: 'actions',
			header: t('push.jobs.list.table.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<>
					<Link href={`/v/${props.row.original._id}`}>
						<a>
							<Button size={'compact-sm'} variant="primary">
								<Translation id={'push.jobs.list.table.actions.view'}/>
							</Button>
						</a>

					</Link>

				</>
			)
		}] as MRT_ColumnDef<IItem>[];
		if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);
		return cols;
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	function tryCreate() {
		return api.createItem({ team: activeTeam }).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={t('push.jobs.list.title')}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate} variant="primary">
						<Translation id={'push.jobs.list.create'}/>
					</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
