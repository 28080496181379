import {LocaleKeys} from "../localeKeys.tsx";

const cfAccountsList = {
	'cfAccounts.list.title': 'Cloudflare Accounts',
	'cfAccounts.list.tableHeader._id': 'ID',
	'cfAccounts.list.tableHeader.name': 'Account Name',
	'cfAccounts.list.tableHeader.keyType': 'Key Type',
	'cfAccounts.list.tableHeader.keyType.TOKEN': 'Global API Key',
	'cfAccounts.list.tableHeader.keyType.KEY': 'API Tokens',

	'cfAccounts.list.tableHeader.actions': 'Actions',
	'cfAccounts.list.tableHeader.actions.view': 'View',
	'cfAccounts.list.create': 'Add Account',
	'cfAccounts.modal.title.create': 'Add Account',
	'cfAccounts.modal.label.name': 'Account Name',
	'cfAccounts.modal.label.keyType': 'Key Type',
	'cfAccounts.modal.label.keyType.token': 'Global API Key',
	'cfAccounts.modal.label.keyType.key': 'API Tokens',

	'cfAccounts.modal.label.apiToken': 'API Token',

	'cfAccounts.modal.label.apiKey': 'API Key',
	'cfAccounts.modal.label.apiEmail': 'API Email',

	'cfAccounts.modal.label.accountId': 'Account ID',
	'cfAccounts.modal.label.accountId.description': 'Where to find Cloudflare account ID?',
}

const cfAccountsEdit = {
	'cfAccounts.edit.title': 'Account Editor',
	'cfAccounts.edit.saveSuccess': 'Saved Successfully',
	'cfAccounts.edit.saveButton': 'Save',
	'cfAccounts.edit.removeButton': 'Delete',

	'cfAccounts.edit.deleteButton': 'Delete',

	'cfAccounts.edit.form.name': 'Account Name',
	'cfAccounts.edit.form.keyType': 'Key Type',
	'cfAccounts.edit.form.keyType.token': 'Global API Key',
	'cfAccounts.edit.form.keyType.key': 'API Tokens',

	'cfAccounts.edit.form.apiToken': 'API Token',

	'cfAccounts.edit.form.apiKey': 'API Key',
	'cfAccounts.edit.form.apiEmail': 'API Email',

	'cfAccounts.edit.form.accountId': 'Account ID',
}

const webAppDomainTab = {
	"webApps.view.DomainTab.title": "Domain",
	"webApps.view.DomainTab.subTitle": "A domain is required to run the PWA. You can purchase a domain through us or use your own",

	"webApps.view.DomainTab.buy.guide.1": "Buy a domain",
	"webApps.view.DomainTab.buy.guide.2": "Click the 'Buy a Domain' button, then enter the desired domain.",
	"webApps.view.DomainTab.buy.guide.3": "Wait for confirmation and click the 'Confirm' button.",
	"webApps.view.DomainTab.buy.guide.4": "Once the domain is added to your list of available domains, the domain purchase process will begin.",
	"webApps.view.DomainTab.buy.openModal": "Buy a new domain",
	"webApps.view.DomainTab.buy.modalTitle": "Buy a Domain",
	"webApps.view.DomainTab.buy.confirmButton": "Buy Domain",

	"webApps.view.DomainTab.buy.registrar": "Registrar: {{value}}",
	"webApps.view.DomainTab.buy.price": "Price:",
	"webApps.view.DomainTab.buy.split": "Allow this domain to be used on multiple PVAs (Split mode)",

	"webApps.view.DomainTab.link.guide.1": "Link a domain",
	"webApps.view.DomainTab.link.guide.2": "You can also link your own domain",
	"webApps.view.DomainTab.link.openModal": "Link Domain",

	"webApps.view.DomainTab.link.modalTitle": "Link Domain",
	"webApps.view.DomainTab.link.wizard.1.domain.label": "Domain you want to link",
	"webApps.view.DomainTab.link.wizard.1.allowSplit.label": "Allow this domain to be used on multiple PVAs (Split mode)",
	"webApps.view.DomainTab.link.wizard.1.continue": "Continue",
	"webApps.view.DomainTab.link.wizard.2.nsListTitle": "Add the following DNS records with your registrar",
	"webApps.view.DomainTab.link.wizard.2.nsListItem": "Record {{i}}",
	"webApps.view.DomainTab.link.wizard.2.done": "Done",

	"webApps.view.DomainTab.linkExists.title": "Choose an available domain",
	"webApps.view.DomainTab.linkExists.guide.1": "If you have unused domains, you can use them",
	"webApps.view.DomainTab.linkExists.openModal": "Choose a Domain",
	"webApps.view.DomainTab.linkExists.modalTitle": "Your Domains",

	"webApps.view.DomainTab.linkDomain.title": "Link Domain",
	"webApps.view.DomainTab.linkDomain.myDomains": "My Domains",
	"webApps.view.DomainTab.linkDomain.myDomains.list": "List of My Domains",

	"webApps.view.DomainTab.LinkedDomainInfo.state.OK": "Domain is ready to use",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE": "Awaiting NS record update",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE.title": "Add the following NS records",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE.item": "Record {{i}}",
	"webApps.view.DomainTab.LinkedDomainInfo.state.BUY_IN_PROGRESS": "Domain purchase in progress",
	"webApps.view.DomainTab.LinkedDomainInfo.state.BUY_IN_PROGRESS.info": "Purchasing domain, please wait...",
	"webApps.view.DomainTab.LinkedDomainInfo.state.UNKNOWN": "Unknown",
	"webApps.view.DomainTab.LinkedDomainInfo.state.ERROR": "Error",
	"webApps.view.DomainTab.LinkedDomainInfo.state.IN_PROGRESS": "Purchase in progress",
	"webApps.view.DomainTab.LinkedDomainInfo.state.DEFAULT": "Purchased",

	"webApps.view.DomainTab.LinkedDomainInfo.state.WAIT_ACTIVATION": "Waiting for domain activation",
	"webApps.view.DomainTab.LinkedDomainInfo.state.WAIT_ACTIVATION.info": "Domain is already purchased, waiting for all DNS records to be updated",

	"webApps.view.DomainTab.LinkedDomainInfo.addARecord.title": "Add A Record",
	"webApps.view.DomainTab.LinkedDomainInfo.addARecord.description": "Please add an A record for the domain to IP: ",
	"webApps.view.DomainTab.LinkedDomainInfo.autoUpdate": "Auto-update",
	"webApps.view.DomainTab.LinkedDomainInfo.goToDomainPage.label": "Go to Domain Management",
	"webApps.view.DomainTab.LinkedDomainInfo.buyJob.label": "Purchase Operation Details",

};


const webAppAnalyticsTab = {
	"webApps.view.AnalyticsTab.keitaro.title": "Keitaro Integration",
	"webApps.view.AnalyticsTab.keitaro.description": "In this section, you can configure the integration with Keitaro. Enable the option to activate this integration and fill in the necessary fields to set the API URL and key.",
	"webApps.view.AnalyticsTab.keitaro.enabled.label": "Enabled",
	"webApps.view.AnalyticsTab.keitaro.enabled.description": "Integration is disabled",
	"webApps.view.AnalyticsTab.keitaro.apiUrl.label": "Keitaro domain",
	"webApps.view.AnalyticsTab.keitaro.apiKey.label": "Keitaro API key",

	"webApps.view.AnalyticsTab.gTag.title": "Google Analytics",
	"webApps.view.AnalyticsTab.gTag.description": "This section allows you to configure the integration with Google Analytics. Enable the option to track events via Google Tag and fill in the necessary data such as the ID and name of the installation event.",
	"webApps.view.AnalyticsTab.gTag.enabled": "Enabled",
	"webApps.view.AnalyticsTab.gTag.gTagId": "Google Tag ID",
	"webApps.view.AnalyticsTab.gTag.installEventName": "Installation event name",

	"webApps.view.AnalyticsTab.clarity.title": "Microsoft Clarity",
	"webApps.view.AnalyticsTab.clarity.description": "This section allows you to configure the integration with Microsoft Clarity. Enable the option to activate the integration and provide the ID.",
	"webApps.view.AnalyticsTab.clarity.enabled": "Enabled",
	"webApps.view.AnalyticsTab.clarity.tagId": "Tag ID",
	"webApps.view.AnalyticsTab.clarity.findInfo": "Where to find the ID?",
	"webApps.view.AnalyticsTab.clarity.findInfo.description": "In the code provided by Claply, find the section highlighted in red",

	"webApps.view.AnalyticsTab.googleTracking.title": "Google Lead Tracking",
	"webApps.view.AnalyticsTab.googleTracking.description": "This section allows you to configure integration with Google Ads by passing leads via a link to a table",
	"webApps.view.AnalyticsTab.googleTracking.enabled": "Enabled",
	"webApps.view.AnalyticsTab.googleTracking.entry.label": "Select the link where leads for this app will be available",

	"webApps.view.AnalyticsTab.facebook.title": "Facebook",
	"webApps.view.AnalyticsTab.facebook.description": "In this section, you can configure the integration with Facebook. Enable the option to work with the Facebook pixel and fill in the access token and pixel ID fields.",
	"webApps.view.AnalyticsTab.facebook.enabled": "Enabled",
	"webApps.view.AnalyticsTab.facebook.mainPixel": "Main Pixel",
	"webApps.view.AnalyticsTab.facebook.mainPixel.label": "Select Pixel",
	"webApps.view.AnalyticsTab.facebook.requireFBP.label": "Require FBP before proceeding to the installer",
	"webApps.view.AnalyticsTab.facebook.requireFBP.description": "If enabled, the FBP and FBC parameters will be retrieved before proceeding to the installer; otherwise, they will be retrieved on the installer page",

	"webApps.view.AnalyticsTab.facebook.additionalPixels.title": "Additional Pixels",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.label": "Select Pixel",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.add.modalTitle": "Add Pixel",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.add.buttonLabel": "Add Additional Pixel",

	"webApps.view.AnalyticsTab.onInstallWebHook.title": "Install Webhook",
	"webApps.view.AnalyticsTab.onInstallWebHook.description": "This section allows you to configure a webhook for processing installation events. Enable the option to activate the webhook and provide the URL and request method.",
	"webApps.view.AnalyticsTab.onInstallWebHook.enabled": "Enabled",
	"webApps.view.AnalyticsTab.onInstallWebHook.url": "Webhook URL",
	"webApps.view.AnalyticsTab.onInstallWebHook.method": "Request Method",

	"webApps.view.AnalyticsTab.remoteEventsMap.title": "Event Tracking",
	"webApps.view.AnalyticsTab.remoteEventsMap.description": "You can send us events that we will store and forward to the external services you connect",
	"webApps.view.AnalyticsTab.remoteEventsMap.input.label": "Send requests here",

	"webApps.view.AnalyticsTab.remoteEventsMap.alert.title": "Please note",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replace": "Replace ",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replaceIdentifier": "The user identifier you received in the field",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replaceForValues": "(listed below) with the required values.",

	"webApps.view.AnalyticsTab.remoteEventsMap.alert.info.1": "You can also pass the parameter in this link",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.info.2": "to use a different pixel",
	"webApps.view.AnalyticsTab.remoteEventsMap.mapping.title": "EventName field mapping",
	"webApps.view.AnalyticsTab.remoteEventsMap.onRegistration": "Possible values for user registration event",
	"webApps.view.AnalyticsTab.remoteEventsMap.onDeposit": "Possible values for user deposit event"
}


const listWebApps = {
	"webApps.list.title": "WebApps",
	"webApps.list.create": "New Record",
	"webApps.list.table.identifier": "Identifier",
	"webApps.list.table.name": "Name",
	"webApps.list.table.domain": "Domain",
	"webApps.list.table.locales": "Languages",
	"webApps.list.table.domain.NONE": "None",
	"webApps.list.table.status": "Publication Status",
	"webApps.list.table.status.PUBLISHED": "Published",
	"webApps.list.table.status.DRAFT": "Draft",
	"webApps.list.table.status.DEFAULT": "Unpublished",
	"webApps.list.table.statistics": "Visit Statistics for 24 Hours",
	"webApps.list.table.statistics.uniq": "Unique",
	"webApps.list.table.statistics.installs": "Installations",
	"webApps.list.table.actions": "Actions",
	"webApps.list.table.actions.edit": "Edit",
	"webApps.list.table.actions.clone": "Clone",
	"webApps.list.create.title": "New PWA",
	"webApps.list.create.name": "PWA Name",
	"webApps.list.clone.title": "Clone PWA",
	"webApps.list.clone.name": "PWA Name"
};

const webAppPublishTab = {
	"webApps.view.PublishTab.title": "PWA Publishing",

	"webApps.view.PublishTab.platform.splitWarning.title": "This PWA is used with a split domain, platform management is available in domain settings",
	"webApps.view.PublishTab.platform.splitWarning.link": "Go to domain settings",


	"webApps.view.PublishTab.platform.title": "Hosting Platform",
	"webApps.view.PublishTab.platform.pwaCloud.title": "PWA Cloud",
	"webApps.view.PublishTab.platform.pwaCloud.description": "Abuse-resistant distributed platform",
	"webApps.view.PublishTab.platform.globalEdge.title": "Global Edge",
	"webApps.view.PublishTab.platform.globalEdge.description": "Scalable platform for high-load projects",
	"webApps.view.PublishTab.platform.currentLabel": "Current Platform",
	"webApps.view.PublishTab.platform.button.active": "Already selected",
	"webApps.view.PublishTab.platform.button.inactive": "Select",

	"webApps.view.PublishTab.publish.title": "Publish My PWA",
	"webApps.view.PublishTab.publish.checklist.domain": "Domain",
	"webApps.view.PublishTab.publish.checklist.content": "Content Filling",
	"webApps.view.PublishTab.publish.checklist.analytics": "Analytics Setup",
	"webApps.view.PublishTab.publish.checklist.links": "User Flow Management",

	"webApps.view.PublishTab.publish.details.domain": "Domain",
	"webApps.view.PublishTab.publish.details.content": "Content Filling",
	"webApps.view.PublishTab.publish.details.analytics": "Analytics Setup",
	"webApps.view.PublishTab.publish.details.links": "User Flow Management",

	"webApps.view.PublishTab.publish.details.allOk": "Everything is set up correctly",
	"webApps.view.PublishTab.publish.details.warning": "There are non-critical errors; fix them if possible",
	"webApps.view.PublishTab.publish.details.error": "There are critical errors; fix them",
	"webApps.view.PublishTab.publish.details.title": "Errors or Warnings",
	"webApps.view.PublishTab.publish.details.seeLang": "Check {{lang}}",

	// errors
	"webApps.view.PublishTab.publish.details.DOMAIN_NOT_FOUND": "Specify a domain",
	"webApps.view.PublishTab.publish.details.WRONG_DOMAIN_STATE": "This domain cannot be used",
	"webApps.view.PublishTab.publish.details.DOMAIN_NOT_READY": "The domain is not ready",
	"webApps.view.PublishTab.publish.details.NO_LANGUAGES": "Specify at least one language",
	"webApps.view.PublishTab.publish.details.NO_DEFAULT_LANGUAGE": "No default language selected",
	"webApps.view.PublishTab.publish.details.NO_ICON": "No icon uploaded",
	"webApps.view.PublishTab.publish.details.NO_SCREEN": "No screenshots uploaded",
	"webApps.view.PublishTab.publish.details.TOO_MANY_SCREEN": "Too many screenshots uploaded",
	"webApps.view.PublishTab.publish.details.NOT_ENOUGH_SCREEN": "Too few screenshots uploaded",
	"webApps.view.PublishTab.publish.details.NO_SCREEN_IMAGE": "Screenshot upload error",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_NAME": "Specify the review author's name",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_AVATAR": "Upload or select an avatar for the review",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_RATING": "Review rating not specified",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_TEXT": "Review text not specified",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_DATE": "Review publication date not specified",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_OWNER_RESPONSE": "No response to the review specified",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_OWNER_RESPONSE_DATE": "Response date not specified",
	"webApps.view.PublishTab.publish.details.CONTENT_RENDER_ERROR": "Unknown error",
	"webApps.view.PublishTab.publish.details.NO_GTAG_ID": "Specify Google Analytics ID",
	"webApps.view.PublishTab.publish.details.NO_GTAG_EVENT_NAME": "Specify Google Analytics installation event",
	"webApps.view.PublishTab.publish.details.NO_CLARITY_ID": "Specify Microsoft Clarity ID",
	"webApps.view.PublishTab.publish.details.NO_GOOGLE_TRACKING_ENTRY": "Specify a domain for Google Ads integration",
	"webApps.view.PublishTab.publish.details.NO_FACEBOOK_AUTH_DATA": "Specify Facebook Pixel",
	"webApps.view.PublishTab.publish.details.NO_ON_INSTALL_WEBHOOK_URL": "Specify a valid webhook URL",
	"webApps.view.PublishTab.publish.details.NO_REMOTE_EVENT_ON_REGISTRATION": "Installation event names not specified",
	"webApps.view.PublishTab.publish.details.NO_REMOTE_EVENT_ON_DEPOSIT": "Deposit event names not specified",
	"webApps.view.PublishTab.publish.details.EMPTY_PRIMARY_OFFER_LINK": "Primary offer link not specified",
	"webApps.view.PublishTab.publish.details.NO_CONDITION_VALUES": "Condition values not specified",
	"webApps.view.PublishTab.publish.details.NO_THRESHOLD_VALUE": "Threshold value not specified",
	"webApps.view.PublishTab.publish.details.EMPTY_ALTERNATIVE_OFFER_LINK": "One of the alternative offer links is missing",
	"webApps.view.PublishTab.publish.details.EMPTY_REDIRECT_TARGET_URL": "Redirect target link not specified",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_REDIRECT_URL": "Cloaking redirect link not specified",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_WHITE_PAGE": "Cloaking white page not specified",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_COUNTRY_LIST": "Allowed countries list is empty",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_OS_LIST": "Allowed operating systems list is empty",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_DEVICE_TYPE_LIST": "Allowed device types list is empty",

	"webApps.view.PublishTab.publish.details.NO_NAME": "Specify the app name",
	"webApps.view.PublishTab.publish.details.NO_OWNER": "Specify the app owner",
	"webApps.view.PublishTab.publish.details.NO_RATING": "Specify the app rating",
	"webApps.view.PublishTab.publish.details.NO_RATINGS_COUNT": "Specify the number of ratings",
	"webApps.view.PublishTab.publish.details.NO_DOWNLOADS_COUNT": "Specify the number of downloads",
	"webApps.view.PublishTab.publish.details.NO_DESCRIPTION": "Provide the app description",
	"webApps.view.PublishTab.publish.details.NO_UPDATED_DATE": "Specify the last update date",
	"webApps.view.PublishTab.publish.details.NO_TAGS": "Specify app tags",
	"webApps.view.PublishTab.publish.details.NO_REVIEWS": "Add reviews",

	"webApps.view.PublishTab.publish.description.title": "Publishing",
	"webApps.view.PublishTab.publish.description.text": "In this section, you can check whether your PWA is ready for publishing. If there are critical errors, publishing will not be possible. Click on the corresponding menu item to see what's wrong with your app.",
	"webApps.view.PublishTab.publish.button": "Publish",
	"webApps.view.PublishTab.publish.quickUnPublishConfirm": "Do you really want to hide this app?",
	"webApps.view.PublishTab.publish.quickPublishConfirm": "Do you really want to publish this app?",

	"webApps.view.PublishTab.unpublish.title": "Hide App",
	"webApps.view.PublishTab.unpublish.description": "Switch the app to draft status if you want to hide it",

	"webApps.view.PublishTab.unpublish.button": "Hide application",
	"webApps.view.PublishTab.unpublish.": "",
	"webApps.view.PublishTab.unpublish.inProgress.description": "Please wait...",
	"webApps.view.PublishTab.unpublish.inProgress.title": "Hiding application",
	"webApps.view.PublishTab.unpublish.waitTask.errorMessage": "Contact the administrator",
	"webApps.view.PublishTab.unpublish.waitTask.errorTitle": "Error while hiding the application",
	"webApps.view.PublishTab.unpublish.waitTask.successTitle": "Application hidden",
	"webApps.view.PublishTab.unpublish.waitTask.successMessage": "The application has been hidden",
	"webApps.view.PublishTab.publish.": "",
	"webApps.view.PublishTab.publish.inProgress.title": "Publishing application",
	"webApps.view.PublishTab.publish.inProgress.description": "Please wait...",
	"webApps.view.PublishTab.publish.waitTask.errorMessage": "Contact the administrator",
	"webApps.view.PublishTab.publish.waitTask.errorTitle": "Error while publishing the application",
	"webApps.view.PublishTab.publish.waitTask.successMessage": "The application has been published",
	"webApps.view.PublishTab.publish.waitTask.successTitle": "Application published",
	"webApps.view.PublishTab.platform.": "",
	"webApps.view.PublishTab.platform.inProgress.description": "Please wait...",
	"webApps.view.PublishTab.platform.inProgress.title": "Changing deployment method",
	"webApps.view.PublishTab.platform.waitTask.errorMessage": "Contact the administrator",
	"webApps.view.PublishTab.platform.waitTask.errorTitle": "Error while changing deployment method",
	"webApps.view.PublishTab.platform.waitTask.successMessage": "The application has been relocated",
	"webApps.view.PublishTab.platform.waitTask.successTitle": "Application relocated"

};

const uptimeChecker = {
	"domainsPage.view.status.title": "Domain Status",

	"domainsPage.list.table.uptime.ok": "Domain is operational",
	"domainsPage.list.table.uptime.warning": "Domain is partially operational",
	"domainsPage.list.table.uptime.error": "Check error",
	"domainsPage.view.uptime.title.ok": "Domain is operational",
	"domainsPage.view.uptime.desc.ok": "Domain is operational",
	"domainsPage.view.uptime.title.warning": "Domain is partially operational",
	"domainsPage.view.uptime.desc.warning": "Domain is working, but the application was not found",
	"domainsPage.view.uptime.title.error": "Check error",
	"domainsPage.view.uptime.desc.error": "Domain is not operational",
}

const domainsPageLIst = {
	"domainsPage.list.title": "Domains",
	"domainsPage.list.create.label": "Add Domain",
	"domainsPage.list.create.link": "Link Domain",
	"domainsPage.list.create.purchase": "Purchase Domain",

	"domainsPage.list.table.identifier": "Identifier",
	"domainsPage.list.table.domainName": "Domain",
	"domainsPage.list.table.registrar": "Registrar",
	"domainsPage.list.table.state": "State",
	"domainsPage.list.table.actions": "Actions",
	"domainsPage.list.table.actions.edit": "Edit",
	"domainsPage.list.table.state.ADDED": "Added",
	"domainsPage.list.table.state.WAIT": "Pending Purchase",
	"domainsPage.list.table.state.IN_PROGRESS": "In Progress of Purchase",
	"domainsPage.list.table.state.DONE": "Purchased",
	"domainsPage.list.table.state.ERROR": "Purchase Error",
	"domainsPage.list.table.state.UNKNOWN": "Unknown State",

	"domainsPage.list.buy.label": "Buy",
	"domainsPage.list.link.label": "Add",
	"domainsPage.list.buy.modal.title": "Domain Purchase",
	"domainsPage.list.buy.modal.buy": "Buy",
	"domainsPage.list.link.modal.title": "Domain Addition",

}

const domainsPageView = {
	"domainsPage.view.title": "Editor",
	"domainsPage.view.action.delete": "Delete",
	"domainsPage.view.form.title": "General settings",
	"domainsPage.view.form.domain": "Domain",
	"domainsPage.view.form.comment": "Comment",
	"domainsPage.view.status.WAIT": "System is waiting for the worker to take the task",
	"domainsPage.view.status.IN_PROGRESS": "Task is currently in progress. Current action:",
	"domainsPage.view.status.DONE": "Task fully completed",
	"domainsPage.view.status.ERROR": "An error occurred while performing the task:",
	"domainsPage.view.status.link.label": "Go to the purchase task of this domain",

	"domainsPage.view.form.allowSplit": "Allow this domain to be used by multiple PWAs",
	"domainsPage.view.form.split.target": "Where PWAs will be hosted",
	"domainsPage.view.form.split.title": "Weight Configuration",
	"domainsPage.view.form.split.weight": "Weight of this PWA",
	"domainsPage.view.form.split.reroll": "Reset weight key",
	"domainsPage.view.form.split.rerollSuccess": "Weight key reset, don't forget to save",

}

const mainPage = {
	"pages.mainPage.title": "Main Page",
	"pages.mainPage.teamMembers.all": "All Users",
	"pages.mainPage.pwa.all": "All Applications",
	"pages.mainPage.generalBadges.visits": "Visits",
	"pages.mainPage.generalBadges.uniqVisits": "Unique Visits",
	"pages.mainPage.generalBadges.installs": "Installs",
	"pages.mainPage.generalBadges.registrations": "Registrations",
	"pages.mainPage.generalBadges.deposits": "Deposits",
	"pages.mainPage.cta.register": "C2R",
	"pages.mainPage.cta.install": "C2I",
	"pages.mainPage.cta.deposit": "C2D",

	"pages.mainPage.county": "Countries",
	"pages.mainPage.county.map": "Map",
	"pages.mainPage.county.table": "Table",

	"pages.mainPage.apps": "PWA",
	"pages.mainPage.sources": "Sources",
	"pages.mainPage.devices": "Devices",
	"pages.mainPage.devices.browser": "Browsers",
	"pages.mainPage.devices.os": "Operating Systems",
	"pages.mainPage.pushStats": "Push Notifications",
}

const pushStats = {
	'pages.pushLogs.pushStats.sent': 'Sent',
	'pages.pushLogs.pushStats.open': 'Opened',
	'pages.pushLogs.pushStats.delivered': 'Delivered',
	'pages.pushLogs.pushStats.failed': 'Failed',
	'pages.pushLogs.pushStats.title': 'Statistics',
}

const webAppsTrackerTab = {
	"webApps.view.TrackerTab.title": "Offer",
	"webApps.view.TrackerTab.description.1": "Enter the link to the offer where PWA users will be redirected after installing and launching the app.",
	"webApps.view.TrackerTab.description.2": "Use the link without query parameters, as they will be added automatically based on the parameters that led the user to the PWA installation screen.",

	"webApps.view.TrackerTab.primaryLink.title": "Primary Link",
	"webApps.view.TrackerTab.primaryLink.label": "Offer Link",

	"webApps.view.TrackerTab.primaryLink.type.label": "Link Type",
	"webApps.view.TrackerTab.primaryLink.type.static": "Static Link",
	"webApps.view.TrackerTab.primaryLink.type.dynamic": "Dynamic Link (connected to keitaro)",
	"webApps.view.TrackerTab.primaryLink.kDomain": "Keitaro Domain (https://)",
	"webApps.view.TrackerTab.primaryLink.token": "Company Token",
	"webApps.view.TrackerTab.primaryLink.kFallback.label": "Fallback Link",
	"webApps.view.TrackerTab.primaryLink.kFallback.description": "If the system fails to connect to your keitaro, the following link will be used.",

	"webApps.view.TrackerTab.rules.title": "Rules",
	"webApps.view.TrackerTab.rules.desc.1": "You can add several rules that will override the primary link.",
	"webApps.view.TrackerTab.rules.desc.2": "If no conditions match, the primary link will be used.",
	"webApps.view.TrackerTab.rules.enabled": "Is the option active?",
	"webApps.view.TrackerTab.rules.comment.label": "Option Comment",
	"webApps.view.TrackerTab.rules.comment.description": "For your convenience",
	"webApps.view.TrackerTab.rules.conditions.title": "Trigger Conditions",
	"webApps.view.TrackerTab.rules.conditions.button.add": "Add Condition",
	"webApps.view.TrackerTab.rules.offerLink.title": "Link",
	"webApps.view.TrackerTab.rules.offerLink.label": "Redirect Link",
	"webApps.view.TrackerTab.rules.offerLink.button.add": "Add Link",

	"webApps.view.TrackerTab.rules.offerLink.type.label": "Link Type",
	"webApps.view.TrackerTab.rules.offerLink.type.static": "Static Link",
	"webApps.view.TrackerTab.rules.offerLink.type.dynamic": "Dynamic Link (connected to keitaro)",
	"webApps.view.TrackerTab.rules.offerLink.kDomain": "Keitaro Domain (https://)",
	"webApps.view.TrackerTab.rules.offerLink.token": "Company Token",
	"webApps.view.TrackerTab.rules.offerLink.kFallback.label": "Fallback Link",
	"webApps.view.TrackerTab.rules.offerLink.kFallback.description": "If the system fails to connect to your keitaro, the following link will be used.",

	"webApps.view.TrackerTab.resetClientCache.title": "Reset Link Cache",
	"webApps.view.TrackerTab.resetClientCache.description": "If needed, you can reset the link cache to refresh them.",
	"webApps.view.TrackerTab.resetClientCache.button": "Reset",
	"webApps.view.TrackerTab.resetClientCache.success": "Link cache has been reset. Don't forget to save changes!",
}


const profilePage = {
	'pages.profile.title': 'Profile',
	'pages.profile.avatar': 'Avatar',
	'pages.profile.changePassword': 'Change password',
	'pages.profile.changePassword.success': 'Password successfully changed',
	'pages.profile.changePassword.title': 'Change password',
	'pages.profile.changePassword.error.title': 'Password change error',
	'pages.profile.changePassword.error.INVALID_PASSWORD': 'Incorrect old password',
	'pages.profile.changePassword.oldPassword': 'Old password',
	'pages.profile.changePassword.oldPassword.placeholder': 'Enter old password',
	'pages.profile.changePassword.newPassword': 'New password',
	'pages.profile.changePassword.newPassword.placeholder': 'Enter new password',
	'pages.profile.changePassword.change': 'Change',

	'pages.profile.tgConnect.success': 'Telegram successfully connected! ({{tgId}})',
	'pages.profile.tgConnect.description': 'Connect Telegram: click the button below',
	'pages.profile.tgConnect.button': 'Connect Telegram',
	'pages.profile.tgConnect.button.disconnect': 'Disconnect Telegram',

	"webApps.views.DescriptionTab.content.trailer.title": "Trailer",
	"webApps.views.DescriptionTab.content.trailer.enabled": "Enable trailer",
	"webApps.views.DescriptionTab.content.trailer.url.label": "Trailer link",
	"webApps.views.DescriptionTab.content.trailer.url.decs": "YouTube and Facebook links are supported",
}

const descriptionTab = {
	"webApps.views.DescriptionTab.name.label": "Application Name",
	"webApps.views.DescriptionTab.content.isVerify.label": "Application Verified",
	"webApps.views.DescriptionTab.content.isVerify.description": "When this option is enabled, a verified application badge appears next to the application's name",
	"webApps.views.DescriptionTab.content.owner.label": "Application Developer",
	"webApps.views.DescriptionTab.content.description.label": "Application Description",
	"webApps.views.DescriptionTab.content.updatedDate.label": "Last Application Update Date",
	"webApps.views.DescriptionTab.content.": "",
	"webApps.views.DescriptionTab.content.relatedApps.app.rating": "Application Rating",
	"webApps.views.DescriptionTab.content.relatedApps.app.url": "Application Link",
	"webApps.views.DescriptionTab.content.relatedApps.app.name": "Application Name",
	"webApps.views.DescriptionTab.content.relatedApps.position.bottom": "Bottom",
	"webApps.views.DescriptionTab.content.relatedApps.position.top": "Top",
	"webApps.views.DescriptionTab.content.relatedApps.position": "Block Position",
	"webApps.views.DescriptionTab.content.relatedApps.enabled": "Is Block Active?",
	"webApps.views.DescriptionTab.content.relatedApps.title": "Related Applications",
}

const webAppsTemplateEdit = {
	"webAppsTemplateEdit.playMarket.name": "Name",
	"webAppsTemplateEdit.playMarket.isVerify": "App is verified",
	"webAppsTemplateEdit.playMarket.owner": "App developer",
	"webAppsTemplateEdit.playMarket.rating": "Rating",
	"webAppsTemplateEdit.playMarket.screens": "App screens",
	"webAppsTemplateEdit.playMarket.description": "App description",
	"webAppsTemplateEdit.playMarket.icon": "App icon",
	"webAppsTemplateEdit.playMarket.updatedDate": "Last update date",
	"webAppsTemplateEdit.playMarket.tags": "App tags",

	"webAppsTemplateEdit.rouletteBalls.icon": "PWA icon",
	"webAppsTemplateEdit.rouletteBalls.playButtonText": "Start game button",
	"webAppsTemplateEdit.rouletteBalls.sign": "Currency sign ($)",
	"webAppsTemplateEdit.rouletteBalls.mainHowToPlay": "Title",
	"webAppsTemplateEdit.rouletteBalls.mainRules": "Subtitle",
	"webAppsTemplateEdit.rouletteBalls.mainBetSize": "Line 1",
	"webAppsTemplateEdit.rouletteBalls.mainLevel": "Line 2",
	"webAppsTemplateEdit.rouletteBalls.mainWin": "Line 3",
	"webAppsTemplateEdit.rouletteBalls.popup.group": "Popup window",
	"webAppsTemplateEdit.rouletteBalls.popup.title": "Title",
	"webAppsTemplateEdit.rouletteBalls.popup.winValue": "Reward",
	"webAppsTemplateEdit.rouletteBalls.popup.register": "Text",
	"webAppsTemplateEdit.rouletteBalls.popup.popupButtonText": "Claim reward button",
	"webAppsTemplateEdit.rouletteBalls.collisionReward": "Reward",
	"webAppsTemplateEdit.rouletteBalls.popupTriggerValue": "Show popup if value reached",
}

const pushStatsPage = {
	'pages.push.stats.menuItem': 'Push Notifications Statistics',
	'pages.push.stats.title': 'Push Notifications Statistics',

	"pages.push.stats.teamMembers.all": "All Users",
	"pages.push.stats.pwa.all": "All Applications",
	'pages.push.stats.top.sent.title': 'Top Sent',
	'pages.push.stats.top.opened.title': 'Top Opened',
	'pages.push.stats.top.notOpened.title': 'Top Not Opened',
	'pages.push.stats.top.unsubsribed.title': 'Top Unsubscribed',
};



export const locale: Record<LocaleKeys, string> = {
	...pushStatsPage,
	...profilePage,
	...cfAccountsEdit,
	...cfAccountsList,
	...webAppDomainTab,
	...listWebApps,
	...webAppAnalyticsTab,
	...webAppPublishTab,
	...uptimeChecker,
	...domainsPageLIst,
	...domainsPageView,
	...mainPage,
	...pushStats,
	...webAppsTrackerTab,
	...descriptionTab,
	...webAppsTemplateEdit,

	// yoFormModal
	'yoFormModal.continue': 'Continue',
	// Auth page
	'auth.title': 'Welcome back...',
	'auth.authError.title': 'Authorization Error',
	'auth.authError.message': 'Incorrect login or password',
	'auth.login': 'Login',
	// Menu elements
	'menuItems.mainPage': 'App',
	'menuItems.webApps': 'My PWAs',
	'menuItems.domains': 'My Domains',
	'menuItems.leads': 'Leads',
	'menuItems.fbPixels': 'My Pixels',
	'menuItems.googleTrackingEntry': 'Links for google ads',
	'menuItems.system': 'System',
	'menuItems.system.adminUsers': 'Panel Users',
	'menuItems.system.jobs': 'Jobs',
	'menuItems.system.adspect': 'adspect settings',
	'menuItems.system.ua': 'User agents of bots',
	'menuItems.system.ip': 'IP blacklist',
	'menuItems.system.deeplSettings': 'DeepL Settings',
	'menuItems.system.admSettings': 'admTools Registrar Settings',
	'menuItems.system.godaddySettings': 'GoDaddy Registrar Settings',
	'menuItems.system.cloudflareSettings': 'Cloudflare Settings',
	'menuItems.system.nameCheapSettings': 'NameCheap Settings',
	'menuItems.system.porkBunSettings': 'PorkBun Settings',
	"menuItems.system.openaiSettings": "OpenAI Settings",
	'menuItems.system.exchangeRateSettings': 'ExchangeRate Settings',
	'menuItems.system.domainRegistrarsSettings': 'Domain Registrars Settings',

	'menuItems.system.cfAccounts': 'Cloudflare Accounts',
	// jobs page
	'jobs.customConfirmPrompt': 'Are you sure you want to retry this job?',
	'jobs.confirmRunningJobDelete': 'The job is currently running, and deleting it is not recommended. Continue?',
	'jobs.view.title': 'Job View',
	'jobs.view.deleteButton': 'Delete',
	'jobs.view.retryButton': 'Retry Job',
	'jobs.view.jobTypeCaption': 'Job Type',
	'jobs.view.jobTypeCaption.buyDomain': 'Job Type: Buy Domain',
	'jobs.view.state.wait.title': 'State: Waiting',
	'jobs.view.state.wait.description': 'The system is waiting for a worker to take the job',
	'jobs.view.state.inProgress.title': 'State: In Progress',
	'jobs.view.state.inProgress.description': 'The job is currently in progress. Current action: ',
	'jobs.view.state.done.title': 'State: Done',
	'jobs.view.state.done.description': 'The job has been fully completed',
	'jobs.view.state.error.title': 'State: Error',
	'jobs.view.state.error.description': 'An error occurred while executing the job: ',
	'jobs.view.logsButton': 'Job Logs',
	'jobs.view.logsModalTitle': 'Job Logs',

	'fbPixelsLogs.list.title': 'Pixel Logs',
	'fbPixelsLogs.list.createButton': 'New Task',
	'fbPixelsLogs.list.tableHeader._id': 'Identifier',
	'fbPixelsLogs.list.tableHeader.createdAt': 'Request Date',
	'fbPixelsLogs.list.tableHeader.lead': 'Lead',
	'fbPixelsLogs.list.tableHeader.lead.empty': 'Lead Missing',
	'fbPixelsLogs.list.tableHeader.event': 'Request Date',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully': 'Request Sent Successfully',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully.true': 'Yes',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully.false': 'No',
	'fbPixelsLogs.list.tableHeader.actions': 'Actions',
	'fbPixelsLogs.list.tableHeader.actions.edit': 'Edit',

	'fbPixelsLogs.view.title': 'View Pixel Log',
	'fbPixelsLogs.view.action.delete': 'Delete',
	'fbPixelsLogs.view.fields.event': 'Event Type',
	'fbPixelsLogs.view.fields.sentSuccessfully': 'Event Sent Successfully',
	'fbPixelsLogs.view.fields.fbTraceId': 'Facebook Trace ID',
	'fbPixelsLogs.view.fields.lead.empty': 'Lead Missing',
	'fbPixelsLogs.view.fields.goToLead': 'Go to Lead',

	'fbPixelsLogs.view.fields.sentUnsuccessfully': 'Event Not Sent',
	'fbPixelsLogs.view.fields.fbErrMessage': 'Error Message',
	'fbPixelsLogs.view.fields.fbErrCode': 'Error Code',
	'fbPixelsLogs.view.fields.fbErrSubCode': 'Error Subcode',
	'fbPixelsLogs.view.fields.fbErrType': 'Error Type',


	'jobs.list.title': 'Task List',
	'jobs.list.createJobButton': 'New Task',
	'jobs.list.createJobTitle': 'New Task',
	'jobs.list.tableHeader._id': 'Identifier',
	'jobs.list.tableHeader.type': 'Task Type',
	'jobs.list.tableHeader.state': 'Status',
	'jobs.list.tableHeader.state.WAIT': 'Pending',
	'jobs.list.tableHeader.state.IN_PROGRESS': 'In Progress',
	'jobs.list.tableHeader.state.DONE': 'Completed',
	'jobs.list.tableHeader.state.ERROR': 'Error',
	'jobs.list.tableHeader.state.UNKNOWN': 'Unknown',
	'jobs.list.tableHeader.createdAt': 'Task Creation Date',
	'jobs.list.tableHeader.actions': 'Actions',
	'jobs.list.tableHeader.actions.edit': 'Edit',

	// general messages
	'settings.general.savedMessage': 'Successfully saved',
	'settings.general.saveBth': 'Save',

	// adm settings
	'settings.adm.title': 'AdmTools Domain Registrar Settings',
	'settings.adm.apiKey': 'Access Token',
	'settings.adm.limitDomainPriceUAN': 'Maximum Domain Price (UAH)',
	// godaddy settings
	'settings.godaddy.title': 'GoDaddy Domain Registrar Settings',
	'settings.godaddy.apiKey': 'Api key',
	'settings.godaddy.secret': 'secret',

	// nameCheap settings
	'settings.nameCheap.title': 'NameCheap Domain Registrar Settings',
	'settings.nameCheap.apiKey': 'Api key',
	'settings.nameCheap.baseUrl': 'baseUrl',
	'settings.nameCheap.apiUser': 'apiUser',
	'settings.nameCheap.clientIP': 'clientIP',

	// porkBun settings
	'settings.porkBun.title': 'PorkBun Domain Registrar Settings',
	'settings.porkBun.apiKey': 'Api key',
	'settings.porkBun.secret': 'secret',

	// exchangeRate settings
	'settings.exchangeRate.title': 'ExchangeRate Settings',
	'settings.exchangeRate.apiKey': 'Api key',

	'leads.list.title': 'Leads',
	'leads.list.onlyUniqs': 'Show only unique leads',
	'leads.list.tableHeader._id': 'Identifier',
	'leads.list.tableHeader.clientId': 'Client session identifier',
	'leads.list.tableHeader.clientId.log': 'Log of this session',
	'leads.list.tableHeader.runId': 'Client session ID',
	'leads.list.tableHeader.runId.log': 'Log of this session',
	'leads.list.tableHeader.clientIp': 'IP address',
	'leads.list.tableHeader.browserName': 'Browser',
	'leads.list.tableHeader.osName': 'Operating system',
	'leads.list.tableHeader.deviceType': 'Device type',
	'leads.list.tableHeader.tryAppInstall': 'Attempt to install?',
	'leads.list.tableHeader.tryAppInstall.true': 'Yes',
	'leads.list.tableHeader.tryAppInstall.false': 'No',
	'leads.list.tableHeader.appInstalled': 'Successfully installed?',
	'leads.list.tableHeader.appInstalled.true': 'Yes',
	'leads.list.tableHeader.appInstalled.false': 'No',
	'leads.list.tableHeader.userRegistered': 'User registered?',
	'leads.list.tableHeader.userRegistered.true': 'Yes',
	'leads.list.tableHeader.userRegistered.false': 'No',
	'leads.list.tableHeader.userDeposited': 'Deposit made',
	'leads.list.tableHeader.userDeposited.true': 'Yes',
	'leads.list.tableHeader.userDeposited.false': 'No',
	'leads.list.tableHeader.createdAt': 'Task creation date',
	'leads.list.tableHeader.actions': 'Actions',
	'leads.list.tableHeader.actions.view': 'View',

	"leads.list.tableHeader.fireCloaking": "Cloaking triggered?",
	"leads.list.tableHeader.fireCloaking.true": "Yes",
	"leads.list.tableHeader.fireCloaking.false": "No",
	"leads.list.tableHeader.fireCloakingReason": "Cloaking reason",
	"leads.list.tableHeader.fireCloakingReason.badOS": "Unsupported OS",
	"leads.list.tableHeader.fireCloakingReason.badIp": "IP from blacklist",
	"leads.list.tableHeader.fireCloakingReason.adspect": "adspect filter",
	"leads.list.tableHeader.fireCloakingReason.badUserAgent": "UserAgent from blacklist",
	"leads.list.tableHeader.fireCloakingReason.noCountryCode": "cf-ipcountry unavailable",
	"leads.list.tableHeader.fireCloakingReason.badCountryCode": "Unsupported country",
	"leads.list.tableHeader.fireCloakingReason.badDeviceType": "Unsupported device type",

	'leads.list.customFilters.leadSource': 'Lead source',
	'leads.list.customFilters.leadSource.any': 'All',
	'leads.list.customFilters.leadSource.facebook': 'Facebook',
	'leads.list.customFilters.leadSource.google': 'Google',
	'leads.list.customFilters.refferer': 'Referrer',


	"leads.view.title": "Lead view",
	"leads.view.delete": "Delete",
	"leads.view.goToApp": "Go to app",
	"leads.view.allLeadsForClient": "All leads for this client",
	"leads.view.clientLogs": "Client logs",
	"leads.view.sessionLogs": "Session logs",
	"leads.view.clientStatus": "Client status",
	"leads.view.appInstalled": "App installed",
	"leads.view.userRegistered": "User registered",
	"leads.view.userDeposited": "User deposited",
	"leads.view.clientIp": "Client IP",
	"leads.view.otherClientIps": "Other known client IP addresses",
	"leads.view.browserInfo": "Browser information",
	"leads.view.userAgent": "User agent",
	"leads.view.browserName": "Browser",
	"leads.view.browserVersion": "Browser version",
	"leads.view.osName": "Operating system",
	"leads.view.osVersion": "Operating system version",
	"leads.view.deviceType": "Device type",
	"leads.view.analytics": "Analytics",
	"leads.view.primary": "Primary",
	"leads.view.facebook": "Facebook",
	"leads.view.fbc": "FBC",
	"leads.view.fbp": "FBP",
	"leads.view.google": "Google",
	"leads.view.gclid": "GCLID",
	"leads.view.rawRequestHeaders": "Raw request headers",
	"leads.view.rawQueryParams": "Raw query parameters",
	"leads.view.showAll": "Show all",
	"leads.view.hide": "Hide",
	"leads.view.time": "Time",
	"leads.view.unknown": "Unknown",
	"leads.view.referer": "Referer",

	'components.timeRangeSelect.today': 'Today',
	'components.timeRangeSelect.yesterday': 'Yesterday',
	'components.timeRangeSelect.currentWeek': 'Current Week',
	'components.timeRangeSelect.last7Days': 'Last 7 Days',
	'components.timeRangeSelect.currentMonth': 'Current Month',
	'components.timeRangeSelect.lastMonth': 'Last Month',
	'components.timeRangeSelect.last30Days': 'Last 30 Days',
	'components.timeRangeSelect.currentYear': 'Current Year',
	'components.timeRangeSelect.allTime': 'All Time',
	'components.timeRangeSelect.custom': 'Date Range',

	'fbPixels.list.tableHeader._id': 'ID',
	'fbPixels.list.tableHeader.name': 'Name',
	'fbPixels.list.tableHeader.pixel': 'Pixel',
	'fbPixels.list.tableHeader.actions': 'Actions',
	'fbPixels.modal.title': 'New Pixel',
	'fbPixels.modal.label.name': 'Pixel Name',
	'fbPixels.modal.label.pixel': 'Pixel ID',
	'fbPixels.modal.label.token': 'Token for Pixel',
	'fbPixels.header.title': 'My Pixels',
	'fbPixels.header.button.newPixel': 'New Pixel',

	'fbPixels.edit.headerTitle': 'Pixel Editor',
	'fbPixels.edit.logsButton': 'Logs',
	'fbPixels.edit.saveButton': 'Save',
	'fbPixels.edit.deleteButton': 'Delete',
	'fbPixels.edit.pixelNameLabel': 'Pixel Name',
	'fbPixels.edit.pixelIdLabel': 'Pixel ID',
	'fbPixels.edit.pixelTokenLabel': 'Pixel Token',
	'fbPixels.edit.saveSuccess': 'Save successful',

	'fbPixels.edit.eventsMapping.title': 'Event mapping',
	'fbPixels.edit.eventsMapping.installEventNameLabel': 'Send PWA install event as',
	'fbPixels.edit.eventsMapping.registrationEventNameLabel': 'Send registration event as',
	'fbPixels.edit.eventsMapping.depositEventNameLabel': 'Send deposit event as',

	'fbPixels.stat.title': 'Pixel Statistics',

	'fbPixels.stat.selectWebApp': 'Select PWA',
	'fbPixels.stat.timeRange': 'Time Range',
	'fbPixels.stat.installs': 'Install Events',
	'fbPixels.stat.registrations': 'Registration Events',
	'fbPixels.stat.deposits': 'Deposit Events',
	'fbPixels.stat.installCount': 'Install Count',
	'fbPixels.stat.registrationCount': 'Registration Count',
	'fbPixels.stat.depositCount': 'Deposit Count',

	'settings.openai.saveSuccess': 'Settings saved successfully',
	'settings.openai.headerTitle': 'OpenAI Settings',
	'settings.openai.saveButton': 'Save Settings',
	'settings.openai.tokenLabel': 'OpenAI Access Token',
	'settings.openai.endpointLabel': 'OpenAI Endpoint (Optional)',
	'settings.openai.modelLabel': 'OpenAI Model',
	'settings.openai.promptsTitle': 'Prompts',
	'settings.openai.generateDescriptionLabel': 'Application Description Generation',
	'settings.openai.translateLabel': 'Translation',
	'settings.openai.generateCommentsLabel': 'Comment Generation',

	'leads.list.tableHeader.isSubscribed': 'Push notifications subscription',
	'leads.list.tableHeader.isSubscribed.true': 'Subscribed',
	'leads.list.tableHeader.isSubscribed.false': 'Not subscribed',

	'leads.view.sendPush.title': 'Send Push Notification',
	'leads.view.sendPush.form.title': 'Title',
	'leads.view.sendPush.form.message': 'Message',
	'leads.view.sendPush.success': 'Notification sent successfully',

	'leads.view.push.sendPush': 'Send Push Notification',
	'leads.view.push.notSubscribed': 'Not subscribed to push notifications',
	'leads.view.push.subscribed': 'Subscribed to push notifications',

	"leads.view.sendPush.form.openApp": "Open App",
	"leads.view.sendPush.form.openAppDescription": "Open the app upon clicking the notification",
	"leads.view.sendPush.form.targetLink": "Link where the user will be directed after clicking the notification",
	"leads.view.sendPush.form.image": "Image",

	"menuItems.pushJobs": "PUSH notifications",
	"sendPush.list.tableHeader.appId": "Application",
	"sendPush.list.tableHeader.appId.notSet": "Not set",
	"sendPush.list.tableHeader.title": "Title",
	"sendPush.list.tableHeader.createdAt": "Created",
	"sendPush.list.tableHeader.actions.view": "View",
	"sendPush.list.createJob.title": "Create PUSH notification",
	"sendPush.list.createJob.form.appId": "Application",
	"sendPush.list.createJob.form.title": "Title",
	"sendPush.list.createJob.form.message": "Message",
	"sendPush.list.createJob.form.openApp": "Open application",
	"sendPush.list.createJob.form.openAppDescription": "Open application when clicking on the notification",
	"sendPush.list.createJob.form.targetLink": "Link where the user will be directed after clicking the notification",
	"sendPush.list.createJob.form.image": "Image",
	"sendPush.list.title": "PUSH notifications",
	"sendPush.list.actions.create": "Create PUSH notification",


	"pushJobs.edit.title": "View PUSH Campaign",
	"pushJobs.edit.actions.remove": "Delete",
	"pushJobs.edit.actions.retry": "Retry PUSH Campaign",
	"pushJobs.edit.actions.retryConfirm": "Do you really want to retry the PUSH campaign?",
	"pushJobs.edit.form.appId": "App",
	"pushJobs.edit.form.title": "Title",
	"pushJobs.edit.form.message": "Message",
	"pushJobs.edit.form.openApp": "Open App",
	"pushJobs.edit.form.openAppDescription": "Open the app upon clicking the notification",
	"pushJobs.edit.form.targetLink": "Link where the user will be directed after clicking the notification",
	"pushJobs.edit.form.image": "Image",
	"pushJobs.edit.stats.details.hide": "Hide",
	"pushJobs.edit.form.details": "Show all",
	"pushJobs.edit.stats.title": "PUSH Campaign Statistics",
	"pushJobs.edit.stats.details.title": "Detailed PUSH Campaign Statistics",

	'menuItems.push': 'PUSH Notifications',
	'menuItems.push.Jobs': 'Mailings',
	'menuItems.push.Segments': 'Segments',
	'menuItems.push.Messages': 'Messages',

	'push.messages.list.table.id': 'ID',
	'push.messages.list.table.comment': 'Comment',
	'push.messages.list.table.view': 'View',
	'push.messages.list.title': 'Push Message Templates',
	'push.messages.list.create': 'Create Template',

	'push.messages.view.title': 'PUSH Message Template',
	'push.messages.view.save': 'Save',
	'push.messages.view.remove': 'Delete',
	'push.messages.view.fields.comment': 'Comment',
	'push.messages.view.fields.comment.description': 'Comment for your convenience',
	'push.messages.view.items': 'Messages',
	'push.messages.view.items.add': 'Add Message',
	'push.messages.view.fields.language': 'Language',
	'push.messages.view.items.title': 'Title',
	'push.messages.view.items.message': 'Message',
	'push.messages.view.items.image': 'Image',
	'push.messages.view.fallbackLanguage': 'Default Language',
	'push.messages.view.fallbackLanguage.description': 'If none of the specified languages are suitable for the user, this language will be used',
	'push.messages.view.fields.fallbackLanguage': 'Default Language',

	'push.messages.view.fields.autoTranslate': 'Automatically translate message to user language',
	'push.messages.view.save.invalid': 'Data did not pass validation, save anyway?',

	'push.segments.list.table.id': 'ID',
	'push.segments.list.table.comment': 'Comment',
	'push.segments.list.table.actions.view': 'View',
	'push.segments.list.title': 'User Segments',
	'push.segments.list.create': 'Create Segment',

	'push.segments.edit.saveSuccess': 'Segment saved successfully',
	'push.segments.edit.title': 'View Segment',
	'push.segments.edit.actions.save': 'Save',
	'push.segments.edit.actions.remove': 'Delete',

	'push.segments.edit.fields.comment': 'Comment',
	'push.segments.edit.fields.comment.description': 'Comment for your convenience',
	'push.segments.edit.fields.webApps': 'Applications',
	'push.segments.edit.flags': 'Client Status',
	'push.segments.edit.flags.isTryAppInstall': 'User attempted to install the app',
	'push.segments.edit.flags.isAppInstalled': 'App installed',
	'push.segments.edit.flags.isAppRunned': 'App launched',
	'push.segments.edit.flags.isRegistered': 'User registered',
	'push.segments.edit.flags.isDeposited': 'User made a deposit',

	'push.jobs.list.table.id': 'ID',
	'push.jobs.list.table.enabled': 'Enabled',
	'push.jobs.list.table.enabled.true': 'Yes',
	'push.jobs.list.table.enabled.false': 'No',
	'push.jobs.list.table.comment': 'Comment',
	'push.jobs.list.table.runMode': 'Run Mode',
	'push.jobs.list.table.runMode.manual': 'Manual',
	'push.jobs.list.table.runMode.schedule': 'Scheduled',
	'push.jobs.list.table.runMode.trigger': 'Triggered',
	'push.jobs.list.table.actions': 'Actions',
	'push.jobs.list.table.actions.view': 'View',
	'push.jobs.list.title': 'PUSH Mailings',
	'push.jobs.list.create': 'Create Mailing',

	'push.jobs.edit.saveSuccess': 'Mailing saved successfully',
	'push.jobs.edit.title': 'View Mailing',
	'push.jobs.edit.actions.save': 'Save',
	'push.jobs.edit.actions.remove': 'Delete',
	'push.jobs.edit.fields.comment': 'Comment',
	'push.jobs.edit.fields.comment.description': 'Comment for your convenience',
	'push.jobs.edit.fields.enabled': 'Enable Mailing',
	'push.jobs.edit.fields.enabled.description': 'Enable or disable the mailing',
	'push.jobs.edit.fields.messages': 'Messages',
	'push.jobs.edit.fields.messages.description': 'Messages for mailing; if multiple are selected, one will be used',
	'push.jobs.edit.fields.segments': 'User Segments',
	'push.jobs.edit.fields.segments.description': 'User segments',
	'push.jobs.edit.fields.runMode': 'Run Mode',
	'push.jobs.edit.fields.runMode.description': 'Mailing run mode',
	'push.jobs.edit.fields.runMode.manual': 'Manual',
	'push.jobs.edit.fields.runMode.schedule': 'Scheduled',
	'push.jobs.edit.fields.runMode.trigger': 'Triggered',
	'push.jobs.edit.fields.scheduleProps.nextRun': 'Next Run',
	'push.jobs.edit.fields.scheduleProps.repeat': 'Repeat',
	'push.jobs.edit.fields.scheduleProps.repeatPeriod': 'Repeat Period',
	'push.jobs.edit.fields.triggerProps.triggers.add': 'Add Trigger',

	'push.jobs.edit.addTrigger.title': 'Add Trigger',

	'push.jobs.edit.addTrigger.variants.onTryAppInstall': 'User started installing the app',
	'push.jobs.edit.addTrigger.variants.onAppInstalled': 'App successfully installed by the user',
	'push.jobs.edit.addTrigger.variants.appNotInstalled': 'App not installed by the user',
	'push.jobs.edit.addTrigger.variants.onAppRunned': 'User launched the app',
	'push.jobs.edit.addTrigger.variants.appNotRunned': 'User did not launch the app',
	'push.jobs.edit.addTrigger.variants.onRegister': 'User registered',
	'push.jobs.edit.addTrigger.variants.notRegister': 'User did not register',
	'push.jobs.edit.addTrigger.variants.onDeposit': 'User made a deposit',
	'push.jobs.edit.addTrigger.variants.notDeposit': 'User did not make a deposit',

	'push.jobs.edit.addTrigger.for': 'Time elapsed',
	'push.jobs.edit.addTrigger.triggerType': 'Trigger Type',

	'doc.periodInput.title': 'Examples',
	'doc.periodInput.30s': '"30s" = 30 seconds',
	'doc.periodInput.15m': '"15m" = 15 minutes',
	'doc.periodInput.2h': '"2h" = 2 hours',
	'doc.periodInput.1d': '"1d" = 1 day',
	'doc.periodInput.1w': '"1w" = 1 week',
	'doc.periodInput.1M': '"1M" = 1 month',


	'menuItems.whitePages': 'White Pages',
	'whitePages.list.table.id': 'ID',
	'whitePages.list.table.name': 'Name',
	'whitePages.list.table.actions': 'Actions',
	'whitePages.list.table.actions.view': 'View',
	'whitePages.list.createItem.title': 'Upload Landing',
	'whitePages.list.createItem.label.name': 'Landing Name',
	'whitePages.list.createItem.description.name': 'Optional, for your convenience',
	'whitePages.list.createItem.label.file': 'Landing',
	'whitePages.list.createItem.actions': 'Actions',
	'whitePages.list.description': 'Upload landings to protect your PWAs',
	'whitePages.list.createItem.action': 'Upload Landing',

	'whitePages.view.saveSuccess': 'Landing saved successfully',
	'whitePages.view.title': 'Landing',
	'whitePages.view.landingInfo.title': 'Landing Information',
	'whitePages.view.landingInfo.notFoundIndex': 'index.html not found',
	'whitePages.view.landingInfo.assetsFolderWarning': 'Do not use the assets folder, it may conflict with the PWA configuration',
	'whitePages.view.landingInfo.swWarning': 'Do not use sw.js, it may conflict with the PWA configuration',
	'whitePages.view.landingInfo.stripLevel': 'index.html is not in the root of the archive, it is recommended to repack so that the site files are not in a subfolder',
	'whitePages.view.landingInfo.type': 'Type',
	'whitePages.view.landingInfo.path': 'Path',
	'whitePages.view.landingInfo.size': 'Size',
	'whitePages.view.saveButton': 'Save',
	'whitePages.view.removeButton': 'Remove',
	'whitePages.view.label.name': 'Name',
	'whitePages.view.landingInfo.site': 'Site',



	"webApps.view.title": "PWA Settings",
	"webApps.view.saved": "Save Successful",
	"webApps.view.unnamed": "Untitled",
	"webApps.view.header.leads": "Leads",
	"webApps.view.header.save": "Save",
	"webApps.view.header.delete": "Delete",

	"webApps.view.tabs.list.base": "General Settings",
	"webApps.view.tabs.list.domain": "Domain",
	"webApps.view.tabs.list.tracker": "Tracker",
	"webApps.view.tabs.list.content": "Content",
	"webApps.view.tabs.list.analytics": "Analytics",
	"webApps.view.tabs.list.push": "PUSH Notifications",
	"webApps.view.tabs.list.ab": "A/B Testing",
	"webApps.view.tabs.list.cloaking": "Cloaking",
	"webApps.view.tabs.list.publish": "Publish PWA",

	"webApps.view.tabs.list.stats": "Statistics",
	"webApps.view.tabs.list.links": "Links",

	"webApps.view.GeneralTab.tryAddNewUser.title": "New User",
	"webApps.view.GeneralTab.tryAddNewUser.button": "Add New User",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.label": "Role",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.editor": "Editor",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.viewer": "Viewer",

	"webApps.view.GeneralTab.FindUserByName.description.user": "User",

	"webApps.view.GeneralTab.form.name": "PWA Name",
	"webApps.view.GeneralTab.form.sharing.label": "PWA Sharing",

	"webApps.views.ContentTab.tabsList.languages": "Languages",
	"webApps.views.ContentTab.tabsList.description": "Description",
	"webApps.views.ContentTab.tabsList.images": "Images",
	"webApps.views.ContentTab.tabsList.reviews": "Reviews and Ratings",
	"webApps.views.ContentTab.tabsList.additional": "Additional Settings",
	"webApps.views.ContentTab.LanguagesTab.activeLanguages.title": "Active Languages",
	"webApps.views.ContentTab.LanguagesTab.isDefault.label": "Default Language?",
	"webApps.views.ContentTab.LanguagesTab.translateEngine.label": "Translation Platform",
	"webApps.views.ContentTab.LanguagesTab.addLanguage.buttonLabel": "Add Language",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.search.label": "Enter Language Name",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.submit.label": "Add Language",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.copyAndTranslate.label": "Copy and Translate from {{lang}}",

	"webApps.views.ContentTab.LanguagesTab.DeleteLangButton.cancel": "Cancel",
	"webApps.views.ContentTab.LanguagesTab.DeleteLangButton.delete": "Delete Language",
	"webApps.views.ContentSettingsTab.security.title": "Personal Data Processing Settings",
	"webApps.views.ContentSettingsTab.security.noDataCollected": "The app does not collect data",
	"webApps.views.ContentSettingsTab.security.noDataShare": "The app does not share data with third parties",

	"webApps.views.ContentSettingsTab.installButtonBehavior.title": "Install Button Behavior",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.title": "Wait for Install Event",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.description": "After pressing the install PWA button, the browser will wait for the install event.",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.scrollToBottom.label": "Scroll to bottom after pressing",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.scrollToBottom.description": "Encourage the user to manually scroll up, thereby triggering the install event",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.title": "Scroll to Bottom",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.description": "After pressing the install PWA button, the browser will scroll to the bottom.",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.clickCount.label": "Number of clicks required for scroll to occur",
	"webApps.views.ContentSettingsTab.behavior.none.title": "None",
	"webApps.views.ContentSettingsTab.behavior.none.description": "No patterns for triggering the install event will be used.",
	"webApps.views.ContentSettingsTab.tweaks.title": "PWA Tweaks",
	"webApps.views.ContentSettingsTab.tweaks.label": "Hide App Title",
	"webApps.views.ContentSettingsTab.tweaks.description": "When this setting is enabled, the app title will be hidden",
	"webApps.views.ContentSettingsTab.tweaks.reloadPageIfInstallNotAllowed": "Reload page if PWA install is not allowed",
	"webApps.views.ContentSettingsTab.tweaks.installButtonAnimSpeed": "Install button animation speed",

	"webApps.views.DescriptionTab.name.label": "App Name",

	"webApps.view.GenerateReviewsModal.title": "Generate Reviews",
	"webApps.view.GenerateReviewsModal.prompt.label": "Prompt",
	"webApps.view.GenerateReviewsModal.prompt.description": "Write a prompt for content generation",
	"webApps.view.GenerateReviewsModal.amount.label": "Number of Reviews",
	"webApps.view.GenerateReviewsModal.submitButton": "Generate",
	"webApps.view.GenerateReviewsModal.reviews.title": "Reviews",
	"webApps.view.GenerateReviewsModal.reviews.showTranslated": "Show Translation",
	"webApps.view.GenerateReviewsModal.reviews.row.name": "Name",
	"webApps.view.GenerateReviewsModal.reviews.row.rating": "Rating",
	"webApps.view.GenerateReviewsModal.reviews.row.text": "Text",
	"webApps.view.GenerateReviewsModal.reviews.row.reviewRating": "Review Rating",
	"webApps.view.GenerateReviewsModal.reviews.row.commentDate": "Comment Date",
	"webApps.view.GenerateReviewsModal.reviews.row.addToReviews": "Add to Reviews",
	"webApps.view.GenerateReviewsModal.reviews.row.delete": "Delete",

	"webApps.view.ImagesTab.icon.title": "App Icon",
	"webApps.view.ImagesTab.useIconFromBaseLanguage": "Use Icon from Base Language",
	"webApps.view.ImagesTab.useScreensFromBaseLanguage.title": "App Screens",
	"webApps.view.ImagesTab.useScreensFromBaseLanguage.label": "Use Screens from Base Language",

	"webApps.view.ReviewForm.avatar": "Avatar",
	"webApps.view.ReviewForm.name": "Name",
	"webApps.view.ReviewForm.text": "Review Text",
	"webApps.view.ReviewForm.rating": "Rating",
	"webApps.view.ReviewForm.reviewRating": "How many people find the review helpful",
	"webApps.view.ReviewForm.commentDate": "Comment Date",
	"webApps.view.ReviewsTab.tryAddComment.title": "Add Review",
	"webApps.view.ReviewsTab.title": "Ratings",
	"webApps.view.ReviewsTab.useRatingFromBaseLanguage": "Use Rating from Base Language",
	"webApps.view.ReviewsTab.rating.legend": "Overall Ratings",
	"webApps.view.ReviewsTab.rating": "App Rating",
	"webApps.view.ReviewsTab.ratingsCount": "Number of App Ratings",
	"webApps.view.ReviewsTab.downloadsCount": "Number of App Downloads",
	"webApps.view.ReviewsTab.ratingValues.legend": "Detailed Ratings (used for reviews)",
	"webApps.view.ReviewsTab.reviews.title": "Reviews",
	"webApps.view.ReviewsTab.reviews.button.add": "Add Comment",
	"webApps.view.ReviewsTab.reviews.button.generate": "Generate Comments",


	"webApps.view.PushTab.title": "Push Notifications",
	"webApps.view.PushTab.selfPush.enabled": "Enabled",
	"webApps.view.PushTab.selfPush.alert": "To enable push notifications, the user needs to subscribe to them. Please select one or more options for how they will do this.",
	"webApps.view.PushTab.selfPush.promptOnInstall": "Show notification on install",
	"webApps.view.PushTab.selfPush.promptOnStart.label": "Show notification on PWA launch",
	"webApps.view.PushTab.selfPush.promptOnStart.description": "Recommended",

	"webApps.view.PushTab.oneSignal.title": "Integration with oneSignal",
	"webApps.view.PushTab.oneSignal.description1": "Set up integration with oneSignal to send push notifications to your website users.",
	"webApps.view.PushTab.oneSignal.description2": "Enable the option and provide the site ID to activate this feature.",
	"webApps.view.PushTab.oneSignal.enabled": "Enabled",
	"webApps.view.PushTab.oneSignal.appId.label": "oneSignal Site ID",
	"webApps.view.PushTab.oneSignal.alert.t1": "In the section",
	"webApps.view.PushTab.oneSignal.alert.t2": "enable the option",
	"webApps.view.PushTab.oneSignal.alert.replace.title": "Fill in the fields with the following values:",

	"webApps.view.ABTab.title": "A/B Testing",
	"webApps.view.ABTab.enabled": "Enable A/B Testing",
	"webApps.view.ABTab.rules.header": "Rule",
	"webApps.view.ABTab.rules.enabled": "Is the rule active?",
	"webApps.view.ABTab.rules.comment.label": "Rule Comment",
	"webApps.view.ABTab.rules.comment.description": "For your convenience",
	"webApps.view.ABTab.rules.conditions.title": "Trigger Conditions",
	"webApps.view.ABTab.rules.conditions.header": "Condition",
	"webApps.view.ABTab.rules.conditions.add": "Add Condition",
	"webApps.view.ABTab.rules.actionType.label": "Action",
	"webApps.view.ABTab.rules.actionType.redirect": "Redirect",
	"webApps.view.ABTab.rules.actionType.blankPage": "Show Blank Page",
	"webApps.view.ABTab.rules.actionType.showRawContent": "Show HTML page",
	"webApps.view.ABTab.rules.actionType.rawContent.content.label": "HTML page",
	"webApps.view.ABTab.rules.actionType.redirect.targetUrl.label": "Redirect Link",
	"webApps.view.ABTab.rules.actionType.saveOriginalQueryString": "Save Original Query Parameters (UTM tags)",
	"webApps.view.ABTab.rules.add": "Add New Rule",

	"webApps.view.CloakingTab.title": "Ai Cloaking",
	"webApps.view.CloakingTab.enabled": "AI cloaking enabled",
	"webApps.view.CloakingTab.useIpBlacklist.legend": "IP Address Check",
	"webApps.view.CloakingTab.useIpBlacklist.label": "Check IP in Blacklist",
	"webApps.view.CloakingTab.useIpBlacklist.description": "Check the IP address for presence in the local blacklist",

	"webApps.view.CloakingTab.useUaBlacklist.legend": "User Agent Check",
	"webApps.view.CloakingTab.useUaBlacklist.label": "Check User Agent in Blacklist",
	"webApps.view.CloakingTab.useUaBlacklist.description": "Check the user agent for presence in the local blacklist",

	"webApps.view.CloakingTab.countryList.legend": "GEO Check",
	"webApps.view.CloakingTab.countryListMode.label": "Check Mode",
	"webApps.view.CloakingTab.countryListMode.WHITELIST": "Allow specified countries",
	"webApps.view.CloakingTab.countryListMode.BLACKLIST": "Deny specified countries",

	"webApps.view.CloakingTab.useDeviceType.legend": "Device Type Check",
	"webApps.view.CloakingTab.useDeviceType.label": "Check Enabled",
	"webApps.view.CloakingTab.deviceTypeListMode.label": "Check Mode",
	"webApps.view.CloakingTab.deviceTypeListMode.WHITELIST": "Allow specified device types",
	"webApps.view.CloakingTab.deviceTypeListMode.BLACKLIST": "Deny specified device types",

	"webApps.view.CloakingTab.useOSList.legend": "OS Type Check",
	"webApps.view.CloakingTab.useOSList.label": "Check Enabled",
	"webApps.view.CloakingTab.osListMode.label": "Check Mode",
	"webApps.view.CloakingTab.osListMode.WHITELIST": "Allow specified OS types",
	"webApps.view.CloakingTab.osListMode.BLACKLIST": "Deny specified OS types",

	"webApps.view.CloakingTab.useAdspect.legend": "Additional check by adspect databases",
	"webApps.view.CloakingTab.useAdspect.label": "Check enabled",
	"webApps.view.CloakingTab.adspectStreamId.label": "Your stream ID",
	"webApps.view.CloakingTab.adspectStreamId.placeholder": "Optional",

	"webApps.view.CloakingTab.rejectAction.legend": "Actions when filter triggers",
	"webApps.view.CloakingTab.rejectAction.label": "Check mode",
	"webApps.view.CloakingTab.rejectAction.SHOW_200": "Show blank page with 200 code",
	"webApps.view.CloakingTab.rejectAction.SHOW_404": "Show blank page with 404 code",
	"webApps.view.CloakingTab.rejectAction.REDIRECT": "Perform redirect",
	"webApps.view.CloakingTab.rejectAction.WHITE_PAGE": "Display landing (white page)",

	"webApps.view.CloakingTab.redirectUrl.label": "Redirect URL",
	"webApps.view.CloakingTab.whitePageSelect.label": "Select landing for display",

	"webApps.view.PublishTab.title": "PWA Publication",

	"leads.view.timezone": "Time Zone",
	"push.jobs.edit.isDefault": "This is the default rule",
	"push.jobs.edit.isDefault.description": "This rule is set as the default. You can unlock it, and it will be available for all your PWAs",
	"push.jobs.edit.isDefault.unlock": "Unlock",
	"push.jobs.edit.addTrigger.delay": "Delay before start",
	"push.jobs.edit.addTrigger.variants.pushSubscribe": "User subscribed to push notifications",
	"webApps.view.PushTab.selfPush.useDefaultPush.label": "Use default rules",
	"webApps.view.PushTab.selfPush.useDefaultPush.description": "Only default rules marked with the DEFAULT label will be used for push notifications",

	"webApps.archive.title": "Archived PWA",
	"webApps.archive.goBack": "Back",
	"webApps.list.archive": "Archive",

	"leads.view.appLaunched": "The app is running",

	"webApps.views.DescriptionTab.content.whatsNew.title": "Section 'What's New'",
	"webApps.views.DescriptionTab.content.whatsNew.enabled": "Display the section",
	"webApps.views.DescriptionTab.content.whatsNew.content": "Section content",

	"webApps.view.ReviewForm.ownerResponse.show": "Is there a developer's response?",
	"webApps.view.ReviewForm.ownerResponse.content": "Developer's response",
	"webApps.view.ReviewForm.ownerResponse.date": "Developer's response date",

	"avatarGallery.select.gender.label": "Gender",
	"avatarGallery.select.gender.MALE": "Male",
	"avatarGallery.select.gender.FEMALE": "Female",

	"avatarGallery.select.age.label": "Age",
	"avatarGallery.select.age.YOUNG_ADULT": "Young (18-30 years)",
	"avatarGallery.select.age.ADULT": "Adult (31-60 years)",
	"avatarGallery.select.age.ELDERLY": "Elderly (60 years and older)",

	"avatarGallery.select.race.label": "Race",
	"avatarGallery.select.race.ASIAN": "Asian",
	"avatarGallery.select.race.EUROPEAN": "European",
	"avatarGallery.select.race.AFRICAN_AMERICAN": "African",
	"avatarGallery.select.race.LATINO": "Latino",
	"avatarGallery.select.race.ARAB": "Arab",

	"avatarGallery.select.race.INDIAN": "Indian",

	'whitePagesTemplates.list.table.name': 'Name',
	'whitePagesTemplates.list.table.actions': 'Actions',
	'whitePagesTemplates.list.table.actions.view': 'View',
	'whitePagesTemplates.list.createItem.title': 'Upload Template',
	'whitePagesTemplates.list.createItem.label.name': 'Template Name',
	'whitePagesTemplates.list.createItem.description.name': 'Template Description',
	'whitePagesTemplates.list.createItem.label.file': 'Template',
	'whitePagesTemplates.list.createItem': 'Upload Template',
	'whitePagesTemplates.list.description': 'Upload templates for generating landing pages',

	'whitePagesTemplates.view.landingInfo.title': 'Template Information',
	'whitePagesTemplates.view.landingInfo.notFoundIndex': 'index.html not found',
	'whitePagesTemplates.view.landingInfo.assetsFolderWarning': 'The archive contains an assets folder, which may conflict with the PWA configuration',
	'whitePagesTemplates.view.landingInfo.swWarning': 'The archive contains sw.js, which may conflict with the PWA configuration',
	'whitePagesTemplates.view.landingInfo.stripLevel': 'index.html is not located in the root of the archive, it is recommended to repack to ensure the site files are not in a subfolder',
	'whitePagesTemplates.view.landingInfo.actions.addPrompt.title': 'Add Prompt Modifier',
	'whitePagesTemplates.view.landingInfo.actions.addPrompt.fields.prompt': 'Prompt Modifier',
	'whitePagesTemplates.view.saveSuccess': 'Template Saved',
	'whitePagesTemplates.view.title': 'Landing Page Template',
	'whitePagesTemplates.view.saveButton': 'Save',
	'whitePagesTemplates.view.removeButton': 'Delete',
	'whitePagesTemplates.view.label.name': 'Name',
	'whitePagesTemplates.view.label.description': 'Description',
	'whitePagesTemplates.view.landingInfo.site': 'Template',
	'whitePagesTemplates.view.landingInfo.image': 'Preview Image',

	'whitePages.view.generateStatus.wait.title': 'Generation Waiting',
	'whitePages.view.generateStatus.wait.message': 'Please wait, we will soon start creating your white page',
	'whitePages.view.generateStatus.inProgress.message': 'Your white page is being generated',
	'whitePages.view.generateStatus.error.title': 'Generation Error',
	'whitePages.view.generateStatus.error.message': 'An error occurred while generating your white page',
	'whitePages.view.generateStatus.success.title': 'Generation Complete',
	'whitePages.view.generateStatus.success.message': 'Your white page has been generated',



	'webApps.views.ContentTab.LanguagesTab.AddLanguageModal.wait.label': 'Generating translation',
	'webApps.views.ContentTab.LanguagesTab.AddLanguageModal.wait.description': 'Please wait, this may take a few minutes',

	'webApps.views.ContentTab.LanguagesTab.noLanguages.title': 'No languages added',
	'webApps.views.ContentTab.LanguagesTab.noLanguages.label': 'Add a language using the "Add Language" button',

}

