import {IconAppWindow} from "@tabler/icons-react";
import MenuManager from "@engine/MenuManager.ts";
import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {endOfToday, startOfToday} from "date-fns";
import {Card, Flex, SimpleGrid, Stack, Title} from "@mantine/core";
import useTeams from "../Teams/Context/useTeams.ts";
import {useState} from "react";
import {MultiSelectWebApp} from "../WebApps/utils/selectWebApp.tsx";
import {TeamMembersMultiSelect} from "../Teams/TeamMembersMultiSelect.tsx";
import {GeneralBadges} from "./statsWidgets/GeneralBadges.tsx";
import {Chart} from "./statsWidgets/chart.tsx";
import {ClickToActionBadges} from "./statsWidgets/clickToActionBadges.tsx";
import {CountryTop} from "./statsWidgets/countryTop.tsx";
import {AppTop} from "./statsWidgets/appTop.tsx";
import {SourcesTop} from "./statsWidgets/sourcesTop.tsx";
import {DevicesTop} from "./statsWidgets/devicesTop.tsx";
import {useScopedLocale} from "../../locale";

MenuManager.RegisterItem({
	position: -1,
	path: '/',
	key: 'main-page',
	nameKey: 'menuItems.mainPage',
	requiredRights: [],
	icon: <IconAppWindow/>
});


export function MainPage() {
	const t = useScopedLocale('pages.mainPage.');
	const {activeTeamRole} = useTeams();
	const [activeChart, setActiveChart] = useState('visits');
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [selectedApps, setSelectedApps] = useState<string[]>([]);
	const timeControls = useTimeState(startOfToday(), endOfToday());
	return (
		<Stack gap={'md'}>
			<Flex justify={'space-between'}>
				<Title order={3}>{t('title')}</Title>
				<Flex gap={'md'}>
					{['admin'].includes(activeTeamRole!) && (
						<TeamMembersMultiSelect
							placeholder={selectedUsers.length ? undefined : t('teamMembers.all')}
							value={selectedUsers}
							onChange={setSelectedUsers}
							searchable
						/>
					)}
					<MultiSelectWebApp
						hideAll
						placeholder={selectedApps.length ? undefined : t('pwa.all')}
						query={selectedUsers.length ? {owner: {type: 'in', value: selectedUsers}} : {}}
						value={selectedApps}
						onChange={setSelectedApps}
						searchable
					/>
					<TimeRangeSelect onChange={timeControls.setValue} defaultMode={'today'}/>
				</Flex>
			</Flex>
			<GeneralBadges
				minDate={timeControls.value[0]!}
				maxDate={timeControls.value[1]!}
				selectedApps={selectedApps}
				selectedUsers={selectedUsers}

				value={activeChart}
				onChange={setActiveChart}
			/>
			<br/>
			<Card>
				<Chart
					minDate={timeControls.value[0]!}
					maxDate={timeControls.value[1]!}
					selectedApps={selectedApps}
					selectedUsers={selectedUsers}
					type={activeChart}
				/>
			</Card>
			<br/>
			<ClickToActionBadges
				minDate={timeControls.value[0]!}
				maxDate={timeControls.value[1]!}
				selectedApps={selectedApps}
				selectedUsers={selectedUsers}
			/>
			<SimpleGrid cols={2}>
				<Card>
					<Title order={3}>{
						t('county')
					}</Title>
					<CountryTop
						minDate={timeControls.value[0]!}
						maxDate={timeControls.value[1]!}
						selectedApps={selectedApps}
						selectedUsers={selectedUsers}
					/>
				</Card>
				<Card>
					<Title order={3}>
						{t('apps')}
					</Title>
					<AppTop
						minDate={timeControls.value[0]!}
						maxDate={timeControls.value[1]!}
						selectedApps={selectedApps}
						selectedUsers={selectedUsers}
					/>
				</Card>
				<Card>
					<Title order={3}>
						{t('sources')}
					</Title>
					<SourcesTop
						minDate={timeControls.value[0]!}
						maxDate={timeControls.value[1]!}
						selectedApps={selectedApps}
						selectedUsers={selectedUsers}
					/>
				</Card>
				<Card>
					<Title order={3}>
						{t('devices')}
					</Title>
					<DevicesTop
						minDate={timeControls.value[0]!}
						maxDate={timeControls.value[1]!}
						selectedApps={selectedApps}
						selectedUsers={selectedUsers}
					/>
				</Card>
			</SimpleGrid>
		</Stack>
	)
}
