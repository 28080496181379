import {useNavigate} from "@hooks/useNavigate.tsx";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {useEffect, useState} from "react";
import {TeamsApiClient} from "../api.ts";
import {notifications, showNotification} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {Anchor, Button, Center, Flex, Group, Loader, Modal, NumberInput, Table, Tabs} from "@mantine/core";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {Header} from "@components/Header";
import MembersList from "./MembersList.tsx";
import {type RawDocumentTypeTeams} from "@shared/models";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {VisibleOnlyAdmin} from "../../../utils/visibleOnlyAdmin.tsx";
import {RawDocumentType} from "@shared/lib/ugly/rawDocumentType.ts";
import {AdminUser} from "@shared/models/AdminUsers.ts";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {useUser} from "@engine/UserContext.tsx";

const api = new TeamsApiClient();

function TeamNotifications() {
    const params = useParams<{ id: string }>();
    const tgLinkId = useYoProvider('tgLinkId', {defaultValue: ''});
    const ctx = useYoProvider('connectedTgAccounts', {defaultValue: []});
    const userCtx = useUser();
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Username</Table.Th>
                        <Table.Th>ChatId</Table.Th>
                        <Table.Th>
                            <Flex justify={'space-between'} align={'center'}>
                                <div>
                                    Actions
                                </div>
                                <Button size={'compact-sm'} onClick={() => setOpen(true)}>
                                    Now to connect
                                </Button>
                            </Flex>
                        </Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {!ctx.value.length && <Table.Tr><Table.Td colSpan={3}><Center>No connected tg accounts</Center></Table.Td></Table.Tr>}
                    <YoContextArrayMap field={'connectedTgAccounts'}>
                        <Table.Tr>
                            <Table.Td>
                                <RenderFuncField<string> field={'username'}>
                                    {ctx => ctx.value}
                                </RenderFuncField>
                            </Table.Td>
                            <Table.Td>
                                <RenderFuncField<string> field={'chatId'}>
                                    {ctx => ctx.value}
                                </RenderFuncField>
                            </Table.Td>
                            <Table.Td>
                                <RenderFuncField>
                                    {ctx => (
                                        <>
                                            <Button size={'compact-sm'} color={'yellow'} onClick={() => ctx.bypassProps.onRemove()}>
                                                Disconnect
                                            </Button>
                                        </>
                                    )}
                                </RenderFuncField>
                            </Table.Td>
                        </Table.Tr>
                    </YoContextArrayMap>
                </Table.Tbody>
            </Table>
            <Modal opened={isOpen} onClose={() => setOpen(false)} centered size={'lg'} title={'Connect to tg account'} withCloseButton>
                {tgLinkId.value ? (
                    <>
                        To connect to your Telegram account, you need to send the command <pre>/team-connect {tgLinkId.value}</pre> to the bot @{userCtx.settings.tgBotName}
                        <br/>
                        <a
                            target={'_blank'}
                            href={`https://t.me/${userCtx.settings.tgBotName}?start=c-t-${tgLinkId.value}`}
                            onClick={() => {
                                window.addEventListener('focus', () => {
                                    window.location.reload();
                                }, {once: true});
                            }}
                        >
                            Or follow the link
                        </a>
                    </>
                ): (
                    <>
                        To activate, click on the
                        <br/>
                        <Button size={'compact-sm'} fullWidth onClick={() => api.saveItem(params.id, {tgLinkId: [Math.random().toString(36).slice(2),Math.random().toString(36).slice(2)].join('')}).then(() => window.location.reload())}>Activate</Button>
                    </>
                )}

            </Modal>
        </>
    );
}

export function ViewTeams() {
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
    const [data, setData] = useState<(RawDocumentTypeTeams & { owner: RawDocumentType<AdminUser> }) | null>(null);
    const [changes, setChanges] = useState({});
    useEffect(() => promise.data && setData(promise.data.item as any), [promise.isLoading]);

    function trySave() {
        api.saveItem(params.id, changes).then(() => {
            notifications.show({ message: "Saved" });
            setChanges({});
        }).catch(displayErrorNotification)
    }

    async function tryRemove() {
        if(!await ConfirmDeleteModal()) return false;
        return api.deleteItem(params.id).then(() => navigate('/'))
    }

    if (!data) return <Loader />

    return (
        <YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges} bypassProps={{hasUnsavedChanges: Object.keys(changes).length}}>
            <Header
                backButtonUrl=""
                title={`Team «${data.name || 'unnamed'}»`}
                items={(
                    <>
                        <Button variant="primary" size="compact-sm" onClick={trySave}>Save</Button>
                        <Button variant="outline" size="compact-sm"  color="white" onClick={tryRemove}>Delete</Button>
                    </>
                )}
            />

            <Tabs defaultValue="general">
                <Tabs.List>
                    <Tabs.Tab value="general">
                        General
                    </Tabs.Tab>
                    <Tabs.Tab value="members">
                        Team members
                    </Tabs.Tab>
                    <Tabs.Tab value="notifications">
                        Notifications
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="general">
                    <VisibleOnlyAdmin debug>
                        <Group align="center" justify="space-between">
                            <Anchor mb="sm" fw={600} component={Link} to={`~/system/users/v/${data.owner._id}`}>
                                {`Owner: ${data.owner?.username}`}
                            </Anchor>
                            <Button
                                onClick={() => {
                                    yoFormModal({
                                        title: 'Add balance',
                                        required: ['amount'],
                                        defaultValue: {
                                            amount: 50,
                                        },
                                        body: (
                                            <>
                                                <RenderFuncField<number> field={'amount'}>
                                                    {ctx => (
                                                        <NumberInput
                                                            label="amount"
                                                            value={ctx.value}
                                                            onChange={ctx.onChange as any}
                                                        />
                                                    )}
                                                </RenderFuncField>
                                            </>
                                        )
                                    }).then((values) => {
                                        api._superAdminAddBalance(data._id, { amount: values.amount })
                                            .then(() => {
                                                showNotification({ title: 'Success', message: 'Balance added' });
                                            })
                                    })
                                }}
                            >
                                Add balance
                            </Button>
                        </Group>
                    </VisibleOnlyAdmin>
                    <InputField
                        label="name"
                        field="name"
                        mb="xl"
                    />
                </Tabs.Panel>
                <Tabs.Panel value="members">
                    <MembersList teamId={data._id} />
                </Tabs.Panel>
                <Tabs.Panel value="notifications">
                    <TeamNotifications />
                </Tabs.Panel>
            </Tabs>
        </YoContextEditor>
    )
}
