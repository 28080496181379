import {Input, Slider} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'sliderNumber'>> = (props) => {
    return (
        <Input.Wrapper label={props.label}>
            <Slider
                value={props.value}
                onChange={v => {
                    props.onChange(v)
                }}
            />
        </Input.Wrapper>
    );
}

export default FieldTypeString;