import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button, Card, Stack} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {AdminUsersApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {PasswordField} from "@components/YoContextEditor/components/passwordField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

const api = new AdminUsersApiClient();
export function ViewAdminUsers() {
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: 'Сохранение успешно'});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	async function tryAuthAsUser () {
		return api.authAsUser(params.id).then(() => {
			window.location.href = '/';
		})
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={'Редактор'}
				items={(
					<>
						<Button onClick={tryAuthAsUser} size={'compact-sm'}>Авторизоваться под этим юзером</Button>
						<Button variant="primary" onClick={trySave} size={'compact-sm'}>Сохранить</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>Удалить</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
				<InputField field={'username'} label={'Имя пользователя'}/>
				<PasswordField field={'password'} label={'Новый пароль'}/>
			</Card>
		</YoContextEditor>
	);
}
