import {usePromise} from "@hooks/usePromise.ts";
import {GoogleTrackingEntryApiClient} from "./api.ts";
import {Select, SelectProps} from "@mantine/core";
import useTeams from "../Teams/Context/useTeams.ts";

const api = new GoogleTrackingEntryApiClient();

interface iGoogleTrackingEntrySelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
	onChange: (el: string | null) => void;
	value: string;
}

export function GoogleTrackingEntrySelect({onChange, value, ...otherProps}: iGoogleTrackingEntrySelectProps) {
	const { activeTeam } = useTeams();
	const promise = usePromise(() => api.fetchList({limit: 1000, query: { team: activeTeam }}).then(res => res.items.map((el: any) => {
		return {
			label: el.name,
			value: el._id
		}
	})), [value]);

	return (
		<Select
			value={value}
			onChange={onChange}
			data={promise.data ?? []}
			{...otherProps}
		/>
	);
}
