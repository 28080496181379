import {useEffect, useState} from "react";
import {Dropzone, DropzoneProps, FileWithPath, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import ky from "ky";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import {getFileLink} from "../../utils/getFileLink.tsx";
import {notifications} from "@mantine/notifications";
import {Group, Image, rem, Text} from "@mantine/core";
import {parseApiError} from "../../utils/parseApiError.tsx";
import PQueue from "p-queue";

const queue = new PQueue({concurrency: 3});

export interface IImageUploaderProps extends Omit<DropzoneProps, 'loading' | 'onDrop' | 'disabled'> {
	onDrop?: (image: {_id: string, url: string}) => void;
	onDropAll?: (images: {_id: string, url: string}[]) => void;
	imageId?: string;
	imageUrl?: string;
	hideDescriptionWithImage?: boolean;
}

export function ImageUploader({ onDropAll, hideDescriptionWithImage, ...props}: IImageUploaderProps) {
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		function fn() {
			setDisabled(queue.size > 0);
		}

		queue.addListener('idle', fn);
		queue.addListener('add', fn);

		return () => {
			queue.removeListener('idle', fn);
			queue.removeListener('add', fn);
		};
	}, []);

	async function onDrop(files: Array<FileWithPath>) {
		const uploadedFiles: any[] = [];
		await queue.addAll(files.map(file => async () => {
			try {
				const fd = new FormData();
				fd.append('file', file as File);
				const res = await ky.put('/api/v1/files', {
					body: fd,
				}).json<{item: {_id: string, url: string}}>();
				uploadedFiles.push(res.item);
				props.onDrop?.(res.item);
			} catch (err) {
				notifications.show({message: parseApiError(err)});
			}
		}));

		setDisabled(false);
        onDropAll?.(uploadedFiles);
	}

	const showDescription = !(hideDescriptionWithImage && (props.imageId || props.imageUrl));

	return (
		<Dropzone
			accept={IMAGE_MIME_TYPE}
			{...props}
			loading={disabled}
			onDrop={onDrop}
			disabled={disabled}
		>
			<Group justify="center" gap="xl" mih={220} style={{pointerEvents: 'none'}}>
				<Dropzone.Accept>
					<IconUpload
						style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)'}}
						stroke={1.5}
					/>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<IconX
						style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)'}}
						stroke={1.5}
					/>
				</Dropzone.Reject>
				<Dropzone.Idle>
					{props.imageId || props.imageUrl ? (
						<Image
							src={props.imageUrl || getFileLink(props.imageId as string)}
							radius="md"
							h={200}
							w="auto"
							fit="contain"
						/>
					) : (
						<IconPhoto
							style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)'}}
							stroke={1.5}
						/>
					)}
				</Dropzone.Idle>
				{showDescription && (
					<div>
						<Text size="xl" inline>
							Перетащите изображение сюда или нажмите, чтобы выбрать файлы
						</Text>
						<Text size="sm" c="dimmed" inline mt={7}>
							Вы можете загрузить один файл размером не более 10mb
						</Text>
					</div>
				)}
			</Group>
		</Dropzone>
	);
}
