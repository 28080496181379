import {Collapse, CollapseProps} from "@mantine/core";
import {useYoProvider} from "@components/YoContextEditor";

export function baseEq(data: any) {
	return !!data;
}

export function eqString(str: string) {
	return (data: any) => data === str;
}

export function notEq(data: any) {
	return !data;
}

export function CollapseField<T = any>({field, eqFn = baseEq , children, ...otherProps}: {
	field: string,
	eqFn?: (data: T) => boolean
} & Omit<CollapseProps, 'value' | 'onChange' | 'in'>) {
	const ctx = useYoProvider<T>(field);
	return (
		<Collapse in={eqFn(ctx.value)} {...otherProps}>
			{children}
		</Collapse>
	)
}
