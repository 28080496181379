import {Select, SelectProps} from "@mantine/core";
import {WhitePagesApiClient} from "./api.ts";
import {usePromise} from "@hooks/usePromise.ts";

const api = new WhitePagesApiClient();

export function WhitePageSelect(props: Omit<SelectProps, 'data'>) {
	const promise = usePromise(() => api.fetchList({limit: 1000}).then(res => res.items.map((el: any) => {
		return {
			value: el._id,
			label: el.name || `white page ${el._id}`
		}
	})), []);

	const data = (promise.data ?? []);

	return <Select data={data} {...props}/>
}
