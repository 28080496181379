import {Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {TeamsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useMemo} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {VisibleOnlyAdmin} from "../../utils/visibleOnlyAdmin.tsx";

const api = new TeamsApiClient()

interface IItem {
    _id: string;
    name: string;
}

export function ListTeams() {
    const navigate = useNavigate();
    const defaults = useDefaultsTableState();
    // const t = useScopedLocale('teamsPage.list.');

    const createTableState = useMRTState<IItem>({
        visibilityLocalStorageKey: 'ui.Domains.columnVisibility',
        defaults
    });

    const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [
        {
            accessorKey: '_id',
            header: "Identifier",
            enableSorting: false,
            enableColumnActions: false,
        }, {
            accessorKey: 'name',
            header: "Name",
        },
        {
            accessorKey: 'actions',
            header: "Actions",
            enableSorting: false,
            enableColumnActions: false,
            Cell: (props) => (
                <>

                    <Button
                        variant="primary"
                        component={Link}
                        href={`/v/${props.row.original._id}`}
                        size={'compact-sm'}
                    >
                        Edit
                    </Button>
                </>
            )
        }
    ], []);

    const data = useYoApi({ apiClient: api, defaults });
    const tableState = createTableState({
        data,
        columns
    });
    useMRTYoApiDataAdapter({
        apiClient: data,
        tableParams: tableState.adapterProps,
        defaults,
    });
    const table = useMantineReactTable<IItem>(tableState);

    async function tryCreate() {
        const data = await yoFormModal<any>({
            title: "Create team",
            required: ['name'],
            defaultValue: {name: ''},
            size: 'lg',
            body: (
                <>
                    <InputField field={'name'} label="name"/>
                </>
            )
        })
        return api.createItem(data).then(res => navigate(`/v/${res.item._id}`)).catch(displayErrorNotification);
    }

    return <>
        <Header
            title="Teams"
            items={(
                <>
                    <VisibleOnlyAdmin>
                        <Button variant="primary" size={'compact-sm'} onClick={tryCreate}>Create</Button>
                    </VisibleOnlyAdmin>
                </>
            )}
        />
        <MantineReactTable
            table={table}
        />
    </>;
}
