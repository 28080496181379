import {useState} from "react";
import {TeamsApiClient} from "../api.ts";
import {Button, Stack, TextInput} from "@mantine/core";

const api = new TeamsApiClient();

function CreateTeamForm({ onSuccess }: { onSuccess: () => Promise<void> }) {
    const [processing, setProcessing] = useState<boolean>(false);
    const [name, setName] = useState("");

    const tryCreateTeam = async () => {
        setProcessing(true);
        await api.createItem({
            name: name,
        });
        await onSuccess();
        setProcessing(false);
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            void tryCreateTeam();
        }}>
            <Stack>
                <TextInput
                    required
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    type="submit"
                    disabled={processing}
                >
                    Create new team
                </Button>
            </Stack>
        </form>
    );
}

export default CreateTeamForm;