import {Card, Group, Loader, Stack, Title, Text, Divider, Button} from "@mantine/core";
import {TeamInvitesApiClient, TMyTeamInvite} from "../OnBoarding/api.ts";
import useTeams from "../Teams/Context/useTeams";
import {useEffect, useState } from "react";

const api = new TeamInvitesApiClient();

function TeamInvitesPage() {
    const { loadMyTeams } = useTeams();
    const [teamInvites, setTeamInvites] = useState<TMyTeamInvite[] | null>(null);

    const loadUserTeamInvites = async () => {
        api.getMyInvites().then((data) => {
            if (data.status) {
                setTeamInvites(data.items);
            }
        })
    }

    useEffect(() => {
        void loadUserTeamInvites();
    }, []);

    const tryAcceptTeamInvite = async (id: string) => {
        api.accept(id).then(() => {
            void loadUserTeamInvites();
            void loadMyTeams();
        })
    }

    const tryRejectTeamInvite = async (id: string)=>  {
        api.reject(id).then(() => {
            void loadUserTeamInvites();
            void loadMyTeams();
        });
    }

    return (
        <div>
            <Stack>
                <Title order={3}>
                    Your invites to teams
                </Title>
                {
                    !teamInvites ? <Loader /> : (
                        teamInvites.length > 0 ? (
                            teamInvites.map((invite) => (
                                <Card key={invite._id} withBorder>
                                    <Group justify="space-between">
                                        <Group>
                                            <Stack gap={0}>
                                                <Text c="dimmed" size="xs">
                                                    Team
                                                </Text>
                                                <Text>
                                                    {invite.team?.name}
                                                </Text>
                                            </Stack>
                                            <Divider  orientation="vertical" />
                                            <Stack gap={0}>
                                                <Text c="dimmed" size="xs">
                                                    Role
                                                </Text>
                                                <Text>
                                                    {invite.role}
                                                </Text>
                                            </Stack>
                                        </Group>
                                        <Group>
                                            <Button
                                                variant="light"
                                                c="green"
                                                onClick={() => tryAcceptTeamInvite(invite._id)}
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                variant="light"
                                                c="red"
                                                onClick={() => tryRejectTeamInvite(invite._id)}
                                            >
                                                Reject
                                            </Button>
                                        </Group>
                                    </Group>
                                </Card>
                            ))) : (
                                <Text>
                                    you have no invitations to the team
                                </Text>

                        )
                    )
                }
            </Stack>
        </div>
    )
}

export default TeamInvitesPage;