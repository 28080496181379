import {IconChevronRight, IconLogout, IconSearch, IconUser, IconUsers} from '@tabler/icons-react';
import {Avatar, Group, Kbd, Menu, rem, Text, UnstyledButton} from "@mantine/core";
import classes from './style.module.pcss'
import {useUser} from "@engine/UserContext.tsx";
import defaultUserIcon from './user.png';
import {spotlight} from "@mantine/spotlight";
import useTeams from "../../Pages/Teams/Context/useTeams.ts";
import {teamUserRoles} from "@shared/_data/teamUserRoles.ts";
import {useEffect, useState} from "react";
import {TransactionsApiClient} from "../../Pages/Billing/Transactions/api.ts";
import {getFileLink} from "../../utils/getFileLink.tsx";
import {Link} from "wouter";

const api = new TransactionsApiClient()

function SpentThisMonth () {
	const [spentThisMonth, setSpentThisMonth] = useState<number | null>(null);

	useEffect(() => {
		api.spentThisMonth()
			.then(data => {
				if (data.status) {
					setSpentThisMonth(data.result)
				}
			})
	}, []);

	if (spentThisMonth === null) return null;

	return (
		<Group wrap="nowrap" justify="space-between" align="center">
			<Text c="dimmed" size="xs">
				Spent this month:
			</Text>
			<Text>
				{`${spentThisMonth.toFixed(2)}$`}
			</Text>
		</Group>
	);
}

export function UserMenu() {
	const {user, logout} = useUser();
	const { myTeams, activeTeam } = useTeams();
	const selectedTeam = myTeams.find(e => e._id === activeTeam);
	const role = teamUserRoles[selectedTeam?.role || 'admin'].label;

	const displayBalance = selectedTeam ? selectedTeam.balance : 0;

	return (
		<Menu width={200} shadow="md">
			<Menu.Target>
				<UnstyledButton className={classes.user}>
					<Group align="start">
						<Avatar
							src={user.avatar ? getFileLink(user.avatar) : defaultUserIcon}
							radius="xl"
						/>
						<div style={{flex: 1}}>
							<Group align="start" justify="space-between">
								<Text size="sm" fw={500} c="brand">
									{user.username}
								</Text>
								<Text c="dimmed" size="xs">
									{user.isSuperAdmin ? 'SuperAdmin*' : role}
								</Text>
							</Group>
							<Group wrap="nowrap" justify="space-between" align="center">
								<Text c="dimmed" size="xs">
									Balance:
								</Text>
								<Text>
									{`${displayBalance.toFixed(2)}$`}
								</Text>
							</Group>
							{
								role !== 'buyer' && (
									<SpentThisMonth />
								)
							}
						</div>
						<IconChevronRight style={{width: rem(14), height: rem(14)}} stroke={1.5}/>
					</Group>
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
					component={Link}
					href="~/profile"
				>
					Profile
				</Menu.Item>
				<Menu.Item
					leftSection={<IconSearch style={{ width: rem(14), height: rem(14) }} />}
					onClick={() => spotlight.open()}
				>
					Spotlight <br/> (<Kbd>shift</Kbd> + <Kbd>tab</Kbd>)
				</Menu.Item>
				<Menu.Item component={Link} to='/teamInvites' leftSection={<IconUsers style={{ width: rem(14), height: rem(14) }} />}>
					Team invites
				</Menu.Item>
				<Menu.Item
					leftSection={<IconLogout style={{ width: rem(14), height: rem(14), color: 'red' }} />}
					onClick={logout}
				>
					Выйти
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	)

}
