import './utils/sentry.ts';
import '@total-typescript/ts-reset';
import ReactDOM from 'react-dom/client'
import AppLoader from './App.tsx'
import {Router} from "wouter";

if(import.meta.env.DEV) {
    document.title = `[DEV] ${document.title}`;
} else if(location.host.includes('stage')) {
    document.title = `[STAGE] ${document.title}`;
}

const domNode = document.getElementById('root');
ReactDOM.createRoot(domNode as Element).render(
    <Router>
        <AppLoader />
    </Router>
);
