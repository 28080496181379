export const yearSuffixMap: Record<string, string> = {
	RU: 'г.',    // Русский
	UK: 'р.',    // Украинский
	PL: 'r.',    // Польский
	BG: 'г.',    // Болгарский
	SR: 'г.',    // Сербский
	HR: 'g.',    // Хорватский
	RO: 'an',    // Румынский
	HU: 'év',    // Венгерский
	TH: 'ปี',    // Тайский
	ID: 'thn',   // Индонезийский
	MS: 'thn',   // Малайский
	EL: 'έτος',  // Греческий
	HI: 'वर्ष',  // Хинди
	BN: 'বছর',   // Бенгальский
	TA: 'ஆண்டு', // Тамильский
	UR: 'سال',   // Урду
};
