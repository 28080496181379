import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {endOfToday, startOfToday} from "date-fns";
import {usePromise} from "@hooks/usePromise.ts";
import {Flex, Loader, SimpleGrid} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {FbPixelsApiClient} from "./api.ts";
import {StatsGrid} from "@components/StatsGrid";
import {AreaChart} from "@mantine/charts";
import {useState} from "react";
import {SelectWebApp} from "../WebApps/utils/selectWebApp.tsx";
import {useLocale} from "../../locale";

const api = new FbPixelsApiClient();

export function PixelStats(props: { id: string }) {
	const t = useLocale();
	const timeControls = useTimeState(startOfToday(), endOfToday());
	const [webAppId, setWebAppId] = useState('any');
	const statCards = usePromise(() => api.fetchStats(props.id, {...timeControls.queryValue, appId: webAppId !== 'any' ? webAppId : undefined}), [props.id, webAppId, JSON.stringify(timeControls.queryValue)]);
	const statChart = usePromise(() => api.fetchStatsChart(props.id, {...timeControls.queryValue, appId: webAppId !== 'any' ? webAppId : undefined}), [props.id, webAppId, JSON.stringify(timeControls.queryValue)]);

	return (
		<>
			<Flex justify={'space-between'}>
				<SelectWebApp value={webAppId} onChange={setWebAppId as any} label={t('fbPixels.stat.selectWebApp')}/>
				<TimeRangeSelect onChange={timeControls.setValue} defaultMode={'today'} label={t('fbPixels.stat.timeRange')}/>
			</Flex>
			{statCards.isLoading ? <Flex justify={'center'}><Loader/></Flex> : null}
			{statCards.isError ? displayTSMatchGeneralError(statCards) : null}
			{!statCards.isLoading && statCards.data ? (
				<>
					<SimpleGrid cols={3}>
						<StatsGrid
							value={statCards.data.installs}
							title={t('fbPixels.stat.installs')}
						/>
						<StatsGrid
							value={statCards.data.registrations}
							title={t('fbPixels.stat.registrations')}
						/>
						<StatsGrid
							value={statCards.data.deposits}
							title={t('fbPixels.stat.deposits')}
						/>
					</SimpleGrid>
				</>
			) : null}
			<br/>
			{statChart.isLoading ? <Flex justify={'center'}><Loader/></Flex> : null}
			{statChart.isError ? displayTSMatchGeneralError(statChart) : null}
			{!statChart.isLoading && statChart.data ? (
				<>
					<AreaChart
						h={300}
						data={statChart.data.data}
						dataKey="label"
						series={[
							{ name: 'i', label: t('fbPixels.stat.installCount'), color: 'indigo.6' },
							{ name: 'r', label: t('fbPixels.stat.registrationCount'), color: 'blue.6' },
							{ name: 'd', label: t('fbPixels.stat.depositCount'), color: 'teal.6' },
						]}
						curveType="linear"
					/>
				</>
			) : null}
		</>
	);
}

