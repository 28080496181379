import {Route} from "wouter";
import {ListPushMessages} from "./listPushMessages.tsx";
import {ViewPushMessages} from "./viewPushMessages.tsx";
import MenuManager from "@engine/MenuManager.ts";

const baseUrl = '/messages';

MenuManager.RegisterItem({
	path: `/push${baseUrl}`,
	key: 'pushMessages',
	nameKey: 'menuItems.push.Messages',
	parent: 'push'
})

export function PushMessagesRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListPushMessages}/>
			<Route path={'/v/:id'} component={ViewPushMessages}/>
		</Route>
	);
}
