import {tariffsConfig} from "@shared/_data/tariffs/tariffsConfig.ts";
import {Card, Divider, Grid, Group, Space, Stack, Text} from "@mantine/core";
import useTeams from "../../../Teams/Context/useTeams.ts";
import SwitchTariffModal from "./SwitchTariffModal.tsx";

function TariffsGrid() {
    const { team } = useTeams();
    if (!team) return null;
    const teamTariff = team.tariff;

    return (
        <Grid justify="center">
            {Object.entries(tariffsConfig).map(([tariffKey, tariff]) => {
                const active = tariffKey === teamTariff;

                return (
                    <Grid.Col key={tariffKey} span={{lg: 4}}>
                        <Card
                            variant="section"
                            h="13rem"
                            withBorder
                            shadow="md"
                            style={{
                                border: active ? '1px solid green' : undefined,
                            }}
                        >
                            <Text size="xl" fw={600} mb="xs">
                                {tariff.label}
                            </Text>
                            {
                                tariff.maxTeamMembers > 0 ? (
                                    <Group justify="space-between">
                                        <Text>
                                            Max team members:
                                        </Text>
                                        <Text>
                                            {tariff.maxTeamMembers}
                                        </Text>
                                    </Group>
                                ) : null
                            }
                            <Group justify="space-between">
                                <Text>Maximum apps:</Text>
                                <Text>{tariff.maxPwaEdgeRecords}</Text>
                            </Group>
                            <Divider style={{marginTop: 'auto'}}/>
                            <Space h="sm"/>
                            {
                                tariff.pricePerInstall && (
                                    <Group justify="end">
                                        <Text>
                                            Price per install
                                        </Text>
                                        <Text>
                                            {tariff.pricePerInstall}$
                                        </Text>
                                    </Group>
                                )
                            }
                            <Group justify="space-between">
                                <div>
                                    {
                                        active ? (
                                            <Text>
                                                Current tariff
                                            </Text>
                                        ) : (
                                            <SwitchTariffModal
                                                tariffKey={tariffKey}
                                            />
                                        )
                                    }
                                </div>
                                {
                                    tariff.type === 'monthly' && (
                                        <Stack gap={0}>
                                            <Text size="xl">
                                                {tariff.price}$
                                            </Text>
                                            <Text size="xs" c="dimmed">
                                                per month
                                            </Text>
                                        </Stack>
                                    )
                                }
                                {
                                    tariff.type === 'payAsYouGo' && (
                                        <Text>
                                            Pay As You Go
                                        </Text>
                                    )
                                }
                            </Group>
                        </Card>
                    </Grid.Col>
                )
            })}
        </Grid>
    );
}

export default TariffsGrid;