import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

interface iGalleryItem {
	_id: string;
	name: string;
	description: string;
	image: string;
}

@singleton
export class WhitePagesApiClient extends YoApiClient {
	path = '/api/v1/whitePages';

	fetchLandingAnalysis(landingId: string, fileId: string) {
		return this.ky.get(`${landingId}/analyzeLanding`, {
			searchParams: {
				fileId
			}
		}).json();
	}

	fetchGallery() {
		return this.ky.get('gallery').json<{status: boolean, items: iGalleryItem[]}>();
	}

	createItemFromTemplate(id: string, override: any) {
		return this.ky.put(`gallery`, {
			searchParams: {templateId: id},
			json: override
		}).json<{ item: any, status: boolean }>();
	}
}
