import {notifications} from "@mantine/notifications";
import {IconHandStop} from "@tabler/icons-react";
import {HTTPError} from "ky";

export async function displayErrorNotification(err: unknown) {
	let title = 'Ошибка';
	let errMessage = 'Неизвестная ошибка';
	if (err instanceof Error) errMessage = err.message;
	if (err instanceof HTTPError && err.response.body) {
		const errJson = await err.response.json() as {message: string};
		errMessage = errJson.message;
		title = `Ошибка ${err.response.status}`;
	}
	return notifications.show({
		color: 'red',
		title,
		message: errMessage,
		icon: <IconHandStop/>
	});
}
