import {Box, Flex, LoadingOverlay, Table} from "@mantine/core";
import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {iStateProps} from "../../../MainPage/IStateProps";
import {addStatsJob} from "../../../MainPage/utils/statsQueue";
import {useScopedLocale} from "../../../../locale";
import {IconRefresh} from "@tabler/icons-react";
import {Link} from "wouter";

export function NotOpenedPushTop(props: iStateProps) {
	const t = useScopedLocale('pages.push.stats.');
	const promise = usePromiseWithTeamDependency(() => {
	return addStatsJob(() => getKyInstance().post('/api/v1/stats/push/notOpened', {
			json: {
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers
			},
			timeout: 1000 * 30
		}).json<{ items: any[] }>());
	}, [JSON.stringify([props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);

	const items = (promise.data?.items || []);

	return (
		<>
			<Flex justify={'space-between'} align={'center'}>
				<h4>{t('top.notOpened.title')}</h4>
				<IconRefresh onClick={promise.run} style={{cursor: 'pointer'}}/>
			</Flex>
			<Box pos={'relative'}>
				<LoadingOverlay visible={promise.isLoading}/>
				<div style={{
					height: '300px',
					overflow: 'scroll'
				}}>
					<Table stickyHeader style={{overflow: 'scroll'}} data={{
						head: ['Template', "Locale", 'Title', 'Count'],
						body: items.map(data => [
							<Link href={`~/push/messages/v/${data.template._id}`}>
								{data.template.title ?? 'No title'}
							</Link>,
							data.locale,
							data.title,
							data.count,
						])
					}}/>
					{!promise.isLoading && items.length === 0 && (
						<div style={{textAlign: 'center'}}>No data</div>
					)}
					{promise.isError && displayTSMatchGeneralError(promise)}
				</div>
			</Box>
		</>
	);
}
