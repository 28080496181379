import {Button, TextInput} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {GoogleTrackingEntryApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {CopyButtonWithTooltip} from "../../utils/copyButtonWithTooltip.tsx";
import useTeams from "../Teams/Context/useTeams.ts";

const api = new GoogleTrackingEntryApiClient()

interface IItem {
	_id: string;
}

export function ListGoogleTrackingEntry() {
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();
	const { activeTeam } = useTeams();
	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.GoogleTrackingEntry.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: '_id',
		header: 'Идентификатор',
		enableSorting: false,
		enableColumnActions: false,
	},{
		accessorKey: 'name',
		header: 'Название',
	},{
		accessorKey: 'fullDomain',
		header: 'Ссылка на отчёт в формате Excel',
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => {
			const value = props.cell.getValue<string>();
			return (
				<TextInput
					value={`https://${value}/export/feed.xlsx`}
					readOnly
					rightSection={<CopyButtonWithTooltip value={`https://${value}/export/feed.xlsx`}/>}
				/>
			);
		}
	},{
		accessorKey: 'actions',
		header: 'Действия',
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Link href={`/v/${props.row.original._id}`} >
					<a>
						<Button size={'compact-sm'}>Редактировать</Button>
					</a>

				</Link>

			</>
		)
	}], []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const data = await yoFormModal<any>({
			title: 'Новая запись',
			required: ['name'],
			defaultValue: {},
			size: 'lg',
			body: (
				<>
					<InputField field={'name'} label={'Название'} />
				</>
			)
		})
		return api.createItem({ ...data, team: activeTeam }).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={'Ссылки для передачи лидов google ads'}
			items={(
				<>
					<Button variant="primary" size={'compact-sm'} onClick={tryCreate}>Новая ссылка</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
