import {Select, SelectProps} from "@mantine/core";
import {useYoProvider} from "@components/YoContextEditor/index.tsx";
import useTeams from "../../Pages/Teams/Context/useTeams.ts";

export interface SelectFieldProps extends Omit<SelectProps, 'value' | 'onChange' | 'data'> {
    field?: string;
    defaultValue?: string;
}

export function TeamSelectField({ field: _field, defaultValue ,  ...otherProps }: SelectFieldProps) {
    const { myTeams } = useTeams();
    const field = _field || 'team';
    const ctx = useYoProvider<string>(field);
    const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;

    return (
        <Select
            label="Team"
            value={ctx.value ?? defaultValue ?? 'personal'}
            onChange={e => {
                ctx.onChange(e === 'personal' ? null : e)
            }}
            {...otherProps}
            readOnly={readOnly}
            data={myTeams.map(e => ({ label: e.name, value: e._id }))}
        />
    )
}

export default TeamSelectField;