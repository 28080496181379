import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField, eqString, notEq} from "@components/YoContextEditor/components/CollapseField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {Button, Card, Fieldset, Flex, SimpleGrid, Title} from "@mantine/core";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {ReadonlyAreaField} from "@components/YoContextEditor/ReadonlyAreaField.tsx";
import {RuleEditorField} from "./components/rules/ruleEditorField.tsx";
import {RulesHeaderField} from "./components/rules/RulesHeaderField.tsx";
import {useScopedLocale} from "../../../locale";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";

export function ABTab() {
	const tt = useScopedLocale('webApps.view.ABTab.');

	return (
		<Card withBorder>
			<Title order={4} mb="lg">{tt('title')}</Title>
			<Card variant="section">
				<CheckboxField field={'redirectActions.enabled'} label={tt('enabled')}/>
			</Card>
			<br/>
			<CollapseField field={'redirectActions.enabled'}>
				<YoContextArrayMap field={'redirectActions.rules'}>
					<Fieldset>
						<RulesHeaderField badgeColor={'gray'} getBadgeContent={ctx => `${tt('rules.header')} №${ctx.bypassProps.__index + 1}`}/>
						<br/>
						<SimpleGrid cols={2}>
							<Flex align={'center'}>
								<CheckboxField field={'enabled'} label={tt('rules.enabled')}/>
							</Flex>
							<InputField field={'comment'} label={tt('rules.comment.label')} description={tt('rules.comment.description')}/>
						</SimpleGrid>
						<ReadonlyAreaField field={'enabled'} eqFn={notEq}>
							<h4>{tt('rules.conditions.title')}</h4>
							<YoContextArrayMap field={'conditions'}>
								<RulesHeaderField badgeColor={'teal'} getBadgeContent={ctx => `${tt('rules.conditions.header')} №${ctx.bypassProps.__index + 1}`}/>
								<RuleEditorField/>
								<hr/>
							</YoContextArrayMap>
							<hr/>
							<RenderFuncField field={'conditions'}>
								{ctx => (
									<Button disabled={ctx.bypassProps.readOnly} size={'compact-sm'} fullWidth onClick={() => ctx.push({_id: mongoObjectId(), ruleType: 'language'})}>
										{tt('rules.conditions.add')}
									</Button>
								)}
							</RenderFuncField>
							<h4>Действие</h4>
							<SelectField
								field={'actionType'}
								label={tt('rules.actionType.label')}
								data={[
									{label: tt('rules.actionType.redirect'), value: 'redirect'},
									{label: tt('rules.actionType.blankPage'), value: 'blankPage'},
									{label: tt('rules.actionType.showRawContent'), value: 'showRawContent'}
								]}/>
							<CollapseField field={'actionType'} eqFn={eqString('redirect')}>
								<InputField field={'targetUrl'} label={tt('rules.actionType.redirect.targetUrl.label')}/>
								<br/>
								<CheckboxField field={'saveOriginalQueryString'} label={tt('rules.actionType.saveOriginalQueryString')}/>
							</CollapseField>
							<CollapseField field={'actionType'} eqFn={eqString('showRawContent')}>
								<TextareaField field={'rawContent'} label={tt('rules.actionType.rawContent.content.label')}/>
							</CollapseField>
							<hr/>
						</ReadonlyAreaField>
					</Fieldset>
				</YoContextArrayMap>
				<br/>
				<RenderFuncField field={'redirectActions.rules'}>
					{ctx => (
						<Button fullWidth onClick={() => ctx.push({_id: mongoObjectId(), enabled: true})}>
							{tt('rules.add')}
						</Button>
					)}
				</RenderFuncField>
			</CollapseField>
		</Card>
	)
}
