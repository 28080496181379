import {MultiSelect, MultiSelectProps} from "@mantine/core";
import {useLocaleContext} from "../locale";

const multiLangData = [
	{
		"label": {
			"ru": "Игровые консоли (Sony PlayStation, Microsoft Xbox)",
			"en": "Game consoles (Sony PlayStation, Microsoft Xbox)",
			"uk": "Ігрові консолі (Sony PlayStation, Microsoft Xbox)"
		},
		"value": "console"
	},
	{
		"label": {
			"ru": "Встроенные устройства (Amazon Echo Dot, Tesla)",
			"en": "Embedded devices (Amazon Echo Dot, Tesla)",
			"uk": "Вбудовані пристрої (Amazon Echo Dot, Tesla)"
		},
		"value": "embedded"
	},
	{
		"label": {
			"ru": "Мобильные телефоны (Apple iPhone, Samsung Galaxy)",
			"en": "Mobile phones (Apple iPhone, Samsung Galaxy)",
			"uk": "Мобільні телефони (Apple iPhone, Samsung Galaxy)"
		},
		"value": "mobile"
	},
	{
		"label": {
			"ru": "Умные телевизоры (LG Smart TV, Samsung Smart TV)",
			"en": "Smart TVs (LG Smart TV, Samsung Smart TV)",
			"uk": "Розумні телевізори (LG Smart TV, Samsung Smart TV)"
		},
		"value": "smarttv"
	},
	{
		"label": {
			"ru": "Планшеты (Apple iPad, Samsung Galaxy Tab)",
			"en": "Tablets (Apple iPad, Samsung Galaxy Tab)",
			"uk": "Планшети (Apple iPad, Samsung Galaxy Tab)"
		},
		"value": "tablet"
	},
	{
		"label": {
			"ru": "Носимые устройства (Pebble, Apple Watch)",
			"en": "Wearable devices (Pebble, Apple Watch)",
			"uk": "Носимі пристрої (Pebble, Apple Watch)"
		},
		"value": "wearable"
	},
	{
		"label": {
			"ru": "Устройства расширенной реальности (Google Glass, Oculus Quest)",
			"en": "Augmented reality devices (Google Glass, Oculus Quest)",
			"uk": "Пристрої доповненої реальності (Google Glass, Oculus Quest)"
		},
		"value": "xr"
	},
	{
		"label": {
			"ru": "Персональные компьютеры и ноутбуки (PC, MacBook и другие не определенные устройства)",
			"en": "Personal computers and laptops (PC, MacBook, and other unspecified devices)",
			"uk": "Персональні комп’ютери та ноутбуки (PC, MacBook та інші непередбачені пристрої)"
		},
		"value": "other"
	}
];

export function DeviceTypeSelect(props: MultiSelectProps) {
	const { selectedLocale } = useLocaleContext();

	const data = multiLangData.map(item => ({...item, label: item.label[selectedLocale] ?? item.label['en']}))

	return <MultiSelect data={data} {...props}/>;
}
