import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {Card, Fieldset, Stack, Title} from "@mantine/core";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import { VisibleOnlyAdmin } from "../../../utils/visibleOnlyAdmin";
import {CountryMultiSelect} from "@components/Utils/Country/CountrySelect.tsx";
import { DeviceTypeSelect } from "@components/deviceTypeSelect";
import {OsTypeSelect} from "@components/osTypeSelect.tsx";
import {WhitePageSelect} from "../../WhitePages/whitePageSelect.tsx";
import {useScopedLocale} from "../../../locale";

export function CloakingSettingsPanel() {
	const tt = useScopedLocale('webApps.view.CloakingTab.');

	return (
		<Card withBorder>
			<Title order={4} mb="lg">{tt('title')}</Title>
			<Stack>
				<Card variant="section">
					<CheckboxField field={'cloaking.enabled'} label={tt('enabled')}/>
				</Card>
				<CollapseField field={'cloaking.enabled'}>
					<Stack gap="lg">
						<VisibleOnlyAdmin debug>
							<Fieldset legend={tt('useIpBlacklist.legend')}>
								<CheckboxField
									field={'cloaking.useIpBlacklist'}
									label={tt('useIpBlacklist.label')}
									description={tt('useIpBlacklist.description')}
								/>
							</Fieldset>
							<Fieldset legend={tt('useUaBlacklist.legend')}>
								<CheckboxField
									field={'cloaking.useUaBlacklist'}
									label={tt('useUaBlacklist.label')}
									description={tt('useUaBlacklist.description')}
								/>
							</Fieldset>
						</VisibleOnlyAdmin>
						<div>
							<Title order={5}>
								{tt('countryList.legend')}
							</Title>
							<Card variant="section">
								<RenderFuncField<string[]> field={'cloaking.countryList'}>
									{ctx => (
										<CountryMultiSelect searchable value={ctx.value ?? []} onChange={e => ctx.onChange(e)} cca2/>
									)}
								</RenderFuncField>
								<SelectField
									mt="sm"
									label={tt('countryListMode.label')}
									field={'cloaking.countryListMode'}
									data={[{
										label: `✅ ${tt('countryListMode.WHITELIST')}`,
										value: 'WHITELIST'
									}, {
										label: `❌ ${tt('countryListMode.BLACKLIST')}`,
										value: 'BLACKLIST'
									}]}
								/>
							</Card>
						</div>
						<div>
							<Title order={5}>
								{tt('useDeviceType.legend')}
							</Title>
							<Card variant="section">
								<CheckboxField field={'cloaking.useDeviceType'} label={tt('useDeviceType.label')}/>
								<CollapseField field={'cloaking.useDeviceType'} pt="md">
									<RenderFuncField<string[]> field={'cloaking.deviceTypeList'}>
										{ctx => (
											<DeviceTypeSelect searchable value={ctx.value ?? []} onChange={e => ctx.onChange(e)}/>
										)}
									</RenderFuncField>
									<SelectField
										label={tt('deviceTypeListMode.label')}
										field={'cloaking.deviceTypeListMode'}
										data={[{
											label: `✅ ${tt('deviceTypeListMode.WHITELIST')}`,
											value: 'WHITELIST'
										}, {
											label: `❌ ${tt('deviceTypeListMode.BLACKLIST')}`,
											value: 'BLACKLIST'
										}]}
									/>
								</CollapseField>
							</Card>
						</div>
						<div>
							<Title order={5}>
								{tt('useOSList.legend')}
							</Title>
							<Card variant="section">
								<CheckboxField field={'cloaking.useOSList'} label={tt('useOSList.label')}/>
								<CollapseField field={'cloaking.useOSList'} pt="md">
									<RenderFuncField<string[]> field={'cloaking.osList'}>
										{ctx => (
											<OsTypeSelect searchable value={ctx.value ?? []} onChange={e => ctx.onChange(e)}/>
										)}
									</RenderFuncField>
									<SelectField
										label={tt('osListMode.label')}
										field={'cloaking.osListMode'}
										data={[{
											label: `✅ ${tt('osListMode.WHITELIST')}`,
											value: 'WHITELIST'
										}, {
											label: `❌ ${tt('osListMode.BLACKLIST')}`,
											value: 'BLACKLIST'
										}]}
									/>
								</CollapseField>
							</Card>
						</div>
						<div>
							<Title order={5}>
								{tt('useAdspect.legend')}
							</Title>
							<Card variant="section">
								<CheckboxField
									field={'cloaking.useAdspect'}
									label={tt('useAdspect.label')}
								/>
								<CollapseField field={'cloaking.useAdspect'} pt="md">
									<InputField
										field={'cloaking.adspectStreamId'}
										label={tt('adspectStreamId.label')}
										placeholder={tt('adspectStreamId.placeholder')}
									/>
								</CollapseField>
							</Card>
						</div>
						<div>
							<Title order={5}>
								{tt('rejectAction.legend')}
							</Title>
							<Card variant="section">
								<SelectField
									label={tt('rejectAction.label')}
									field={'cloaking.rejectAction'}
									data={[{
										label: tt('rejectAction.SHOW_200'),
										value: 'SHOW_200'
									}, {
										label: tt('rejectAction.SHOW_404'),
										value: 'SHOW_404'
									}, {
										label: tt('rejectAction.REDIRECT'),
										value: 'REDIRECT'
									}, {
										label: tt('rejectAction.WHITE_PAGE'),
										value: 'WHITE_PAGE',
									}]}
								/>
								<CollapseField field={'cloaking.rejectAction'} eqFn={eqString('REDIRECT')} pt="md">
									<InputField field={'cloaking.redirectUrl'} label={tt('redirectUrl.label')}/>
								</CollapseField>
								<CollapseField field={'cloaking.rejectAction'} eqFn={eqString('WHITE_PAGE')} pt="md">
									<RenderFuncField<string> field={'cloaking.whitePage'}>
										{ctx => <WhitePageSelect label={tt('whitePageSelect.label')} value={ctx.value} onChange={ctx.onChange}/>}
									</RenderFuncField>
								</CollapseField>
							</Card>
						</div>
					</Stack>
				</CollapseField>
			</Stack>
		</Card>
	);
}
