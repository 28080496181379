import {
	Badge,
	Box,
	Button,
	Card,
	Center, Chip, Flex,
	Grid,
	Group,
	Image,
	LoadingOverlay,
	Modal, Pagination, Table,
	Text, TextInput,
	Title
} from "@mantine/core";
import {useScopedLocale} from "../../../../../../locale";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {RulesHeaderField} from "../../../components/rules/RulesHeaderField.tsx";
import {mongoObjectId} from "../../../../../../utils/mongoObjectId.ts";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {ImageUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {WebAppsApiClient} from "../../../../api.ts";
import {useYoProvider} from "@components/YoContextEditor";
import {useState} from "react";
import {usePromise} from "@hooks/usePromise.ts";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {getFileLink} from "../../../../../../utils/getFileLink.tsx";
import {useDebouncedValue} from "@mantine/hooks";

const api = new WebAppsApiClient();

function SelectAppCardGrid(props: { items: any[], locale: string, onSelect: (app: any) => void }) {
	return (
		<Grid>
			{props.items.length === 0 && (
				<Grid.Col span={12}>
					<Center mih={'70vh'}>
						<Text size="xl" color="dimmed">
							No apps found
						</Text>
					</Center>
				</Grid.Col>
			)}
			{props.items.map(app => {
				const locale = app.localeAppData[props.locale] ?? Object.values(app.localeAppData).find((el: any) => el.isDefault);
				if (!locale) return null;

				const name = locale.content.dataMap?.name ?? locale.content.name;
				const icon = locale.content.dataMap?.icon ?? locale.content.icon;
				const rating = locale.content.dataMap?.rating?.rating ?? locale.content.rating;

				return (
					<Grid.Col span={6}>
						<Card shadow="sm" padding="lg" radius="md" withBorder>
							<Card.Section>
								<Image
									src={getFileLink(icon)}
									height={160}
									alt={app.name}
								/>
							</Card.Section>

							<Group justify="space-between" mt="md" mb="xs">
								<Text fw={500}>
									{name ?? '[NO NAME]'}
									<br/>
									<Text size={'xs'}>
										{app.name}
									</Text>
								</Text>
								<Badge color="pink">
									{app.templateKey}
								</Badge>
							</Group>

							<Text size="sm" c="dimmed">
								Domain: <b>{app.domain.domain}</b>
								<br/>
								Rating: <b>{rating}</b>
							</Text>

							<Button color="blue" fullWidth mt="md" radius="md" onClick={() => props.onSelect(app)}>
								Select this app
							</Button>
						</Card>
					</Grid.Col>
				)
			})}
		</Grid>
	);
}

function SelectAppCardTable(props: { onSelect: (app: any) => void, items: any[], locale: string }) {
	return (
		<Table>
			<Table.Thead>
				<Table.Tr>
					<Table.Th>App name</Table.Th>
					<Table.Th>Icon</Table.Th>
					<Table.Th>Type</Table.Th>
					<Table.Th>Domain</Table.Th>
					<Table.Th>Rating</Table.Th>
					<Table.Th/>
				</Table.Tr>
			</Table.Thead>
			<Table.Tbody>
				{props.items.length === 0 && (
					<Table.Tr>
						<Table.Td colSpan={6}>
							<Center mih={'70vh'}>
								<Text size="xl" color="dimmed">
									No apps found
								</Text>
							</Center>
						</Table.Td>
					</Table.Tr>
				)}
				{props.items.map(app => {
					const locale = app.localeAppData[props.locale] ?? Object.values(app.localeAppData).find((el: any) => el.isDefault);
					if (!locale) return null;

					const name = locale.content.dataMap?.name ?? locale.content.name;
					const icon = locale.content.dataMap?.icon ?? locale.content.icon;
					const rating = locale.content.dataMap?.rating?.rating ?? locale.content.rating;

					return (
						<Table.Tr key={app._id}>
							<Table.Td>
								{name}
								<br/>
								<Text size={'xs'}>
									{app.name}
								</Text>
							</Table.Td>
							<Table.Td>
								<Image
									src={getFileLink(icon)}
									height={40}
									alt={app.name}
								/>
							</Table.Td>
							<Table.Td>{app.templateKey}</Table.Td>
							<Table.Td>{app.domain.domain}</Table.Td>
							<Table.Td>{rating}</Table.Td>
							<Table.Td>
								<Button color="blue" onClick={() => props.onSelect(app)}>
									Select this app
								</Button>
							</Table.Td>
						</Table.Tr>
					)
				})}
			</Table.Tbody>
		</Table>
	)
}

function SelectAppModal(props: { onSubmit: (app: any) => void, locale: string }) {
	const [viewMode, setViewMode] = useState<'list' | 'cards'>('cards');
	const [search, setSearch] = useState<string>('');
	const [debouncedSearch] = useDebouncedValue(search, 850);
	const [offset, setOffset] = useState<number>(0);
	const limit = viewMode === 'cards' ? 5 : 10;
	const promise = usePromise(() => {
		return api.fetchList({
			limit,
			offset,
			query: {
				name: debouncedSearch ? {
					useDebouncedValue: debouncedSearch,
					type: 'contains'
				} : undefined,
				state: {
					value: 'PUBLISHED',
					type: 'equals',
				}
			}
		});
	},[debouncedSearch, limit]);

	const count = promise.data?.count ?? 0;
	const data = (promise.data?.items ?? []).filter(app => {
		const locale = app.localeAppData[props.locale] ?? Object.values(app.localeAppData).find((el: any) => el.isDefault);
		const names = [
			locale ? locale.content.dataMap?.name ?? locale.content.name : false,
			app.name
		].filter(Boolean).map(el => el.toLowerCase()).join(' ');

		return names.includes(search.toLowerCase());
	});

	function selectApp(app: any) {
		const locale = app.localeAppData[props.locale] ?? Object.values(app.localeAppData).find((el: any) => el.isDefault);

		const name = locale.content.dataMap?.name ?? locale.content.name;
		const icon = locale.content.dataMap?.icon ?? locale.content.icon;
		const rating = locale.content.dataMap?.rating?.rating ?? locale.content.rating;

		return props.onSubmit({
			url: `https://${app.domain.domain}`,
			name,
			icon,
			rating
		});
	}

	if (promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<Box pos={'relative'} mih={'70vh'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<Flex>
				<TextInput
					w={'100%'}
					value={search}
					onChange={e => setSearch(e.target.value)}
				/>
				<Flex justify={'space-between'}>
					<Chip checked={viewMode === 'list'} onClick={() => setViewMode('list')}>Table</Chip>
					<Chip checked={viewMode === 'cards'} onClick={() => setViewMode('cards')}>Cards</Chip>
				</Flex>
			</Flex>
			{viewMode === 'list' ? (
				<SelectAppCardTable
					onSelect={selectApp}
					items={data}
					locale={props.locale}
				/>
			) : viewMode === 'cards' ? (
				<SelectAppCardGrid
					onSelect={selectApp}
					items={data}
					locale={props.locale}
				/>
			) : null}

			<Pagination
				total={Math.ceil(count / limit)}
				value={Math.ceil(offset / limit) + 1}
				onChange={val => setOffset((val - 1) * limit)}
			/>

		</Box>
	);
}

export default function FieldTypeString() {
	const tt = useScopedLocale('webApps.views.DescriptionTab.content.relatedApps.');
	const ctx = useYoProvider('relatedApps.apps');
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div>
			<Title order={5} mb="xs">
				{tt('title')}
			</Title>
			<Card variant="section">
				<CheckboxField field={'relatedApps.enabled'} label={tt('enabled')}/>
				<CollapseField field={'relatedApps.enabled'}>
					<SelectField
						field={'relatedApps.position'}
						label={tt('position')}
						data={[
							{value: 'top', label: tt('position.top')},
							{value: 'bottom', label: tt('position.bottom')}
						]}
					/>
					<h4>Apps</h4>
					<Grid justify="flex-start" align="stretch">
						<YoContextArrayMap field={'relatedApps.apps'}>
							<Grid.Col span={3}>
								<Card>
									<RulesHeaderField badgeColor={'gray'} getBadgeContent={() => ``}/>
									<InputField field={'name'} label={tt('app.name')}/>
									<ImageUploaderField field={'icon'} hideDescriptionWithImage/>
									<InputField field={'url'} label={tt('app.url')}/>
									<NumberField min={1} max={5} field={'rating'} label={tt('app.rating')}/>
								</Card>
							</Grid.Col>
						</YoContextArrayMap>
						<Grid.Col span={3}>
							<Card h={'100%'}>
								<Center mih={'430px'}>
									<Button fullWidth onClick={() => setIsOpen(true)}>
										Add app
									</Button>
								</Center>
							</Card>
						</Grid.Col>
					</Grid>
				</CollapseField>
			</Card>
			<Modal keepMounted={false} opened={isOpen} onClose={() => setIsOpen(false)} title="Add app" size="xl">
				<SelectAppModal
					locale={ctx.bypassProps.lang}
					onSubmit={app => {
						setIsOpen(false);
						ctx.push({_id: mongoObjectId(), ...app});
					}}/>
			</Modal>
		</div>
	);
}
