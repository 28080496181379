import {Select} from "@mantine/core";
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {IconX} from "@tabler/icons-react";
import {DatePickerInput} from "@mantine/dates";
import {useLocale} from "../locale";
import {
	endOfHour,
	endOfMonth,
	endOfToday,
	endOfWeek,
	endOfYear,
	endOfYesterday,
	startOfHour,
	startOfMonth,
	startOfToday,
	startOfWeek,
	startOfYear,
	startOfYesterday,
	subDays,
	subHours
} from "date-fns";


export function useTimeState(start?: Date, end?: Date) {
	const [value, setValue] = useState<[Date | null, Date | null]>(() => [start ?? null, end ?? null]);

	let queryValue: any = {};
	if(!value[0] && !value[1]) queryValue = undefined;
	if(value[0] && value[1]) queryValue = {type: 'between',  from: value[0], to: value[1]};
	return {value, queryValue, setValue};
}

function getRangeFromMode(mode: string): [Date | null, Date | null] {
	let from: Date | null = null;
	let to: Date | null = null;

	switch (mode) {
		case 'today':
			from = startOfHour(subHours(new Date(), 24));
			to = endOfHour(new Date());
			break;
		case 'yesterday':
			from = startOfYesterday();
			to = endOfYesterday();
			break;
		case 'week':
			from = startOfWeek(new Date(), {weekStartsOn: 1});
			to = endOfWeek(new Date(), {weekStartsOn: 1});
			break;
		case 'last7Days':
			from = startOfToday();
			from = subDays(from, 6);
			to = endOfToday();
			break;
		case 'month':
			from = startOfMonth(new Date());
			to = endOfMonth(new Date());
			break;
		case 'lastMonth':
			from = startOfMonth(subDays(new Date(), 30));
			to = endOfMonth(subDays(new Date(), 30));
			break;
		case 'last30Days':
			from = startOfToday();
			from = subDays(from, 29);
			to = endOfToday();
			break;
		case 'year':
			from = startOfYear(new Date());
			to = endOfYear(new Date());
			break;
		case 'allTime':
			from = null;
			to = null;
			break;
	}

	return [from, to];
}

export function TimeRangeSelect({onChange, defaultMode, label}: {
	defaultMode?: string,
	onChange: Dispatch<SetStateAction<[Date | null, Date | null]>>,
	label?: string
}) {
	const t = useLocale();
	const [mode, setMode] = useState(defaultMode ?? 'allTime');
	const [value, setValue] = useState<[Date | null, Date | null]>(() => getRangeFromMode(defaultMode ?? 'allTime'));

	const timeRanges = useMemo(() => {
		return [
			{label: t('components.timeRangeSelect.today'), value: 'today'},
			{label: t('components.timeRangeSelect.yesterday'), value: 'yesterday'},
			{label: t('components.timeRangeSelect.currentWeek'), value: 'week'},
			{label: t('components.timeRangeSelect.last7Days'), value: 'last7Days'},
			{label: t('components.timeRangeSelect.currentMonth'), value: 'month'},
			{label: t('components.timeRangeSelect.lastMonth'), value: 'lastMonth'},
			{label: t('components.timeRangeSelect.last30Days'), value: 'last30Days'},
			{label: t('components.timeRangeSelect.currentYear'), value: 'year'},
			{label: t('components.timeRangeSelect.allTime'), value: 'allTime'},
			{label: t('components.timeRangeSelect.custom'), value: 'custom'},
		];
	}, [])

	useEffect(() => {
		let [from, to] = getRangeFromMode(mode);
		if(mode === 'custom') {
			from = value[0];
			to = value[1];
		}

		if (from && to) {
			onChange([from, to]);
		}
	}, [mode, ...value]);

	if(mode === 'custom') {
		return <DatePickerInput rightSection={<IconX style={{cursor: 'pointer'}} onClick={() => setMode('today')}/>} label={label} type="range" value={value} onChange={setValue} />
	}
	return <Select label={label} data={timeRanges} value={mode} onChange={setMode as any}/>;
}
