import {useMemo} from "react";
import {MRT_ColumnFiltersState} from "mantine-react-table";
import {ColumnFilterWithFilterType} from "../columnFilterWithFilterType.tsx";
import {ColumnFilter} from "@tanstack/react-table";
import {useSearchParams} from "@hooks/useSearchParams.ts";

export function useDefaultsTableState() {
	const [sp, setQs] = useSearchParams();

	const offset = useMemo(() => sp.has('offset') ? Number(sp.get('offset') as string) : 0, []);
	const query = useMemo<ColumnFilterWithFilterType[]>(() => {
		if(!sp.has('query')) return [];
		const data = JSON.parse(sp.get('query') as string) as any;
		return Object.entries<{value: string}>(data).map(([id, value]) => ({id, value: value?.value}) as ColumnFilter);
	}, []);

	const apiQuery = useMemo<any>(() => {
		if(!sp.has('query')) return {};
		return JSON.parse(sp.get('query') as string) as any;
	}, []);


	function storeQuery(newQuery: MRT_ColumnFiltersState) {
		return setQs(prev => ({
			...Object.fromEntries(prev.entries()),
			query: JSON.stringify(newQuery)
		}))
	}

	function storeOffset(newOffset: number) {
		return setQs(prev => {
			console.log(prev);
			return {
				...Object.fromEntries(prev.entries()),
				offset: String(newOffset)
			};
		})
	}

	return {
		offset,
		storeOffset,

		query,
		apiQuery,
		storeQuery
	}
}

export type UseDefaultsTableStateStore = ReturnType<typeof useDefaultsTableState>;
