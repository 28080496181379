import * as dateLocales from "date-fns/locale";
import {format} from "date-fns";
import {yearSuffixMap} from "./yearSuffixMap";

export function createDateFormater(language: string) {
	// @ts-ignore
	let locale = dateLocales[language.toLowerCase()];

	const postfix = yearSuffixMap[language] || '';
	return (date: Date) => `${format(date, 'dd MMM yyyy', {locale})} ${postfix}`
}
