import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class UaBlacklistApiClient extends YoApiClient {
	path = '/api/v1/blacklist/ua';

	async bulkAdd(data: string[]) {
		return this.ky.put('bulk', {json: {items: data}}).json();
	}
}
