import {
	IconFile,
	IconFileTypeCss,
	IconFileTypeHtml,
	IconFileTypeJpg,
	IconFileTypeJs,
	IconFileTypePhp,
	IconFileTypePng,
	IconIcons
} from "@tabler/icons-react";

export function FileIcon({fileType, size = 32}: { fileType: string, size?: number }) {
	if (fileType.endsWith('.html')) {
		return <IconFileTypeHtml size={size}/>
	} else if (fileType.endsWith('.css')) {
		return <IconFileTypeCss size={size}/>
	} else if (fileType.endsWith('.js')) {
		return <IconFileTypeJs size={size}/>
	} else if (fileType.endsWith('.jpg')) {
		return <IconFileTypeJpg size={size}/>
	} else if (fileType.endsWith('.png')) {
		return <IconFileTypePng size={size}/>
	} else if (fileType.endsWith('.ico')) {
		return <IconIcons size={size}/>
	} else if (fileType.endsWith('.php')) {
		return <IconFileTypePhp color={'red'} title={'PHP is not supported'} size={size}/>
	} else {
		return <IconFile size={size}/>;
	}
}
