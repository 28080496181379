import useTeams from "../Context/useTeams.ts";
import {Select} from "@mantine/core";
import {useUser} from "@engine/UserContext.tsx";

function ActiveTeamSelect() {
    const { user } = useUser()
    const { myTeams, activeTeam, setActiveTeam } = useTeams();

    if (myTeams.length === 0) return null;

    return (
        <Select
            searchable
            m="xs"
            value={activeTeam || 'global'}
            data={[ ...(user.isSuperAdmin ? [{ label: 'Global (Only for superAdmin)', value: 'global' }] : []), ...myTeams.map(e => ({ label: e.name, value: e._id }))]}
            allowDeselect
            onChange={v => {
                if (v) {
                    setActiveTeam(v === "global" ? null : v);
                }
            }}
        />
    );
}

export default ActiveTeamSelect;