import {TransactionsApiClient} from "./api.ts";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
// import {useLocale} from "../../locale";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import type {RawDocumentTypeTransactions} from "@shared/models";
import {useEffect, useMemo, useState} from "react";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useYoApi} from "@hooks/useYoApi.tsx";
import useTeams from "../../Teams/Context/useTeams.ts";
import {TeamsApiClient, TTeamUser} from "../../Teams/api.ts";

const api = new TransactionsApiClient();
const teamsApi = new TeamsApiClient();

// todo фильтровать по баеру
function ListTransactions() {
    // const t = useLocale();
    const { team } = useTeams();
    const defaults = useDefaultsTableState();
    const [teamUsers, setTeamUsers] = useState<TTeamUser[]>([]);

    useEffect(() => {
        if (!team) return;
        teamsApi.getTeamsUsers(team._id)
            .then(data => setTeamUsers(data.items))
    }, [team]);

    const createTableState = useMRTState<RawDocumentTypeTransactions>({
        visibilityLocalStorageKey: 'ui.Expenses.columnVisibility',
        defaults
    });

    const columns = useMemo<MRT_ColumnDef<RawDocumentTypeTransactions>[]>(() => [
        {
            accessorKey: 'amount',
            header: 'amount',
            Cell: (props: any) => {
                const v = props.cell.getValue();

                return (
                    `${v < 0 ? `-` : `+`} ${v.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2
                    })}$`
                )
            }
        },
        {
            accessorKey: 'createdAt',
            header: 'date',
            Cell: (props: any) => props.cell.getValue() ? new Date(props.cell.getValue()).toLocaleString() : null,
        },
        {
            accessorKey: 'reason',
            header: 'type',
            Cell: (props: any) => {
                // 'domain:purchase' | 'subscription:monthly' | 'app:click' | 'payment:income'
                switch (props.cell.getValue()) {
                    case 'domain:purchase':
                        return 'domain purchase';
                    case 'subscription:monthly':
                        return 'monthly subscription payment';
                    case 'app:click':
                        return 'click summary';
                    case 'payment:income':
                        return 'incoming payment';
                    default: return '';
                }
            }
        },
        (team?.role !== 'buyer') && {
            accessorKey: 'owner',
            header: 'owner',
            Cell: (props: any) => {
                const  v = props.cell.getValue();
                const teamUser = teamUsers.find(e => e._id === v);
                if (!teamUser) return v;
                return (
                    teamUser.username
                )
            },
        }
    ].filter(Boolean), [teamUsers]);

    const data = useYoApi({apiClient: api, defaults});
    const tableState = createTableState({
        data,
        columns
    });
    useMRTYoApiDataAdapter({
        apiClient: data,
        tableParams: tableState.adapterProps,
        defaults,
    });
    const table = useMantineReactTable<RawDocumentTypeTransactions>(tableState);

    return <>
        <MantineReactTable table={table}/>
    </>;
}

export default ListTransactions;
