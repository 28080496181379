import {IconGripVertical} from "@tabler/icons-react";
import {useSimpleSortItemDragHandle} from "@components/SimpleSorting/simpleSortItemProvider.tsx";
import {ActionIcon} from "@mantine/core";

export function SimpleDragHandle() {
	const props = useSimpleSortItemDragHandle();
	return (
		<div style={{cursor: 'grab'}} {...props}>
			<ActionIcon variant="subtle" c="white">
				<IconGripVertical size={16}/>
			</ActionIcon>
		</div>
	)
}
