import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button, Card, SimpleGrid, Stack, TextInput, Title} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {GoogleTrackingEntryApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {CopyButtonWithTooltip} from "../../utils/copyButtonWithTooltip.tsx";

const api = new GoogleTrackingEntryApiClient();
export function ViewGoogleTrackingEntry() {
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: 'Сохранение успешно'});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={'Редактор'}
				items={(
					<>
						<Button variant="primary" onClick={trySave} size={'compact-sm'}>Сохранить</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>Удалить</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack} gap="xl">
				<Stack>
					<InputField field={'name'} label={'name'}/>
					<RenderFuncField<string>  field={'fullDomain'}>
						{ctx => (
							<>
								<TextInput
									label={'Ссылка на отчёт в формате Excel'}
									value={`https://${ctx.value}/export/feed.xlsx`}
									readOnly
									rightSection={<CopyButtonWithTooltip value={`https://${ctx.value}/export/feed.xlsx`}/>}
								/>
								<TextInput
									label={'Ссылка на отчёт в формате JSON'}
									value={`https://${ctx.value}/export/feed.json`}
									readOnly
									rightSection={<CopyButtonWithTooltip value={`https://${ctx.value}/export/feed.json`}/>}
								/>
							</>
						)}
					</RenderFuncField>
				</Stack>
				<div>
					<Title order={4} mb="xs">Маппинг событий</Title>
					<Stack gap="xs">
						<SimpleGrid cols={3}>
							<InputField field={'installEventName'} label={'Название события установки'}/>
							<NumberField field={'installEventPrice'} label={'Цена события установки'}/>
							<InputField field={'installEventCurrency'} label={'Валюта события установки'}/>
						</SimpleGrid>
						<SimpleGrid cols={3}>
							<InputField field={'registrationEventName'} label={'Название события регистрации'}/>
							<NumberField field={'registrationEventPrice'} label={'Цена события регистрации'}/>
							<InputField field={'registrationEventCurrency'} label={'Валюта события регистрации'}/>
						</SimpleGrid>
						<SimpleGrid cols={3}>
							<InputField field={'depositEventName'} label={'Название события пополнения'}/>
							<NumberField field={'depositEventPrice'} label={'Цена события пополнения'}/>
							<InputField field={'depositEventCurrency'} label={'Валюта события пополнения'}/>
						</SimpleGrid>
					</Stack>
				</div>
				<div>
					<Title order={4} mb="xs">Глубина</Title>
					<NumberField field={'daysCount'} label={'За сколько дней запрашивать данные'}/>
				</div>
			</Card>
		</YoContextEditor>
	);
}
