import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button, Card, Stack} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconBadgeAd} from "@tabler/icons-react";

MenuManager.RegisterItem({
	path: '/system/settings/adspect',
	name: 'Настройки adspect',
	nameKey: 'menuItems.system.adspect',
	parent: 'system',
	onlySuperAdmin: true,
	icon: <IconBadgeAd />
})


export function AdspectSettings() {
	const controller = useSiteSettings('adspect.');

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: 'Настройки успешно сохранены'}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={'Настройки adspect'} items={(
				<>
					<Button variant="primary" onClick={saveSettings}>Сохранить настройки</Button>
				</>
			)}/>
			<Card withBorder component={Stack}>
				<InputField field={'defaultStream'} label={'Поток по умолчанию'}/>
			</Card>
		</YoContextEditor>
	);
}
