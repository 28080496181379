import {Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {WhitePagesTemplatesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {FileUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {Translation, useLocale} from "../../../locale";

const api = new WhitePagesTemplatesApiClient()

interface IItem {
	_id: string;
}

export function ListWhitePagesTemplates() {
	const t = useLocale();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.WhitePagesTemplates.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		return [{
			accessorKey: 'name',
			header: t('whitePagesTemplates.list.table.name'),
		}, {
			accessorKey: 'actions',
			header: t('whitePagesTemplates.list.table.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<>
					<Link href={`/v/${props.row.original._id}`}>
						<a>
							<Button size={'compact-sm'}>
								<Translation id={'whitePagesTemplates.list.table.actions.view'}/>
							</Button>
						</a>

					</Link>

				</>
			)
		}] as MRT_ColumnDef<IItem>[];
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const form = await yoFormModal({
			title: t('whitePagesTemplates.list.createItem.title'),
			required: ['file'],
			body: (
				<>
					<InputField field={'name'} label={t('whitePagesTemplates.list.createItem.label.name')} description={t('whitePagesTemplates.list.createItem.description.name')}/>
					<h4>
						<Translation id={'whitePagesTemplates.list.createItem.label.file'}/>
					</h4>
					<FileUploaderField field={'file'} accept={['application/zip']}/>
				</>
			)
		})
		return api.createItem({...form }).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={'WhitePages'}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate}>
						<Translation id={'whitePagesTemplates.list.createItem'}/>
					</Button>
				</>
			)}
		/>
		<p>
			<Translation id={'whitePagesTemplates.list.description'}/>
		</p>
		<MantineReactTable table={table} />
	</>;
}
