import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

type ApiResult <T> = ({
	status: true,
	result: T
}) | ({
	status: false,
	message: string
})

@singleton
export class TransactionsApiClient extends YoApiClient {
	path = '/api/v1/transactions';

	async spentThisMonth () {
		return this.ky.get('spentThisMonth').json<ApiResult<number>>()
	}
}
