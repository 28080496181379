import {Route} from "wouter";
import {ListPushSegments} from "./listPushSegments.tsx";
import {ViewPushSegments} from "./viewPushSegments.tsx";
import MenuManager from "@engine/MenuManager.ts";

const baseUrl = '/segments';

MenuManager.RegisterItem({
	path: `/push${baseUrl}`,
	key: 'pushSegments',
	nameKey: 'menuItems.push.Segments',
	parent: 'push'
})

export function PushSegmentsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListPushSegments}/>
			<Route path={'/v/:id'} component={ViewPushSegments}/>
		</Route>
	);
}
