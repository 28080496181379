import {ReactNode, useContext} from "react";
import {useYoProvider, YoRawContext} from "@components/YoContextEditor/index.tsx";

export function ReadonlyAreaField(props: { field: string, children: ReactNode, eqFn?: (data: any) => boolean }) {
	const ctx = useYoProvider(props.field);
	const isEquals = props.eqFn ? props.eqFn(ctx.value) : ctx.value;
	const baseContextValue = useContext(YoRawContext);

	const bypassProps = {
		...baseContextValue.bypassProps,
		readOnly: isEquals
	}
	return (
		<YoRawContext.Provider value={{...baseContextValue, bypassProps}}>
			{props.children}
		</YoRawContext.Provider>
	)
}
