import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class AvatarGalleryApiClient extends YoApiClient {
	path = '/api/v1/system/avatars';

	async fetchRandomItem(param: { age: string; gender: string; race: string }) {
		return this.ky.post('random', {json: param}).json<{ item: any, status: boolean }>();
	}
}
