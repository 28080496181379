import {usePromise} from "@hooks/usePromise.ts";
import {CountryApiClient} from "./api.ts";
import {MultiSelect, MultiSelectProps, Select, SelectProps} from "@mantine/core";

const api = new CountryApiClient();

function useCountryPromise(p: {isCCA2: boolean}) {
	return usePromise(() => api.fetchList({}).then(res => res.items).then(rawItems => {
		return rawItems.map(el => {
			return {
				label: `${el.flagChar} ${el.name}`,
				value: p.isCCA2 ? el.cca2 : el.cca3
			}
		})
	}), []);
}
export function CountrySelect(props: SelectProps & {cca2?: boolean}) {
	const promise = useCountryPromise({isCCA2: !!props.cca2});
	const items = promise.data ?? [];
	return (
		<Select
			data={items}
			disabled={promise.isLoading}
			{...props}
		/>
	)
}
export function CountryMultiSelect(props: MultiSelectProps & {cca2?: boolean}) {
	const promise = useCountryPromise({isCCA2: !!props.cca2});
	const items = promise.data ?? [];
	return (
		<MultiSelect
			data={items}
			disabled={promise.isLoading}
			{...props}
		/>
	)
}
