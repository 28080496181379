import useTeams from "../Teams/Context/useTeams.ts";
import {useEffect, useState} from "react";
import {ActionIcon, Anchor, Button, Card, Center, Divider, Group, Loader, Stack, Text, TextInput, Title} from "@mantine/core";
import {TeamInvitesApiClient, TMyTeamInvite} from "./api.ts";
import CreateTeamForm from "../Teams/Components/CreateTeamForm.tsx";
import { TeamsApiClient } from "../Teams/api.ts";
import TariffsGrid from "../Billing/Tariffs/Components/TariffsGrid.tsx";
import {IconArrowLeft} from "@tabler/icons-react";
import {useUser} from "@engine/UserContext.tsx";

const teamInvitesApi = new TeamInvitesApiClient();
const teamsApi = new TeamsApiClient();

function CompleteLoader () {

    useEffect(() => {
        teamsApi.completeOnboarding().then(() => {
            window.location.reload();
        })
    }, []);

    return (
        <Center>
            <Loader />
        </Center>
    )
}

function JoinTeam () {
    const { loadMyTeams } = useTeams();
    const user = useUser();
    const [teamInvites, setTeamInvites] = useState<TMyTeamInvite[] | null>(null);

    const loadUserTeamInvites = async () => {
        teamInvitesApi.getMyInvites().then((data) => {
            if (data.status) {
                setTeamInvites(data.items);
            }
        })
    }

    useEffect(() => {
        void loadUserTeamInvites();
    }, []);

    const tryAcceptTeamInvite = async (id: string) => {
        teamInvitesApi.accept(id).then(() => {
            void loadUserTeamInvites();
            void loadMyTeams();
        })
    }

    const tryRejectTeamInvite = async (id: string)=>  {
        teamInvitesApi.reject(id).then(() => {
            void loadUserTeamInvites();
            void loadMyTeams();
        });
    }

    if (!teamInvites) {
        return <Loader />
    }

    return (
        <>
            <Center mt="6rem">
                <div style={{maxWidth: '40rem', width: '100%'}}>
                    <Title order={2} mb="xl">
                        For start, you need a team to continue
                    </Title>
                    {
                        teamInvites.length ? (
                            <Stack>
                                <Title order={3}>
                                    Your invites to teams
                                </Title>
                                {teamInvites.map((invite) => (
                                    <Card key={invite._id} withBorder>
                                        <Group justify="space-between">
                                            <Group>
                                                <Stack gap={0}>
                                                    <Text c="dimmed" size="xs">
                                                        Team
                                                    </Text>
                                                    <Text>
                                                        {invite.team?.name}
                                                    </Text>
                                                </Stack>
                                                <Divider orientation="vertical"/>
                                                <Stack gap={0}>
                                                    <Text c="dimmed" size="xs">
                                                        Role
                                                    </Text>
                                                    <Text>
                                                        {invite.role}
                                                    </Text>
                                                </Stack>
                                            </Group>
                                            <Group>
                                                <Button
                                                    variant="light"
                                                    c="green"
                                                    onClick={() => tryAcceptTeamInvite(invite._id)}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    variant="light"
                                                    c="red"
                                                    onClick={() => tryRejectTeamInvite(invite._id)}
                                                >
                                                    Reject
                                                </Button>
                                            </Group>
                                        </Group>
                                    </Card>
                                ))}
                            </Stack>
                        ) : (
                            <Card>
                                <Title order={4} mb="sm">
                                    Create team
                                </Title>
                                <CreateTeamForm onSuccess={loadMyTeams}/>
                            </Card>
                        )
                    }
                </div>
            </Center>
            <Center mt={'1rem'}>
                <Anchor size={'13px'} c={'dimmed'} underline={'never'} onClick={user.logout}>Log out</Anchor>
            </Center>
        </>
    )
}

function RenameTeam({onSuccess }: { onSuccess: () => void }) {
    const [processing, setProcessing] = useState(false);
    const {team, loadMyTeams } = useTeams();
    const [name, setName] = useState(team?.name || '');

    const tryRename = async () => {
        if (!team) return null;
        setProcessing(true);
        teamsApi.saveItem(team._id, { name }).then(() => {
            loadMyTeams().then(() => {
                onSuccess();
            })
        })
        setProcessing(false);
        onSuccess()
    }

    return (
        <Center mt="6rem">
            <div style={{ maxWidth: '40rem', width: '100%' }}>
                <Card>
                    <Group align="center" justify='space-between' mb="sm">
                        <Title order={4}>
                            Rename team
                        </Title>
                        <Button size="xs" onClick={() => onSuccess()} variant="light">
                            Skip
                        </Button>
                    </Group>
                    <form onSubmit={e => {
                        e.preventDefault();
                        void tryRename();
                    }}>
                        <Stack>
                            <TextInput
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                            <Button
                                disabled={processing}
                                type="submit"
                            >
                                Rename
                            </Button>
                        </Stack>
                    </form>
                </Card>
            </div>
        </Center>
    )
}

function OnboardingRouter() {
    const [renameTeam, setRenameTeam] = useState(false);
    const { user } = useUser();
    const { myTeams, team } = useTeams();

    const haveTeams = myTeams?.length > 0;

    if (!haveTeams) return (
        <JoinTeam />
    );

    if (!team) return 'Team not found';
    if (renameTeam) {
        return (
            <RenameTeam
                onSuccess={() => {
                    setRenameTeam(false);
                }}
            />
        )
    }
    if (!team.tariff) {
        return (
            <Center mt="6rem">
                <div style={{ maxWidth: '90rem', width: '100%' }}>
                    <Group align="center"  mb="sm">
                        {
                            team.ownerId === user._id && (
                                <ActionIcon
                                    variant="outline"
                                    onClick={() => setRenameTeam(true)}
                                >
                                    <IconArrowLeft />
                                </ActionIcon>
                            )
                        }
                        <Title order={4} c="dimmed">
                            {`Team: ${team.name}`}
                        </Title>
                    </Group>
                    <Title mb="md">
                        Select tariff to continue
                    </Title>
                    <TariffsGrid />
                </div>
            </Center>
        )
    }

    return (
        <CompleteLoader />
    );
}

export default OnboardingRouter;
