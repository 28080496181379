import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";
import {RawDocumentTypeTeamInvites} from "@shared/models";


type ApiResult <T> = ({
	status: true,
} & T) | ({
	status: false,
	message: string
});

export type TMyTeamInvite = RawDocumentTypeTeamInvites & {
	team: {
		_id: string,
		name: string,
	}
}

@singleton
export class TeamInvitesApiClient extends YoApiClient {
	path = '/api/v1/teamInvites';

	getMyInvites () {
		return this.ky.get('').json<ApiResult<{ items: TMyTeamInvite[] }>>();
	}

	reject (id: string) {
		return this.ky.post(`${id}/reject`).json<ApiResult<never>>();
	}

	accept = async (id: string) => {
		return this.ky.post(`${id}/accept`).json<ApiResult<never>>();
	}
}
