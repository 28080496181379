import {getKyInstance} from "@engine/yoApiClient.tsx";
import {iStateProps} from "../IStateProps.tsx";
import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {Box, LoadingOverlay, Table, Tabs} from "@mantine/core";
import {useState} from "react";
import {useScopedLocale} from "../../../locale";
import {addStatsJob} from "../utils/statsQueue.ts";

export function DevicesTop(props: iStateProps) {
	const t = useScopedLocale('pages.mainPage.devices.');
	const [type, setType] = useState<'browser' | 'os'>('browser');
	const promise = usePromiseWithTeamDependency(() => {
		return addStatsJob(() => getKyInstance().post('/api/v1/stats/devices', {
			json: {
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers,
				type
			},
			timeout: 1000 * 30
		}).json<{items: any[]}>());
	}, [type,JSON.stringify([props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);

	if(promise.isError) return displayTSMatchGeneralError(promise);

	return (
		<Tabs value={type} onChange={setType as any}>
			<Tabs.List>
				<Tabs.Tab value="browser">{t('browser')}</Tabs.Tab>
				<Tabs.Tab value="os">{t('os')}</Tabs.Tab>
			</Tabs.List>
			<>
				<Box pos={'relative'}>
					<LoadingOverlay visible={promise.isLoading} />
					<div style={{
						height: '300px',
						overflow: 'scroll'
					}}>
						<Table stickyHeader style={{overflow: 'scroll'}} data={{
							head: ['Name', 'Count'],
							body: promise.data?.items.map(data => [
								data.name,
								data.count
							]),
						}}/>
					</div>
				</Box>
			</>
		</Tabs>
	)
}

