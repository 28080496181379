import {useContext} from "react";
import {TeamsContext} from "./TeamsContext.tsx";


export default function useTeams() {
    return useContext(TeamsContext);
}

export function useCurrentTeam() {
    const ctx = useContext(TeamsContext);

    return ctx.myTeams.find(e => e._id === ctx.activeTeam)!;
}
