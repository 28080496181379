import {Select, SelectProps} from "@mantine/core";
import {useMemo} from "react";
import {getLocaleString} from "../../../locale";

export function TriggerTypeSelect(props: SelectProps) {
	const options = useMemo(() => {
		return [
			{
				value: 'install',
				label: getLocaleString('push.jobs.edit.addTrigger.variants.onAppInstalled')
			},
			{
				value: 'registration',
				label: getLocaleString('push.jobs.edit.addTrigger.variants.onRegister')
			},
			{
				value: 'deposit',
				label: getLocaleString('push.jobs.edit.addTrigger.variants.onDeposit')
			},
			{
				value: 'subscribe',
				label: getLocaleString('push.jobs.edit.addTrigger.variants.pushSubscribe')
			}
		]
	}, []);
	return (
		<Select
			{...props}
			data={options}
		/>
	);
}
