import {Badge, Button} from "@mantine/core";
import {Link, useParams} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {FbPixelsLogsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {Translation, useLocale} from "../../../locale";

const api = new FbPixelsLogsApiClient()

interface IItem {
	_id: string;
}

export function ListFbPixelsLogs() {
	const t = useLocale();
	const {pixelId} = useParams<{pixelId: string}>();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.FbPixelsLogs.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: '_id',
		header: t('fbPixelsLogs.list.tableHeader._id'),
		enableSorting: false,
		enableColumnActions: false,
	},{
		accessorKey: 'createdAt',
		header: t('fbPixelsLogs.list.tableHeader.createdAt'),
		Cell: (props) => (new Date(props.cell.getValue<string>())).toLocaleString()
	},{
		accessorKey: 'lead',
		header: t('fbPixelsLogs.list.tableHeader.lead'),
		Cell: (props) => {
			const value = props.cell.getValue<{clientId: string, _id: string}>();
			if(!value) return t('fbPixelsLogs.list.tableHeader.lead.empty');
			return <Link href={`~/leads/v/${value._id}`}>{value.clientId}</Link>;
		}
	},{
		accessorKey: 'event',
		header: t('fbPixelsLogs.list.tableHeader.event'),
	},{
		accessorKey: 'sentSuccessfully',
		header: t('fbPixelsLogs.list.tableHeader.sentSuccessfully'),
		Cell: (props) => (props.cell.getValue<boolean>() ? <Badge color={'green'}><Translation id={'fbPixelsLogs.list.tableHeader.sentSuccessfully.true'}/></Badge> : <Badge color={'red'}><Translation id={'fbPixelsLogs.list.tableHeader.sentSuccessfully.false'}/></Badge>)
	},{
		accessorKey: 'actions',
		header: t('fbPixelsLogs.list.tableHeader.actions'),
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Button href={`/v/${props.row.original._id}`} component={Link} variant="primary" size={'compact-sm'}>
					<Translation id={'fbPixelsLogs.list.tableHeader.actions.edit'}/>
				</Button>
			</>
		)
	}], []);

	const data = useYoApi({ apiClient: api, defaults, forceQuery: {pixel: {value: pixelId}} });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);


	return <>
		<Header
			title={t('fbPixelsLogs.list.title')}
		/>
		<MantineReactTable table={table} />
	</>;
}
