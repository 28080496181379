import {Route} from "wouter";
import {ListRegisterInvites} from "./listRegisterInvites.tsx";
import {ViewRegisterInvites} from "./viewRegisterInvites.tsx";


export function RegisterInvitesRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath} nest>
			<Route path={'/'} component={ListRegisterInvites}/>
			<Route path={'/v/:id'} component={ViewRegisterInvites}/>
		</Route>
	);
}
