import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button, Card, Stack} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {Translation, useLocale} from "../../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {DisplayRegistrarBalance} from "./DisplayRegistrarBalance.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";

/*MenuManager.RegisterItem({
	path: '/system/settings/nameCheap',
	parent: 'system',
	key: 'nameCheapSettings',
	name: 'Настройки регистратора nameCheap',
	nameKey: 'menuItems.system.nameCheapSettings',
	icon: <IconWorldWww/>
});*/

export function NameCheapSettingsPage() {
	const controller = useSiteSettings('domains.nameCheap.');
	const t = useLocale();

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: t('settings.general.savedMessage')}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={t('settings.nameCheap.title')} items={(
				<>
					<DisplayRegistrarBalance registrar="nameCheap" />
					<Button variant="primary" onClick={saveSettings}>
						<Translation id={'settings.general.saveBth'}/>
					</Button>
				</>
			)}/>
			<Card withBorder component={Stack}>
				<CheckboxField field="hidden" label="hidden" />
				<InputField field={'apiKey'} label={t('settings.nameCheap.apiKey')}/>
				<InputField field={'baseUrl'} label={t('settings.nameCheap.baseUrl')}/>
				<InputField field={'apiUser'} label={t('settings.nameCheap.apiUser')}/>
				<InputField field={'clientIP'} label={t('settings.nameCheap.clientIP')}/>
				<InputField field={'currency'} label="currency"/>
			</Card>
		</YoContextEditor>
	);
}
