import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class TransferFbPixelLogsJobApiClient extends YoApiClient {
    path = '/api/v1/transferFbPixelLogsJob';


    proceedJob (id: string) {
        return this.ky.post(`${id}/proceedJob`).json();
    }

    getJobsForPixel({ pixelId }: { pixelId: string }) {
        return this.ky.get(`getJobsForPixel/${pixelId}`).json();
    }
}
