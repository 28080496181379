import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";
import {Card, Checkbox, Collapse, Grid, Slider, Text, TextInput} from "@mantine/core";
import {useIsSecondary} from "../../../hooks/useIsSecondary.tsx";
import {useScopedLocale} from "../../../../../../locale";

function RatingSlider({
    value,
    onChange,
    label
}: {
    value: number;
    label: string;
    onChange: (value: number) => void;
}) {

    return (
        <>
            <Text size="sm">{label}</Text>
            <Slider
                value={value ?? 50}
                onChange={v => onChange(v)}
            />
        </>
    );
}

const FieldTypeRating: ComponentType<FieldTypeRendererProps<'rating'>> = (props) => {
    const isSecondary = useIsSecondary();
    const tt = useScopedLocale('webApps.view.ReviewsTab.');

    const value: typeof props.value = props.value || {
        useRatingFromBaseLanguage: true,
        rating: '',
        ratingsCount: '',
        downloadsCount: '',
        ratingValues: {
            five: 50,
            four: 50,
            three: 50,
            two: 50,
            one: 50,
        }
    }

    return (
        <Card variant="section" mb="xl">
            {isSecondary && (
                <>
                    <Checkbox
                        label={tt('useRatingFromBaseLanguage')}
                        checked={value.useRatingFromBaseLanguage}
                        onChange={e => {
                            props.onChange({
                                ...value,
                                useRatingFromBaseLanguage: e.target.checked
                            })
                        }}
                    />
                </>
            )}
            <Collapse in={isSecondary ? !value.useRatingFromBaseLanguage : true}>
                {
                    isSecondary && (
                        <br/>
                    )
                }
                <Grid>
                    <Grid.Col span={4}>
                        <Text>
                            {tt('rating.legend')}
                        </Text>
                        <Card variant="section" withBorder>
                            <TextInput
                                label={tt('rating')}
                                value={value.rating}
                                onChange={e => {
                                    props.onChange({
                                        ...value,
                                        rating: e.target.value
                                    })
                                }}
                            />
                            <TextInput
                                label={tt('ratingsCount')}
                                value={value.ratingsCount}
                                onChange={e => {
                                    props.onChange({
                                        ...value,
                                        ratingsCount: e.target.value
                                    })
                                }}
                            />
                            <TextInput
                                label={tt('downloadsCount')}
                                value={value.downloadsCount}
                                onChange={e => {
                                    props.onChange({
                                        ...value,
                                        downloadsCount: e.target.value
                                    })
                                }}
                            />
                        </Card>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Text>
                            {tt('ratingValues.legend')}
                        </Text>
                        <Card variant="section" withBorder>
                            {
                                ([
                                    { label: '5', key: 'five', },
                                    { label: '4', key: 'four' },
                                    { label: '3', key: 'three' },
                                    { label: '2', key: 'two' },
                                    { label: '1', key: 'one' }
                                ] as const).map(({ label, key }) => (
                                    <RatingSlider
                                        key={key}
                                        label={label}
                                        value={value.ratingValues?.[key]}
                                        onChange={v => {
                                            props.onChange({
                                                ...value,
                                                ratingValues: {
                                                    ...value?.ratingValues,
                                                    [key]: v
                                                }
                                            })
                                        }}
                                    />
                                ))
                            }
                        </Card>
                    </Grid.Col>
                </Grid>
            </Collapse>
        </Card>
    );
}

export default FieldTypeRating;