import {Alert, Button, Modal, Stack, Text} from "@mantine/core";
import {useState} from "react";
import useTeams from "../../../Teams/Context/useTeams.ts";
import {tariffsConfig} from "@shared/_data/tariffs/tariffsConfig.ts";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {TeamsApiClient} from "../../../Teams/api.ts";

const api = new TeamsApiClient();

function SwitchTariffModal({ tariffKey }: { tariffKey: string }) {
    const { team, loadMyTeams } = useTeams();
    const [process, setProcess] = useState<boolean>(false);
    const [opened, setOpened] = useState(false);

    if (!team) return null;
    const currentTariff = team.tariff ? tariffsConfig[team.tariff as keyof typeof tariffsConfig] : null;
    // if (!currentTariff) return null;
    const wantedTariff = tariffsConfig[tariffKey as keyof typeof tariffsConfig];
    if (!wantedTariff) return null;

    async function switchTariff() {
        setProcess(true);
        try {
            await api.switchTariff(team!._id, { tariff: tariffKey });
            await loadMyTeams();
            setOpened(false);
            setProcess(false);
        } catch (e) {
            void displayErrorNotification(e);
        }
    }

    const canSwitchTo = team.teamMembersCount <= wantedTariff.maxTeamMembers;

    return (
        <>
            <div>
                <Button
                    disabled={!canSwitchTo}
                    variant={ canSwitchTo ? "secondary" : "outlined"}
                    size="sm"
                    onClick={() => setOpened(true)}
                >
                    {currentTariff ? 'Switch Tariff' : 'Select Tariff'}
                </Button>
                {
                    !canSwitchTo && (
                        team.teamMembersCount > wantedTariff.maxTeamMembers ? (
                            <Text c="dimmed" size="xs">
                                Your team have more members than available in tariff.
                            </Text>
                        ) : null
                    )
                }
            </div>
            <Modal opened={opened} onClose={() => setOpened(false)} title="Switch Tariff">
                <Stack>
                    {
                        currentTariff ? (
                            <>
                                {
                                    wantedTariff.type === 'monthly' && team.tariffPayedTill ? (
                                        new Date(team.tariffPayedTill).valueOf() > new Date().valueOf() ? (
                                            <Alert>
                                                <Text size="md">
                                                    {`if you change the tariff, the ${wantedTariff.price}$ will be charged instantly`}
                                                </Text>
                                            </Alert>
                                        ) : null
                                    ) : null
                                }
                                <Text size="md">
                                    {`Current tariff: ${currentTariff.label}`}
                                </Text>
                            </>
                        ) : null
                    }
                    <Text>
                        {`You wanna switch to ${wantedTariff.label}`}
                    </Text>
                    <Button
                        disabled={process}
                        onClick={() => switchTariff()}
                    >
                        Confirm
                    </Button>
                </Stack>
            </Modal>
        </>
    );
}

export default SwitchTariffModal;