import {Checkbox, CheckboxProps} from "@mantine/core";
import {useYoProvider} from "../index.tsx";

export function CheckboxField({field, ...otherProps}: { field: string } & Omit<CheckboxProps, 'value' | 'onChange'>) {
	const ctx = useYoProvider<boolean>(field);
	const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;
	return <Checkbox checked={ctx.value} onChange={e => {
		if (readOnly) return;
		ctx.onChange(e.target.checked);
	}} {...otherProps} readOnly={readOnly}/>
}
