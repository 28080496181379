import {Route} from "wouter";
import {ListDomains} from "./listDomains.tsx";
import {ViewDomains} from "./viewDomains.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconWorldWww} from "@tabler/icons-react";

const baseUrl = '/domains';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'Domains',
	name: 'Мои домены',
	nameKey: 'menuItems.domains',
	icon: <IconWorldWww/>,
	requiredRights: ['domains']
})

export function DomainsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListDomains}/>
			<Route path={'/v/:id'} component={ViewDomains}/>
		</Route>
	);
}
