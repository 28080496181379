
type TariffConfig = {
    label: string,
    maxTeamMembers: number;
    maxPwaEdgeRecords?: number;
    pricePerInstall: number;
    overdraft: number; // насколько $ баланс может уходить в минус до блокировки
} & ({
    type: 'monthly'
    price: number,
} | {
    type: 'payAsYouGo'
})

const createTariff = (config: TariffConfig): TariffConfig => config;

export const tariffsConfig = {
    smallTeam: createTariff({
        label: 'Small team',
        type: 'monthly',
        pricePerInstall: 0.02,
        price: 50,
        maxTeamMembers: 10,
        maxPwaEdgeRecords: 300,
        overdraft: 20
    }),
    mediumTeam: createTariff({
        label: 'Medium team',
        type: 'monthly',
        pricePerInstall: 0.02,
        price: 150,
        maxTeamMembers: 20,
        maxPwaEdgeRecords: 600,
        overdraft: 50
    }),
    unlimitedTeam: createTariff({
        label: 'Unlimited team',
        type: 'monthly',
        pricePerInstall: 0.02,
        price: 500,
        maxTeamMembers: 100,
        maxPwaEdgeRecords: 2_000,
        overdraft: 80
    }),
    soloBuyer: createTariff({
        label: 'Solo Buyer',
        type: 'payAsYouGo',
        pricePerInstall: 0.03,
        maxPwaEdgeRecords: 50,
        maxTeamMembers: 0,
        overdraft: 5
    }),
    unlimited: createTariff({
        label: 'Unlimited',
        type: 'monthly',
        pricePerInstall: 0.02,
        price: 150,
        maxPwaEdgeRecords: 600,
        maxTeamMembers: 0,
        overdraft: 20
    })
}


export type TariffKey = keyof typeof tariffsConfig;