import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class FbPixelsApiClient extends YoApiClient {
	path = '/api/v1/fbPixels';

	fetchStats(id: string, queryValue: any) {
		return this.ky.post(`${id}/stats`, {json: queryValue}).json<any>();
	}

	fetchStatsChart(id: string, queryValue: any) {
		return this.ky.post(`${id}/stats/chart`, {json: queryValue}).json<any>();
	}

	transferEvents({ from, to, countries }: { from: string, to: string, countries?: string[] }) {
		return this.ky.post(`transferEvents`, { json: { from, to, countries }, timeout: false }).json<{ status: true, jobId: string } | { status: false, message: string }>();
	}

	checkIfTransferAvailable(from: string, to: any) {
		return this.ky.get(`checkIfTransferAvailable`, { searchParams: { from, to } }).json<{ status: true, result: { available: boolean, pendingJobs: any[] } }>();
	}
}
