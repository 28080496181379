import {Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {RegisterInvitesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import { InputField } from "@components/YoContextEditor/components/inputField.tsx";
import { RenderFuncField } from "@components/YoContextEditor/components/renderFuncField.tsx";
import {IconCirclePlus} from "@tabler/icons-react";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {DatePickerInput} from "@mantine/dates";
import { addDays, endOfDay } from "date-fns";
import {CopyButtonWithTooltip} from "../../../../utils/copyButtonWithTooltip.tsx";

const api = new RegisterInvitesApiClient()

interface IItem {
	_id: string;
}

async function generateCode() {
	const codeLength = 8;
	return Math.random().toString(36).substring(2, codeLength + 2);
}

export function ListRegisterInvites() {
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.RegisterInvites.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: 'inviteCode',
		header: 'Код инвайта',
		Cell: (props) => (
			<>
				{props.cell.getValue<string>()}
				<CopyButtonWithTooltip value={props.cell.getValue<string>()}/>
			</>
		)
	},{
		accessorKey: 'maxInvites',
		header: 'Количество инвайтов',
		Cell: (props) => props.cell.getValue<number>() ? props.cell.getValue<number>() : '∞'
	},{
		accessorKey: 'disableDate',
		header: 'Дата окончания инвайта',
		Cell: (props) => props.cell.getValue<Date>() ? new Date(props.cell.getValue<Date>()).toLocaleDateString() : '∞'
	},{
		accessorKey: 'actions',
		header: 'Действия',
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Link href={`/v/${props.row.original._id}`} >
					<a>
						<Button size={'compact-sm'}>Редактировать</Button>
					</a>

				</Link>

			</>
		)
	}], []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {

		const form = await yoFormModal({
			title: 'New Invite',
			required: ['inviteCode'],
			body: (
				<>
					<InputField
						label={'Invite code'}
						field={'inviteCode'}
						required
						rightSection={(
							<RenderFuncField field={'inviteCode'}>
								{ctx => (
									<IconCirclePlus
										onClick={() => generateCode().then(val => {
											ctx.onChange(val);
										})}
									/>
								)}
							</RenderFuncField>
						)}
					/>
					<NumberField field={'maxInvites'} label={'Max invites'}/>
					<RenderFuncField<string> field={'disableDate'}>
						{ctx => (
							<DatePickerInput
								label={'Disable date'}
								value={ctx.value ? new Date(ctx.value) : null}
								onChange={v => ctx.onChange(endOfDay(v as Date) as any)}
								minDate={addDays(new Date(), 1)}
							/>
						)}
					</RenderFuncField>
				</>
			)
		})

		return api.createItem(form).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={'RegisterInvites'}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate}>New Invite</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
