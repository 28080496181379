import {RegistrarKeys} from "@shared/lib/RegistrarsApi/registrars.ts";
import {useEffect, useState} from "react";
import {ActionIcon, Group, Loader, Text} from "@mantine/core";
import {DomainsApiClient} from "../../../Domains/api.ts";
import {IconReload} from "@tabler/icons-react";

const api = new DomainsApiClient();

export function DisplayRegistrarBalance ({ registrar }: { registrar: RegistrarKeys }) {
    const [balance, setBalance] = useState<number | null>(null);

    const loadRegistrarBalance = async () => {
        setBalance(null);
        try {
            await api.checkRegistrarBalance(registrar).then(res => {
                if (!res.status) throw res.message;
                setBalance(res.result);
            })
        } catch(e)  {
            // void displayErrorNotification(e);
            setBalance(-1);
        }
    }

    useEffect(() => {
        void loadRegistrarBalance();
    }, [registrar]);


    return (
        <Group gap={0} align="center">
            <Text>
                {
                    balance === null ? (
                        <Loader size="xs" />
                    ) : (
                        balance === -1 ? (
                            "-"
                        ) : (
                            balance?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })
                        )
                    )
                }
            </Text>
            <ActionIcon size="xs" variant="subtle" onClick={loadRegistrarBalance}>
                <IconReload />
            </ActionIcon>
        </Group>
    )
}