import {IconChartArea} from "@tabler/icons-react";
import MenuManager from "@engine/MenuManager.ts";
import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {endOfToday, startOfToday} from "date-fns";
import {Flex, Stack, Title} from "@mantine/core";
import {useState} from "react";
import {useScopedLocale} from "../../../locale";
import useTeams from "../../Teams/Context/useTeams.ts";
import {statsTypes} from "../PushLogs/api.ts";
import {TeamMembersMultiSelect} from "../../Teams/TeamMembersMultiSelect.tsx";
import {MultiSelectWebApp} from "../../WebApps/utils/selectWebApp.tsx";
import {PushStatsCards, PushStatsChart} from "../PushLogs/pushStats";
import {SentPushTop} from "./widgets/sentPushTop.tsx";
import {OpenedPushTop} from "./widgets/openedPushTop.tsx";
import {NotOpenedPushTop} from "./widgets/notOpenedPushTop.tsx";
import {UnsubscribedPushTop} from "./widgets/unsubscribedPushTop.tsx";

MenuManager.RegisterItem({
	position: -1,
	path: '/push/stats',
	parent: 'push',
	key: 'push.stats',
	nameKey: 'pages.push.stats.menuItem',
	requiredRights: [],
	icon: <IconChartArea/>
});


export function PushStatsPage() {
	const t = useScopedLocale('pages.push.stats.');
	const {activeTeamRole} = useTeams();
	const [pushStatChartType, setPushStatChartType] = useState<statsTypes>('opened');
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [selectedApps, setSelectedApps] = useState<string[]>([]);
	const timeControls = useTimeState(startOfToday(), endOfToday());
	return (
		<Stack gap={'md'}>
			<Flex justify={'space-between'}>
				<Title order={3}>{t('title')}</Title>
				<Flex gap={'md'}>
					{['admin'].includes(activeTeamRole!) && (
						<TeamMembersMultiSelect
							placeholder={selectedUsers.length ? undefined : t('teamMembers.all')}
							value={selectedUsers}
							onChange={setSelectedUsers}
							searchable
						/>
					)}
					<MultiSelectWebApp
						hideAll
						placeholder={selectedApps.length ? undefined : t('pwa.all')}
						query={selectedUsers.length ? {owner: {type: 'in', value: selectedUsers}} : {}}
						value={selectedApps}
						onChange={setSelectedApps}
						searchable
					/>
					<TimeRangeSelect onChange={timeControls.setValue} defaultMode={'today'}/>
				</Flex>
			</Flex>
			<PushStatsCards
				from={timeControls.value[0]!}
				to={timeControls.value[1]!}
				selectedType={pushStatChartType}
				setType={setPushStatChartType as any}

				selector={{
					team: true,
					apps: selectedApps,
					users: selectedUsers
				}}
			/>
			<PushStatsChart
				chartType={pushStatChartType}
				from={timeControls.value[0]!}
				to={timeControls.value[1]!}
				selector={{
					team: true,
					apps: selectedApps,
					users: selectedUsers
				}}
			/>
			<SentPushTop
				minDate={timeControls.value[0]!}
				maxDate={timeControls.value[1]!}
				selectedApps={selectedApps}
				selectedUsers={selectedUsers}
			/>
			<OpenedPushTop
				minDate={timeControls.value[0]!}
				maxDate={timeControls.value[1]!}
				selectedApps={selectedApps}
				selectedUsers={selectedUsers}
			/>
			<NotOpenedPushTop
				minDate={timeControls.value[0]!}
				maxDate={timeControls.value[1]!}
				selectedApps={selectedApps}
				selectedUsers={selectedUsers}
			/>
			<UnsubscribedPushTop
				minDate={timeControls.value[0]!}
				maxDate={timeControls.value[1]!}
				selectedApps={selectedApps}
				selectedUsers={selectedUsers}
			/>
		</Stack>
	)
}
