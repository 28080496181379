import MenuManager from "@engine/MenuManager.ts";
import {IconPigMoney} from "@tabler/icons-react";
import {Link, Route, useLocation} from "wouter";
import ListTransactions from "./Transactions/ListTransactions.tsx";
import {Tabs} from "@mantine/core";
import PageTariffs from "./Tariffs/PageTariffs.tsx";
import PagePayments from "./Payments";
import {useEffect} from "react";
import useTeams from "../Teams/Context/useTeams.ts";

const baseUrl = '/billing';

MenuManager.RegisterItem({
    path: baseUrl,
    key: 'Billing',
    name: 'Billing',
    // nameKey: 'menuItems.teams',
    icon: <IconPigMoney />,
    requiredRights: []
});


function TransactionsRouter() {
    const { team } = useTeams();
    const [location, navigate] = useLocation();
    const links = [
        team?.role !== 'buyer' && { label: 'Tariffs', to: `${baseUrl}` },
        team?.role !== 'buyer' && { label: 'Payments', to: `${baseUrl}/payments` },
        { label: 'Transactions', to: `${baseUrl}/transactions` },
    ].filter(Boolean);

    useEffect(() => {
        if (location.startsWith(baseUrl)) {
            if (!links.find(e => e.to === location)) {
                navigate(links[0]!.to);
            }
        }
    }, [links]);

    return (
        <>
            <Route path={baseUrl} nest>
                <Tabs value={location}>
                    <Tabs.List>
                        {
                            links.map((link) => (
                                <Tabs.Tab
                                    value={link.to}
                                    key={link.to}
                                    component={Link}
                                    // @ts-expect-error незадокументированный функционал
                                    to={`~${link.to}`}
                                >
                                    {link.label}
                                </Tabs.Tab>
                            ))
                        }
                    </Tabs.List>
                </Tabs>
                <Route path={'/'} component={PageTariffs} />
                <Route path={'/payments'} component={PagePayments} />
                <Route path={`/transactions`} nest>
                    <Route path={'/'} component={ListTransactions} />
                </Route>
            </Route>
        </>
    );
}

export default TransactionsRouter;