
const cfAccountsList = {
	'cfAccounts.list.title': 'Аккаунты Cloudflare',
	'cfAccounts.list.tableHeader._id': 'ID',
	'cfAccounts.list.tableHeader.name': 'Название аккаунта',
	'cfAccounts.list.tableHeader.keyType': 'Тип ключа',
	'cfAccounts.list.tableHeader.keyType.TOKEN': 'Global API Key',
	'cfAccounts.list.tableHeader.keyType.KEY': 'API Tokens',

	'cfAccounts.list.tableHeader.actions': 'Действия',
	'cfAccounts.list.tableHeader.actions.view': 'Просмотреть',
	'cfAccounts.list.create': 'Добавить аккаунт',
	'cfAccounts.modal.title.create': 'Добавление аккаунта',
	'cfAccounts.modal.label.name': 'Название аккаунта',
	'cfAccounts.modal.label.keyType': 'Тип ключа',
	'cfAccounts.modal.label.keyType.token': 'Global API Key',
	'cfAccounts.modal.label.keyType.key': 'API Tokens',

	'cfAccounts.modal.label.apiToken': 'API токен',

	'cfAccounts.modal.label.apiKey': 'API ключ',
	'cfAccounts.modal.label.apiEmail': 'API email',

	'cfAccounts.modal.label.accountId': 'ID аккаунта',
	'cfAccounts.modal.label.accountId.description': 'Где найти id аккаунта Cloudflare?',
}

const cfAccountsEdit = {
	'cfAccounts.edit.title': 'Редактор аккаунта',
	'cfAccounts.edit.saveSuccess': 'Сохранение успешно',
	'cfAccounts.edit.saveButton': 'Сохранить',
	'cfAccounts.edit.removeButton': 'Удалить',

	'cfAccounts.edit.deleteButton': 'Удалить',

	'cfAccounts.edit.form.name': 'Название аккаунта',
	'cfAccounts.edit.form.keyType': 'Тип ключа',
	'cfAccounts.edit.form.keyType.token': 'Global API Key',
	'cfAccounts.edit.form.keyType.key': 'API Tokens',

	'cfAccounts.edit.form.apiToken': 'API токен',

	'cfAccounts.edit.form.apiKey': 'API ключ',
	'cfAccounts.edit.form.apiEmail': 'API email',

	'cfAccounts.edit.form.accountId': 'ID аккаунта',
}

const webAppDomainTab = {
	"webApps.view.DomainTab.title": "Домен",
	"webApps.view.DomainTab.subTitle": "Для работы PWA необходим домен. Вы можете купить домен через нас или использовать свой",

	"webApps.view.DomainTab.buy.guide.1": "Купить домен",
	"webApps.view.DomainTab.buy.guide.2": "Нажмите кнопку купить домен, после чего введите желаемый домен.",
	"webApps.view.DomainTab.buy.guide.3": "Дождитесь подтверждения и нажмите кнопку \"Подтвердить\".",
	"webApps.view.DomainTab.buy.guide.4": "После того, как домен будет добавлен в список доступных вам доменов, будет запущена процедура покупки домена.",
	"webApps.view.DomainTab.buy.openModal": "Купить новый домен",
	"webApps.view.DomainTab.buy.modalTitle": "Купить домен",
	"webApps.view.DomainTab.buy.confirmButton": "Купить домен",

	"webApps.view.DomainTab.buy.registrar": "Регистратор: {{value}}",
	"webApps.view.DomainTab.buy.price": "Стоимость:",
	"webApps.view.DomainTab.buy.split": "Разрешить использование этого домена на нескольких PVA (режим Split)",

	"webApps.view.DomainTab.link.guide.1": "Привязать домен",
	"webApps.view.DomainTab.link.guide.2": "Вы можете так же привязать свой домен",
	"webApps.view.DomainTab.link.openModal": "Привязать домен",

	"webApps.view.DomainTab.link.modalTitle": "Привязать домен",
	"webApps.view.DomainTab.link.wizard.1.domain.label": "Домен который вы хотите привязать",
	"webApps.view.DomainTab.link.wizard.1.allowSplit.label": "Разрешить использование этого домена на нескольких PVA (режим Split)",

	"webApps.view.DomainTab.link.wizard.1.continue": "Продолжить",
	"webApps.view.DomainTab.link.wizard.2.nsListTitle": "Укажите следующие DNS записи у вашего регистратора",
	"webApps.view.DomainTab.link.wizard.2.nsListItem": "Запись {{i}}",
	"webApps.view.DomainTab.link.wizard.2.done": "Готово",

	"webApps.view.DomainTab.linkExists.title": "Выбрать свободный домен",
	"webApps.view.DomainTab.linkExists.guide.1": "Если у вас есть неиспользуемые домены вы можете использовать их",
	"webApps.view.DomainTab.linkExists.openModal": "Выбрать домен",
	"webApps.view.DomainTab.linkExists.modalTitle": "Ваши домены",

	"webApps.view.DomainTab.linkDomain.title": "Привязать домен",
	"webApps.view.DomainTab.linkDomain.myDomains": "Мои домены",
	"webApps.view.DomainTab.linkDomain.myDomains.list": "Список моих доменов",

	"webApps.view.DomainTab.LinkedDomainInfo.state.OK": "Домен готов к работе",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE": "Ожидаем обновление NS записей",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE.title": "Добавьте следующие NS записи",
	"webApps.view.DomainTab.LinkedDomainInfo.state.AWAIT_NS_UPDATE.item": "Запись {{i}}",
	"webApps.view.DomainTab.LinkedDomainInfo.state.BUY_IN_PROGRESS": "Покупаем домен",
	"webApps.view.DomainTab.LinkedDomainInfo.state.BUY_IN_PROGRESS.info": "Покупаем домен, пожалуйста, подождите...",
	"webApps.view.DomainTab.LinkedDomainInfo.state.UNKNOWN": "Неизвестно",
	"webApps.view.DomainTab.LinkedDomainInfo.state.ERROR": "Ошибка",
	"webApps.view.DomainTab.LinkedDomainInfo.state.IN_PROGRESS": "В процессе выкупа",
	"webApps.view.DomainTab.LinkedDomainInfo.state.DEFAULT": "Выкуплен",

	"webApps.view.DomainTab.LinkedDomainInfo.state.WAIT_ACTIVATION": "Ожидаем активации домена",
	"webApps.view.DomainTab.LinkedDomainInfo.state.WAIT_ACTIVATION.info": "Домен уже выкуплен, ожидаем обновления всех DNS записей",



	"webApps.view.DomainTab.LinkedDomainInfo.addARecord.title": "Добавление A записи",
	"webApps.view.DomainTab.LinkedDomainInfo.addARecord.description": "Пожалуйста, добавьте A запись для домена на IP: ",
	"webApps.view.DomainTab.LinkedDomainInfo.autoUpdate": "Автообновление",
	"webApps.view.DomainTab.LinkedDomainInfo.goToDomainPage.label": "Перейти к управлению доменом",
	"webApps.view.DomainTab.LinkedDomainInfo.buyJob.label": "Детали операции выкупа"

};

const webAppPublishTab = {
	"webApps.view.PublishTab.title": "Публикация PWA",

	"webApps.view.PublishTab.platform.splitWarning.title": 'Данное PWA используется вместе с сплит-доменом, управление платформой доступно в настройках домена',
	"webApps.view.PublishTab.platform.splitWarning.link": "Перейти к настройкам домена",

	"webApps.view.PublishTab.platform.title": "Платформа для размещения",
	"webApps.view.PublishTab.platform.pwaCloud.title": "PWA Cloud",
	"webApps.view.PublishTab.platform.pwaCloud.description": "Абузоустойчивая распределенная платформа",
	"webApps.view.PublishTab.platform.globalEdge.title": "Global Edge",
	"webApps.view.PublishTab.platform.globalEdge.description": "Масштабируемая платформа для высоконагруженных проектов",
	"webApps.view.PublishTab.platform.currentLabel": "Текущая платформа",
	"webApps.view.PublishTab.platform.button.active": "Уже выбрана",
	"webApps.view.PublishTab.platform.button.inactive": "Выбрать",
	"webApps.view.PublishTab.publish.title": "Опубликовать мою PWA",
	"webApps.view.PublishTab.publish.checklist.domain": "Домен",
	"webApps.view.PublishTab.publish.checklist.content": "Наполнение контентом",
	"webApps.view.PublishTab.publish.checklist.analytics": "Настройка аналитики",
	"webApps.view.PublishTab.publish.checklist.links": "Управление потоком пользователей",

	"webApps.view.PublishTab.publish.details.domain": "Домен",
	"webApps.view.PublishTab.publish.details.content": "Наполнение контентом",
	"webApps.view.PublishTab.publish.details.analytics": "Настройка аналитики",
	"webApps.view.PublishTab.publish.details.links": "Управление потоком пользователей",

	"webApps.view.PublishTab.publish.details.allOk": "Все настроено правильно",
	"webApps.view.PublishTab.publish.details.warning": "Есть некритичные ошибки, исправьте их по возможности",
	"webApps.view.PublishTab.publish.details.error": "Есть критичные ошибки, исправьте их",
	"webApps.view.PublishTab.publish.details.title": "Ошибки или предупреждения",
	"webApps.view.PublishTab.publish.details.seeLang": "Проверьте {{lang}}",

	// errors
	"webApps.view.PublishTab.publish.details.DOMAIN_NOT_FOUND": "Укажите домен",
	"webApps.view.PublishTab.publish.details.WRONG_DOMAIN_STATE": "Невозможно использовать данный домен",
	"webApps.view.PublishTab.publish.details.DOMAIN_NOT_READY": "Домен не готов к работе",
	"webApps.view.PublishTab.publish.details.NO_LANGUAGES": "Укажите хотя бы один язык",
	"webApps.view.PublishTab.publish.details.NO_DEFAULT_LANGUAGE": "Не выбран язык по умолчанию",
	"webApps.view.PublishTab.publish.details.NO_ICON": "Не загружена иконка",
	"webApps.view.PublishTab.publish.details.NO_SCREEN": "Скриншоты не загружены",
	"webApps.view.PublishTab.publish.details.TOO_MANY_SCREEN": "Загружено слишком много скриншотов",
	"webApps.view.PublishTab.publish.details.NOT_ENOUGH_SCREEN": "Загружено слишком мало скриншотов",
	"webApps.view.PublishTab.publish.details.NO_SCREEN_IMAGE": "Ошибка загрузки изображения",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_NAME": "Укажите автора отзыва",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_AVATAR": "Загрузите или выберите аватар для отзыва",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_RATING": "Не указан рейтинг отзыва",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_TEXT": "Не указан текст отзыва",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_DATE": "Не указана дата публикации отзыва",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_OWNER_RESPONSE": "Не указан ответ на отзыв",
	"webApps.view.PublishTab.publish.details.NO_REVIEW_OWNER_RESPONSE_DATE": "Не указана дата ответа на отзыв",
	"webApps.view.PublishTab.publish.details.CONTENT_RENDER_ERROR": "Неизвестная ошибка",
	"webApps.view.PublishTab.publish.details.NO_GTAG_ID": "Укажите Google Analytics ID",
	"webApps.view.PublishTab.publish.details.NO_GTAG_EVENT_NAME": "Укажите Google Analytics событие установки",
	"webApps.view.PublishTab.publish.details.NO_CLARITY_ID": "Укажите Microsoft Clarity ID",
	"webApps.view.PublishTab.publish.details.NO_GOOGLE_TRACKING_ENTRY": "Укажите домен для интеграции Google Ads",
	"webApps.view.PublishTab.publish.details.NO_FACEBOOK_AUTH_DATA": "Укажите Facebook Pixel",
	"webApps.view.PublishTab.publish.details.NO_ON_INSTALL_WEBHOOK_URL": "Укажите валидный URL для вебхука",
	"webApps.view.PublishTab.publish.details.NO_REMOTE_EVENT_ON_REGISTRATION": "Не указаны названия событий установки",
	"webApps.view.PublishTab.publish.details.NO_REMOTE_EVENT_ON_DEPOSIT": "Не указаны названия событий депозита",
	"webApps.view.PublishTab.publish.details.EMPTY_PRIMARY_OFFER_LINK": "Не указана ссылка на основной оффер",
	"webApps.view.PublishTab.publish.details.NO_CONDITION_VALUES": "У условия не указаны значения",
	"webApps.view.PublishTab.publish.details.NO_THRESHOLD_VALUE": "Не указано пороговое значение",
	"webApps.view.PublishTab.publish.details.EMPTY_ALTERNATIVE_OFFER_LINK": "Одна из ссылок на альтернативные офферы не указана",
	"webApps.view.PublishTab.publish.details.EMPTY_REDIRECT_TARGET_URL": "Не указана целевая ссылка редиректа",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_REDIRECT_URL": "Не указана ссылка для клоакинга",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_WHITE_PAGE": "Не указан White Page для клоакинга",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_COUNTRY_LIST": "Список разрешенных стран пустой",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_OS_LIST": "Список разрешенных операционных систем пустой",
	"webApps.view.PublishTab.publish.details.EMPTY_CLOAKING_DEVICE_TYPE_LIST": "Список разрешенных типов устройств пустой",

	"webApps.view.PublishTab.publish.details.NO_NAME": "Укажите название приложения",
	"webApps.view.PublishTab.publish.details.NO_OWNER": "Укажите владельца приложения",
	"webApps.view.PublishTab.publish.details.NO_RATING": "Укажите рейтинг приложения",
	"webApps.view.PublishTab.publish.details.NO_RATINGS_COUNT": "Укажите количество оценок",
	"webApps.view.PublishTab.publish.details.NO_DOWNLOADS_COUNT": "Укажите количество скачиваний",
	"webApps.view.PublishTab.publish.details.NO_DESCRIPTION": "Укажите описание приложения",
	"webApps.view.PublishTab.publish.details.NO_UPDATED_DATE": "Укажите дату последнего обновления",
	"webApps.view.PublishTab.publish.details.NO_TAGS": "Укажите теги приложения",
	"webApps.view.PublishTab.publish.details.NO_REVIEWS": "Добавьте отзывы",

	"webApps.view.PublishTab.publish.description.title": "Публикация",
	"webApps.view.PublishTab.publish.description.text": "В этом разделе вы можете просмотреть, все ли в порядке с вашей PWA. При наличии критичных ошибок публикация невозможна. Чтобы посмотреть, что не так с вашим приложением, нажмите на соответствующий пункт меню.",
	"webApps.view.PublishTab.publish.button": "Опубликовать",
	"webApps.view.PublishTab.publish.quickUnPublishConfirm": "Вы действительно хотите скрыть это приложение?",
	"webApps.view.PublishTab.publish.quickPublishConfirm": "Вы действительно хотите опубликовать приложение?",

	"webApps.view.PublishTab.unpublish.title": "Скрыть приложение",
	"webApps.view.PublishTab.unpublish.description": "Переведите приложение в статус черновика, если вы хотите скрыть его",
	"webApps.view.PublishTab.unpublish.button": "Скрыть приложение",
	"webApps.view.PublishTab.unpublish.": "",
	"webApps.view.PublishTab.unpublish.inProgress.description": "Пожалуйста, подождите...",
	"webApps.view.PublishTab.unpublish.inProgress.title": "Скрываем приложение",
	"webApps.view.PublishTab.unpublish.waitTask.errorMessage": "Свяжите с администратором",
	"webApps.view.PublishTab.unpublish.waitTask.errorTitle": "Ошибка при скрытии приложения",
	"webApps.view.PublishTab.unpublish.waitTask.successTitle": "Приложение скрыто",
	"webApps.view.PublishTab.unpublish.waitTask.successMessage": "Приложение скрыто",
	"webApps.view.PublishTab.publish.": "",
	"webApps.view.PublishTab.publish.inProgress.title": "Публикация приложения",
	"webApps.view.PublishTab.publish.inProgress.description": "Пожалуйста, подождите...",
	"webApps.view.PublishTab.publish.waitTask.errorMessage": "Свяжите с администратором",
	"webApps.view.PublishTab.publish.waitTask.errorTitle": "Ошибка при публикации приложения",
	"webApps.view.PublishTab.publish.waitTask.successMessage": "Приложение опубликовано",
	"webApps.view.PublishTab.publish.waitTask.successTitle": "Приложение опубликовано",
	"webApps.view.PublishTab.platform.": "",
	"webApps.view.PublishTab.platform.inProgress.description": "Пожалуйста, подождите...",
	"webApps.view.PublishTab.platform.inProgress.title": "Меняем способ размещения",
	"webApps.view.PublishTab.platform.waitTask.errorMessage": "Свяжите с администратором",
	"webApps.view.PublishTab.platform.waitTask.errorTitle": "Ошибка при смене способа размещения",
	"webApps.view.PublishTab.platform.waitTask.successMessage": "Приложение перенесено",
	"webApps.view.PublishTab.platform.waitTask.successTitle": "Приложение перенесено",

};

const listWebApps = {
	"webApps.list.title": "WebApps",
	"webApps.list.create": "Новая запись",
	"webApps.list.table.identifier": "Идентификатор",
	"webApps.list.table.name": "Название",
	"webApps.list.table.domain": "Домен",
	"webApps.list.table.locales": "Языки",
	"webApps.list.table.domain.NONE": "Нет",
	"webApps.list.table.status": "Статус публикации",
	"webApps.list.table.status.PUBLISHED": "Опубликован",
	"webApps.list.table.status.DRAFT": "Черновик",
	"webApps.list.table.status.DEFAULT": "Не опубликован",
	"webApps.list.table.statistics": "Статистика посещения за 24 часа",
	"webApps.list.table.statistics.uniq": "Уникальных",
	"webApps.list.table.statistics.installs": "Установок",
	"webApps.list.table.actions": "Действия",
	"webApps.list.table.actions.edit": "Редактировать",
	"webApps.list.table.actions.clone": "Клонировать",
	"webApps.list.create.title": "Новое pwa",
	"webApps.list.create.name": "Название PWA",
	"webApps.list.clone.title": "Клонирование pwa",
	"webApps.list.clone.name": "Название PWA",
}

const webAppAnalyticsTab = {
	"webApps.view.AnalyticsTab.keitaro.title": "Интеграция с Кейтаро",
	"webApps.view.AnalyticsTab.keitaro.description": "В этом разделе можно настроить интеграцию с Кейтаро. Включите опцию, чтобы активировать эту интеграцию и заполните необходимые поля для настройки API URL и ключа.",
	"webApps.view.AnalyticsTab.keitaro.enabled.label": "Включено",
	"webApps.view.AnalyticsTab.keitaro.enabled.description": "Интеграция отключена",
	"webApps.view.AnalyticsTab.keitaro.apiUrl.label": "Домен кейтары",
	"webApps.view.AnalyticsTab.keitaro.apiKey.label": "Ключ API кейтары",

	"webApps.view.AnalyticsTab.gTag.title": "Google Analytics",
	"webApps.view.AnalyticsTab.gTag.description": "Этот раздел позволяет настроить интеграцию с Google Analytics. Включите опцию для отслеживания событий через Google Tag и заполните необходимые данные, такие как идентификатор и название события становки.",
	"webApps.view.AnalyticsTab.gTag.enabled": "Включено",
	"webApps.view.AnalyticsTab.gTag.gTagId": "Идентификатор google tag",
	"webApps.view.AnalyticsTab.gTag.installEventName": "Название события установки",

	"webApps.view.AnalyticsTab.clarity.title": "Microsoft Clarity",
	"webApps.view.AnalyticsTab.clarity.description": "Этот раздел позволяет настроить интеграцию с Microsoft Clarity. Включите опцию, чтобы активировать интеграцию и укажите идентификатор.",
	"webApps.view.AnalyticsTab.clarity.enabled": "Включено",
	"webApps.view.AnalyticsTab.clarity.tagId": "Идентификатор",
	"webApps.view.AnalyticsTab.clarity.findInfo": "Где найти идентификатор?",
	"webApps.view.AnalyticsTab.clarity.findInfo.description": "В коде предоставляемым claply найдите выделенный красным фрагмент",

	"webApps.view.AnalyticsTab.googleTracking.title": "Google Отслеживание лидов",
	"webApps.view.AnalyticsTab.googleTracking.description": "Этот раздел позволяет настроить интеграцию с Google Ads путём передачи лидов по ссылке на таблицу",
	"webApps.view.AnalyticsTab.googleTracking.enabled": "Включено",
	"webApps.view.AnalyticsTab.googleTracking.entry.label": "Выберите ссылку в которой будут доступны лиды этого приложения",

	"webApps.view.AnalyticsTab.facebook.title": "Facebook",
	"webApps.view.AnalyticsTab.facebook.description": "В этом разделе можно настроить интеграцию с Facebook. Включите опцию для работы с пикселем Facebook и заполните поля токена доступа и идентификатора пикселя.",
	"webApps.view.AnalyticsTab.facebook.enabled": "Включено",
	"webApps.view.AnalyticsTab.facebook.mainPixel": "Основной пиксель",
	"webApps.view.AnalyticsTab.facebook.mainPixel.label": "Выберите пиксель",
	"webApps.view.AnalyticsTab.facebook.requireFBP.label": "Требовать FBP перед переходом к установщику",
	"webApps.view.AnalyticsTab.facebook.requireFBP.description": "Если включено то параметры FBP и FBC будут получены перед переходом к установщику, иначе они будут получены на странице установщика",

	"webApps.view.AnalyticsTab.facebook.additionalPixels.title": "Дополнительные пиксели",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.label": "Выберите пиксель",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.add.modalTitle": "Добавить пиксель",
	"webApps.view.AnalyticsTab.facebook.additionalPixels.add.buttonLabel": "Добавить дополнительный пиксель",

	"webApps.view.AnalyticsTab.onInstallWebHook.title": "Install webhook",
	"webApps.view.AnalyticsTab.onInstallWebHook.description": "Этот раздел позволяет настроить вебхук для обработки событий установки. Включите опцию, чтобы активировать вебхук и укажите URL и метод запроса.",
	"webApps.view.AnalyticsTab.onInstallWebHook.enabled": "Включено",
	"webApps.view.AnalyticsTab.onInstallWebHook.url": "URL вебхука",
	"webApps.view.AnalyticsTab.onInstallWebHook.method": "Метод запроса",

	"webApps.view.AnalyticsTab.remoteEventsMap.title": "Отслеживание событий",
	"webApps.view.AnalyticsTab.remoteEventsMap.description": "Вы можете передавать нам события которые мы будем сохранять у себя и передавать в внешние сервисы которые вы подключите",
	"webApps.view.AnalyticsTab.remoteEventsMap.input.label": "Передавайте запросы сюда",

	"webApps.view.AnalyticsTab.remoteEventsMap.alert.title": "Обратите внимание",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replace": "Замените ",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replaceIdentifier": "Идентификатор пользователя, который вы получили в поле",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.replaceForValues": "(указанные ниже) на нужные значения.",

	"webApps.view.AnalyticsTab.remoteEventsMap.alert.info.1": "Так же вы можете передать в данной ссылке параметр",
	"webApps.view.AnalyticsTab.remoteEventsMap.alert.info.2": "для того чтобы использовать другой пиксель",
	"webApps.view.AnalyticsTab.remoteEventsMap.mapping.title": "Маппинг поля eventName",
	"webApps.view.AnalyticsTab.remoteEventsMap.onRegistration": "Возможные значения для события регистрации пользователя",
	"webApps.view.AnalyticsTab.remoteEventsMap.onDeposit": "Возможные значения для события вноса пользователем депозита",
}

const domainsPageList = {
	"domainsPage.list.title": "Домены",
	"domainsPage.list.create.label": "Добавить домен",
	"domainsPage.list.create.link": "Добавить домен",
	"domainsPage.list.create.purchase": "Купить домен",

	"domainsPage.list.table.identifier": "Идентификатор",
	"domainsPage.list.table.domainName": "Домен",
	"domainsPage.list.table.registrar": "Регистратор",
	"domainsPage.list.table.state": "Состояние",
	"domainsPage.list.table.actions": "Функции",
	"domainsPage.list.table.actions.edit": "Редактировать",

	"domainsPage.list.table.state.ADDED": "Добавлен",
	"domainsPage.list.table.state.WAIT": "Ожидает выкупа",
	"domainsPage.list.table.state.IN_PROGRESS": "В процессе выкупа",
	"domainsPage.list.table.state.DONE": "Выкуплен",
	"domainsPage.list.table.state.ERROR": "Ошибка выкупа",
	"domainsPage.list.table.state.UNKNOWN": "Неизвестное состояние",

	"domainsPage.list.buy.label": "Купить",
	"domainsPage.list.link.label": "Добавить",
	"domainsPage.list.buy.modal.title": "Покупка домена",
	"domainsPage.list.buy.modal.buy": "Купить",
	"domainsPage.list.link.modal.title": "Добавление домена",
}

const domainsPageView = {
	"domainsPage.view.title": "Редактор",
	"domainsPage.view.action.delete": 'Удалить',
	"domainsPage.view.form.title": "Настройки",
	"domainsPage.view.form.domain": "Домен сайта",
	"domainsPage.view.form.comment": "Комментарий",
	"domainsPage.view.status.WAIT": "Система ждёт, когда воркер возьмёт задачу",
	"domainsPage.view.status.IN_PROGRESS": "Задача в данный момент выполняется. Текущее действие: ",
	"domainsPage.view.status.DONE": "Задание полностью выполнено",
	"domainsPage.view.status.ERROR": "При выполнении задачи произошла ошибка: ",
	"domainsPage.view.status.link.label": "Перейти к заданию покупки данного домена",

	"domainsPage.view.form.allowSplit": "Разрешить использование этого домена несколькими PWA",
	"domainsPage.view.form.split.target": "Где будут размещаться PWA",
	"domainsPage.view.form.split.title": "Настройка весов",
	"domainsPage.view.form.split.weight": "Вес данной PWA",
	"domainsPage.view.form.split.reroll":"Сбросить ключ весов",
	"domainsPage.view.form.split.rerollSuccess": 'Ключ весов сброшен, не забывайте сохранить',


}

const uptimeChecker = {
	"domainsPage.view.status.title":"Статус домена",

	"domainsPage.list.table.uptime.ok": "Домен работоспособен",
	"domainsPage.list.table.uptime.warning": "Домен работасопобен",
	"domainsPage.list.table.uptime.error": "Ошибка проверки",
	"domainsPage.view.uptime.title.ok": "Домен работоспособен",
	"domainsPage.view.uptime.desc.ok": "Домен работоспособен",
	"domainsPage.view.uptime.title.warning": "Домен работасопобен",
	"domainsPage.view.uptime.desc.warning": "Домен работает но приложение не найдено",
	"domainsPage.view.uptime.title.error": "Ошибка проверки",
	"domainsPage.view.uptime.desc.error": "Домен не работоспособен",
}

const mainPage = {
	"pages.mainPage.title": "Главная страница",
	"pages.mainPage.teamMembers.all": "Все пользователи",
	"pages.mainPage.pwa.all": "Все приложения",
	"pages.mainPage.generalBadges.visits": "Визиты",
	"pages.mainPage.generalBadges.uniqVisits": "Уник. визиты",
	"pages.mainPage.generalBadges.installs": "Установки",
	"pages.mainPage.generalBadges.registrations": "Регистрации",
	"pages.mainPage.generalBadges.deposits": "Депозиты",
	"pages.mainPage.cta.register": "C2R",
	"pages.mainPage.cta.install": "C2I",
	"pages.mainPage.cta.deposit": "C2D",

	"pages.mainPage.county": "Страны",
	"pages.mainPage.county.map": "Карта",
	"pages.mainPage.county.table": "Таблица",

	"pages.mainPage.apps": "PWA",
	"pages.mainPage.sources": "Источники",
	"pages.mainPage.devices": "Устройства",
	"pages.mainPage.devices.browser": "Браузеры",
	"pages.mainPage.devices.os": "Операционные системы",
	"pages.mainPage.pushStats": "Push-уведомления",
}

const pushLogsStats = {
	'pages.pushLogs.pushStats.sent': 'Отправлено',
	'pages.pushLogs.pushStats.open': 'Открыто',
	'pages.pushLogs.pushStats.delivered': 'Доставлено',
	'pages.pushLogs.pushStats.failed': 'Ошибка доставки',
	'pages.pushLogs.pushStats.title': 'Статистика',
}

const webAppsTrackerTab = {
	"webApps.view.TrackerTab.title": "Оффер",
	"webApps.view.TrackerTab.description.1": "Введите ссылку на оффер куда будут попадать пользователи PWA после установки и запуска приложения.",
	"webApps.view.TrackerTab.description.2": "Используйте ссылку без query параметров, т.к они будут добавлены автоматически на основе параметров по которым пользователь перешёл на экран установки pwa",

	"webApps.view.TrackerTab.primaryLink.title": "Основная ссылка",
	"webApps.view.TrackerTab.primaryLink.label": "Ссылка на оффер",

	"webApps.view.TrackerTab.primaryLink.type.label": "Тип ссылки",
	"webApps.view.TrackerTab.primaryLink.type.static": "Статичная ссылка",
	"webApps.view.TrackerTab.primaryLink.type.dynamic": "Динамичная ссылка (подключение к keitaro)",
	"webApps.view.TrackerTab.primaryLink.kDomain": "Домен keitaro (https://)",
	"webApps.view.TrackerTab.primaryLink.token": "Ключ компании",
	"webApps.view.TrackerTab.primaryLink.kFallback.label": "Запасная ссылка",
	"webApps.view.TrackerTab.primaryLink.kFallback.description": "Если система не сможет подключиться к вашему keitaro, то будет использоваться следующая ссылка",


	"webApps.view.TrackerTab.rules.title": "Правила",
	"webApps.view.TrackerTab.rules.desc.1": "Вы можете добавить несколько правил которые будут переопределять основную ссылку",
	"webApps.view.TrackerTab.rules.desc.2": "Если условия не срабатывают, то будет использоваться основная ссылка",
	"webApps.view.TrackerTab.rules.enabled": "Вариант активен?",
	"webApps.view.TrackerTab.rules.comment.label": "Комментарий к варианту",
	"webApps.view.TrackerTab.rules.comment.description": "Для вашего удобства",
	"webApps.view.TrackerTab.rules.conditions.title": "Условия срабатывания",
	"webApps.view.TrackerTab.rules.conditions.button.add": "Добавить условие",
	"webApps.view.TrackerTab.rules.offerLink.title": "Ссылка",
	"webApps.view.TrackerTab.rules.offerLink.label": "Ссылка на перенаправление",
	"webApps.view.TrackerTab.rules.offerLink.button.add": "Добавить ссылку",

	"webApps.view.TrackerTab.rules.offerLink.type.label": "Тип ссылки",
	"webApps.view.TrackerTab.rules.offerLink.type.static": "Статичная ссылка",
	"webApps.view.TrackerTab.rules.offerLink.type.dynamic": "Динамичная ссылка (подключение к keitaro)",
	"webApps.view.TrackerTab.rules.offerLink.kDomain": "Домен keitaro (https://)",
	"webApps.view.TrackerTab.rules.offerLink.token": "Ключ компании",
	"webApps.view.TrackerTab.rules.offerLink.kFallback.label": "Запасная ссылка",
	"webApps.view.TrackerTab.rules.offerLink.kFallback.description": "Если система не сможет подключиться к вашему keitaro, то будет использоваться следующая ссылка",


	"webApps.view.TrackerTab.resetClientCache.title": "Сбросить кэш ссылок",
	"webApps.view.TrackerTab.resetClientCache.description": "При необходимости вы можете сбросить кеш ссылок, чтобы они обновились",
	"webApps.view.TrackerTab.resetClientCache.button": "Сбросить",
	"webApps.view.TrackerTab.resetClientCache.success": "Кэш ссылок сброшен, Не забудьте сохранить изменения!",
}

const profilePage = {
	'pages.profile.title': 'Профиль',
	'pages.profile.avatar': 'Аватар',
	'pages.profile.changePassword': 'Изменить пароль',
	'pages.profile.changePassword.success': 'Пароль успешно изменен',
	'pages.profile.changePassword.title': 'Изменить пароль',
	'pages.profile.changePassword.error.title': 'Ошибка изменения пароля',
	'pages.profile.changePassword.error.INVALID_PASSWORD': 'Неверный старый пароль',
	'pages.profile.changePassword.oldPassword': 'Старый пароль',
	'pages.profile.changePassword.oldPassword.placeholder': 'Введите старый пароль',
	'pages.profile.changePassword.newPassword': 'Новый пароль',
	'pages.profile.changePassword.newPassword.placeholder': 'Введите новый пароль',
	'pages.profile.changePassword.change': 'Сменить',

	'pages.profile.tgConnect.success': 'Telegram успешно подключён! ({{tgId}})',
	'pages.profile.tgConnect.description': 'Подключите Telegram: нажмите на кнопку ниже',
	'pages.profile.tgConnect.button': 'Подключить Telegram',
	'pages.profile.tgConnect.button.disconnect': 'Отвязать Telegram',
}

const descriptionTab = {
	"webApps.views.DescriptionTab.name.label": "Название приложения",
	"webApps.views.DescriptionTab.content.isVerify.label": "Приложение верифицировано",
	"webApps.views.DescriptionTab.content.isVerify.description": "При установке этого параметра рядом с названием приложения появляется знак верифицированного приложения",
	"webApps.views.DescriptionTab.content.owner.label": "Разработчик приложения",
	"webApps.views.DescriptionTab.content.description.label": "Описание приложения",
	"webApps.views.DescriptionTab.content.updatedDate.label": "Дата последнего обновления приложения",
	"webApps.views.DescriptionTab.content.": "",
	"webApps.views.DescriptionTab.content.relatedApps.app.rating": "Рейтинг приложения",
	"webApps.views.DescriptionTab.content.relatedApps.app.url": "Ссылка на приложение",
	"webApps.views.DescriptionTab.content.relatedApps.app.name": "Название приложения",
	"webApps.views.DescriptionTab.content.relatedApps.position.bottom": "Снизу",
	"webApps.views.DescriptionTab.content.relatedApps.position.top": "Сверху",
	"webApps.views.DescriptionTab.content.relatedApps.position": "Положение блока",
	"webApps.views.DescriptionTab.content.relatedApps.enabled": "Блок активен?",
	"webApps.views.DescriptionTab.content.relatedApps.title": "Похожие приложения",

	"webApps.views.DescriptionTab.content.trailer.title": "Трейлер",
	"webApps.views.DescriptionTab.content.trailer.enabled": "Включить трейлер",
	"webApps.views.DescriptionTab.content.trailer.url.label": "Ссылка на трейлер",
	"webApps.views.DescriptionTab.content.trailer.url.decs": "Поддерживается YouTube и Facebook",
}

const webAppsTemplateEdit = {
	"webAppsTemplateEdit.playMarket.name": "Название",
	"webAppsTemplateEdit.playMarket.isVerify": "Приложение верифицировано",
	"webAppsTemplateEdit.playMarket.owner": "Разработчик приложения",
	"webAppsTemplateEdit.playMarket.rating": "Рейтинг",
	"webAppsTemplateEdit.playMarket.screens": "Экраны приложения",
	"webAppsTemplateEdit.playMarket.description": "Описание приложения",
	"webAppsTemplateEdit.playMarket.icon": "Иконка приложения",
	"webAppsTemplateEdit.playMarket.updatedDate": "Дата последнего обновления приложения",
	"webAppsTemplateEdit.playMarket.tags": "Теги приложения",

	"webAppsTemplateEdit.rouletteBalls.icon": "Иконка PWA",
	"webAppsTemplateEdit.rouletteBalls.playButtonText": "Кнопка начать играть",
	"webAppsTemplateEdit.rouletteBalls.sign": "Знак валюты ($)",
	"webAppsTemplateEdit.rouletteBalls.mainHowToPlay": "Заголовок",
	"webAppsTemplateEdit.rouletteBalls.mainRules": "Подзаголовок",
	"webAppsTemplateEdit.rouletteBalls.mainBetSize": "Строка 1",
	"webAppsTemplateEdit.rouletteBalls.mainLevel": "Строка 2",
	"webAppsTemplateEdit.rouletteBalls.mainWin": "Строка 3",
	"webAppsTemplateEdit.rouletteBalls.popup.group": "Всплывающее окно",
	"webAppsTemplateEdit.rouletteBalls.popup.title": "Заголовок",
	"webAppsTemplateEdit.rouletteBalls.popup.winValue": "Награда",
	"webAppsTemplateEdit.rouletteBalls.popup.register": "Текст",
	"webAppsTemplateEdit.rouletteBalls.popup.popupButtonText": "Кнопка забрать награду",
	"webAppsTemplateEdit.rouletteBalls.collisionReward": "Награда",
	"webAppsTemplateEdit.rouletteBalls.popupTriggerValue": "Показывать попап если значение достигло",
}

const pushStatsPage = {
	'pages.push.stats.menuItem': 'Статистика Push уведомлений',
	'pages.push.stats.title': 'Статистика Push уведомлений',

	"pages.push.stats.teamMembers.all": "Все пользователи",
	"pages.push.stats.pwa.all": "Все приложения",

	'pages.push.stats.top.sent.title': 'Топ отправленных',
	'pages.push.stats.top.opened.title': 'Топ открытых',
	'pages.push.stats.top.notOpened.title': 'Топ не открытых',
	'pages.push.stats.top.unsubsribed.title': 'Топ отписок',

}

export const locale = {
	// yoFormModal
	'yoFormModal.continue': 'Продолжить',

	...pushStatsPage,
	...profilePage,
	...pushLogsStats,
	...uptimeChecker,
	...domainsPageList,
	...domainsPageView,
	...cfAccountsList,
	...cfAccountsEdit,
	...webAppDomainTab,
	...listWebApps,
	...webAppAnalyticsTab,
	...webAppPublishTab,
	...webAppsTrackerTab,
	...mainPage,
	...descriptionTab,
	...webAppsTemplateEdit,

	// Auth page
	'auth.title': 'Добро пожаловать, Снова...',
	'auth.authError.title': 'Ошибка авторизации',
	'auth.authError.message': 'Неверный логин или пароль',
	'auth.login': 'Войти',
	// Menu elements
	'menuItems.mainPage': 'Главная страница',
	'menuItems.webApps': 'Мои PWA',
	'menuItems.domains': 'Мои домены',
	'menuItems.leads': 'Лиды',
	'menuItems.fbPixels': 'Мои пиксели',
	'menuItems.googleTrackingEntry': 'Ссылки для google ads',
	'menuItems.system': 'Система',
	'menuItems.system.adminUsers': 'Пользователи панели',
	'menuItems.system.jobs': 'Задачи',
	'menuItems.system.adspect': 'Настройки adspect',
	'menuItems.system.ua': 'Юзер агенты ботов',
	'menuItems.system.ip': 'IP блэклист',
	'menuItems.system.deeplSettings':'Настройки deepl',
	'menuItems.system.admSettings':'Настройки регистратора admTools',
	'menuItems.system.godaddySettings':'Настройки регистратора GoDaddy',
	'menuItems.system.nameCheapSettings':'Настройки NameCheap',
	'menuItems.system.porkBunSettings':'Настройки PorkBun',
	'menuItems.system.cloudflareSettings':'Настройки cloudflare (Глобальный аккаунт)',
	'menuItems.system.openaiSettings':'Настройки OpenAI',
	'menuItems.system.exchangeRateSettings':'Настройки ExchangeRate',
	'menuItems.system.cfAccounts':'Аккаунты Cloudflare',
	'menuItems.system.domainRegistrarsSettings': 'Настройки регистраторов',
	// jobs page
	'jobs.customConfirmPrompt': 'Вы действительно хотите повторить данное задание?',
	'jobs.confirmRunningJobDelete': 'Задача в данный момент выполняется, и её удаление не рекомендуется, продолжить?',
	'jobs.view.title': 'Просмотр задачи',
	'jobs.view.deleteButton': 'Удалить',
	'jobs.view.retryButton': 'Повторить задачу',
	'jobs.view.jobTypeCaption': 'Тип задания',
	'jobs.view.jobTypeCaption.buyDomain': 'Тип задания: Купить домен',
	'jobs.view.state.wait.title':'Состояние: Ожидает',
	'jobs.view.state.wait.description':'Система ждёт когда воркер возьмёт задачу',
	'jobs.view.state.inProgress.title':'Состояние: В работе',
	'jobs.view.state.inProgress.description':'Задача в данный момент выполняется. Текущее действие: ',
	'jobs.view.state.done.title':'Состояние: Выполнена',
	'jobs.view.state.done.description':'Задание полностью выполнено',
	'jobs.view.state.error.title':'Состояние: Ошибка',
	'jobs.view.state.error.description':'При выполнении задачи произошла ошибка: ',
	'jobs.view.logsButton': 'Логи задачи',
	'jobs.view.logsModalTitle': 'Логи задачи',

	'jobs.list.title': 'Список задач',
	'jobs.list.createJobButton': 'Новая задача',
	'jobs.list.createJobTitle': 'Новая задача',
	'jobs.list.tableHeader._id': 'Идентификатор',
	'jobs.list.tableHeader.type': 'Тип задания',
	'jobs.list.tableHeader.state': 'Состояние',
	'jobs.list.tableHeader.state.WAIT': 'Ожидает',
	'jobs.list.tableHeader.state.IN_PROGRESS': 'В процессе: ',
	'jobs.list.tableHeader.state.DONE': 'Выполнено',
	'jobs.list.tableHeader.state.ERROR': 'Ошибка',
	'jobs.list.tableHeader.state.UNKNOWN': 'Неизвестно',
	'jobs.list.tableHeader.createdAt': 'Дата создания задачи',
	'jobs.list.tableHeader.actions': 'Действия',
	'jobs.list.tableHeader.actions.edit': 'Редактировать',

	'fbPixelsLogs.list.title': 'Логи пикселя',
	'fbPixelsLogs.list.createButton': 'Новая задача',
	'fbPixelsLogs.list.tableHeader._id': 'Идентификатор',
	'fbPixelsLogs.list.tableHeader.createdAt': 'Дата запроса',
	'fbPixelsLogs.list.tableHeader.lead': 'Лид',
	'fbPixelsLogs.list.tableHeader.lead.empty': 'Лид отсутствует',
	'fbPixelsLogs.list.tableHeader.event': 'Дата запроса',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully': 'Запрос успешно отправлен',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully.true': 'Да',
	'fbPixelsLogs.list.tableHeader.sentSuccessfully.false': 'Нет',
	'fbPixelsLogs.list.tableHeader.actions': 'Действия',
	'fbPixelsLogs.list.tableHeader.actions.edit': 'Редактировать',

	'fbPixelsLogs.view.title': 'Просмотр лога пикселя',
	'fbPixelsLogs.view.action.delete': 'Удалить',
	'fbPixelsLogs.view.fields.event': 'Тип события',
	'fbPixelsLogs.view.fields.sentSuccessfully': 'Событие успешно передано',
	'fbPixelsLogs.view.fields.fbTraceId': 'ID трассировки facebook',
	'fbPixelsLogs.view.fields.lead.empty': 'Лид отсутствует',
	'fbPixelsLogs.view.fields.goToLead': 'Перейти к лиду',

	'fbPixelsLogs.view.fields.sentUnsuccessfully': 'Событие не передано',
	'fbPixelsLogs.view.fields.fbErrMessage': 'Сообщение об ошибке',
	'fbPixelsLogs.view.fields.fbErrCode': 'Код ошибки',
	'fbPixelsLogs.view.fields.fbErrSubCode': 'Подкод ошибки',
	'fbPixelsLogs.view.fields.fbErrType': 'Тип ошибки',

	'fbPixels.edit.eventsMapping.title': 'Маппинг событий',
	'fbPixels.edit.eventsMapping.installEventNameLabel': 'Передавать событие установки PWA как',
	'fbPixels.edit.eventsMapping.registrationEventNameLabel': 'Передавать событие регистрации как',
	'fbPixels.edit.eventsMapping.depositEventNameLabel': 'Передавать событие вноса депозита как',

	// general messages
	'settings.general.savedMessage': 'Успешно сохранено',
	'settings.general.saveBth': 'Сохранить',

	// adm settings
	'settings.adm.title': 'Настройки регистратора доменов AdmTools',
	'settings.adm.apiKey': 'Токен доступа',
	'settings.adm.limitDomainPriceUAN': 'Максимальная цена домена (UAH)',

	// godaddy settings
	'settings.godaddy.title': 'Настройки регистратора доменов GoDaddy',
	'settings.godaddy.apiKey': 'Api key',
	'settings.godaddy.secret': 'secret',

	// nameCheap settings
	'settings.nameCheap.title': 'Настройки регистратора доменов NameCheap',
	'settings.nameCheap.apiKey': 'Api key',
	'settings.nameCheap.baseUrl': 'baseUrl',
	'settings.nameCheap.apiUser': 'apiUser',
	'settings.nameCheap.clientIP': 'clientIP',

	// porkBun settings
	'settings.porkBun.title': 'Настройки регистратора доменов PorkBun',
	'settings.porkBun.apiKey': 'Api key',
	'settings.porkBun.secret': 'secret',

	// exchangeRate settings
	'settings.exchangeRate.title': 'Настройки ExchangeRate',
	'settings.exchangeRate.apiKey': 'Api key',

	// leads page
	'leads.list.title': 'Лиды',
	'leads.list.onlyUniqs': 'Показывать только уникальные лиды',
	'leads.list.tableHeader._id': 'Идентификатор',
	'leads.list.tableHeader.clientId': 'Идентификатор сессии клиента',
	'leads.list.tableHeader.clientId.log': 'Лог этой сессии',
	'leads.list.tableHeader.runId': 'Идентификатор сеанса клиента',
	'leads.list.tableHeader.runId.log': 'Лог этой сеанса',
	'leads.list.tableHeader.clientIp': 'ip адрес',
	'leads.list.tableHeader.browserName': 'Браузер',
	'leads.list.tableHeader.osName': 'Операционная система',
	'leads.list.tableHeader.deviceType': 'Тип устройства',

	'leads.list.tableHeader.fireCloaking': 'Клоака сработала?',
	'leads.list.tableHeader.fireCloaking.true': 'Да',
	'leads.list.tableHeader.fireCloaking.false': 'Нет',
	'leads.list.tableHeader.fireCloakingReason': 'Причина клоаки',
	'leads.list.tableHeader.fireCloakingReason.badOS': 'Неподходящая OS',
	'leads.list.tableHeader.fireCloakingReason.badIp': 'ip из черного списка',
	'leads.list.tableHeader.fireCloakingReason.adspect': 'adspect фильтр',
	'leads.list.tableHeader.fireCloakingReason.badUserAgent': 'UserAgent из черного списка',
	'leads.list.tableHeader.fireCloakingReason.noCountryCode': 'cf-ipcountry недоступен',
	'leads.list.tableHeader.fireCloakingReason.badCountryCode': 'Неподходящая страна',
	'leads.list.tableHeader.fireCloakingReason.badDeviceType': 'Неподходящий тип устройства',

	'leads.list.tableHeader.tryAppInstall': 'Попытка установки?',
	'leads.list.tableHeader.tryAppInstall.true': 'Да',
	'leads.list.tableHeader.tryAppInstall.false': 'Нет',
	'leads.list.tableHeader.appInstalled': 'Установлено успешно?',
	'leads.list.tableHeader.appInstalled.true': 'Да',
	'leads.list.tableHeader.appInstalled.false': 'Нет',
	'leads.list.tableHeader.userRegistered': 'Пользователь зарегистрирован?',
	'leads.list.tableHeader.userRegistered.true': 'Да',
	'leads.list.tableHeader.userRegistered.false': 'Нет',
	'leads.list.tableHeader.userDeposited': 'Депозит внесён',
	'leads.list.tableHeader.userDeposited.true': 'Да',
	'leads.list.tableHeader.userDeposited.false': 'Нет',
	'leads.list.tableHeader.createdAt': 'Дата создания задачи',
	'leads.list.tableHeader.actions': 'Действия',
	'leads.list.tableHeader.actions.view': 'Просмотреть',

	'leads.list.customFilters.leadSource': 'Источник лидов',
	'leads.list.customFilters.leadSource.any': 'Любой',
	'leads.list.customFilters.leadSource.facebook': 'Facebook',
	'leads.list.customFilters.leadSource.google': 'Google',
	'leads.list.customFilters.refferer':'Реферер',

	'leads.view.title': 'Просмотр лида',
	'leads.view.delete': 'Удалить',
	'leads.view.goToApp': 'Перейти в приложение',
	'leads.view.allLeadsForClient': 'Все лиды этого клиента',
	'leads.view.clientLogs': 'Логи этого клиента',
	'leads.view.sessionLogs': 'Логи данного сеанса',
	'leads.view.clientStatus': 'Состояния клиента',
	'leads.view.appInstalled': 'Приложение установлено',
	'leads.view.userRegistered': 'Пользователь зарегистрирован',
	'leads.view.userDeposited': 'Пользователь пополнил баланс',
	'leads.view.clientIp': 'IP клиента',
	'leads.view.timezone': 'Часовой пояс',
	'leads.view.otherClientIps': 'Другие известные ip адреса клиентов',
	'leads.view.browserInfo': 'Информация о браузере',
	'leads.view.userAgent': 'User agent',
	'leads.view.browserName': 'Браузер',
	'leads.view.browserVersion': 'Версия браузера',
	'leads.view.osName': 'Операционная система',
	'leads.view.osVersion': 'Версия операционной системы',
	'leads.view.deviceType': 'Тип устройства',
	'leads.view.analytics': 'Аналитика',
	'leads.view.primary': 'Основные',
	'leads.view.facebook': 'facebook',
	'leads.view.fbc': 'FBC',
	'leads.view.fbp': 'FBP',
	'leads.view.google': 'google',
	'leads.view.gclid': 'GCLID',
	'leads.view.rawRequestHeaders': 'Сырые заголовки запроса',
	'leads.view.rawQueryParams': 'Сырые query параметры',
	'leads.view.showAll': 'Показать все',
	'leads.view.hide': 'Скрыть',
	'leads.view.time': 'Время',
	'leads.view.unknown': 'неизвестно',
	'leads.view.referer':'Реферер',

	'components.timeRangeSelect.today': 'Сегодня',
	'components.timeRangeSelect.yesterday': 'Вчера',
	'components.timeRangeSelect.currentWeek': 'Текущая неделя',
	'components.timeRangeSelect.last7Days': 'Последние 7 дней',
	'components.timeRangeSelect.currentMonth': 'Текущий месяц',
	'components.timeRangeSelect.lastMonth': 'Предыдущий месяц',
	'components.timeRangeSelect.last30Days': 'Последние 30 дней',
	'components.timeRangeSelect.currentYear': 'Текущий год',
	'components.timeRangeSelect.allTime': 'Все время',
	'components.timeRangeSelect.custom': 'Интервал дат',

	'fbPixels.list.tableHeader._id': 'Идентификатор',
	'fbPixels.list.tableHeader.name': 'Название',
	'fbPixels.list.tableHeader.pixel': 'Пиксель',
	'fbPixels.list.tableHeader.actions': 'Действия',
	'fbPixels.modal.title': 'Новый пиксель',
	'fbPixels.modal.label.name': 'Название пикселя',
	'fbPixels.modal.label.pixel': 'Идентификатор пикселя',
	'fbPixels.modal.label.token': 'Токен для пикселя',
	'fbPixels.header.title': 'Мои пиксели',
	'fbPixels.header.button.newPixel': 'Новый пиксель',

	'fbPixels.edit.headerTitle': 'Редактор пикселя',
	'fbPixels.edit.logsButton': 'Логи',
	'fbPixels.edit.saveButton': 'Сохранить',
	'fbPixels.edit.deleteButton': 'Удалить',
	'fbPixels.edit.pixelNameLabel': 'Название пикселя',
	'fbPixels.edit.pixelIdLabel': 'Идентификатор пикселя',
	'fbPixels.edit.pixelTokenLabel': 'Токен для пикселя',
	'fbPixels.edit.saveSuccess': 'Сохранение успешно',

	'fbPixels.stat.title': 'Статистика пикселя',
	'fbPixels.stat.selectWebApp': 'Выберите PWA',
	'fbPixels.stat.timeRange': 'Временной период',
	'fbPixels.stat.installs': 'Передано событий установки',
	'fbPixels.stat.registrations': 'Передано событий регистрации',
	'fbPixels.stat.deposits': 'Передано событий депозита',
	'fbPixels.stat.installCount': 'Количество установок',
	'fbPixels.stat.registrationCount': 'Количество регистрации',
	'fbPixels.stat.depositCount': 'Количество депозитов',

	'settings.openai.saveSuccess': 'Настройки успешно сохранены',
	'settings.openai.headerTitle': 'Настройки OpenAI',
	'settings.openai.saveButton': 'Сохранить настройки',
	'settings.openai.tokenLabel': 'Токен доступа к OpenAI',
	'settings.openai.endpointLabel': 'Эндпоинт для доступа к OpenAI (Опционально)',
	'settings.openai.modelLabel': 'Модель OpenAI',
	'settings.openai.promptsTitle': 'Промпты',
	'settings.openai.generateDescriptionLabel': 'Генерация описания приложения',
	'settings.openai.translateLabel': 'Перевод',
	'settings.openai.generateCommentsLabel': 'Генерация комментариев',

	'leads.list.tableHeader.isSubscribed': 'Подписка на push-уведомления',
	'leads.list.tableHeader.isSubscribed.true': 'Подписан',
	'leads.list.tableHeader.isSubscribed.false': 'Не подписан',

	'leads.view.sendPush.title': 'Отправить push-уведомление',
	'leads.view.sendPush.form.title': 'Заголовок',
	'leads.view.sendPush.form.message': 'Сообщение',
	'leads.view.sendPush.success': 'Уведомление успешно отправлено',

	'leads.view.push.sendPush': 'Отправить push-уведомление',
	'leads.view.push.notSubscribed': 'Не подписан на push-уведомления',
	'leads.view.push.subscribed': 'Подписан на push-уведомления',

	'leads.view.sendPush.form.openApp': 'Открыть приложение',
	'leads.view.sendPush.form.openAppDescription': 'Открыть приложение по клику на уведомление',
	'leads.view.sendPush.form.targetLink': 'Ссылка куда будет направлен пользователь после клика на уведомление',
	'leads.view.sendPush.form.image': 'Изображение',

	'menuItems.pushJobs': 'PUSH рассылки',
	'sendPush.list.tableHeader.appId': 'Приложение',
	'sendPush.list.tableHeader.appId.notSet': 'Не задано',
	'sendPush.list.tableHeader.title': 'Заголовок',
	'sendPush.list.tableHeader.createdAt': 'Создан',
	'sendPush.list.tableHeader.actions.view': 'Просмотреть',
	'sendPush.list.createJob.title': 'Создание PUSH рассылки',
	'sendPush.list.createJob.form.appId': 'Приложение',
	'sendPush.list.createJob.form.title': 'Заголовок',
	'sendPush.list.createJob.form.message': 'Сообщение',
	'sendPush.list.createJob.form.openApp': 'Открыть приложение',
	'sendPush.list.createJob.form.openAppDescription': 'Открыть приложение по клику на уведомление',
	'sendPush.list.createJob.form.targetLink': 'Ссылка куда будет направлен пользователь после клика на уведомление',
	'sendPush.list.createJob.form.image': 'Изображение',
	'sendPush.list.title': 'PUSH рассылки',
	'sendPush.list.actions.create': 'Создать PUSH рассылку',

	'pushJobs.edit.title': 'Просмотр PUSH рассылки',
	'pushJobs.edit.actions.remove': 'Удалить',
	'pushJobs.edit.actions.retry': 'Повторить PUSH рассылку',
	'pushJobs.edit.actions.retryConfirm': 'Вы действительно хотите повторить PUSH рассылку?',
	'pushJobs.edit.form.appId': 'Приложение',
	'pushJobs.edit.form.title': 'Заголовок',
	'pushJobs.edit.form.message': 'Сообщение',
	'pushJobs.edit.form.openApp': 'Открыть приложение',
	'pushJobs.edit.form.openAppDescription': 'Открыть приложение по клику на уведомление',
	'pushJobs.edit.form.targetLink': 'Ссылка куда будет направлен пользователь после клика на уведомление',
	'pushJobs.edit.form.image': 'Изображение',
	'pushJobs.edit.stats.details.hide': 'Скрыть',
	'pushJobs.edit.form.details': 'Показать все',
	'pushJobs.edit.stats.title': 'Статистика PUSH рассылки',
	'pushJobs.edit.stats.details.title': 'Подробная статистика PUSH рассылки',

	'menuItems.push': 'PUSH уведомления',
	'menuItems.push.Jobs': 'Рассылки',
	'menuItems.push.Segments': 'Сегменты',
	'menuItems.push.Messages': 'Сообщения',

	'push.messages.list.table.id': 'ID',
	'push.messages.list.table.comment': 'Комментарий',
	'push.messages.list.table.view': 'Просмотреть',
	'push.messages.list.title': 'Шаблоны push сообщений',
	'push.messages.list.create': 'Создать шаблон',

	'push.messages.view.title': 'Шаблон PUSH сообщения',
	'push.messages.view.save': 'Сохранить',
	'push.messages.view.remove': 'Удалить',
	'push.messages.view.fields.comment': 'Комментарий',
	'push.messages.view.fields.comment.description': 'Комментарий для вашего удобства',
	'push.messages.view.items': 'Сообщения',
	'push.messages.view.items.add': 'Добавить сообщение',
	'push.messages.view.fields.language': 'Язык',
	'push.messages.view.items.title': 'Заголовок',
	'push.messages.view.items.message': 'Сообщение',
	'push.messages.view.items.image': 'Изображение',
	'push.messages.view.fallbackLanguage': 'Язык по умолчанию',
	'push.messages.view.fallbackLanguage.description': 'Если не один из вышеуказанные языков не подходит для пользователя то будет использован этот язык',
	'push.messages.view.fields.fallbackLanguage': 'Язык по умолчанию',
	'push.messages.view.fields.autoTranslate': 'Автоматически переводить сообщение на язык пользователя',
	'push.messages.view.save.invalid': 'Данные не прошли проверку, сохранить всё равно?',

	'push.segments.list.table.id': 'ID',
	'push.segments.list.table.comment': 'Комментарий',
	'push.segments.list.table.actions.view': 'Просмотреть',
	'push.segments.list.title': 'Сегменты пользователей',
	'push.segments.list.create': 'Создать сегмент',

	'push.segments.edit.saveSuccess': 'Сегмент успешно сохранен',
	'push.segments.edit.title': 'Просмотр сегмента',
	'push.segments.edit.actions.save': 'Сохранить',
	'push.segments.edit.actions.remove': 'Удалить',

	'push.segments.edit.fields.comment': 'Комментарий',
	'push.segments.edit.fields.comment.description': 'Комментарий для вашего удобства',
	'push.segments.edit.fields.webApps': 'Приложения',
	'push.segments.edit.flags': 'Состояние клиента',
	'push.segments.edit.flags.isTryAppInstall': 'Пользователь пытался установить приложение',
	'push.segments.edit.flags.isAppInstalled': 'Приложение установлено',
	'push.segments.edit.flags.isAppRunned': 'Приложение запущено',
	'push.segments.edit.flags.isRegistered': 'Пользователь зарегистрирован',
	'push.segments.edit.flags.isDeposited': 'Пользователь внёс депозит',

	'push.jobs.list.table.id': 'ID',
	'push.jobs.list.table.enabled': 'Включено',
	'push.jobs.list.table.enabled.true': 'Да',
	'push.jobs.list.table.enabled.false': 'Нет',
	'push.jobs.list.table.comment': 'Комментарий',
	'push.jobs.list.table.runMode': 'Режим запуска',
	'push.jobs.list.table.runMode.manual': 'Ручной',
	'push.jobs.list.table.runMode.schedule': 'По расписанию',
	'push.jobs.list.table.runMode.trigger': 'По тригеру',
	'push.jobs.list.table.actions': 'Действия',
	'push.jobs.list.table.actions.view': 'Просмотреть',
	'push.jobs.list.title': 'Рассылки PUSH',
	'push.jobs.list.create': 'Создать рассылку',

	'push.jobs.edit.saveSuccess': 'Рассылка успешно сохранена',
	'push.jobs.edit.title': 'Просмотр рассылки',
	'push.jobs.edit.actions.save': 'Сохранить',
	'push.jobs.edit.actions.remove': 'Удалить',
	'push.jobs.edit.isDefault': 'Это правило по умолчанию',
	'push.jobs.edit.isDefault.description': 'Данное правило является правилом по умолчанию. Вы можете его разблокировать и оно будет доступно для всех ваших PWA',
	'push.jobs.edit.isDefault.unlock': 'Разблокировать',
	'push.jobs.edit.fields.comment': 'Комментарий',
	'push.jobs.edit.fields.comment.description': 'Комментарий для вашего удобства',
	'push.jobs.edit.fields.enabled': 'Включить рассылку',
	'push.jobs.edit.fields.enabled.description': 'Включить или выключить рассылку',
	'push.jobs.edit.fields.messages': 'Сообщения',
	'push.jobs.edit.fields.messages.description': 'Сообщения для рассылки, если выбрать несколько будет использовано одно из них',
	'push.jobs.edit.fields.segments': 'Сегменты пользователей',
	'push.jobs.edit.fields.segments.description': 'Сегменты пользователей',
	'push.jobs.edit.fields.runMode': 'Режим запуска',
	'push.jobs.edit.fields.runMode.description': 'Режим запуска рассылки',
	'push.jobs.edit.fields.runMode.manual': 'Вручную',
	'push.jobs.edit.fields.runMode.schedule': 'По расписанию',
	'push.jobs.edit.fields.runMode.trigger': 'По тригеру',
	'push.jobs.edit.fields.scheduleProps.nextRun': 'Следующий запуск',
	'push.jobs.edit.fields.scheduleProps.repeat': 'Повторять',
	'push.jobs.edit.fields.scheduleProps.repeatPeriod': 'Период повтора',
	'push.jobs.edit.fields.triggerProps.triggers.add': 'Добавить тригер',

	'push.jobs.edit.addTrigger.title': 'Добавить тригер',
	'push.jobs.edit.addTrigger.delay': 'Задержка перед запуском',

	'push.jobs.edit.addTrigger.variants.onTryAppInstall': 'Пользователь начал установку приложения',
	'push.jobs.edit.addTrigger.variants.onAppInstalled': 'Приложение успешно установлено пользователем',
	'push.jobs.edit.addTrigger.variants.appNotInstalled': 'Приложение не установлено пользователем',
	'push.jobs.edit.addTrigger.variants.onAppRunned': 'Пользователь запустил приложение',
	'push.jobs.edit.addTrigger.variants.appNotRunned': 'Пользователь не запустил приложение',
	'push.jobs.edit.addTrigger.variants.onRegister': 'Пользователь зарегистрировался',
	'push.jobs.edit.addTrigger.variants.notRegister': 'Пользователь не зарегистрировался',
	'push.jobs.edit.addTrigger.variants.onDeposit': 'Пользователь совершил депозит',
	'push.jobs.edit.addTrigger.variants.notDeposit': 'Пользователь не совершил депозит',
	'push.jobs.edit.addTrigger.variants.pushSubscribe': 'Пользователь подписался на рассылку',

	'push.jobs.edit.addTrigger.for': 'Прошло времени',
	'push.jobs.edit.addTrigger.triggerType': 'Тип тригера',

	'doc.periodInput.title': 'Примеры',
	'doc.periodInput.30s': '"30s" = 30 секунд',
	'doc.periodInput.15m': '"15m" = 15 минут',
	'doc.periodInput.2h': '"2h" = 2 часа',
	'doc.periodInput.1d': '"1d" = день',
	'doc.periodInput.1w': '"1w" = неделю',
	'doc.periodInput.1M': '"1M" = месяц',

	'menuItems.whitePages': 'White Pages',
	'whitePages.list.table.id': 'ID',
	'whitePages.list.table.name': 'Название',
	'whitePages.list.table.actions': 'Действия',
	'whitePages.list.table.actions.view': 'Просмотреть',
	'whitePages.list.createItem.title': 'Загрузить лендинг',
	'whitePages.list.createItem.label.name': 'Название лендинга',
	'whitePages.list.createItem.description.name': 'Не обязательно, для вашего удобства',
	'whitePages.list.createItem.label.file': 'Лендинг',
	'whitePages.list.createItem.actions': 'Действия',
	'whitePages.list.description': 'Загружайте лендинги для защиты ваших PWA',
	'whitePages.list.createItem.action': 'Загрузить лендинг',

	'whitePages.view.saveSuccess': 'Лендинг успешно сохранен',
	'whitePages.view.title': 'Лендинг',
	'whitePages.view.landingInfo.title': 'Информация о лендинге',
	'whitePages.view.landingInfo.notFoundIndex': 'index.html не найден',
	'whitePages.view.landingInfo.assetsFolderWarning': 'Не используйте папку assets, она может конфликтовать с конфигурацией PWA',
	'whitePages.view.landingInfo.swWarning': 'Не используйте sw.js, она может конфликтовать с конфигурацией PWA',
	'whitePages.view.landingInfo.stripLevel': 'index.html не находиться в корне архива, рекомендуется перепаковать чтобы файлы сайта не лежали в подпапке',
	'whitePages.view.landingInfo.type': 'Тип',
	'whitePages.view.landingInfo.path': 'Путь',
	'whitePages.view.landingInfo.size': 'Размер',
	'whitePages.view.saveButton': 'Сохранить',
	'whitePages.view.removeButton': 'Удалить',
	'whitePages.view.label.name': 'Название',
	'whitePages.view.landingInfo.site': 'Сайт',

	// /domains page


	"webApps.view.title": "Настройки PWA",
	"webApps.view.saved": "Сохранение успешно",
	"webApps.view.unnamed": "Без названия",
	"webApps.view.header.leads": "Лиды",
	"webApps.view.header.save": "Сохранить",
	"webApps.view.header.delete": "Удалить",

	"webApps.view.tabs.list.base": "Общие настройки",
	"webApps.view.tabs.list.domain": "Домен",
	"webApps.view.tabs.list.tracker": "Трекер",
	"webApps.view.tabs.list.content": "Контент",
	"webApps.view.tabs.list.analytics": "Аналитика",
	"webApps.view.tabs.list.push": "PUSH уведомления",
	"webApps.view.tabs.list.ab": "A/B тестирование",
	"webApps.view.tabs.list.cloaking": "Клоакинг",
	"webApps.view.tabs.list.publish": "Публикация",

	"webApps.view.tabs.list.stats": "Статистика",
	"webApps.view.tabs.list.links": "Ссылки",

	"webApps.view.GeneralTab.tryAddNewUser.title": "Новый пользователь",
	"webApps.view.GeneralTab.tryAddNewUser.button": "Добавить нового пользователя",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.label": "Роль",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.editor": "Редактор",
	"webApps.view.GeneralTab.tryAddNewUser.form.role.viewer": "Просмотр",

	"webApps.view.GeneralTab.FindUserByName.description.user": "Пользователь",

	"webApps.view.GeneralTab.form.name": "Название PWA",
	"webApps.view.GeneralTab.form.sharing.label": "Шаринг PWA",







	"webApps.views.ContentTab.tabsList.languages": "Языки",
	"webApps.views.ContentTab.tabsList.description": "Описание",
	"webApps.views.ContentTab.tabsList.images": "Изображения",
	"webApps.views.ContentTab.tabsList.reviews": "Отзывы и оценки",
	"webApps.views.ContentTab.tabsList.additional": "Дополнительные настройки",
	"webApps.views.ContentTab.LanguagesTab.activeLanguages.title": "Активные языки",
	"webApps.views.ContentTab.LanguagesTab.isDefault.label": "Основной язык?",
	"webApps.views.ContentTab.LanguagesTab.translateEngine.label": "Платформа перевода",
	"webApps.views.ContentTab.LanguagesTab.addLanguage.buttonLabel": "Добавить язык",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.search.label": "Введите название языка",

	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.submit.label": "Добавить язык",
	"webApps.views.ContentTab.LanguagesTab.AddLanguageModal.copyAndTranslate.label": "Скопировать и перевести с {{lang}}",

	"webApps.views.ContentTab.LanguagesTab.DeleteLangButton.cancel": "Отменить",
	"webApps.views.ContentTab.LanguagesTab.DeleteLangButton.delete": "Удалить язык",
	"webApps.views.ContentSettingsTab.security.title": "Параметры обработки персональных данных",
	"webApps.views.ContentSettingsTab.security.noDataCollected": "Приложение не собирает данные",
	"webApps.views.ContentSettingsTab.security.noDataShare": "Приложение не передаёт данные третьим лицам",


	"webApps.views.ContentSettingsTab.installButtonBehavior.title": "Поведение кнопки Установить",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.title": "Ожидать срабатывание события установки",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.description": "После нажатия кнопки установить PWA браузер будет ждать срабатывание события установки.",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.scrollToBottom.label": "После нажатия скролить вниз страницы",
	"webApps.views.ContentSettingsTab.installButtonBehavior.waitInstall.scrollToBottom.description": "Провоцируем пользователя вручную проскролить наверх тем самым спровоцировать событие установки",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.title": "Скролить вниз страницы",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.description": "После нажатия кнопки установить PWA браузер будет скролить вниз страницы.",
	"webApps.views.ContentSettingsTab.behavior.scrollToBottom.clickCount.label": "Сколько раз пользователь должен нажать кнопку чтобы скрол произошёл",
	"webApps.views.ContentSettingsTab.behavior.none.title": "Нет",
	"webApps.views.ContentSettingsTab.behavior.none.description": "Никакие паттерны для провоцирования события установки будут использованы.",
	"webApps.views.ContentSettingsTab.tweaks.title": "Твики PWA",
	"webApps.views.ContentSettingsTab.tweaks.label": "Скрыть заголовок приложения",
	"webApps.views.ContentSettingsTab.tweaks.description": "При установке этого параметра заголовок приложения будет скрыт",
	"webApps.views.ContentSettingsTab.tweaks.reloadPageIfInstallNotAllowed": "Перезагрузить страницу если установка pwa не разрешена",
	"webApps.views.ContentSettingsTab.tweaks.installButtonAnimSpeed": "Скорость анимации заполнения кнопки установки",



	"webApps.view.GenerateReviewsModal.title": "Сгенерировать отзывы",
	"webApps.view.GenerateReviewsModal.prompt.label": "Промпт",
	"webApps.view.GenerateReviewsModal.prompt.description": "Напишите промпт для генерации контента",
	"webApps.view.GenerateReviewsModal.amount.label": "Количество отзывов",
	"webApps.view.GenerateReviewsModal.submitButton": "Генерировать",
	"webApps.view.GenerateReviewsModal.reviews.title": "Отзывы",
	"webApps.view.GenerateReviewsModal.reviews.showTranslated": "Показать перевод",
	"webApps.view.GenerateReviewsModal.reviews.row.name": "Имя",
	"webApps.view.GenerateReviewsModal.reviews.row.rating": "Рейтинг",
	"webApps.view.GenerateReviewsModal.reviews.row.text": "Текст",
	"webApps.view.GenerateReviewsModal.reviews.row.reviewRating": "Рейтинг отзыва",
	"webApps.view.GenerateReviewsModal.reviews.row.commentDate": "Дата комментария",
	"webApps.view.GenerateReviewsModal.reviews.row.addToReviews": "Добавить к отзывам",
	"webApps.view.GenerateReviewsModal.reviews.row.delete": "Удалить",
	"webApps.view.ImagesTab.icon.title": "Иконка приложения",
	"webApps.view.ImagesTab.useIconFromBaseLanguage": "Использовать иконку из базового языка",
	"webApps.view.ImagesTab.useScreensFromBaseLanguage.title": "Экраны приложения",
	"webApps.view.ImagesTab.useScreensFromBaseLanguage.label": "Использовать экраны из базового языка",

	"webApps.view.ReviewForm.avatar": "Аватар",
	"webApps.view.ReviewForm.name": "Имя",
	"webApps.view.ReviewForm.text": "Текст отзыва",
	"webApps.view.ReviewForm.rating": "Рейтинг",
	"webApps.view.ReviewForm.reviewRating": "Сколько людей считают отзыв полезным",
	"webApps.view.ReviewForm.commentDate": "Дата комментария",
	"webApps.view.ReviewsTab.tryAddComment.title": "Добавить отзыв",
	"webApps.view.ReviewsTab.title": "Оценки",
	"webApps.view.ReviewsTab.useRatingFromBaseLanguage": "Использовать рейтинг из базового языка",
	"webApps.view.ReviewsTab.rating.legend": "Общие оценки",
	"webApps.view.ReviewsTab.rating": "Рейтинг приложения",
	"webApps.view.ReviewsTab.ratingsCount": "Количество оценок приложения",
	"webApps.view.ReviewsTab.downloadsCount": "Количество скачиваний приложения",
	"webApps.view.ReviewsTab.ratingValues.legend": "Оценки подробно (используются для отзывов)",
	"webApps.view.ReviewsTab.reviews.title": "Отзывы",
	"webApps.view.ReviewsTab.reviews.button.add": "Добавить комментарий",
	"webApps.view.ReviewsTab.reviews.button.generate": "Сгенерировать комментарии",


	"webApps.view.PushTab.title": "Push уведомления",
	"webApps.view.PushTab.selfPush.enabled": "Включено",
	"webApps.view.PushTab.selfPush.alert": "Для работы push уведомлений необходимо чтобы пользователь подписался на них, пожалуйста выберете один или несколько вариантов как он будет это делать",
	"webApps.view.PushTab.selfPush.promptOnInstall": "Показывать уведомление при установке",
	"webApps.view.PushTab.selfPush.promptOnStart.label": "Показывать уведомление при запуске PWA",
	"webApps.view.PushTab.selfPush.promptOnStart.description": "Рекомендуется",
	"webApps.view.PushTab.selfPush.useDefaultPush.label": "Использовать правила по умолчанию",
	"webApps.view.PushTab.selfPush.useDefaultPush.description": "Для рассылки пушей будут использоваться только правила по умолчанию помеченные меткой DEFAULT",

	"webApps.view.PushTab.oneSignal.title": "Интеграция с oneSignal",
	"webApps.view.PushTab.oneSignal.description1": "Настройте интеграцию с oneSignal для отправки push-уведомлений пользователям вашего сайта.",
	"webApps.view.PushTab.oneSignal.description2": "Включите опцию и укажите идентификатор сайта для активации этой функции.",
	"webApps.view.PushTab.oneSignal.enabled": "Включено",
	"webApps.view.PushTab.oneSignal.appId.label": "Идентификатор сайта в oneSignal",
	"webApps.view.PushTab.oneSignal.alert.t1": "В разделе",
	"webApps.view.PushTab.oneSignal.alert.t2": "включите опцию",
	"webApps.view.PushTab.oneSignal.alert.replace.title": "Заполните поля следующими значениями:",

	"webApps.view.ABTab.title": "A/B тестирование",
	"webApps.view.ABTab.enabled": "Включить A/B тестирование",
	"webApps.view.ABTab.rules.header": "Правило",
	"webApps.view.ABTab.rules.enabled": "Правило активно?",
	"webApps.view.ABTab.rules.comment.label": "Комментарий к правилу",
	"webApps.view.ABTab.rules.comment.description": "Для вашего удобства",
	"webApps.view.ABTab.rules.conditions.title": "Условия срабатывания",
	"webApps.view.ABTab.rules.conditions.header": "Условие",
	"webApps.view.ABTab.rules.conditions.add": "Добавить условие",
	"webApps.view.ABTab.rules.actionType.label": "Действие",
	"webApps.view.ABTab.rules.actionType.redirect": "Перенаправление",
	"webApps.view.ABTab.rules.actionType.blankPage": "Показать пустую страницу",
	"webApps.view.ABTab.rules.actionType.showRawContent": "Показать HTML страницу",
	"webApps.view.ABTab.rules.actionType.rawContent.content.label": "HTML страница",
	"webApps.view.ABTab.rules.actionType.redirect.targetUrl.label": "Ссылка на перенаправление",
	"webApps.view.ABTab.rules.actionType.saveOriginalQueryString": "Сохранять оригинальные query параметры (UTM метки)",
	"webApps.view.ABTab.rules.add": "Добавить новое правило",

	"webApps.view.CloakingTab.title": "Ai Cloaking",
	"webApps.view.CloakingTab.enabled": "ai cloaking включён",
	"webApps.view.CloakingTab.useIpBlacklist.legend": "Проверка ip адреса",
	"webApps.view.CloakingTab.useIpBlacklist.label": "Проверка ip в черном списке",
	"webApps.view.CloakingTab.useIpBlacklist.description": "Проверять ip адрес на наличие его в локальном чёрном списке адресов",

	"webApps.view.CloakingTab.useUaBlacklist.legend": "Проверка юзер агента",
	"webApps.view.CloakingTab.useUaBlacklist.label": "Проверка юзер агента в черном списке",
	"webApps.view.CloakingTab.useUaBlacklist.description": "Проверять user agent на наличие его в локальном чёрном списке адресов",

	"webApps.view.CloakingTab.countryList.legend": "Проверка ГЕО",
	"webApps.view.CloakingTab.countryListMode.label": "Режим проверки",
	"webApps.view.CloakingTab.countryListMode.WHITELIST": "Разрешить указанные страны",
	"webApps.view.CloakingTab.countryListMode.BLACKLIST": "Запретить указанные страны",

	"webApps.view.CloakingTab.useDeviceType.legend": "Проверка типа устройства",
	"webApps.view.CloakingTab.useDeviceType.label": "Проверка включена",
	"webApps.view.CloakingTab.deviceTypeListMode.label": "Режим проверки",
	"webApps.view.CloakingTab.deviceTypeListMode.WHITELIST": "Разрешить указанные типы устройств",
	"webApps.view.CloakingTab.deviceTypeListMode.BLACKLIST": "Запретить указанные типы устройств",

	"webApps.view.CloakingTab.useOSList.legend": "Проверка типа OS",
	"webApps.view.CloakingTab.useOSList.label": "Проверка включена",
	"webApps.view.CloakingTab.osListMode.label": "Режим проверки",
	"webApps.view.CloakingTab.osListMode.WHITELIST": "Разрешить указанные типы OS",
	"webApps.view.CloakingTab.osListMode.BLACKLIST": "Запретить указанные типы OS",

	"webApps.view.CloakingTab.useAdspect.legend": "Дополнительная проверка по базам adspect",
	"webApps.view.CloakingTab.useAdspect.label": "Проверка включена",
	"webApps.view.CloakingTab.adspectStreamId.label": "Ваш stream id",
	"webApps.view.CloakingTab.adspectStreamId.placeholder": "Не обязательно",

	"webApps.view.CloakingTab.rejectAction.legend": "Действия при срабатывании фильтра",
	"webApps.view.CloakingTab.rejectAction.label": "Режим проверки",
	"webApps.view.CloakingTab.rejectAction.SHOW_200": "Показать пустую страницу с 200 кодом",
	"webApps.view.CloakingTab.rejectAction.SHOW_404": "Показать пустую страницу с 404 кодом",
	"webApps.view.CloakingTab.rejectAction.REDIRECT": "Выполнять перенаправление",
	"webApps.view.CloakingTab.rejectAction.WHITE_PAGE": "Отобразить лендинг (white page)",

	"webApps.view.CloakingTab.redirectUrl.label": "URL перенаправления",
	"webApps.view.CloakingTab.whitePageSelect.label": "Выберете лендинг для отображения",

	"webApps.archive.title": "Архив PWA",
	"webApps.archive.goBack": "К активным PWA",
	"webApps.list.archive": "Архив",

	"leads.view.appLaunched": "Приложение запущено",

	"webApps.views.DescriptionTab.content.whatsNew.title": "Раздел \"Что нового\"",
	"webApps.views.DescriptionTab.content.whatsNew.enabled": "Отображать раздел",
	"webApps.views.DescriptionTab.content.whatsNew.content": "Содержимое раздела",

	"webApps.view.ReviewForm.ownerResponse.show": "Есть ответ разработчика?",
	"webApps.view.ReviewForm.ownerResponse.content": "Ответ разработчика",
	"webApps.view.ReviewForm.ownerResponse.date": "Дата ответа разработчика",

	'avatarGallery.select.gender.label': 'Пол',
	'avatarGallery.select.gender.MALE': 'Мужской',
	'avatarGallery.select.gender.FEMALE': 'Женский',

	'avatarGallery.select.age.label': 'Возраст',
	'avatarGallery.select.age.YOUNG_ADULT': 'Молодой (18-30 лет)',
	'avatarGallery.select.age.ADULT': 'Взрослый (31-60 лет)',
	'avatarGallery.select.age.ELDERLY': 'Старший (60 лет и старше)',

	'avatarGallery.select.race.label': 'Раса',
	'avatarGallery.select.race.ASIAN': 'Азиатский',
	'avatarGallery.select.race.EUROPEAN': 'Европейский',
	'avatarGallery.select.race.AFRICAN_AMERICAN': 'Африканский',
	'avatarGallery.select.race.LATINO': 'Латинский',
	'avatarGallery.select.race.ARAB': 'Арабский',
	'avatarGallery.select.race.INDIAN': 'Индийский',

	'whitePagesTemplates.list.table.name': 'Название',
	'whitePagesTemplates.list.table.actions': 'Действия',
	'whitePagesTemplates.list.table.actions.view': 'Просмотреть',
	'whitePagesTemplates.list.createItem.title': 'Загрузить шаблон',
	'whitePagesTemplates.list.createItem.label.name': 'Название шаблона',
	'whitePagesTemplates.list.createItem.description.name': 'Описание шаблона',
	'whitePagesTemplates.list.createItem.label.file': 'Шаблон',
	'whitePagesTemplates.list.createItem': 'Загрузить шаблон',
	'whitePagesTemplates.list.description': 'Загружайте шаблоны для генерации лендингов',

	'whitePagesTemplates.view.landingInfo.title': 'Информация о шаблоне',
	'whitePagesTemplates.view.landingInfo.notFoundIndex': 'index.html не найден',
	'whitePagesTemplates.view.landingInfo.assetsFolderWarning': 'В архиве присутствует папка assets, она может конфликтовать с конфигурацией PWA',
	'whitePagesTemplates.view.landingInfo.swWarning': 'В архиве присутствует sw.js, она может конфликтовать с конфигурацией PWA',
	'whitePagesTemplates.view.landingInfo.stripLevel': 'index.html не находиться в корне архива, рекомендуется перепаковать чтобы файлы сайта не лежали в подпапке',
	'whitePagesTemplates.view.landingInfo.actions.addPrompt.title': 'Добавить промт-модификатор',
	'whitePagesTemplates.view.landingInfo.actions.addPrompt.fields.prompt': 'Промт-модификатор',
	'whitePagesTemplates.view.saveSuccess': 'Шаблон сохранен',
	'whitePagesTemplates.view.title': 'Шаблон лендинга',
	'whitePagesTemplates.view.saveButton': 'Сохранить',
	'whitePagesTemplates.view.removeButton': 'Удалить',
	'whitePagesTemplates.view.label.name': 'Название',
	'whitePagesTemplates.view.label.description': 'Описание',
	'whitePagesTemplates.view.landingInfo.site': 'Шаблон',
	'whitePagesTemplates.view.landingInfo.image': 'Изображение-превью',

	'whitePages.view.generateStatus.wait.title': 'Ожидание генерации',
	'whitePages.view.generateStatus.wait.message': 'Подождите, уже скоро мы начнём создавать ваш вайтпейдж',
	'whitePages.view.generateStatus.inProgress.message': 'Ваш вайтпейдж генерируется',
	'whitePages.view.generateStatus.error.title': 'Ошибка генерации',
	'whitePages.view.generateStatus.error.message': 'При генерации вашего вайтпейджа произошла ошибка',
	'whitePages.view.generateStatus.success.title': 'Генерация завершена',
	'whitePages.view.generateStatus.success.message': 'Ваш вайтпейдж генерирован',

	'webApps.views.ContentTab.LanguagesTab.AddLanguageModal.wait.label': 'Генерируем перевод',
	'webApps.views.ContentTab.LanguagesTab.AddLanguageModal.wait.description': 'Пожалуйста, подождите, это может занять некоторое время',

	'webApps.views.ContentTab.LanguagesTab.noLanguages.title': 'Ни один язык не добавлен',
	'webApps.views.ContentTab.LanguagesTab.noLanguages.label': 'Добавьте язык воспользовавшись кнопкой "Добавить язык"',


}

