import {Route} from "wouter";
import {ListLeads} from "./listLeads.tsx";
import {ViewLeads} from "./viewLeads.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {LeadsLogsRouter} from "./LeadsLogs";
import {IconUsers} from "@tabler/icons-react";

const baseUrl = '/leads';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'Leads',
	name: 'Лиды',
	nameKey: 'menuItems.leads',
	icon: <IconUsers/>,
	requiredRights: ['leads']
})

export function LeadsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<LeadsLogsRouter basePath={'/logs'}/>
			<Route path={'/'} component={ListLeads}/>
			<Route path={'/v/:id'} component={ViewLeads}/>
		</Route>
	);
}
