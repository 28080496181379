import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";
import {RegistrarKeys} from "@shared/lib/RegistrarsApi/registrars.ts";

type ApiResult <T> = ({
	status: true,
	result: T
}) | ({
	status: false,
	message: string
})

@singleton
export class DomainsApiClient extends YoApiClient {
	path = '/api/v1/domains';

	async registerCheckStatus(domain: string) {
		return this.ky.post('register/checkStatus', {json: {domain}}).json<any>();
	}

	async suggestDomains(query: string) {
		return this.ky.get('suggestDomains', { searchParams: { query } }).json<ApiResult<string[]>>();
	}

	async getDomainsPrices(domains: string[]) {
		type Response = ApiResult<{
			[DomainKey: string]: {
				[RegistrarKey: string]: { value: number, formatedString: string }
			}
		}>
		return this.ky.post('getDomainsPrices', { json: { domains }, timeout: false }).json<Response>();
	}

	async getDomainPrice({ domain, registrar }: { domain: string, registrar: string }) {
		type Response = ApiResult<{
			displayPrice: string,
			domain: string
		}>

		return this.ky.post('getDomainPrice', { json: { domain, registrar } }).json<Response>();
	}

	linkDomain(domain: string, p: { allowSplit?: boolean; pwaId?: string }) {
		return this.ky.put('link', {json: {domain, ...p}}).json<{ item: any }>();
	}

	async buyDomain(param: { pwaId?: string, registrar: string, domain: string, allowSplit?: boolean }) {
		return this.ky.put('buy', {json: param}).json<{ item: any }>();
	}

	linkExistingDomain(id: string, params: { pwaId: string }) {
		return this.ky.post(`${id}/link`, {json: {...params}}).json<{ item: any }>();
	}

	checkRegistrarBalance(registrar: RegistrarKeys) {
		return this.ky.get('checkRegistrarBalance', { searchParams: { registrar } }).json<ApiResult<number>>();
	}
}
