import {NumberInput} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'number'>> = (props) => {
    return (
        <NumberInput
            label={props.label}
            value={props.value}
            onChange={v => {
                props.onChange(typeof v === 'number' ? v : undefined)
            }}
        />
    );
}

export default FieldTypeString;