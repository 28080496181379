import {Route} from "wouter";
import {ListWhitePages} from "./listWhitePages.tsx";
import {ViewWhitePages} from "./viewWhitePages.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconHtml} from "@tabler/icons-react";
import {useUser} from "@engine/UserContext.tsx";
import {WhitePagesTemplatesRouter} from "./WhitePagesTemplates";

const baseUrl = '/whitePages';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'WhitePages',
	nameKey: 'menuItems.whitePages',
	icon: <IconHtml/>,
	name: 'ВайтПейджи',
	requiredRights: ['whitepages']
})

export function WhitePagesRouter(props: { basePath?: string }) {
	const {user} = useUser();
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListWhitePages}/>
			{user?.isSuperAdmin && <WhitePagesTemplatesRouter basePath={'/templates'}/>}
			<Route path={'/v/:id'} component={ViewWhitePages}/>
		</Route>
	);
}
