import {MultiSelect, MultiSelectProps} from "@mantine/core";
import {useLocaleContext} from "../locale";
import {osLabels} from "@shared/_data/osMap.ts";

export function OsTypeSelect(props: MultiSelectProps) {
	const { selectedLocale } = useLocaleContext();

	const data = osLabels.map(item => ({...item, label: item.label[selectedLocale] ?? item.label['en']}))

	return <MultiSelect data={data} {...props}/>;
}
