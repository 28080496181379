import {Select, SelectProps} from "@mantine/core";
import {useYoProvider} from "@components/YoContextEditor";

export interface SelectFieldProps extends Omit<SelectProps, 'value' | 'onChange'> {
	field: string;
	defaultValue?: string;
}

export function SelectField({field, defaultValue ,  ...otherProps}: SelectFieldProps) {
	const ctx = useYoProvider<string>(field);
	const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;
	return <Select value={ctx.value ?? defaultValue ?? ''} onChange={e => ctx.onChange(e as string)} {...otherProps} readOnly={readOnly}/>;
}
