import {Dispatch, SetStateAction, useState} from 'react';
import set from 'lodash/set';

interface ISetFieldController<T> {
	item: T;
	setItem: (obj: T) => void;
	trackedChanges: Partial<T>;
	setTrackedChanges: Dispatch<SetStateAction<T>>;
	reset: () => void
}

export function useSetField<T>(initialState: any,trackedChangesInitialState?: Partial<T>): [T, (key: string, value: any) => void , Partial<T>, ISetFieldController<T>] {
	const [trackedChanges, setTrackedChanges] = useState<T>((trackedChangesInitialState || {}) as T);
	const [item, setItem] = useState(initialState);
	function setField (key: string, value: any) {
		setTrackedChanges(set({...trackedChanges as any},key,value) as T)
		return setItem(set({...item},key,value));
	}
	return [item, setField, trackedChanges, {
		item,
		setItem,
		trackedChanges,
		setTrackedChanges,
		reset: () => setTrackedChanges(trackedChangesInitialState as T)
	}];
}

export function useSimpleContextFormsSetField(initialState: any,trackedChangesInitialState = {}) {
	const [,,,ctr] = useSetField(initialState,trackedChangesInitialState);
	return ctr;
}
