import {Route} from "wouter";
import {ListPushJobs} from "./listPushJobs.tsx";
import {ViewPushJobs} from "./viewPushJobs.tsx";
import MenuManager from "@engine/MenuManager.ts";

const baseUrl = '/jobs';

MenuManager.RegisterItem({
	path: `/push${baseUrl}`,
	key: 'pushJobs',
	nameKey: 'menuItems.push.Jobs',
	parent: 'push'
})

export function PushJobsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListPushJobs}/>
			<Route path={'/v/:id'} component={ViewPushJobs}/>
		</Route>
	);
}
