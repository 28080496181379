import {useEffect, useState} from "react";
import {getKyInstance} from "@engine/yoApiClient.tsx";

export function useWaitTask(p?: { onResolved?: (task: any) => void }) {
	const [taskId, setTaskId] = useState<string | null>(null); // null для отсутствия задачи
	const [disabledByTask, setDisabledByTask] = useState(false);


	useEffect(() => {
		if (!taskId) {
			setDisabledByTask(false);
			return;
		}

		setDisabledByTask(true);
		let stopped = false;

		async function waitForTask() {
			while (!stopped) {
				try {
					const res = await getKyInstance()
						.get(`/api/v1/tasks/${taskId}/waitForComplete`, { timeout: 1000 * 60 })
						.json<{ item: any }>();
					setDisabledByTask(false);
					setTaskId(null); // Сбрасываем после завершения
					p?.onResolved?.(res.item);
					break;
				} catch (err) {
					console.error('Error while waiting for task:', err);
					if (stopped) break;
					await new Promise((resolve) => setTimeout(resolve, 1000)); // Ждем перед повтором
				}
			}
		}

		waitForTask();

		return () => {
			stopped = true; // Остановка цикла при размонтировании
		};
	}, [taskId]);

	const setTack = (id: string) => {
		console.log('setTack called with:', id);
		setTaskId(id);
	};

	return {
		setTack,
		locked: disabledByTask,
	};
}
