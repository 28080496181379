import {useYoApi} from "@hooks/useYoApi.tsx";
import {LeadsLogsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useEffect, useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {useSearchParams} from "@hooks/useSearchParams.ts";
import {Button} from "@mantine/core";

const api = new LeadsLogsApiClient()

interface IItem {
	_id: string;
}

export function ListLeadsLogs() {
	const defaults = useDefaultsTableState();
	const [sp] = useSearchParams();
	const [autoUpdate, setAutoUpdate] = useState(false);



	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.LeadsLogs.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: 'lead',
		header: 'Лид',
	},{
		accessorKey: 'clientId',
		header: 'clientId',
	},{
		accessorKey: 'runId',
		header: 'runId',
	},{
		accessorKey: 'message',
		header: 'message',
	},{
		accessorKey: 'createdAt',
		header: 'createdAt',
		Cell: (props) => (new Date(props.cell.getValue<string>())).toLocaleString(),
	}], []);

	const data = useYoApi({ apiClient: api, defaults, forceQuery: {
			clientId: sp.has('clientId') ? {value: sp.get('clientId')} : undefined,
			runId: sp.has('runId') ? {value: sp.get('runId')} : undefined,
			lead: sp.has('lead') ? {value: sp.get('lead')} : undefined
		} });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	useEffect(() => {
		if(!autoUpdate) return;
		if(data.isLoading) return;

		const timer = setTimeout(() => data.fetch(), 2500);
		return () => clearTimeout(timer);
	}, [autoUpdate, data.isLoading]);

	return <>
		<Header
			title={'LeadsLogs'}
			items={(
				<>
					<Button onClick={() => setAutoUpdate(!autoUpdate)}>Auto update: {autoUpdate ? 'ON' : 'OFF'}</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
