
export const teamUserRoleRights = [
    'all',
    'webApp',
    'push',
    'finances',
    'domains',
    'teams',
    'whitepages',
    'googleAds',
    'pixels',
    'leads'
] as const;

export type TeamUserRoleRights = typeof teamUserRoleRights[number];

export const teamUserRoleKeys = [
    'admin',
    'buyer',
    'techSpecialist',
    'financier',
    'pushSpecialist'
] as const;

export type TeamUserRoleKeys = typeof teamUserRoleKeys[number];

export type TeamUserRoles = {
    [Key in TeamUserRoleKeys ]: {
        label: string,
        comment: string,
        rights: TeamUserRoleRights[]
    }
}

export const teamUserRoles: TeamUserRoles = {
    admin: {
        label: 'Admin',
        comment: 'Same as team owner',
        rights: [
            'all'
        ]
    },
    buyer: {
        label: 'Buyer',
        comment: '',
        rights: [
            'domains',
            'webApp',
            'push',
            'googleAds',
            'pixels',
            'whitepages',
            'leads'
        ]
    },
    techSpecialist: {
        label: 'Tech Specialist',
        comment: '',
        rights: [
            'domains',
            'webApp',
            'push',
            'googleAds',
            'pixels',
            'whitepages',
            'leads'
        ]
    },
    financier: {
        label: 'Financier',
        comment: '',
        rights: [
           'finances'
        ]
    },
    pushSpecialist: {
        label: 'Push Specialist',
        comment: '',
        rights: [
            'push'
        ]
    }
};