import {Alert, Button, Center, Grid, Image, Loader, Modal, Stack} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {WhitePagesApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {FileUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {Translation, useLocale} from "../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../utils/viewOwnerColl.tsx";
import useTeams from "../Teams/Context/useTeams.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {VisibleOnlyAdmin} from "../../utils/visibleOnlyAdmin.tsx";
import {getFileLink} from "../../utils/getFileLink.tsx";
import {LanguageSelect} from "@components/Utils/Languages/LanguageSelect.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

const api = new WhitePagesApiClient()

interface IItem {
	_id: string;
}

function WhitePageGallery() {
	const navigate = useNavigate();
	const [disabled, setDisabled] = useState(false);
	const { activeTeam } = useTeams();
	const promise = usePromise(() => {
		return api.fetchGallery();
	}, []);
	if(promise.isLoading) return <Center h={'50%'}><Loader/></Center>
	if(promise.isError) return displayTSMatchGeneralError(promise);

	async function selectTemplate(tmp: any) {

		const params = await yoFormModal({
			title: 'Generate whitepage',
			defaultValue: {
				name: tmp.name,
			},
			body: (
				<>
					<h4>General settings</h4>
					<InputField field={'name'} label={'Name'}/>
					<h4>
						Generate settings
					</h4>
					<RenderFuncField<string> field={'generateProps.language'}>
						{ctx => (
							<LanguageSelect searchable platform={'openai'} value={ctx.value} onChange={ctx.onChange} label={'Target language'}/>
						)}
					</RenderFuncField>
				</>
			)
		});
		setDisabled(true);
		return api.createItemFromTemplate(tmp._id, {...params,team: activeTeam})
			.then(res => navigate(`/v/${res.item._id}`))
			.catch(displayErrorNotification)
			.finally(() => setDisabled(false));
	}

	return (
		<Stack>
			{promise.data!.items.map(tempate => {
				return (
					<Alert title={tempate.name} variant="light" color="gray">
						<Grid>
							<Grid.Col span={8}>
								{tempate.description}
							</Grid.Col>
							<Grid.Col span={4}>
								<Stack>
									<Image
										src={getFileLink(tempate.image)}
										fallbackSrc="https://placehold.co/600x400?text=Image not found"
									/>
									<Button disabled={disabled} onClick={() => selectTemplate(tempate)}>
										Use this template
									</Button>
								</Stack>
							</Grid.Col>
						</Grid>
					</Alert>
				)
			})}
		</Stack>
	)
}

export function ListWhitePages() {
	const [galleryIsOpen, setGalleryIsOpen] = useState(false);
	const t = useLocale();
	const navigate = useNavigate();
	const {user} = useUser();
	const { activeTeam } = useTeams();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.WhitePages.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		const cols =  [{
			accessorKey: 'name',
			header: t('whitePages.list.table.name'),
		}, {
			accessorKey: 'actions',
			header: t('whitePages.list.table.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<>
					<Link href={`/v/${props.row.original._id}`}>
						<a>
							<Button size={'compact-sm'}>
								<Translation id={'whitePages.list.table.actions.view'}/>
							</Button>
						</a>

					</Link>
				</>
			)
		}] as MRT_ColumnDef<IItem>[];
		if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);
		return cols;
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const form = await yoFormModal({
			title: t('whitePages.list.createItem.title'),
			required: ['file'],
			body: (
				<>
					<InputField field={'name'} label={t('whitePages.list.createItem.label.name')} description={t('whitePages.list.createItem.description.name')}/>
					<h4>
						<Translation id={'whitePages.list.createItem.label.file'}/>
					</h4>
					<FileUploaderField field={'file'} accept={['application/zip']}/>
				</>
			)
		})
		return api.createItem({...form, team: activeTeam}).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={'WhitePages'}
			items={(
				<>
					<VisibleOnlyAdmin>
						<Button size={'compact-sm'} component={Link} href={'/templates'}>
							Templates
						</Button>
					</VisibleOnlyAdmin>
					<Button size={'compact-sm'} onClick={() => setGalleryIsOpen(true)}>
						Create from gallery
					</Button>
					<Button size={'compact-sm'} onClick={tryCreate}>
						<Translation id={'whitePages.list.createItem.action'}/>
					</Button>
				</>
			)}
		/>
		<p>
			<Translation id={'whitePages.list.description'}/>
		</p>
		<MantineReactTable table={table} />
		<Modal keepMounted={false} opened={galleryIsOpen} onClose={() => setGalleryIsOpen(false)} title={'Whitepages gallery'} size={'70%'}>
			<WhitePageGallery/>
		</Modal>
	</>;
}
