import {
	Alert,
	Box,
	Button,
	Card,
	Center,
	Container,
	Divider,
	Modal,
	PasswordInput,
	Select,
	Text,
	Title
} from "@mantine/core";
import {useState} from "react";
import {ImageUploader} from "@YoUi/imageUploader.tsx";
import {useUser} from "@engine/UserContext.tsx";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {notifications} from "@mantine/notifications";
import {useLocaleContext, useScopedLocale} from "../../locale";

function updateUserData(param: any) {
	return getKyInstance().post('/api/v1/user', {
		json: param
	}).json().catch(displayErrorNotification);
}

function ChangePasswordModal(props: { isOpen: boolean, onClose: () => void }) {
	const t = useScopedLocale('pages.profile.changePassword.')
	const [disabled, setDisabled] = useState(false);
	const [password, setPassword] = useState('');
	const [oldPassword, setOldPassword] = useState('');
	const [errCode, setErrCode] = useState('');

	async function handlePasswordChange() {
		setDisabled(true);
		setErrCode('');
		try {
			const result = await updateUserData({password, oldPassword}) as { status: boolean, code: string };
			if (result.status) {
				notifications.show({message: t('success')});
				props.onClose();
			} else {
				setErrCode(result.code);
			}
		} catch (err) {
			await displayErrorNotification(err);
		} finally {
			setDisabled(false);
		}
	}

	return (
		<Modal
			opened={props.isOpen}
			onClose={disabled ? () => {
			} : () => props.onClose()}
			title={t('title')}
		>
			{errCode ? (
				<Alert color={'red'} title={t('error.title')}>
					{errCode === 'INVALID_PASSWORD' ? t('error.INVALID_PASSWORD') : errCode}
				</Alert>
			) : null}
			<PasswordInput
				value={oldPassword}
				onChange={e => setOldPassword(e.target.value)}
				label={t('oldPassword')}
				placeholder={t('oldPassword.placeholder')}
				mb="sm"
				required
				disabled={disabled}
			/>
			<PasswordInput
				value={password}
				onChange={e => setPassword(e.target.value)}
				label={t('newPassword')}
				placeholder={t('newPassword.placeholder')}
				mb="sm"
				required
				disabled={disabled}
			/>
			<Button
				disabled={disabled}
				fullWidth
				onClick={handlePasswordChange}
			>
				{t('change')}
			</Button>
		</Modal>
	)
}

export function UserProfile() {
	const userCtx = useUser();
	const t = useScopedLocale('pages.profile.')
	const {selectedLocale, changeLocale} = useLocaleContext();
	const [passwordModalOpen, setPasswordModalOpen] = useState(false);

	function handleTelegramConnect() {
		window.open(`https://t.me/${userCtx.settings.tgBotName}?start=c-u-${userCtx.user._id}`, '_blank');
		window.addEventListener('focus', () => window.location.reload());
	}

	function handleTelegramDisconnect() {
		updateUserData({tgId: null}).then(() => {
			userCtx.updateUser({tgId: null});
		});
	}

	return (
		<Container size="sm" py="xl">
			<Text size="xl" w={700} >
				{t('title')}
			</Text>
			<Card withBorder>
				<Center>
					<div>
						<Title order={4}>
							{t('avatar')}
						</Title>
						<ImageUploader
							hideDescriptionWithImage
							imageId={userCtx.user.avatar}
							onDrop={file => {
								updateUserData({avatar: file._id}).then(() => {
									userCtx.updateUser({avatar: file._id});
								});
							}}
						/>
					</div>
				</Center>

				<Divider my="sm"/>

				<Button fullWidth onClick={() => setPasswordModalOpen(true)} mb="lg">
					{t('changePassword')}
				</Button>

				<ChangePasswordModal isOpen={passwordModalOpen} onClose={() => setPasswordModalOpen(false)}/>

				<Divider my="sm"/>

				<Box mb="lg">
					{userCtx.user.tgId ? (
						<>
							<Text color="green">
								{t('tgConnect.success').replace('{{tgId}}', userCtx.user.tgId)}
							</Text>
							<br/>
							<Button variant="outline" onClick={handleTelegramDisconnect}>
								{t('tgConnect.button.disconnect')}
							</Button>
						</>
					) : (
						<>
							<Text size="sm" mb="sm">
								{t('tgConnect.description')}
							</Text>
							<Center>
								<Button variant="outline" onClick={handleTelegramConnect}>
									{t('tgConnect.button')}
								</Button>
							</Center>
						</>
					)}
				</Box>
				<Divider my="sm"/>

				<Box mb="lg">
					<Select
						label={'UI Language / Язык интерфейса / Мова інтерфейсу'}
						value={selectedLocale}
						data={[
							{value: 'uk', label: '🇺🇦 Українська'},
							{value: 'en', label: '🇬🇧 English'},
							{value: 'ru', label: 'русский'},
						]}
						onChange={(value) => changeLocale(value as any)}

					/>
				</Box>
			</Card>
		</Container>
	)
}
