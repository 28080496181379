import {Textarea, TextareaProps} from "@mantine/core";
import {useYoProvider} from "@components/YoContextEditor";
import {useEffect, useState} from "react";

export function TextareaField({field, ...otherProps}: { field: string } & Omit<TextareaProps, 'value' | 'onChange'>) {
	const ctx = useYoProvider<string>(field);
	const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;
	const [value, setValue] = useState(ctx.value);

	function onChange(e: any) {
		setValue(e.target.value);
	}

	useEffect(() => {
		setValue(ctx.value);
	}, [ctx.value]);
	return <Textarea
		value={value}
		onChange={onChange}
		onBlur={e => ctx.onChange(e.target.value)}
		{...otherProps}
		readOnly={readOnly}
	/>;
}
