import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

export function SettingsExamplePage() {
	const controller = useSiteSettings('engine.');

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: 'Настройки успешно сохранены'}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={'Тестовые настройки'} items={(
				<>
					<Button onClick={saveSettings}>Сохранить настройки</Button>
				</>
			)}/>
			<InputField field={'test:testFieldWithDefault'} label={'test'}/>
			<InputField field={'newField'} label={'newField'}/>
		</YoContextEditor>
	);
}
