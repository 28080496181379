import {Button, Card, Group, Modal, Stack, Table, Text} from "@mantine/core";
import {IconDownload, IconEdit, IconInfoCircle, IconUpload} from "@tabler/icons-react";
import {useMemo, useState} from "react";
import {Header} from "@components/Header";
import {FbPixelsSelect} from "../FBPixelsSelect.tsx";
import {useLocale} from "../../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {RawDocumentTypeFbPixelsImportEventsJob} from "@shared/models";
import {Link} from "wouter";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {viewOwnerColl} from "../../../utils/viewOwnerColl.tsx";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {FbPixelsImportEventsJobApiClient} from "./api.ts";
import {FileUploader} from "@YoUi/FileUploader.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";

const csvFieldsData = [
    {
        key: 'event',
        type: 'String'
    },
    {
        key: 'city',
        type: 'String'
    },
    {
        key: 'region',
        type: 'String'
    },
    {
        key: 'country',
        type: 'String'
    },
    {
        key: 'client_ip_address',
        type: 'String'
    },
    {
        key: 'client_user_agent',
        type: 'String'
    },
    {
        key: 'event_source_url',
        type: 'String'
    },
    {
        key: 'fbc',
        type: 'String'
    },
    {
        key: 'fbp',
        type: 'String'
    },
    {
        key: 'external_id',
        type: 'String'
    },
    {
        key: 'revenue',
        type: 'String'
    }
]

const api = new FbPixelsImportEventsJobApiClient();

function DisplayCSVFormat () {
    const [opened, setOpened] = useState(false);

    const downloadExample = async () => {

    }

    return (
        <>
            <Button
                variant="light"
                leftSection={<IconInfoCircle />}
                onClick={() => setOpened(true)}
            >
                About csv format
            </Button>
            <Modal title="CSV format" onClose={() => setOpened(false)} opened={opened}>
                <Button
                    onClick={() => downloadExample()}
                    leftSection={<IconDownload />}
                >
                    Download example
                </Button>
                <Text>
                    Columns order
                </Text>
                <Card>
                    <Table>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>
                                    key
                                </Table.Th>
                                <Table.Th>
                                    type
                                </Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {
                                csvFieldsData.map(e => (
                                    <Table.Tr>
                                        <Table.Td>
                                            {e.key}
                                        </Table.Td>
                                        <Table.Td>
                                            {e.type}
                                        </Table.Td>
                                    </Table.Tr>
                                ))
                            }
                        </Table.Tbody>
                    </Table>
                </Card>
            </Modal>
        </>
    )
}

function CreateForm () {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);
    const [pixel, setPixel] = useState<string | null>(null);
    const [file, setFile] = useState<string | null>(null);

    const tryCreate = async () => {
        api.createItem({
            pixel,
            sourceFile: file
        }).then((data) => {
            if (data.status) {
                navigate(`/v/${data.item._id}`)
            }
        })
    }

    const onDrop = async (file: { _id: string }) => {
        setFile(file._id);
    }

    return (
        <>
            <Modal title="Import events" opened={opened} onClose={() => setOpened(false)}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    void tryCreate();
                }}>
                    <Stack>
                        <FbPixelsSelect
                            label="Target pixel"
                            onChange={v => setPixel(v)}
                            value={pixel}
                        />
                        <Card variant="section">
                            <DisplayCSVFormat />
                            <FileUploader
                                onDrop={onDrop}
                                fileId={file || undefined}
                                accept={['text/csv']}
                            />
                        </Card>
                        <Button type="submit">
                            Confirm
                        </Button>
                    </Stack>
                </form>
            </Modal>
            <Button
                onClick={() => setOpened(true)}
                leftSection={<IconUpload />}
            >
                Upload
            </Button>
        </>
    )
}

type IItem = RawDocumentTypeFbPixelsImportEventsJob;

function ListFbPixelsImportEventsJob() {
    const t = useLocale();
    const {user} = useUser();
    const defaults = useDefaultsTableState();

    const createTableState = useMRTState<IItem>({
        visibilityLocalStorageKey: 'ui.FbPixels.columnVisibility',
        defaults
    });

    const columns = useMemo(() => {
        const cols =  [{
            accessorKey: 'status',
            header: 'status',
        }, {
            accessorKey: 'pixel',
            header: 'pixel',
        }, {
            accessorKey: 'actions',
            header: t('fbPixels.list.tableHeader.actions'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: (props) => (
                <Group>
                    <Button<any> title="Edit" component={Link} href={`/v/${props.row.original._id}`} variant="primary" size={'compact-sm'}>
                        <IconEdit/>
                    </Button>
                </Group>
            )
        }] as MRT_ColumnDef<IItem>[];

        if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);

        return cols;
    }, []);

    const data = useYoApi({ apiClient: api, defaults });
    const tableState = createTableState({
        data,
        columns
    });
    useMRTYoApiDataAdapter({
        apiClient: data,
        tableParams: tableState.adapterProps,
        defaults,
    });
    const table = useMantineReactTable<IItem>(tableState);

    return (
        <div>
            <Header
                backButtonUrl="~/fbPixels"
                title=""
                items={(
                    <>
                        <CreateForm />
                    </>
                )}
            />
            <MantineReactTable table={table} />
        </div>
    );
}

export default ListFbPixelsImportEventsJob;