import {WebAppsApiClient} from "../api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {Badge, Loader} from "@mantine/core";
import {useLocale} from "../../../locale";
import PQueue from "p-queue";

const api = new WebAppsApiClient();

const queue = new PQueue({concurrency: 1});

const cache = new Map<string, any>();

export function RenderPwaTableStat(props: { period: string, id: string }) {
	const t = useLocale();
	const promise = usePromise(() => {
		return queue.add(async () => {
			if(cache.has(props.id)) return cache.get(props.id);
			const result = await api.fetchStatsByPeriod(props.id, props.period);
			cache.set(props.id, result);
			return result;
		});
	}, [props.id, props.period]);

	if(promise.isLoading) return <Loader size="1rem" />;
	if(promise.isError) return <>:(</>;

	const {uniqVisits, visits, installs} = promise.data;
	return <Badge color={'blue'}>{visits} ({uniqVisits} {t('webApps.list.table.statistics.uniq')}, {installs} {t('webApps.list.table.statistics.installs')})</Badge>;
}
