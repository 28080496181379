
export function convertToSeconds(timeStr: string): number {
	const match = timeStr.match(/^(\d+)([smhwdM])$/);

	if (!match || !match[1]) {
		throw new Error("Invalid time format. Format should be, for example, '15m', '30s'.");
	}

	const value = parseInt(match[1], 10);
	const unit = match[2];

	switch (unit) {
		case 's': // секунды
			return value;
		case 'm': // минуты
			return value * 60;
		case 'h': // часы
			return value * 3600;
		case 'd': // дни
			return value * 86400;
		case 'w': // недели
			return value * 604800;
		case 'M': // месяцы (предполагаем, что месяц состоит из 30 дней)
			return value * 2592000;
		default:
			throw new Error("Only 's', 'm', 'h', 'd', 'w' , 'M' units are supported.");
	}
}
