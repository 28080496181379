import {PasswordInput, PasswordInputProps} from "@mantine/core";
import {useYoProvider} from "../index.tsx";

export function PasswordField({field, ...otherProps}: {
	field: string
} & Omit<PasswordInputProps, 'value' | 'onChange'>) {
	const ctx = useYoProvider<string>(field);
	const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;
	return <PasswordInput value={ctx.value} onChange={e => ctx.onChange(e.target.value)} {...otherProps} readOnly={readOnly}/>;
}
