import addFormats from 'ajv-formats'
import Ajv from 'ajv';

export function createAjv() {
	return addFormats(new Ajv({allErrors: true }), [
		'date-time',
		'time',
		'date',
		'email',
		'hostname',
		'ipv4',
		'ipv6',
		'uri',
		'uri-reference',
		'uuid',
		'uri-template',
		'json-pointer',
		'relative-json-pointer',
		'regex'
	])
}
