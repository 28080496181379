import {Route} from "wouter";
import {ListJobs} from "./listJobs.tsx";
import {ViewJobs} from "./viewJobs.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconWorldWww} from "@tabler/icons-react";

const baseUrl = '/jobs';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'Jobs',
	name: 'Задачи',
	nameKey: 'menuItems.system.jobs',
	parent: 'system',
	icon: <IconWorldWww/>
})

export function JobsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListJobs}/>
			<Route path={'/v/:id'} component={ViewJobs}/>
		</Route>
	);
}
