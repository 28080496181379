import {useEffect, useState} from 'react';
import {Button, Group, Loader, Modal, NumberInput, Space, Stack} from '@mantine/core';
import {openConfirmModal} from '@mantine/modals';
import {PaymentData, PaymentsApiClient} from "./api.ts";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import useTeams from "../../Teams/Context/useTeams.ts";
import {availableCrypto} from "./availableCrypto.ts";
import type {RawDocumentTypePayments} from "@shared/models";
import AwaitingPayment from "./Components/AwaitingPayment.tsx";

const api = new PaymentsApiClient();

function DepositModal({ opened, setOpened, loadPayments }: { opened: boolean, setOpened: (open: boolean) => void, loadPayments: () => Promise<void> }) {
	const [step, setStep] = useState(1);
	const { activeTeam } = useTeams();

	const [price, setPrice] = useState(50);
	const [minimumPaymentAmount, setMinimumPaymentAmount] = useState<number | null>(null);
	const [cryptoName, setCryptoName] = useState<string>('USDT');

	const [processing, setProcessing] = useState(false);
	const [payment, setPayment] = useState<{ payData: PaymentData, payment: RawDocumentTypePayments } | null>(null);

	const createInvoice = async () => {
		try {
			if (!price || price < (minimumPaymentAmount || 0)) {
				throw new Error(`Expected amount to be greater than ${minimumPaymentAmount}`);
			}
			if (!cryptoName) return null;
			setProcessing(true);
			const data = await api.createPayment({
				amount: price,
				cryptoName: cryptoName,
				teamId: activeTeam!
			});
			void loadPayments();
			if (!data.status) {
				void displayErrorNotification(data.message);
				setProcessing(false);
			} else {
				setStep(2);
				setPayment(data.result);
			}
		} catch (error: any) {
			void displayErrorNotification(error);
		}
	};

	useEffect(() => {
		api.getPaymentConfig().then(data => {
			if (data.status) {
				setMinimumPaymentAmount(data.result.minimumPaymentAmount);
			}
		})
	}, [])

	const clear = () => {
		setPrice(50);
		setCryptoName('USDT')
		setProcessing(false);
		setPayment(null);
	}

	return (
		<Modal
			size="xl"
			title="Make deposit"
			opened={opened}
			onClose={() => {
				if (step === 1) {
					setOpened(false);
					clear()
				} else {
					openConfirmModal({
						title: 'Are you sure you want cancel payment?',
						labels: { confirm: 'Cancel payment', cancel: 'Continue' },
						onConfirm: async () => {
							setOpened(false);
							clear();
						},
					});
				}
			}}
		>
			{
				step === 1 ? (
					<Stack>
						<Group>
							{
								availableCrypto.map(e => {
									const active = e.name === cryptoName;
									return (
										<Button
											radius="lg"
											variant="default"
											onClick={() => {
												setCryptoName(e.name)
											}}
											style={{
												borderColor: active ? "#1DD6FF" : undefined
											}}
											leftSection={(
												<img
													src={e.icon}
													alt={e.name}
													style={{
														width: 24,
														height: 24
													}}
												/>
											)}
										>
											{e.displayName}
										</Button>
									)
								})
							}
						</Group>
						{/*<Select
							searchable
							label="Crypto currency"
							value={cryptoName}
							onChange={v => v && setCryptoName(v)}
							data={availableCrypto.map(e => ({ label: e.displayName, value: e.name }))}
						/>*/}
						<NumberInput
							value={price}
							onChange={v => {
								setPrice(typeof v === 'string' ? 0 : v)
							}}
							label="Amount"
							rightSection="$"
							description={`minimum - ${minimumPaymentAmount}`}
						/>
						<Space h="md" />
						<div>
							<Button
								disabled={processing}
								onClick={createInvoice}
							>
								Deposit
							</Button>
						</div>
					</Stack>
				) : (
					payment ? (
						<AwaitingPayment
							id={payment.payment._id}
							paymentData={payment.payData}
							onFailure={(message) => {
								void displayErrorNotification(message);
								setOpened(false);
								void loadPayments();
								clear();
							}}
							onSuccess={() => {
								clear();
								void loadPayments();
								setOpened(false);
							}}
						/>
					) : (
						<Loader />
					)
				)
			}
		</Modal>
	);
}

export default DepositModal;
