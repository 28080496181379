import {useUser} from "@engine/UserContext.tsx";
import {SystemRouter} from "./Pages/System";
import {JobsRouter} from "./Pages/Jobs";
import {CfAccountsRouter} from "./Pages/CfAccounts";

export function AdminApp() {
	const {user} = useUser();
	if (!user.isSuperAdmin) return null;
	return (
		<>
			<SystemRouter/>
			<CfAccountsRouter/>
			<JobsRouter/>
		</>
	)
}
