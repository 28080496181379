import {Card, Checkbox, Collapse, Menu, Textarea, Title} from "@mantine/core";
import {ComponentType} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";
import {IconBubble} from "@tabler/icons-react";
import {TranslateFieldMenuItem} from "@components/SmartMenuItems/translateFieldMenuItem.tsx";
import {MoveFromBaseMenuItem} from "@components/SmartMenuItems/moveFromBase.tsx";
import {useScopedLocale} from "../../../../../../locale";

const FieldTypeString: ComponentType<FieldTypeRendererProps<'whatsNew'>> = ({ value, onChange }) => {
    const tt = useScopedLocale('webApps.views.DescriptionTab.');

    return (
        <div>
            <Title order={5} mb="xs">
                {tt('content.whatsNew.title')}
            </Title>
            <Card variant="section">
                <Checkbox
                    checked={value?.enabled}
                    onChange={e => {
                        onChange({
                            enabled: e.target.checked,
                            content: value?.content || ''
                        })
                    }}
                    label={tt('content.whatsNew.enabled')}
                />
                <Collapse in={value?.enabled || false}>
                    <Textarea
                        mt="xs"
                        autosize
                        minRows={3}
                        value={value?.content}
                        onChange={e => {
                            if (value) {
                                onChange({
                                    ...value,
                                    content: e.target.value
                                })
                            }
                        }}
                        label={tt('content.whatsNew.content')}
                        rightSection={(
                            <Menu shadow="md" width={250}>
                                <Menu.Target>
                                    <IconBubble/>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <TranslateFieldMenuItem field={'content.whatsNew.content'}/>
                                    <MoveFromBaseMenuItem field={'content.whatsNew.content'}/>
                                </Menu.Dropdown>
                            </Menu>
                        )}
                    />
                </Collapse>
            </Card>
        </div>
    );
}

export default FieldTypeString;