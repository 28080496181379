import {Route} from "wouter";
import {ListLeadsLogs} from "./listLeadsLogs.tsx";
import {ViewLeadsLogs} from "./viewLeadsLogs.tsx";

const baseUrl = '/leadsLogs';


export function LeadsLogsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListLeadsLogs}/>
			<Route path={'/v/:id'} component={ViewLeadsLogs}/>
		</Route>
	);
}
