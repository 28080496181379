import {iStateProps} from "../IStateProps.tsx";
import {usePromiseWithTeamDependency} from "@hooks/usePromise.ts";
import {getKyInstance} from "@engine/yoApiClient.tsx";
import {Box, LoadingOverlay} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {AreaChart} from "@mantine/charts";
import {addStatsJob} from "../utils/statsQueue.ts";

export function Chart(props: iStateProps & { type: string }) {
	const promise = usePromiseWithTeamDependency(() => {
		return addStatsJob(() => getKyInstance().post('/api/v1/stats/chart', {
			json: {
				type: props.type,
				minDate: props.minDate,
				maxDate: props.maxDate,
				selectedApps: props.selectedApps,
				selectedUsers: props.selectedUsers
			},
			timeout: 1000 * 30
		}).json<any>());
	}, [JSON.stringify([props.type, props.minDate, props.maxDate, props.selectedApps, props.selectedUsers])]);
	if (promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={promise.isLoading}/>
			<AreaChart
				h={300}
				data={promise.data?.chart ?? []}
				dataKey="l"
				series={promise.data?.series ?? []}
				curveType="linear"
			/>
		</Box>
	);
}
