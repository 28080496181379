import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class WhitePagesTemplatesApiClient extends YoApiClient {
	path = '/api/v1/whitePages/templates';

	fetchLandingAnalysis(fileId: string) {
		return this.ky.get(`analyze`, {
			searchParams: {
				fileId
			}
		}).json();
	}
}
