import {randomId} from "@mantine/hooks";
import {createElement} from "react";
import {modals} from "@mantine/modals";
import {Avatar, Button, Loader, Table} from "@mantine/core";

import {AvatarGalleryApiClient} from "../../../AvatarGallery/api";
import {usePromise} from "@hooks/usePromise.ts";
import {getFileLink} from "../../../../utils/getFileLink.tsx";

const api = new AvatarGalleryApiClient();

function pickAvatar(param: { age: string; gender: string; race: string }) {
	return new Promise<string>((done, reject) => {
		const modalId = randomId();

		const component = createElement(() => {

			const promise = usePromise<any[]>(() => api.fetchList({
				limit: 30,
				query: {
					age: param.age ? {type: 'equals', value: param.age} : undefined,
					gender: param.gender ? {type: 'equals', value: param.gender} : undefined,
					race: param.race ? {type: 'equals', value: param.race} : undefined
				}
			}).then(res => res.items));

			function onSelect(id: string) {
				modals.close(modalId);
				return done(id);
			}

			return (
				<>
					{promise.isLoading && <Loader/>}
					{promise.isError && 'oops'}
					{!promise.isLoading && !promise.isError && (
						<>
							<Table>
								<Table.Thead>
									<Table.Tr>
										<Table.Th>
											Avatar
										</Table.Th>
										<Table.Th>
											Description
										</Table.Th>
										<Table.Th>
											Select
										</Table.Th>
									</Table.Tr>
								</Table.Thead>
								<Table.Tbody>{promise.data!.map(el => (
									<Table.Tr key={el._id}>
										<Table.Td>
											<Avatar src={getFileLink(el.file)} alt="Common mammoth"/>
										</Table.Td>
										<Table.Td>
											{el.gender} {el.race} {el.age}
										</Table.Td>
										<Table.Td>
											<Button onClick={() => onSelect(el.file)}>
												Select
											</Button>
										</Table.Td>
									</Table.Tr>
								))}</Table.Tbody>
							</Table>
						</>
					)}
				</>
			)
		}, {});

		modals.open({
			title: 'Pick avatar',
			modalId,
			children: component,
			onClose: () => reject('MODAL_CLOSED'),
			size: 'xl'
		})
	});
}


export async function showAvatarPicker(param: { age: string; gender: string; race: string }) {
	return pickAvatar(param);
}

export async function pickRandomAvatar(param: { age: string; gender: string; race: string }) {
	const result = await api.fetchRandomItem(param);
	if(!result.status || !result.item) throw new Error('Failed to fetch random avatar');
	return result.item.file;
}
