import {useYoProvider} from "@components/YoContextEditor";
import {useMemo} from "react";
import {allLanguageList} from "@shared/_data/allLanguageList.ts";
import {TagsInputField} from "@components/YoContextEditor/components/tagsInputField.tsx";

export function RuleTypeValueField() {
	const typeCtx = useYoProvider('ruleType');
	const options = useMemo(() => {
		if (!typeCtx.value) return [];
		if (typeCtx.value === 'language') return allLanguageList.map(el => el.code);
		if (typeCtx.value === 'browserEquals') return ['Chrome', 'Firefox', 'Opera', 'Safari'];
		if (typeCtx.value === 'osEquals') return ['iOS', 'Android', 'windows', 'macOS', 'linux', 'ChromeOS'];
		return [];
	}, [typeCtx.value])
	return (
		<TagsInputField
			field={'values'}
			label={'Значение'}
			data={options}
		/>
	);
}
