export const osGroupMapping: Record<string, string> = {
	'android': 'android',
	'android-x86': 'android',
	'ios': 'ios',
	'watchos': 'ios',
	'blackberry': 'otherMobile',
	'rim tablet os': 'otherMobile',
	'kaios': 'otherMobile',
	'maemo': 'otherMobile',
	'meego': 'otherMobile',
	'bada': 'otherMobile',
	'symbian': 'otherMobile',
	'webos': 'otherMobile',
	'windows phone': 'otherMobile',
	'windows mobile': 'otherMobile',
	'nintendo': 'gaming',
	'playstation': 'gaming',
	'xbox': 'gaming',
	'bsd': 'linux',
	'aix': 'linux',
	'amiga os': 'linux',
	'arch': 'linux',
	'beos': 'linux',
	'centos': 'linux',
	'chrome os': 'linux',
	'chromecast': 'linux',
	'contiki': 'linux',
	'debian': 'linux',
	'deepin': 'linux',
	'dragonfly': 'linux',
	'elementary os': 'linux',
	'fedora': 'linux',
	'firefox os': 'linux',
	'freebsd': 'linux',
	'gentoo': 'linux',
	'ghostbsd': 'linux',
	'gnu': 'linux',
	'haiku': 'linux',
	'hurd': 'linux',
	'linux': 'linux',
	'mageia': 'linux',
	'mandriva': 'linux',
	'manjaro': 'linux',
	'minix': 'linux',
	'netbsd': 'linux',
	'openbsd': 'linux',
	'pc-bsd': 'linux',
	'pclinuxos': 'linux',
	'plan9': 'linux',
	'qnx': 'linux',
	'raspbian': 'linux',
	'redhat': 'linux',
	'risc os': 'linux',
	'sabayon': 'linux',
	'slackware': 'linux',
	'solaris': 'linux',
	'suse': 'linux',
	'ubuntu': 'linux',
	'unix': 'linux',
	'vectorlinux': 'linux',
	'zenwalk': 'linux',
	'windows': 'windows',
	'os/2': 'others',
	'nettv': 'others',
	'openvs': 'others',
	'pico': 'others',
	'netrange': 'others',
	'serenityos': 'others',
	'morph os': 'others',
};

export const osLabels = [
	{
		value: 'android',
		label: {
			ru: 'Андроид',
			uk: 'Андроїд',
			en: 'Android',
		}
	},
	{
		value: 'ios',
		label: {
			ru: 'iOS',
			uk: 'iOS',
			en: 'iOS',
		}
	},
	{
		value: 'otherMobile',
		label: {
			ru: 'Другие мобильные',
			uk: 'Інші мобільні',
			en: 'Other Mobile',
		}
	},
	{
		value: 'gaming',
		label: {
			ru: 'Игровые',
			uk: 'Ігрові',
			en: 'Gaming',
		}
	},
	{
		value: 'linux',
		label: {
			ru: 'Линукс',
			uk: 'Лінукс',
			en: 'Linux',
		}
	},
	{
		value: 'windows',
		label: {
			ru: 'Виндоус',
			uk: 'Віндоус',
			en: 'Windows',
		}
	},
	{
		value: 'others',
		label: {
			ru: 'Другие',
			uk: 'Інші',
			en: 'Others',
		}
	}
];
