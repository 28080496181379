import {useMemo} from "react";
import {metaPixelEvents} from "@shared/types/fbInstallEventNames.ts";
import {useLocaleContext} from "../../locale";
import {SelectField, SelectFieldProps} from "@components/YoContextEditor/components/selectField.tsx";


export function FbEventField(props: SelectFieldProps) {
	const loc = useLocaleContext();
	const options = useMemo(() => {
		return Object.entries(metaPixelEvents).map(([k,v]) => {
			let label = k;
			if(loc.selectedLocale === 'ru') label = v.ru;
			if(loc.selectedLocale === 'uk') label = v.ua;
			if(loc.selectedLocale === 'en') label = v.en;
			return {
				value: k,
				label
			}
		})
	}, []);

	return (
		<SelectField data={options} {...props}/>
	)
}
