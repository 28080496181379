import {ComponentType, ReactNode} from "react";

type Wrapper<P = Record<string, unknown>> = [
	Component: ComponentType<P & { children: ReactNode }>, // children обязательный
	props?: P
];

/**
 * Функция wrapComponents позволяет комбинировать несколько компонентов (провайдеров, контекстов и т.д.)
 * в единую цепочку, передавая их друг другу в нужном порядке, создавая иерархию вложенных компонентов.
 * Эта функция принимает список компонентов и их пропсов, а также начальный компонент, если нужно.
 *
 * Все компоненты и провайдеры будут обернуты друг в друга, начиная с самого нижнего уровня
 * и заканчивая на верхнем, как если бы ты вручную создавал их и передавал каждому
 * компоненты и их пропсы по цепочке.
 *
 * Функция позволяет легко создавать цепочку компонентов без необходимости вручную
 * оборачивать каждый из них в JSX.
 *
 * @param {Array} wrappers - Массив оберток, где каждая обертка представляет собой массив из двух элементов:
 *     - первый элемент: компонент, который будет обернут;
 *     - второй элемент (опционально): объект с пропсами для этого компонента.
 * @param {ReactNode} initialComponent - Начальный компонент, с которого начнется вложенность.
 *     По умолчанию равен null, если не передан.
 * @returns {ReactNode} - Результат композиции, который представляет собой вложенные компоненты,
 *     каждый из которых обернут в свой контекст или провайдер.
 *
 * Пример использования:
 *
 * const AppLoader = wrapComponents([
 *   [MantineProvider, { theme: appTheme, forceColorScheme: 'dark', withCssVariables: true }],
 *   [ModalsProvider],
 *   [UserContext],
 *   [TeamsContextProvider],
 *   [LocaleProvider],
 *   [App]
 * ]);
 */
export function wrapComponents(wrappers: Wrapper[], initialComponent: ReactNode = null): ReactNode {
	return wrappers.reduceRight(
		(child, [Component, props]) => {
			return <Component {...(props || {})}>{child}</Component>;
		},
		initialComponent
	);
}
