import {ComponentType, useState} from "react";
import {FieldTypeRendererProps} from "../WebAppsDesignEditorTab.tsx";
import {useYoProvider} from "@components/YoContextEditor";
import {ActionIcon, Button, Card, Group, SimpleGrid, Stack, Text, Title} from "@mantine/core";
import {useScopedLocale} from "../../../../../../locale";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {ReviewForm} from "../../reviewForm.tsx";
import {mongoObjectId} from "../../../../../../utils/mongoObjectId.ts";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {SimpleDragHandle} from "@components/SimpleSorting/simpleDragHandle.tsx";
import {IconBrandOpenai, IconTrash} from "@tabler/icons-react";
import {GenerateReviewsModal} from "../../generateReviewsModal.tsx";


const FieldTypeReviews: ComponentType<FieldTypeRendererProps<'reviews'>> = () => {
    const tt = useScopedLocale('webApps.view.ReviewsTab.');
    const ct = useScopedLocale('webApps.views.ContentTab.');

    const reviewsCtx = useYoProvider('reviews');
    const [isOpen, setIsOpen] = useState(false);

    async function tryAddComment() {
        const data = await yoFormModal<any>({
            title: tt('tryAddComment.title'),
            required: ['name', 'text', 'rating', 'reviewRating'],
            defaultValue: {rating: 5},
            size: 'xxl',
            body: (
                <>
                    <ReviewForm/>
                </>
            )
        });
        return reviewsCtx.push({_id: mongoObjectId(), ...data});
    }

    return (
        <div>
            <Title order={4} mb="lg">
                {ct('tabsList.reviews')}
            </Title>
            <YoContextArrayMap field={'reviews'}>
                <RenderFuncField<any>>
                    {ctx => (
                        <Stack gap="xs" mb="md">
                            <Group justify="space-between">
                                <Text>
                                    {ctx.value!.name} - {ctx.value!.rating}
                                </Text>
                                <Group align="center" gap={0}>
                                    <SimpleDragHandle/>
                                    <ActionIcon
                                        variant="subtle"
                                        c="white"
                                        onClick={ctx.bypassProps.onRemove}
                                    >
                                        <IconTrash size={'15px'} />
                                    </ActionIcon>
                                </Group>
                            </Group>
                            <Card variant="section">
                                <ReviewForm/>
                            </Card>
                        </Stack>
                    )}
                </RenderFuncField>
            </YoContextArrayMap>
            <SimpleGrid cols={{md: 2, sm: 1}}>
                <Button variant="secondary" onClick={tryAddComment} fullWidth>{tt('reviews.button.add')}</Button>
                <Button variant="secondary" onClick={() => setIsOpen(true)} fullWidth><IconBrandOpenai/>{tt('reviews.button.generate')}</Button>
            </SimpleGrid>
            <GenerateReviewsModal isOpen={isOpen} closeModal={() => setIsOpen(false)}/>
        </div>
    );
}

export default FieldTypeReviews;