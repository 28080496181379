import {useYoProvider} from "@components/YoContextEditor";
import {Popover} from "@mantine/core";
import {IconHelpCircle} from "@tabler/icons-react";
import {Translation} from "../../../locale";

export function PeriodPopoverDoc({field}: { field: string }) {
	const ctx = useYoProvider<string>(field, {});
	return (
		<Popover width={220} withArrow shadow="md">
			<Popover.Target>
				<IconHelpCircle/>
			</Popover.Target>
			<Popover.Dropdown>
				<strong>
					<Translation id={'doc.periodInput.title'}/>
				</strong>
				<ul>
					<li onClick={() => ctx.onChange('30s')}>
						<Translation id={'doc.periodInput.30s'}/>
					</li>
					<li onClick={() => ctx.onChange('15m')}>
						<Translation id={'doc.periodInput.15m'}/>
					</li>
					<li onClick={() => ctx.onChange('2h')}>
						<Translation id={'doc.periodInput.2h'}/>
					</li>
					<li onClick={() => ctx.onChange('1d')}>
						<Translation id={'doc.periodInput.1d'}/>
					</li>
					<li onClick={() => ctx.onChange('1w')}>
						<Translation id={'doc.periodInput.1w'}/>
					</li>
					<li onClick={() => ctx.onChange('1M')}>
						<Translation id={'doc.periodInput.1M'}/>
					</li>
				</ul>
			</Popover.Dropdown>
		</Popover>
	);
}
