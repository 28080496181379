import {
    TWebAppDesignConfig,
    TWebAppDesignGroupFieldConfig,
    TWebAppsDesignConfigEditorFieldKeys,
    TWebAppsDesignConfigEditorFieldsKeysMapType
} from "@shared/_data/webAppsDesignConfig/utils.ts";
import {Alert, Card, Menu, Stack, Title} from "@mantine/core";
import {ComponentType, createContext, Fragment, ReactNode, useContext, useMemo} from "react";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {IconBubble} from "@tabler/icons-react";
import {TranslateFieldMenuItem} from "@components/SmartMenuItems/translateFieldMenuItem.tsx";
import {MoveFromBaseMenuItem} from "@components/SmartMenuItems/moveFromBase.tsx";
import {useLocale} from "../../../../../locale";
import {GenerateFieldMenuItemTrigger} from "@components/SmartMenuItems/generateFieldMenuItemTrigger.tsx";
import {YoGenerateContentProvider} from "@components/YoAi/yoGenerateContentProvider.tsx";

export type FieldTypeRendererProps<
    TKey extends TWebAppsDesignConfigEditorFieldKeys = TWebAppsDesignConfigEditorFieldKeys,
    TValue extends TWebAppsDesignConfigEditorFieldsKeysMapType<TKey> = TWebAppsDesignConfigEditorFieldsKeysMapType<TKey>
> = {
    label: string;
    value: TValue | undefined;
    onChange: (value: TValue | undefined) => void;
    rightSection?: ReactNode
};

const fieldTypesModules = import.meta.glob<{
    default: ComponentType<FieldTypeRendererProps>
}>('/src/Pages/WebApps/view/contentTab/Components/FieldsTypes/**/*.*', { eager: true });

const fieldTypes = Object.entries(fieldTypesModules).reduce((acc, [path, value]) => {
    const k = path.split('/')!.at(-1)!.split('.')[0]! as TWebAppsDesignConfigEditorFieldKeys;

    acc[k] = value as any;

    return acc;
}, {} as Partial<{
    [TKey in TWebAppsDesignConfigEditorFieldKeys]: {
        default: ComponentType<FieldTypeRendererProps<TKey>>
    }
}>);

type TEditorContext = {
    translateEngine: string,
    path: string,
    globalCtx: ReturnType<typeof useYoProvider<any>>
}

const EditorContext = createContext<TEditorContext>({} as TEditorContext);

const EditorContextProvider = ({ children, path }: { children: ReactNode, path: string }) => {
    const globalCtx = useYoProvider();
    console.log('EditorContextProvider', {
        path,
        globalCtx
    })

    const value: TEditorContext = useMemo(() => ({
        translateEngine: globalCtx.value?.translateEngine || 'none',
        path,
        globalCtx
    }), [path])

    return (
        <EditorContext.Provider value={value}>
            {children}
        </EditorContext.Provider>
    )
}

export const useEditorContext = () => useContext(EditorContext);


function FieldRenderer ({
    fieldKey,
    label,
    fieldType,
    aiGenerationAvailable
}: {
    label: string,
    fieldKey: string,
    fieldType: TWebAppsDesignConfigEditorFieldKeys,
    aiGenerationAvailable?: boolean
}) {
    const FieldComponent = fieldTypes[fieldType]?.default;
    const { translateEngine, path } = useEditorContext();
    const t= useLocale();

    if (!FieldComponent) {
        return (
            <Alert color="red">
                {JSON.stringify(fieldKey)}: {JSON.stringify(fieldType)} type not supported by FieldRenderer
            </Alert>
        )
    }

    const Wrapper = aiGenerationAvailable ? YoGenerateContentProvider : Fragment;

    return (
        <Wrapper {...(aiGenerationAvailable ? { contentType: fieldKey, field: fieldKey } : {} as any)}>
            <RenderFuncField field={fieldKey}>
                {ctx => (
                    <FieldComponent
                        value={ctx.value as any}
                        onChange={(v) => ctx.onChange(v as any)}
                        label={t(label as any)}
                        rightSection={path === 'content.dataMap' && (
                            <Menu shadow="md" width={250}>
                                <Menu.Target>
                                    <IconBubble/>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <TranslateFieldMenuItem field={fieldKey} engine={translateEngine} />
                                    <MoveFromBaseMenuItem field={fieldKey} />
                                    {
                                        aiGenerationAvailable ? (
                                            <GenerateFieldMenuItemTrigger/>
                                        ) : null
                                    }
                                </Menu.Dropdown>
                            </Menu>
                        )}
                    />
                )}
            </RenderFuncField>
        </Wrapper>
    )
}

function GroupFieldRenderer (group: TWebAppDesignGroupFieldConfig & { groupKey: string }) {
    const t= useLocale();

    return (
        <div>
            <Title order={5} mb="xs">
                {t(group.label as any)}
            </Title>
            <YoContextEditor name={group.groupKey}>
                <Card variant="section" component={Stack} gap="xs">
                    {Object.entries(group.fields).map(([key, { label, type }]) => (
                        <FieldRenderer key={key} fieldKey={key} fieldType={type} label={t(label as any)}/>
                    ))}
                </Card>
            </YoContextEditor>
        </div>
    )
}

export function WebAppsDesignLocalizationEditorTab({
    webappDesignEditorConfig,
    tabKey
}: {
    webappDesignEditorConfig: TWebAppDesignConfig,
    tabKey: string,
    localeKey: string
}) {
    const fields = (webappDesignEditorConfig.localizationTabs || { description: Object.keys(webappDesignEditorConfig.localization) })[tabKey]!.map((fieldKey) => {
        return Object.entries(webappDesignEditorConfig.localization).find(([key]) => key === fieldKey);
    }).filter(Boolean);

    return (
        <EditorContextProvider path={'content.dataMap'}>
            <YoContextEditor name={'content.dataMap'}>
                <Card withBorder component={Stack} gap="xs">
                    {
                        fields.map(([key, value]) => {

                            if ('fields' in value) {
                                return (
                                    <GroupFieldRenderer
                                        key={key}
                                        groupKey={key}
                                        {...value}
                                    />
                                )
                            } else {
                                return (
                                    <FieldRenderer
                                        key={key}
                                        fieldKey={key}
                                        fieldType={value.type}
                                        label={value.label}
                                        aiGenerationAvailable={value.aiGenerationAvailable}
                                    />
                                )
                            }
                        })
                    }
                </Card>
            </YoContextEditor>
        </EditorContextProvider>
    );
}

export function WebAppsDesignSettingsEditorTab({
    webappDesignEditorConfig
}: {
    webappDesignEditorConfig: TWebAppDesignConfig
}) {
    const fields = Object.entries(webappDesignEditorConfig.additional);

    return (
        <EditorContextProvider path={'additionalDataMap'}>
            <YoContextEditor name={'additionalDataMap'}>
                <Card withBorder component={Stack} gap="lg">
                    <Title order={4}>Additional Settings</Title>
                    {
                        fields.map(([key, value]) => {
                            if ('fields' in value) {
                                return (
                                    <GroupFieldRenderer
                                        groupKey={key}
                                        key={key}
                                        {...value}
                                    />
                                )
                            } else {
                                return (
                                    <FieldRenderer key={key} fieldKey={key} fieldType={value.type} label={value.label}/>
                                )
                            }
                        })
                    }
                </Card>
            </YoContextEditor>
        </EditorContextProvider>
    );
}