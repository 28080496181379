import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button, Card, Stack} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {Translation, useLocale} from "../../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {DisplayRegistrarBalance} from "./DisplayRegistrarBalance.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";

/*MenuManager.RegisterItem({
	path: '/system/settings/admTools',
	parent: 'system',
	key: 'admSettings',
	name: 'Настройки регистратора admTools',
	nameKey: 'menuItems.system.admSettings',
	icon: <IconWorldWww/>
})*/

export function AdmToolsSettingsPage() {
	const controller = useSiteSettings('domains.adm.');
	const t = useLocale();

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: t('settings.general.savedMessage')}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={t('settings.adm.title')} items={(
				<>
					<DisplayRegistrarBalance registrar="adm" />
					<Button variant="primary" onClick={saveSettings}>
						<Translation id={'settings.general.saveBth'}/>
					</Button>
				</>
			)}/>
			<Card withBorder component={Stack}>
				<CheckboxField field="hidden" label="hidden" />
				<InputField field={'apiKey'} label={t('settings.adm.apiKey')}/>
				<NumberField field={'limitDomainPriceUAN'} label={t('settings.adm.limitDomainPriceUAN')} />
				<InputField field={'currency'} label="currency"/>
			</Card>
		</YoContextEditor>
	);
}
