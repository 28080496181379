import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class AdminUsersApiClient extends YoApiClient {
	path = '/api/v1/system/users';

	async authAsUser (userId: string) {
		await this.ky.post(`${userId}/authAsUser`);
	}
}
